export class SerialNumberRequest {
  constructor() {
    this.environment = 'UAT';
    this.partNumber = '';
    this.warranty = 'InWarranty';
    this.quantity = 1;
    this.hardwarePartNumber = '';
    this.productCode = '';
    this.serialNumberDigit = 12;
    this.requesterName = '';
    this.requesterEmail = '';
    this.countryOfOrigin = 'CN';
    this.warrantyDays = 1;
    this.productName = "";
    this.cloneSN = false;
  }
  public serialNumber: string;
  public environment: string;
  public partNumber: string;
  public warranty: string;
  public quantity: number;
  public hardwarePartNumber: string;
  public productCode: string;
  public serialNumberDigit: number;
  public requesterName: string;
  public requesterEmail: string;
  public warrantyDays: number | null;
  public countryOfOrigin: string;
  public productName: string;
  public cloneSN: boolean;
}

export class SerialNumberRequestsStatus {
  constructor() {
    this.showPreloader = false;
  }
  public serialNumberRequest: SerialNumberRequest;
  public responseList: string;
  public showPreloader: boolean;
}
