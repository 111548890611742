import { Injectable } from '@angular/core';
import { OutboundDC, ShipmentMockRequest, ShipmentMockRequestType, ShipmentParams } from '../model/shipment.model';
import * as log from '../AppInsightsLogger';
import { Constants } from './constant.service';
@Injectable({
  providedIn: 'root'
})
export class ShipmentmockerService {
  private logger: log.AppInsightsLogger;
  partnerProfile: OutboundDC;
  batchSize: number;
  doNumbers: string;
  poNumbers: string;
  mode: string;
  showLoader: boolean;
  logMessage: string = '';
  constructor() { }
  createShipmentMockRequest(numberArray: string[], isLoadMode: boolean,model:any){
    if (model.shipmentMocker.shippingType === ShipmentMockRequestType[ShipmentMockRequestType.DC]) {
      return this.createDCShipmentMockRequest(numberArray, isLoadMode,model);
    } 
    else if (model.shipmentMocker.shippingType === ShipmentMockRequestType[ShipmentMockRequestType.CM]) {
      return this.createCMShipmentMockRequest(numberArray, isLoadMode,model);
    }
    else if (model.shipmentMocker.shippingType === ShipmentMockRequestType[ShipmentMockRequestType.ARD]) {
      return this.createInboundShipmentMockRequest(numberArray, isLoadMode,model);
    }
    return null;
  }
  createCMShipmentMockRequest(numberArray: string[], isLoadMode: boolean,model:any){
    const shipmentRequest = new ShipmentMockRequest();
    shipmentRequest.mode = isLoadMode ? Constants.ShipmentModeLoad : Constants.ShipmentModeSync;
    shipmentRequest.shipmentType = 'CMShipment';
    shipmentRequest.Entities = [];
    let _entityType = 'PurchaseOrder';
    if (model.shipmentMocker.warehouseProcessType == 'DirectShip') {
      _entityType = 'CustomerPurchaseOrder';
    }

    numberArray.forEach(poNumber => {
      shipmentRequest.Entities.push(
        {
          EntityNumber: poNumber,
          EntityType: _entityType,
          LoadId: model.shipmentMocker.loadId,
          LineItems: null  // for now support mixed COO in only Sync mode
        });
    });
    const params = new ShipmentParams();
    params.IsConsolidated = false;
    let sender = model.shipmentMocker.cmpartner
    let senderName=sender.split('-')
    params.Sender=senderName[0];
    params.SenderId = senderName[1];
    let receiver = model.shipmentMocker.dcpartner
    let receiverName=receiver.split('-')
    params.Receiver = receiverName[0];
    params.ReceiverId = receiverName[1];
    params.IsConsolidated = model.shipmentMocker.consolidateOrder;
    params.ItemsPerCarton = model.shipmentMocker.cartonItems;
    params.ScacCode = model.shipmentMocker.scacCode;
    params.ProcessType = model.shipmentMocker.cmProcessType?model.shipmentMocker.cmProcessType:'';
    params.PackageScenario = model.shipmentMocker.shipmentFlavourType;
    params.PartnerId=model.shipmentMocker.partnerId?model.shipmentMocker.partnerId:'';
    params.ReceiverPartnerId=model.shipmentMocker.receiverPartnerId?model.shipmentMocker.receiverPartnerId:'';
    params.skipPostShipment = !model.shipmentMocker.postToShipment;
    if (model.shipmentMocker.cartonItems < 1) {
      params.ItemsPerCarton = 1;
    }
    shipmentRequest.shipmentParams = params;
    return shipmentRequest;
  }
  createInboundShipmentMockRequest(numberArray: string[], isLoadMode: boolean,model:any){
    const shipmentRequest = new ShipmentMockRequest();
    shipmentRequest.mode = isLoadMode ? Constants.ShipmentModeLoad : Constants.ShipmentModeSync;
    shipmentRequest.shipmentType = 'InboundShipment';
    shipmentRequest.Entities = [];
    numberArray.forEach(poNumber => {
      shipmentRequest.Entities.push(
        {
          EntityNumber: poNumber,
          EntityType: 'PurchaseOrder', 
          LoadId: model.shipmentMocker.loadId,
          LineItems: null
        });
    });
    const params = new ShipmentParams();
    // Enable Shipment Consolidation only for DC Outbound (Shipment by Delivery Order) & SYNC mode
    params.IsConsolidated = false;
    params.ProcessType = model.shipmentMocker.ardProcessType;
    params.SubProcessType = model.shipmentMocker.subProcessType;
    let sender = model.shipmentMocker.cmpartner
    let senderName=sender.split('-')
    params.SenderId = senderName[1];
    params.ScacCode = model.shipmentMocker.scacCode;
    let receiver = model.shipmentMocker.dcpartner
    let receiverName=receiver.split('-')
    params.ReceiverId = receiverName[1];
    params.skipPostShipment = !model.shipmentMocker.postToShipment;
    shipmentRequest.shipmentParams = params;
    return shipmentRequest;
  }
  createDCShipmentMockRequest(numberArray: string[], isLoadMode: boolean,model:any){
    const shipmentRequest = new ShipmentMockRequest();
    const params = new ShipmentParams();
    shipmentRequest.mode = isLoadMode ? Constants.ShipmentModeLoad : Constants.ShipmentModeSync;
    shipmentRequest.shipmentType = 'DCShipment';
    if(model.shipmentMocker.warehouseProcessType == 'asn_fsl_to_dc'){
      params.ProcessType = model.shipmentMocker.warehouseProcessType;
      params.PartnerId = model.shipmentMocker.partnerId;
    
      shipmentRequest.Entities = [];
      shipmentRequest.Entities.push(
        {
          EntityNumber: "",
          EntityType: 'DeliveryOrder',
          LoadId: model.shipmentMocker.loadId,
          LineItems: null
        });
    }
    else{
    shipmentRequest.tenantId = model.shipmentMocker.partner.TenantId;
    shipmentRequest.Entities = [];
    if(numberArray.length>0){
    numberArray.forEach(doNumber => {
      shipmentRequest.Entities.push(
        {
          EntityNumber: doNumber,
          EntityType: 'DeliveryOrder',
          LoadId: model.shipmentMocker.loadId,
          LineItems: null
        });
    });
  }
  
    params.IsConsolidated = isLoadMode ? false :model.shipmentMocker.consolidateOrder;
    params.IsSerializedScenario = isLoadMode ? false : true;
    params.FlavorType = model.shipmentMocker.shipmentFlavourType;
    params.Sender = model.shipmentMocker.partner.PartnerId;
    params.ScacCode = model.shipmentMocker.scacCode;
    params.PartnerId = model.shipmentMocker.partnerId;
    params.SerialNumber = model.shipmentMocker.serialNumber?model.shipmentMocker.serialNumber:'';
    if (params.Sender == "3040568" || params.Sender == "3057945") {
      params.ReceiverId = "3040568";
      params.ReceiverPartnerId = "4412";
    }
    if (model.shipmentMocker.warehouseProcessType.toUpperCase() == "STANDARD") {
      delete params.ProcessType;
    }
    else {
      params.ProcessType = model.shipmentMocker.warehouseProcessType;
    }
  }
  params.skipPostShipment = !model.shipmentMocker.postToShipment;
    shipmentRequest.shipmentParams = params;
    return shipmentRequest;
  }

  getBatchedArrays(array: string[], batchSize: number) {
    const batchedArrays = [];

    while (array.length > 0) {
      batchedArrays.push(array.splice(0, batchSize));
    }

    return batchedArrays;
  }
  getNumberArray(numInput: string,model:any) {
    let numberArrayUntrimmed: string[];
    let numberArrayTrimmed: string[];

    if (model.shipmentMocker.shippingMode === Constants.ShipmentModeSync && numInput!==undefined) {
      numberArrayUntrimmed = numInput.split(',');
    } else if (model.shipmentMocker.shippingMode === Constants.ShipmentModeLoad && numInput!==undefined) {
      numberArrayUntrimmed = numInput.replace(/\r/g, '').split('\n');
    }

    numberArrayTrimmed = [];
    if(numInput!=undefined){
    numberArrayUntrimmed.forEach(num => {
      if (num != null) {
        numberArrayTrimmed.push(num.trim());
      }
    });
  }
    return numberArrayTrimmed;
  }
}
