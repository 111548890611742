<div class="d-inline-flex">
    <i *ngIf="status != 'Loading'" class="dot" [ngClass]="statusDisplay.class" aria-hidden="true"></i>
    <i *ngIf="status == 'Loading'" class="spin" [ngClass]="statusDisplay.class" aria-hidden="true"></i>
    <div class="d-inline">
        <select role="listbox" aria-label="Status" title="Status" [ngModel]="status"
            (ngModelChange)="handleStatusChange($event)" [disabled]="disabled">
            <option *ngFor="let s of statusDisplayMapping | keyvalue" role="option" [value]="s.value.value"
                [selected]="status === s.value.value" [attr.aria-selected]="status === s.value.value" id="Status"
                aria-label="Status">
                {{ s.value.label }}
            </option>
        </select>
    </div>
</div>
