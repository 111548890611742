<!-- Grid -->
<div>
    <ng-container>
        <!-- <pre>{{rowGroups | json}}</pre> -->
        <!-- [attr.aria-label]="project.label" -->
        <div role="grid" [attr.aria-colcount]="displayColumns.length + 1"
            [attr.aria-rowcount]="totalRows" [id]="gridId" class="container-fluid">
            <!-- grid header -->
            <div role="row" class="row flex-row flex-nowrap heading align-items-center"
                [ngClass]=" {'row-checked': allSelected}" aria-rowindex="1" (mouseenter)="isGridHeaderHovered = true"
                (mouseleave)="isGridHeaderHovered = false">
                <div *ngIf="useCheckBoxForSelection" role="columnheader" aria-label="Select/Unselect All Tasks"
                    class="iconButton d-flex align-items-center justify-content-center">
                    <input class="ml-1" type="checkbox"
                        [ngStyle]="{opacity: allSelected || isGridHeaderHovered || alwaysShowCheckBox ? 1 : 0}" [checked]="allSelected"
                        (change)="setAllCheckboxes(!allSelected)" id="checkbox" aria-label="displayCheckbox">
                </div>
                <!-- <div role="columnheader" aria-label="Toggle Collapse Groups">
                    <button id="toggleCollapseAll" type="button" class="iconButton" title="Toggle Collapse All Groups"
                        [attr.aria-expanded]="!isCollapsedAll" (click)="toggleCollapseAll()">
                        <i class="ms-Icon"
                            [ngClass]="{'ms-Icon--ChevronDown': !isCollapsedAll, 'ms-Icon--ChevronRight': isCollapsedAll}"
                            aria-hidden="true"></i>
                    </button>
                </div> -->
                <!-- column headings -->
                <div *ngFor="let column of displayColumns" role="columnheader" [ngClass]="column.class" >
                   <span class="ms-fontWeight-semibold" style="display: flex;"> {{ column.label }}</span>
                </div>
            </div>
            <!-- grid body -->
            <!-- cdkDropList [cdkDropListData]="rowGroups" (cdkDropListDropped)="taskGroupDrop($event)" -->
            <div class="dropList">
                <!-- cdkDropListGroup -->
                <div>
                    <div *ngIf="rowGroups.length === 0" class="emptyList row" role="presentation">
                        There are no items to show
                    </div>
                    <div *ngFor="let group of rowGroups; let i = index" class="dragRow">
                        <!-- collapsible group heading -->
                        <!-- 'border border-danger': (updateRowStatuses$[group.id] | async).isError, -->
                        <div role="row" [attr.aria-rowindex]="group.startRowIndex + 1"
                            class="row flex-row flex-nowrap heading align-items-center" [ngClass]="{
                                'row-checked': group.checked
                            }"
                            [title]="hasFiltersApplied ? 'You cannot move item groups while filters are applied.' : ''"
                            (mouseenter)="group.isHovered = true" (mouseleave)="group.isHovered = false">
                            <div *ngIf="useCheckBoxForSelection" class="iconButton d-flex align-items-center justify-content-center">
                                <!-- <app-icon-control-request-status [status]="updateRowStatuses$[group.id] | async"></app-icon-control-request-status> -->
                                <input class="ml-1" type="checkbox"
                                    [ngStyle]="{opacity: displayCheckbox(group) ? 1 : 0}" [checked]="group.checked"
                                    (change)="toggleTaskGroupCheckbox(i)"
                                    [disabled]="" aria-label="displayCheckbox">   
                            </div>
                            <!-- <div role="gridcell">
                                <button type="button" class="iconButton"
                                    [attr.aria-expanded]="!isGroupCollapsed(group.id)"
                                    [attr.aria-controls]="gridId + group.key + 'Group'"
                                    [attr.title]="'Toggle Collapse Group '  + group.label"
                                    (click)="this.isGroupCollapsedMap[group.id] = !this.isGroupCollapsedMap[group.id]">
                                    <i class="ms-Icon"
                                        [ngClass]="{'ms-Icon--ChevronDown': !isGroupCollapsed(group.id), 'ms-Icon--ChevronRight': isGroupCollapsed(group.id)}"
                                        aria-hidden="true"></i>
                                </button>
                            </div> -->
                            <div role="gridcell" class="d-flex align-items-left" [ngClass]="displayColumns[0].class">
                                <span *ngIf="displayColumns[0].filterType !== 'link'"
                                    class="form-control ms-fontWeight-regular ms-fontSize-14 hideableTextarea" 
                                    style="overflow: auto;
                                    width: 100%;
                                    white-space:normal;
                                    display: inline-block;"
                                    title="{{group['stepDescription']}}">{{group[displayColumns[0].key]}}</span>
                                <span *ngIf="displayColumns[0].filterType === 'link'" 
                                    class="form-control ms-fontWeight-regular ms-fontSize-14 hideableTextarea link" 
                                    style="overflow: auto;
                                    width: 100%;
                                    white-space:normal;
                                    display: inline-block;" [title]="group[displayColumns[0].key]"
                                    (click)="getUrlParams(group)">{{group[displayColumns[0].key]}} 
                                </span>
                            </div>
                            <div role="gridcell" class="d-flex align-items-left" [ngClass]="displayColumns[1].class">
                                <textarea *ngIf="displayColumns[1].key === 'testCaseName'" readonly style="overflow:hidden" autosize rows="2" aria-label="Edit Item Group Label"
                                    title="Edit Task Group Label"
                                    class="form-control ms-fontWeight-regular ms-fontSize-14 hideableTextarea m-1"
                                    [(ngModel)]="group[displayColumns[1].key]"
                                    (blur)=" isFocusedOnTaskGroupLabel = false; updateTaskGroup(group)"
                                    (focus)="isFocusedOnTaskGroupLabel = true">
                                </textarea>
                                <app-status *ngIf="displayColumns[1].filterType === 'status'" [(status)]="group[displayColumns[1].key]" (statusChange)="updateDisplay()"></app-status>
                                <button aria-label="uploadButton" *ngIf="displayColumns[1].key === ''" type="button" class="ms-Button ms-Button--primary"
                                style="border: 1px solid;"
                                title="Execute step"
                                [disabled]="group['status'] == 'Completed' || group['status'] == 'Complete' || group['status'] == 'Error' || group['status'] == 'Pass'"
                                (click)="displayColumns[1].columnParameters.onCustomEventClicked(group)">
                                    <span class="ms-Button-textContainer">
                                        {{group['buttonText']}}
                                    </span>  
                                </button>
                            </div>
                            <ng-container *ngFor="let column of displayColumns; let i = index;">
                                <div *ngIf="i > 1" role="gridcell" [ngClass]="column.class">
                                    <div *ngIf="column.filterType === 'string' && column.key !== 'status'  && column.key !== 'validSKUs' && column.key !== 'validationStatus' && column.key !== 'currentStep'" >
                                        <textarea *ngIf="column.isEditable !== false" style="font-weight: 400; overflow:auto;" autosize rows="1" aria-label="Edit Task Group Label"
                                            title="Edit Task Group Label"
                                            class="form-control ms-fontSize-14 hideableTextarea m-1"
                                            [(ngModel)]="group[column.key]"
                                            (blur)=" isFocusedOnTaskGroupLabel = false; updateTaskGroup(group)"
                                            (focus)="isFocusedOnTaskGroupLabel = true">
                                        </textarea>
                                        <span *ngIf="column.isEditable === false"
                                            class="ms-fontWeight-regular ms-fontSize-14"
                                            style="overflow: visible;
                                            width: 100%;
                                            white-space: nowrap;
                                            text-overflow: ellipsis;
                                            display: inline-block;"
                                            title="{{group[column.key]}}"
                                        >{{group[column.key]}}</span>    
                                    </div>
                                    <div *ngIf="column.filterType === 'download-link' && group['sampleFileEnabled']" style="display: flex; justify-content: center;">
                                        <button aria-label="sampleInput" id="sampleInput" type="button" class = "ms-Button ms-Button--primary"  style="min-width: 56px; min-height: 32px; "
                                        (click)="downloadSampleInput(group['testPlanPreStepCode'])" title="Download Sample File">
                                            <i class="ms-Icon ms-Icon--Save" aria-hidden="true"></i>
                                        </button>
                                    </div>
                                    <div *ngIf="column.filterType === 'upload-link' && column.key === 'uploadLink' && group['fileUploadEnabled']" 
                                    style="display: flex; justify-content: center;">
                                        <input type="file" #fileInput id="files" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" multiple="false" style="display: none" (change)="selectFile($event)"/>
                                        <button type="button" class="ms-Button ms-Button--primary"  
                                            style="min-width: 56px; min-height: 32px;"
                                            (click)="fileInput.click()" 
                                            [disabled]=" group['fileUploadEnabled'] && group['status']  != 'Not Started'" 
                                            title="This button is disabled if you have already uploaded input for validation."
                                        >
                                        <i class="ms-Icon ms-Icon--Upload"  aria-hidden="true"></i>
                                        </button>
                                    </div>
                                    <div *ngIf="column.filterType === 'string' && column.key === 'validSKUs' && group['downloadResultEnabled']" style="display: flex; justify-content: center;">
                                        <button [disabled]="group['status'] === 'Not Started'" aria-label="downloadbutton" type="button" class="ms-Button ms-Button--primary"  style="min-width: 56px; min-height: 32px;" (click)="downloadSkus(group['testPlanPreStepCode'])"
                                        title="Download execution results">
                                         <i class="ms-Icon ms-Icon--Download"  aria-hidden="true"></i>
                                        </button>
                                    </div>
                                    <div *ngIf="column.filterType === 'string' && column.key === 'validationStatus' && group['testPlanPreStepCode']== 'CR001'" style="display: flex; justify-content: center;">
                                        <span class="ms-fontWeight-semibold">{{validationStatus}}</span>
                                    </div>
                                    <div *ngIf="column.filterType === 'string' && column.key === 'currentStep'" style="display: flex; justify-content: center;">
                                        <textarea style="overflow-y:auto; white-space:normal;" autosize rows="2" aria-label="Edit Task Group Label"
                                        title="Edit Task Group Label"
                                        class="form-control ms-fontSize-14 hideableTextarea l-1"
                                        [(ngModel)]="group[column.key]"
                                        (blur)=" isFocusedOnTaskGroupLabel = false; updateTaskGroup(group)"
                                        (focus)="isFocusedOnTaskGroupLabel = true">
                                        </textarea>
                                    </div>
                                    <div>
                                        <button aria-label="uploadButton" *ngIf="column.key === ''" type="button" class="ms-Button ms-Button--primary"
                                        style="min-width: auto; min-height: 32px;"
                                        title="Execute step"
                                        [disabled]="group['fileUploadEnabled'] && group['status'] != 'Not Started'"
                                        (click)="column.columnParameters.onCustomEventClicked(group)">
                                            <span class="ms-Button-textContainer">
                                                {{group['buttonText']}}
                                            </span>  
                                        </button>
                                    </div>
                                    <app-status *ngIf="column.filterType === 'status'"
                                        [(status)]="group[column.key]" (statusChange)="updateTask(group, column.key)" [detailLink] = "detailLink" [correlationId] = "group['correlationId']"></app-status>
                                    <app-progress *ngIf="column.filterType === 'progress'" [total] = "group['totalSteps']" [completed]="group['totalStepsCompleted']" ></app-progress>
                                    <app-date-picker *ngIf="column.filterType === 'date'" 
                                        [(date)]="group[column.key]" ariaLabel="Started On"></app-date-picker>
                                    <app-edit-parameter *ngIf="column.filterType === 'dialog-parameters'"
                                        [(jsonText)]="group[column.key]" [formlyFields] ="group['inputParameterConfiguration']" (jsonTextChange)="onParameterChange($event, group)">                                
                                    </app-edit-parameter>
                                    <app-error-display *ngIf="column.filterType === 'errorDisplay'" [errorMessage] = group[column.key]></app-error-display>
                                    <app-tracking-details *ngIf="column.filterType === 'tracking-details'" [trackingDetails] = "detailLink" [correlationId] = "group['correlationId']" [testCaseIdentifier] = "group['testCaseIdentifier']"
                                    [testPlanName]= "testPlanName" [disabled] = "group['status'] == 'Not Started' || group['status'] == 'Start'"
                                    ></app-tracking-details>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</div>
