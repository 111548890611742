import { Component, OnInit } from '@angular/core';
import { ConfigService } from '../../service/config.service';
import { AuthService } from '../../service/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MonitoringService } from '../../service/monitoring.service';
import { BaseComponent } from '../../base/base.component';
import { TestRunsFetchInputs, TestRunsChartCounts } from '../../model/manage-testruns.model';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ManageScenariosService } from '../../service/manage-scenarios.service';
import * as XLSX from 'xlsx';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TestRunInputsDetails } from '../../model/execute-testruns.model';
import { MasterDataService } from '../../service/masterdata.service';
import { DescriptionService } from '../../service/description.service';
import { SampleFilesService } from '../../service/samplefiles.service';
import { LandingPageService } from '../../service/landingpage.service';
import { LandingPageResponse, WarningBoxDetail } from '../../model/landingpage.model';
//import { AppInsightsService } from '@markpieszak/ng-application-insights';
import { OrgViewService } from 'src/app/service/org-view.service';
import * as log from '../../AppInsightsLogger';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { FeedbackComponent } from '../../feedback/feedback.component';
import { element } from 'protractor';
import { Organization } from 'src/app/model/org-view.model';
import { Page } from '../../model/masterdata.model';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType, AuthenticationResult, InteractionStatus } from '@azure/msal-browser';
import { filter } from 'rxjs/operators';


@Component({
  selector: 'app-org-view-landing-page',
  templateUrl: './org-view-landing-page.html',
  styleUrls: ['./org-view-landing-page.component.css'],
  providers: [FeedbackComponent]
})
export class OrgViewLandingPageComponent extends BaseComponent implements OnInit {

  name: string;
  email: string;
  alias: string;
  isDevMode: boolean;
  isNotice: boolean;
  warningResponse: WarningBoxDetail[];
  landingPageResponse: LandingPageResponse;
  page: string;
  private logger: log.AppInsightsLogger;
  message1: string;
  message2: string;
  is500error: boolean = false;
  organizations: Organization[] = [];
  orgSelected : Organization;
  organizationsLoaded: boolean = false;
  glossaryList: any[];
  organizationDescription: string;


  constructor(private configService: ConfigService,
    private authService: AuthService,
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private route: ActivatedRoute,
    private monitoringService: MonitoringService,
    private router: Router,
    private ngxLoader: NgxUiLoaderService,
    private modalService: NgbModal,
    private manageTestRunsService: ManageScenariosService,
    public feedbackComponent: FeedbackComponent,
    private masterDataService: MasterDataService,
    private descriptionService: DescriptionService,
    private landingPageService: LandingPageService,
    private orgViewService: OrgViewService) {
    super(monitoringService);
    this.name = this.msalService.instance.getActiveAccount().name;
    this.email  = this.msalService.instance.getActiveAccount().username;
    if (window.location.origin.toLowerCase().indexOf('-test') > 0 || window.location.origin.toLowerCase().indexOf('-dev') > 0 || window.location.origin.toLowerCase().indexOf('-sit') > 0) {
      this.isDevMode = true;
    }
    else {
      this.isDevMode = false;
    }


    this.logger = new log.AppInsightsLogger(configService, msalService);
 }

  ngOnInit() {
    this.manageTestRunsService.ngxLoaderConfig.text = 'Welcome to SCE Test Platform'
    this.ngxLoader.start();
    this.loadGlossary();
    this.isAuthenticated();
    if (!this.authService.isAuthenticatedWithRoles()) {
      this.router.navigate(['/', 'unauthorized']);
    }
    this.getOrganizations(); 
    this.logger.trackTrace("Successfully landed to Org Landing Page");

    this.landingPageResponse = new LandingPageResponse();
    this.landingPageService.getLandingPageExecution().subscribe((response) => {
      if(response != null){
      this.landingPageResponse = response;
      this.warningResponse = this.landingPageResponse.response;
      }
    }, (error:any) =>{
        if(error.status == 500){
          this.is500error = true; 
        }
    });
   
    window.sessionStorage.setItem("inOrgLanding", "true"); 
  }


  loadGlossary() {
    this.descriptionService.getGlossary().subscribe((response: Page[]) => {
      if (response != null) {
        this.glossaryList = response;
      }
      for (var i = 0; i < this.glossaryList.length; i++) {
        if (this.glossaryList[i].name == "Organization") {
          this.organizationDescription = this.glossaryList[i].description;
        }
      }
    }, (error: any) => {
      if (error.status == 500) {
        this.is500error = true;
      }
      this.logger.trackTrace(`In Organization, load Glossary Details Error`);
      this.logger.trackException(error);
      this.ngxLoader.stop();
    });
  }

  isNotification(): boolean {
    return this.configService.isNotification();
  }

  isEnabled(): boolean {
    return this.authService.isEnabled();
  }

  isOrderViewEnabled(): boolean {
    return this.authService.isOrderViewEnabled();
  }

  reloadPage() {
    window.location.reload();
  }

  isAuthenticated() {
    return this.authService.isUserAuthenticated()
  }

  getOrganizations(){
    try{
      this.organizations = this.configService.organizations;
      this.organizationsLoaded = true;
      this.ngxLoader.stop();
    }
    catch(Error) {
        this.logger.trackTrace(`Get Organizations Some error occured.`);
        this.logger.trackException(Error);
        this.organizationsLoaded = false;
        this.ngxLoader.stop();
    }
  }

  setOrganization(orgSelected: Organization){
    this.orgSelected = orgSelected;
    window.sessionStorage.setItem("orgSelected", this.orgSelected.organizationId.toString());
  }
}
