<div style="width: 100%; height: calc(100vh - 46px); ">
  <h3>SAP Trigger Output</h3>
  <br>
  <div class="form-group col-sm-10" style="margin-bottom: 25px; border: 1px solid rgba(0,0,0,.125); border-radius: .25rem;">
    <b>Description:</b>
    <p>{{this.pageDescription}}</p>
  </div>
  <form class="form-horizontal" (ngSubmit)="onSubmitClick()" #outputTriggerForm="ngForm">
    <div class="form-group">
      <label class="control-label col-sm-2" for="mode">SAP Output Type</label>
      <div class="col-sm-8">
        <select aria-label="sapOutputType"  class="form-control" id="type" name="sapOutputType" [(ngModel)]="sapOutputType" required #sapOutputTypeField="ngModel">
          <option value="POOutputToSupplier">PO Output to Supplier via Ariba</option>
          <option value="POAcknowledgement">PO Acknowledgement</option>
          <option value="DCInboundDelivery">Inbound Delivery - DC</option>
          <option value="TMCInboundDelivery">Inbound Delivery - TMC</option>
          <option value="DCOutboundDelivery">Outbound Delivery - DC</option>
          <option value="TMCOutboundDelivery">Outbound Delivery - TMC</option>
        </select>
        <div [hidden]="sapOutputTypeField.valid || sapOutputTypeField.pristine" class="alert alert-danger">SAP Output Type is required</div>
      </div>
    </div>
    <div class="form-group">
      <label class="control-label col-sm-2" for="entityNumbers"><b>DO /PO Numbers</b></label>
      <div class="col-sm-8">
        <input type="text" class="form-control" autocomplete="off" spellcheck="false"
               placeholder="Enter DO/PO number(s). Multiple POs/DOs are separated by ',' (Up to 10 DO's/PO's could be added)" id="entityNumbers" name="entityNumbers"
               [(ngModel)]="entityNumbers" required #entityNumbersField="ngModel" />
        <div [hidden]="entityNumbersField.valid || entityNumbersField.pristine" class="alert alert-danger">
          DO/PO Number field
          is required
        </div>
      </div>
    </div>
    <br>
    <div class="form-group">
      <div class="col-sm-offset-2 col-sm-8">
        <div class="input-group">
          <button type="submit" class="btn btn-primary"
                  [disabled]="!outputTriggerForm.form.valid || showLoader">
            Submit
          </button>
          <app-preloader-small *ngIf="showLoader"></app-preloader-small>
        </div>
      </div>
    </div>
    <br>
    <div class="form-group">
      <div class="input-group">
        <label class="control-label col-sm-1" for="logs">Logs</label><a [routerLink]="" (click)="clearLogs()"> Clear</a>
      </div>
      <div class="col-sm-offset-2 col-sm-10">
        <textarea aria-label="logs" rows="8" class="form-control logmessage-smallfont" autocomplete="off" spellcheck="false" name="logs"
                  [(ngModel)]="logMessage" readonly></textarea>
      </div>
    </div>
  </form>
</div>
