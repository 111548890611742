import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil, startWith, tap, filter, map } from 'rxjs/operators';
import { FormlyFieldConfig, FormlyFormOptions, Field } from '@ngx-formly/core';
import { ScenariosInputsModel } from '../../../model/execute-testruns.model';
import { MasterDataService } from '../../../service/masterdata.service';


@Component({
    selector: 'app-testruns-panel',
    templateUrl: './testruns-input-panel.component.html'
})

export class TestRunsInputsPanelComponent implements OnInit, OnDestroy {
    onDestroy$ = new Subject<void>();
    form = new FormGroup({});
    options: FormlyFormOptions = {};
    @Input() isEditMode = false;
    @Input() fields: FormlyFieldConfig[] = [];
    @Input() model: ScenariosInputsModel;
    @Input() formState: any = {};

    @Output() add = new EventEmitter();
    @Output() update = new EventEmitter();
    @Output() cancel = new EventEmitter();
    isLoading = false;

    constructor(private masterDataService: MasterDataService) {
    }

    ngOnInit() {
        if (this.fields.filter(x => x.type == 'repeat').length > 0) {
            const fieldKey = this.fields.filter(x => x.type == 'repeat')[0].parent;
            this.fields.filter(x => x.key == fieldKey)[0].hooks = {
                onInit: (field) => {
                    field.formControl.valueChanges.pipe(
                        takeUntil(this.onDestroy$),
                        startWith(field.formControl.value),
                        filter(v => v > 0),
                        tap(value => {
                            this.model[this.fields.filter(x => x.type == 'repeat')[0].key.toString()].length = value;
                            this.model = {
                                ...this.model
                            };
                            this.model[this.fields.filter(x => x.key == fieldKey)[0].key.toString()] = value;
                            this.options.formState.mainModel = this.model;
                        }),
                    ).subscribe();
                }
            };
        }
        if (this.fields.filter(x => x['isUpperCase'] == true).length > 0) {
            this.fields.filter(x => x['isUpperCase'] == true).forEach((element: FormlyFieldConfig) => {
                element.hooks = {
                    onInit: (field) => {
                        field.formControl.valueChanges.pipe(
                            takeUntil(this.onDestroy$),
                            startWith(field.formControl.value),
                            tap(value => {
                                this.model[this.fields.filter(x => x.key == element.key)[0].key.toString()] = value.toUpperCase();
                                this.model = {
                                    ...this.model
                                };
                            })
                        ).subscribe();
                    }
                }
            });
        }
        this.options.formState = { ...this.formState };
        this.options.formState.mainModel = this.model;
        this.fields = this.fields.map(f => {
            if (f.templateOptions && f.templateOptions.changeExpr) {
                f.templateOptions.change = Function('field', f.templateOptions.changeExpr).bind(this);
            }
            return f;
        });
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    resetInputControls() {
        this.model = new ScenariosInputsModel();
        this.fields = [];
    }

    addScenarios(model: any) {
        if (this.isEditMode) {
            this.update.emit(model);
        } else {
            this.add.emit(model);
        }
    }

    cancelScenarioUpdate() {
        this.cancel.emit();
    }

    //Identify for which scenario ReadMe is enabled.
    //TODO: Abel Mengistu (1/19/2022), The logic for maintaining for which scenarios readme is enabled along with readmeLink will be driven by configurations after reviewing.  
    isReadMeEnabled(scenarioName: any) {
        if (scenarioName != null && scenarioName.includes("Assemble to Order")) {
            return true;
        } else {
            return false;
        }
    }
}
