import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OrderSummary } from '../model/order-summary.model';
import { OrderList } from '../model/order-list.model';
import { OrderListRequest } from '../model/order-list-request.model';
import { ConfigService } from './config.service';

@Injectable()
export class OrderService {

    constructor(private httpClient: HttpClient, private configService: ConfigService) {
    }

    getOrderSummary(customerPurchaseOrder: string) {
        const baseUrlWithVersion = this.configService.omtestSuiteAPI.baseUrl + '/api/' + this.configService.omtestSuiteAPI.apiVersion;
        return this.httpClient.get<OrderSummary>(baseUrlWithVersion + '/order?customerPurchaseOrder=' + customerPurchaseOrder);
    }

    getOrdersList(orderListRequest: OrderListRequest) {
        const baseUrlWithVersion = this.configService.omtestSuiteAPI.baseUrl + '/api/' + this.configService.omtestSuiteAPI.apiVersion;
        return this.httpClient.post<OrderList>(baseUrlWithVersion + '/order/list', orderListRequest);
    }

    getOrderStatus(salesOrderNumber: string) {
        const baseUrlWithVersion = this.configService.omtestSuiteAPI.baseUrl + '/api/' + this.configService.omtestSuiteAPI.apiVersion;
        return this.httpClient.get<string>(baseUrlWithVersion + '/order/' + salesOrderNumber + '/status');
    }

    getOrderConfirmationByCorrelationId(correlationId: string) {
        const baseUrlWithVersion = this.configService.omtestSuiteAPI.baseUrl + '/api/' + this.configService.omtestSuiteAPI.apiVersion;
        return this.httpClient.get<string>(baseUrlWithVersion + '/payload/orderconfirmation?correlationId=' + correlationId);
    }

    getDeliveryOrderByCorrelationId(correlationId: string) {
        const baseUrlWithVersion = this.configService.omtestSuiteAPI.baseUrl + '/api/' + this.configService.omtestSuiteAPI.apiVersion;
        return this.httpClient.get<string>(baseUrlWithVersion + '/payload/deliveryorder?correlationId=' + correlationId);
    }

    getLoadPlanByCorrelationId(correlationId: string) {
        const baseUrlWithVersion = this.configService.omtestSuiteAPI.baseUrl + '/api/' + this.configService.omtestSuiteAPI.apiVersion;
        return this.httpClient.get<string>(baseUrlWithVersion + '/payload/loadplan?correlationId=' + correlationId);
    }
    getShipmentByCorrelationId(correlationId: string) {
        const baseUrlWithVersion = this.configService.omtestSuiteAPI.baseUrl + '/api/' + this.configService.omtestSuiteAPI.apiVersion;
        return this.httpClient.get<string>(baseUrlWithVersion + '/payload/shipment?correlationId=' + correlationId);
    }
}
