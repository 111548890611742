import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TrackingEntitiesResponse } from '../model/entitytracking.model';
import { ConfigService } from './config.service';

@Injectable()
export class EntityTrackingService {

  constructor(private httpClient: HttpClient, private configService: ConfigService) {
  }

  getEntityTracking(entityType: string, entityNumbers: string[]) {
    const baseUrlWithVersion = this.configService.omtestSuiteAPI.baseUrl + '/api/' + this.configService.omtestSuiteAPI.apiVersion;
    return this.httpClient.post<TrackingEntitiesResponse>(baseUrlWithVersion + '/order/TrackEntities', { EntityType: entityType, EntityIDs: entityNumbers });
  }

  getEntityTrackingInChunks(entityType: string, entityNumbers: string[], chunkIdx: number){
    const baseUrlWithVersion = this.configService.omtestSuiteAPI.baseUrl + '/api/' + this.configService.omtestSuiteAPI.apiVersion;
    return this.httpClient.post<TrackingEntitiesResponse>(
        baseUrlWithVersion + '/order/TrackEntities', 
        { 
          EntityType: entityType, 
          EntityIDs: entityNumbers,
          PartialQuerySection: chunkIdx 
        });   
  }
}
