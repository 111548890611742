<ng-sidebar-container style="height: calc(100vh - 100px);">
  <ng-sidebar [(opened)]="sidebarOpened" position="right">
    <div class="row no-margin border-radius-5 back-color-white full-height overflow-hidden shipment_mocker__mixed_coo_sidebar" style="width: 800px; border: 1px solid #ADADAD; overflow-y: auto; margin-right: 2px !important;">
      <div class="col-lg-12 no-padding" style="background-color: rgb(229, 229, 229); width:450px ">
        <div class="row no-margin padding-5" style="background-color: #676767; color: #FFFFFF;">
          <div class="col-lg-11 no-padding text-left bold">
            <h6>Item Details</h6>
          </div>
          <br>
          <div role="button" class="col-lg-1 no-padding text-right" style="line-height: 15px;">
            <span class="font-28 bold cursor-pointer" (click)="toggleSidebar()">
              &times;
            </span>
          </div>
        </div>

        <div *ngIf="!areMasterPacksLoaded()"
             aria-describedby="MessageBar322" role="region"
             style="background-color: rgb(255, 244, 206); padding: 5px 10px; margin: 15px 10px;">
          <i class="fa fa-exclamation-circle text-center"></i>
          <span style="padding-left:10px; ">
            Warning: MasterPack Quantity (Units/MP) can not be loaded for some Line Items. Please provide the quantity in the field <b>Units/MP</b> below.
          </span>
        </div>

        <div>
          <table>
            <thead>
              <tr>
                <th>Mixed</th>
                <th>Line Item #</th>
                <th>Material</th>
                <th>Qty</th>
                <th>Plant</th>
                <th>Units/MP</th>
                <th># of MPs</th>
                <th>Estimate Split Qty</th>
              </tr>
            </thead>
            <tbody *ngFor="let itemDetail of itemDetails; let i = index;">
              <tr>
                <td><input type="checkbox" id="{{'checkbox'+i}}" [(ngModel)]="checkBox[i]"></td>
                <td>{{itemDetail.lineNum}}</td>
                <td>{{itemDetail.material}}</td>
                <td>{{itemDetail.qty}}</td>
                <td>{{itemDetail.plant}}</td>
                <td>
                  <ng-container *ngIf="!itemDetail.isMasterPackLoaded">
                    <input class="form-group" style="width: 100%; height: 100%; text-align: center; margin-bottom: 0px;"
                           type="number" min="1" max="10000"
                           required [(ngModel)]="itemDetail.unitsPerMP">
                  </ng-container>
                  <ng-container *ngIf="itemDetail.isMasterPackLoaded">
                    {{itemDetail.unitsPerMP}}
                  </ng-container>
                </td>
                <td>{{calculateTotalMasterPacks(i)}}</td>
                <td></td>
              </tr>

              <tr id="{{'firstRow'+i}}" [hidden]="!checkBox[i]">
                <td></td>
                <td style="vertical-align: central;">1st COO:</td>
                <td style="text-align: center;">
                  <select class="form-group"
                          style="text-align: center; align-content: center; width: 100%; height: 100%; margin-bottom: 0px; "
                          id="{{'firstCoo'+i}}" required [(ngModel)]="firstCOO[i]">

                    <option *ngFor="let code of countryCodes" [value]="code">
                      {{code}}
                    </option>
                  </select>
                </td>
                <td>% Split:</td>
                <td style="text-align: center; ">
                  <input class="form-group" style="width: 100%; height: 100%; text-align: center; margin-bottom: 0px;"
                         type="number" min="0" max="100" id="{{'firstSplitPercent'+i}}" required
                         [(ngModel)]="firstSplitPercent[i]" (ngModelChange)="changeSecondSplit($event, i)">
                </td>
                <td></td>
                <td></td>
                <td>{{calculateFirstCooEstimatedQuantity(firstSplitPercent, i)}}</td>
              </tr>

              <tr id="{{'secondRow'+i}}" [hidden]="!checkBox[i]">
                <td></td>
                <td>2nd COO:</td>
                <td>
                  <select class="form-group" style="width: 100%; height: 100%; text-align: center; margin-bottom: 0px;"
                          id="{{'secondCoo'+i}}" required [(ngModel)]="secondCOO[i]">
                    <option *ngFor="let code of countryCodes" [value]="code">
                      {{code}}
                    </option>
                  </select>
                </td>
                <td>% Split:</td>
                <td>
                  <input class="form-group" style="width: 100%; height: 100%; text-align: center; margin-bottom: 0px;"
                         type="number" min="0" max="100" id="{{'secondSplitPercent'+i}}" required
                         [(ngModel)]="secondSplitPercent[i]" (ngModelChange)="changeFirstSplit($event, i)">
                </td>
                <td></td>
                <td></td>
                <td>{{calculateSecondCooEstimatedQuantity(secondSplitPercent, i)}}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div style="position:absolute; left:50%; top:50%;">
          <app-preloader-small [hidden]="itemDetails.length > 0"></app-preloader-small>
        </div>
        <label style="color:red; position: absolute; bottom: 0; left: 0; margin-bottom: 15px; margin-left: 15px;">Make sure to save your changes!</label>
        <div style="position: absolute; bottom: 0; right: 0; margin-bottom: 15px; margin-right: 15px;">
          <button type="button" class="btn btn-primary" [disabled]="!validateMixedCOOState()" (click)="submitSidebarState()">Save</button>
        </div>
      </div>
    </div>
  </ng-sidebar>


  <div ng-sidebar-content style="width: 100%; height: calc(100vh - 46px); ">
    <h3>Shipment Mocker</h3>
    <br>
    <div class="form-group col-sm-10" style="margin-bottom: 25px; border: 1px solid rgba(0,0,0,.125); border-radius: .25rem;">
      <b>Description:</b>
      <p>{{this.pageDescription}}</p>
    </div>
    <form class="form-horizontal" (ngSubmit)="onPostShipmentClick()" #shipmentForm="ngForm">
      <div class="form-group">
        <label class="control-label col-sm-2" for="type">Type</label>
        <div class="col-sm-10">
          <select aria-describedby="dropdownForType" class="form-control" id="type" name="type" [(ngModel)]="type" (change)="clearSidebarState()" required #typeField="ngModel" (ngModelChange)='onTypeChange($event)'>
            <option *ngFor="let p of typeList" [value]="p.value">{{p.label}}</option>
          </select>
          <div [hidden]="typeField.valid || typeField.pristine" class="alert alert-danger">Type is required</div>
        </div>
      </div>
      <div class="form-group">
        <label class="control-label col-sm-2" for="mode">Mode</label>
        <div class="col-sm-10">
          <select araia-describedby="dropdownForMode" class="form-control" id="mode" name="mode" [(ngModel)]="mode" required #modeField="ngModel" (ngModelChange)='onModeChange($event)'>
            <option value="SYNC">SYNC</option>
            <option value="LOAD">LOAD</option>
          </select>
          <div [hidden]="modeField.valid || modeField.pristine" class="alert alert-danger">Mode is required</div>
        </div>
      </div>
      <div class="form-group" *ngIf="MockRequestType[type] === MockRequestType[MockRequestType.DC]">
        <label class="control-label col-sm-4" for="processType_dm">
          Warehouse Process Type
        </label>
        <div class="col-sm-10">
          <select class="form-control" id="processType_dm" name="processType_dm" [(ngModel)]="processType" required #processTypeField="ngModel" (ngModelChange)='onProcessTypeChange($event)'>
            <option *ngFor="let t of dcProcessTypeList" [value]="t.value">{{t.label}}</option>
          </select>
        </div>
      </div>
      <div id="dropdownForCMProcessType" class="form-group" *ngIf="MockRequestType[type] === MockRequestType[MockRequestType.CM]">
        <label class="control-label col-sm-4" for="processType_cm">
          CM Process Type
        </label>
        <div class="col-sm-10">
          <select araia-describedby="dropdownForCMProcessType" class="form-control" id="processType_cm" name="processType_cm" [(ngModel)]="processType" required #processTypeField="ngModel" (ngModelChange)='onProcessTypeChange($event)'>
            <option *ngFor="let t of cmProcessTypeList" [value]="t.value">{{t.label}}</option>
          </select>
          <div [hidden]="processTypeField.valid || processTypeField.pristine" class="alert alert-danger">ProcessType is required</div>
        </div>
      </div>
      <div class="form-group" *ngIf="MockRequestType[type] != MockRequestType[MockRequestType.ARD]">
        <ng-template #flavorTypeDescription>
          Full Pallet - A pallet placed with cartons to its max capacity<br>
          Partial Pallet - A pallet not placed with cartons to its max capacity<br>
          Mixed Pallet - A pallet contains products of different SKUs<br>
          Loose Load/Loose Carton - No pallet, only cartons loosely shipped altogether
        </ng-template>
        <label class="control-label col-sm-4" for="flavorType-tool-tip">
          <button aria-label="flavorType-tool-tip" aria-describedby="flavorType-tool-tip" type="button" id="flavorType-tool-tip" class="tool-tip-button" style="margin-left: 0px;" [ngbTooltip]="flavorTypeDescription" tooltipClass="flavorTypeWideTooltip" placement="right">
            <span style="margin-right:10px">Shipment Flavor Type</span><i class="fa fa-question-circle text-center tool-tip-icon"></i>
          </button>
        </label>
        <div class="col-sm-10">
          <select aria-label="flavorType" aria-describedby="flavorType" class="form-control" id="flavorType" name="flavorType" [(ngModel)]="flavorType" required #flavorTypeField="ngModel">
            <option *ngFor="let t of shipmentFlavorTypeList" [value]="t.value">{{t.label}}</option>
          </select>
          <div [hidden]="flavorTypeField.valid || flavorTypeField.pristine" class="alert alert-danger">FlavorType is required</div>
        </div>
      </div>
      <div class="form-group" *ngIf="MockRequestType[type] === MockRequestType[MockRequestType.ARD]">
        <label class="control-label col-sm-4" for="processType">
          ARD Process Type
        </label>
        <div class="col-sm-10">
          <select class="form-control" id="processType" name="processType" [(ngModel)]="processType" required #processTypeField="ngModel">
            <option *ngFor="let t of ardProcessTypeList" [value]="t.value">{{t.label}}</option>
          </select>
          <div [hidden]="processTypeField.valid || processTypeField.pristine" class="alert alert-danger">ProcessType is required</div>
        </div>
      </div>
      <div class="form-group" *ngIf="MockRequestType[type] === MockRequestType[MockRequestType.ARD]">
        <label class="control-label col-sm-4" for="subProcessType">
          Sub Process
        </label>
        <div class="col-sm-10">
          <select class="form-control" id="subProcessType" name="subProcessType" [(ngModel)]="subProcessType" required #subProcessTypeField="ngModel">
            <option *ngFor="let t of ardSubProcessTypeList" [value]="t.value">{{t.label}}</option>
          </select>
          <div [hidden]="subProcessTypeField.valid || subProcessTypeField.pristine" class="alert alert-danger">subProcessType is required</div>
        </div>
      </div>
      <div class="form-group" *ngIf="MockRequestType[type] === MockRequestType[MockRequestType.DC]">
        <label class="control-label col-sm-3" for="doNumbers">Delivery Order Number(s)</label>
        <div class="col-sm-10">
          <input type="text" [hidden]="mode != 'SYNC'" [disabled]="this.sidebarOpened" class="form-control" autocomplete="off" spellcheck="false"
                 placeholder="Enter DO number(s) - Multiple DOs are separated by &quot;,&quot;" id="doNumbers" name="doNumbers"
                 [(ngModel)]="doNumbers" required #doNumberField="ngModel"
                 (change)="handleDoNumberChange(doNumberField)" />
          <div [hidden]="mode != 'SYNC' || doNumberField.valid || doNumberField.pristine || this.sidebarOpened" class="alert alert-danger">
            <a *ngIf="this.doNumbersValid && this.doNumbers.length == 0">
              Delivery Order Numbers field is required
            </a>
            <a *ngIf="!this.doNumbersValid && this.doNumbers != null && !this.sidebarOpened">
              Maximum of 10 orders in 1 submission
            </a>
          </div>
          <div *ngIf="mode == 'SYNC'" style="margin-top: 12px;">
            <input class="m-1" type="checkbox" id="is-consolidated" name="is-consolidated"
                   [(ngModel)]="isConsolidated" [disabled]="mode != 'SYNC'" (change)="handleConsolidation(doNumberField)">
            <label class="m-1" for="is-consolidated">

              <button style="margin-left:0px" type="button" id="consolidate-tool-tip" class="tool-tip-button"
                      ngbTooltip="Consolidate up to 10 delivery orders into a single-shipment - for DC Outbound testing in SYNC mode." placement="right">
                <span style="margin-right:10px">Consolidate Orders into Single Shipment</span> <i class="fa fa-question-circle text-center tool-tip-icon"></i>
              </button>
            </label>
            <ngb-alert *ngIf="consolidationAlert" (close)="consolidationAlert = false">
              <b>Please verify that the DeliveryOrders to be consolidated have the same values for the following fields:</b>
              <br>(1) ShipFrom Address (i.e. Sender of shipment)
              <br>(2) ShipTo Address (i.e. Receiver of shipment)
              <br>(3) Shipment Date (i.e. DeliveryByDate or CustomerRequestedDeliveryDate fields)
            </ngb-alert>
          </div>
          <div *ngIf="mode == 'SYNC' && processType == 'WTP_FORWARD_RSD_DC'" style="margin-top: 12px;">
            <input class="m-1" type="checkbox" id="is-serialized" name="is-serialized"
                   [(ngModel)]="isSerialized" [disabled]="mode != 'SYNC'" (change)="handleSerialized()">
            <label class="m-1" for="is-serialized">

              <button style="margin-left:0px" type="button" id="serialized-tool-tip" class="tool-tip-button"
                      ngbTooltip="This randomly generates SN for WTP Scenarios of DC Outbound testing in SYNC mode." placement="right">
                <span style="margin-right:10px">Is Serialized Cloud Scenario</span> <i class="fa fa-question-circle text-center tool-tip-icon"></i>
              </button>
            </label>
          </div>
          <input *ngIf="mode == 'LOAD'" type='file' accept='text/plain' multiple
                 (change)='openFile($event, MockRequestType.DC)' required>
          <button type="button" class="btn btn-info float-right cursor-pointer"
                  (click)="downloadSampleFile(MockRequestType.DC)" *ngIf="mode == 'LOAD'"
                  style="color: #ffffff; background-color: #929292; border-color: #929292;">
            <i class="fa fa-file-text-o"></i>
            Download Sample
          </button>
        </div>
      </div>
      <div class="form-group" *ngIf="MockRequestType[type] === MockRequestType[MockRequestType.DC]">
        <label class="control-label col-sm-2" for="partnerProfile">Partner</label>
        <div class="col-sm-10">
          <select class="form-control" id="partnerProfile" name="partnerProfile" [(ngModel)]="partnerProfile" required
                  #partnerProfileField="ngModel">
            <option *ngFor="let partner of dcOutboundPartnerList | filter: filterDCOutboundPartners" [ngValue]="partner">{{partner.PartnerId}}</option>
            <!-- <option value="8000000001">ARVATO-PERF</option> -->
            <!-- <option value="9000000013">ARVATO</option> -->
          </select>

          <label style="color: red;" [hidden]="!doError">&nbsp;&nbsp; Invalid Delivery Order Number(s)</label>
          <div [hidden]="partnerProfileField.valid || partnerProfileField.pristine" class="alert alert-danger">
            Partner
            profile is required
          </div>
        </div>
      </div>
      <div class="form-group" *ngIf="MockRequestType[type] !== MockRequestType[MockRequestType.DC]">
        <div *ngIf="(MockRequestType[type] == MockRequestType[MockRequestType.ARD]) || (MockRequestType[type] == MockRequestType[MockRequestType.CM] && processType !=='DirectShip')">
          <label class="control-label col-sm-8" for="poNumbers">
            Purchase Order Number(s)
            <button type="button" id="po-tool-tip" class="tool-tip-button"
                    ngbTooltip="Currently supports Z4DD/Z4VP" aria-label="po-tool-tip"
                    [closeDelay]="1000" triggers="click" placement="right">
              <i class="fa fa-question-circle text-center tool-tip-icon"></i>
            </button>
          </label>
        </div>
        <div *ngIf="(MockRequestType[type] == MockRequestType[MockRequestType.CM] && processType =='DirectShip')">
          <label class="control-label col-sm-8" for="poNumbers">
            Customer Purchase Order Number
          </label>

        </div>
        <div class="col-sm-10">
          <input type="text" [hidden]="mode != 'SYNC'" [disabled]="this.sidebarOpened" class="form-control" autocomplete="off" spellcheck="false"
                 placeholder="Enter PO number(s). Multiple POs are separated by &quot;,&quot;." id="poNumbers" name="poNumbers"
                 [(ngModel)]="poNumbers" required #poNumberField="ngModel" (change)="handlePONumberChange(poNumberField)" />
          <div [hidden]="poNumberField.valid || poNumberField.pristine || this.sidebarOpened" class="alert alert-danger">
            <a *ngIf="this.poNumbersValid && this.poNumbers != null">
              Purchase orders field
              is required
            </a>
            <a *ngIf="!this.poNumbersValid && this.poNumbers != null && !this.sidebarOpened">
              Maximum of 10 orders in 1 submission
            </a>
          </div>

          <div>
            <button *ngIf="MockRequestType[type] == MockRequestType[MockRequestType.CM] && mode == 'SYNC'"
                    style="margin-top: 12px;" type="button" class="btn btn-primary" id="is-accepted" name="is-accepted"
                    [disabled]="this.total <= 0 || this.total > 1" (click)="toggleSidebar()">
              Edit Mixed COO
            </button>
            <div class="alert alert-danger" [hidden]="!poError" style="margin-top: 10px;">Invalid Order Id(s)</div>
          </div>

          <div *ngIf="mode == 'SYNC' && (MockRequestType[type] == MockRequestType[MockRequestType.CM] )" style="margin-top: 12px;">
            <input class="m-1" type="checkbox" id="is-consolidatedCM" name="is-consolidatedCM"
                   [(ngModel)]="isConsolidated" [disabled]="mode != 'SYNC'" (change)="handlePONumberChange(poNumberField)">
            <label class="m-1" for="is-consolidatedCM">Consolidate Orders into Single Shipment</label>
            <button type="button" id="consolidate-tool-tip" class="tool-tip-button" aria-label="consolidate-tool-tip"
                    ngbTooltip="Consolidate up to 10 customer Purchase orders into a single-shipment in SYNC mode." placement="right">
              <i class="fa fa-question-circle text-center tool-tip-icon"></i>
            </button>
          </div>
          <input *ngIf="mode == 'LOAD'" type='file' accept='text/plain' multiple
                 (change)='openFile($event, MockRequestType.CM)' required>
          <button type="button" class="btn btn-info float-right cursor-pointer"
                  (click)="downloadSampleFile(MockRequestType.CM)" *ngIf="mode == 'LOAD'"
                  style="color: #ffffff; background-color: #929292; border-color: #929292;">
            <i class="fa fa-file-text-o"></i>
            Download Sample
          </button>
          <div>
            <br *ngIf="mode == 'LOAD'">
            <div *ngIf="(MockRequestType[type] == MockRequestType[MockRequestType.ARD]) || (MockRequestType[type] == MockRequestType[MockRequestType.CM] && processType !=='DirectShip')">
              <ngb-alert role="alert" *ngIf="!newPOAlertClosed" (close)="newPOAlertClosed = true" type="info">If POs are newly created, please submit the shipment a couple of hours later.</ngb-alert>
            </div>
            <!--<div *ngIf="(MockRequestType[type] == MockRequestType[MockRequestType.CM] && processType =='DirectShip')">
      <ngb-alert type="info">Currently DirectShip CMs Supports only one PO at a time.</ngb-alert>
    </div>-->
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="control-label col-sm-2" for="loadId" *ngIf="MockRequestType[type] != MockRequestType[MockRequestType.ARD]">LoadId(Optional)</label>
        <label class="control-label col-sm-2" for="loadId" *ngIf="MockRequestType[type] == MockRequestType[MockRequestType.ARD]">LoadId </label>
        <div class="col-sm-10" *ngIf="MockRequestType[type] != MockRequestType[MockRequestType.ARD]">
          <input class="form-control" id="loadId" name="loadId" [(ngModel)]="loadId">
        </div>
        <div class="col-sm-10" *ngIf="MockRequestType[type] == MockRequestType[MockRequestType.ARD]">
          <input class="form-control" id="loadId" name="loadId" [(ngModel)]="loadId" required #loadIdField="ngModel">
          <div [hidden]="loadIdField.valid || loadIdField.pristine" class="alert alert-danger">
            <a *ngIf="this.loadIdValid && this.loadId != null">
              Load Id Field is required
            </a>
          </div>
        </div>
        <div id="loadIdAlert" class="col-sm-10">
          <ngb-alert aria-label="loadIdAlert" aria-describedby="loadIdAlert" role="alert" *ngIf="!loadIdAlertClosed" (close)="loadIdAlertClosed = true" type="info">Load ID will be applied to all the orders listed in PO Feild.</ngb-alert>
        </div>
      </div>

      <div class="form-group" [hidden]="mode == 'LOAD' ">
        <label for="scacCode" class="control-label" style="padding-left:15px">Scac Code (Optional)</label>
        <div class="row no-margin">
          <div class="col-sm-8">
            <input aria-describedby="scacCode" type="text" class="form-control" autocomplete="off" spellcheck="false"
                   placeholder="Enter Scac Code" id="scacCode" name="scacCode" [(ngModel)]="scacCode" />
          </div>
          <div class="col-sm-2 display-inblock no-padding-left" style="height:35px">(e.g. 'UPSN')</div>
        </div>
      </div>
      <div class="form-group" *ngIf="MockRequestType[type] !== MockRequestType[MockRequestType.DC]">
        <label class="control-label col-sm-2" for="senderInfo">CM Partner</label>
        <div class="col-sm-10">
          <select aria-describedby="senderInfo" class="form-control" id="senderInfo" name="senderInfo" [(ngModel)]="senderInfo" required
                  #senderInfoField="ngModel">
            <option *ngFor="let cm of cmPartnerList" [ngValue]="cm">{{cm.vendorLabel}}</option>
          </select>
          <div [hidden]="senderInfoField.valid || senderInfoField.pristine" class="alert alert-danger">
            CM partner is
            required
          </div>
        </div>
      </div>
      <div class="form-group" *ngIf="MockRequestType[type] !== MockRequestType[MockRequestType.DC]">
        <label class="control-label col-sm-2" for="receiverInfo">DC Partner</label>
        <div class="col-sm-10">
          <select aria-describedby="receiverInfo" class="form-control" id="receiverInfo" name="receiverInfo" [(ngModel)]="receiverInfo" required
                  #receiverInfoField="ngModel">
            <option *ngFor="let dc of dcPartnerList" [ngValue]="dc">{{dc.plantLabel}}</option>
          </select>
          <div [hidden]="receiverInfoField.valid || receiverInfoField.pristine" class="alert alert-danger">
            DC partner is
            required
          </div>
        </div>
      </div>
      <div class="form-group" *ngIf="MockRequestType[type] === MockRequestType[MockRequestType.CM] && processType !='DirectShip'">
        <label class="control-label col-sm-4" for="itemPerCarton">Item Per Carton (Master Pack value)</label>
        <div class="col-sm-10">
          <input id="itemPerCarton" #itemPerCartonField="ngModel" type="number" min="1" max="200" class="form-control"
                 placeholder="Enter the number of items per carton (master pack value) in this shipment" name="itemPerCarton"
                 [(ngModel)]="itemPerCarton" required #itemPerCartonField="ngModel">
          <div [hidden]="itemPerCartonField.valid || itemPerCartonField.pristine" class="alert alert-danger">
            Item per
            carton must be between 1 and 200
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="control-label col-sm-2" for="batchSize">Batch Size</label>
        <div class="col-sm-10">
          <input aria-describedby="batchSize" id="batchSize" #batchSizeField="ngModel" type="number" min="1" max="200" class="form-control"
                 placeholder="Enter batch size" [disabled]="(mode == 'SYNC')" [(ngModel)]="batchSize" name="batchSize" required
                 #batchSizeField="ngModel">
          <div [hidden]="batchSizeField.valid || batchSizeField.pristine || mode == 'SYNC'" class="alert alert-danger">
            Batch Size is required
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="col-sm-offset-2 col-sm-10">
          <div class="input-group">
            <button aria-describedby="SubmitWarning" type="submit" class="btn btn-primary"
                    [disabled]="!shipmentForm.form.valid || showLoader"
                    (click)="showSubmitWarning = true;">
              Submit
            </button>
            <app-preloader-small *ngIf="showLoader"></app-preloader-small>
          </div>
          <div *ngIf="showSubmitWarning">
            <br>
            <ngb-alert aria-describedby="showSubmitWarning" role="alert" (close)="showSubmitWarning = false;">
              <b>Certain Distribution Center partners are not yet supported by <i>Delivery Order Service</i>, such as the following:</b>
              <br><i>IvyTech, FLEX, and Teleplan</i>
              <br>
              <br>As a result, you may encounter the following error message after submitting with the Shipment Mocker:
              <i><b>PayloadFetchFromDOServiceFailure</b></i>
            </ngb-alert>
          </div>
        </div>
      </div>
      <br>
      <div class="form-group">
        <div class="input-group">
          <label aria-describedby="log" id="log" role="log" class="control-label col-sm-1" for="logs">Logs</label><a [routerLink]="" (click)="clearLogs()"> Clear</a>
        </div>
        <div class="col-sm-offset-2 col-sm-10">
          <textarea aria-label="logs" id="logs" rows="12" class="form-control" autocomplete="off" spellcheck="false" name="logs"
                    [(ngModel)]="logMessage" readonly></textarea>
        </div>
      </div>
    </form>
  </div>
</ng-sidebar-container>
