<div class="row no-margin">
    <div class="col-lg-12 no-padding">
        <form [formGroup]="form" (ngSubmit)="addScenarios(model)" >
            <formly-form *ngIf="!isLoading" [model]="model" [fields]="fields" [options]="options" [form]="form">
                <div>
                    <button type="submit" class="btn btn-primary margin-right-15" [disabled]="(!form.valid || model.scenarioId < 0 || model.flavourId < 0)" aria-label="Add Business Process (L4)" role="button" *ngIf="!isEditMode">ADD</button>
                    <a *ngIf="isReadMeEnabled(model.scenarioName)" href="https://microsoft.sharepoint.com/:w:/t/DevX/EdvFWi3nssxGjIQjNVuq6voB21nqhX7GeRQ7RRU5JKxbDg" target="_blank">
                      Read Me Doc
                    </a>
                    <button type="submit" class="btn btn-primary margin-right-15" [disabled]="(!form.valid || model.scenarioId < 0 || model.flavourId < 0)" aria-label="Update Scenarios" role="button" *ngIf="isEditMode">UPDATE</button>
                    <button type="button" class="btn btn-secondary" [disabled]="(!form.valid || model.scenarioId < 0 || model.flavourId < 0)" aria-label="Cancel Business Process (L4) Update" role="button" *ngIf="isEditMode" (click)="cancelScenarioUpdate()">CANCEL</button>
                </div>
            </formly-form>
        </form>
    </div>
</div>
<div class="row margin-top-10 display-none">
    <div class="col-lg-12">
        <pre>{{model|json}}</pre>
    </div>
</div>
<div class="row margin-top-10 display-none">
    <div class="col-lg-12">
        <pre>{{options.formState.mainModel|json}}</pre>
    </div>
</div>
