<div>
  <button type="button" class="close" aria-label="Close" title="Close" mat-dialog-close="true" role="button">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="row no-margin">
    <div class="col-lg-11 no-padding">
      <h1 mat-dialog-title>Retry {{stepTitle}} ?</h1>
    </div>
  </div>
  <div class="row no-margin">
    <pre *ngIf="hasUpdate">
You are about to retry this step for <span style="color: red;">{{remainingEntities}}</span> remaining Order(s).
This step was last updated <span style="color: red;">{{friendlyTimeDiffDays}} days, {{friendlyTimeDiffHours}} hours and {{friendlyTimeDiffMinutes}} minutes</span> ago.

Are you sure you want to retry this step?
    </pre>
    <pre *ngIf="!hasUpdate">
You are about to retry this step for <span style="color: red;">{{remainingEntities}}</span> remaining Order(s).
This step has not been updated yet.
 
Are you sure you want to retry this step?
    </pre>
  </div>
  <div class="row no-margin">
    <div class="col-md-8 offset-md-2">
      <div class="dialog-actions">
        <button type="button" class="btn btn-success" (click)="onConfirm()">Yes</button>
        <button type="button" class="btn btn-secondary" mat-dialog-close="true">No</button>
      </div>
    </div>
  </div>
</div>