import { Component } from '@angular/core';
import { FieldArrayType, FormlyFormBuilder, FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-repeat-section',
  template: `
  <formly-field *ngFor="let field of field.fieldGroup; let i = index;" [field]="field" [options]="options" [model]="model[i]">  
  </formly-field>
  <div class="row margin-top-10 display-none">
    <div class="col-lg-12">
      <pre>{{model|json}}</pre>
    </div>
  </div>
  `,
})
export class RepeatTypeComponent extends FieldArrayType {
  constructor(builder: FormlyFormBuilder) {
    super(builder);
  }
}
