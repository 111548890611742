import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ConfigService } from './config.service';
import { LoadTestExecutionResponse, LoadTestDetail} from '../model/loadtesting.model';
import { LoadTestExecutionRequest } from '../model/loadtestingrequest.model';
import { CreateLoadTestRequest } from '../model/createloadtestrequest.model';
import { CreateLoadTestResponse } from '../model/createloadtestresponse.model';
import { ExecuteStepRequest, ExecuteStepResponse } from '../model/executestep.model';
import { ListTestRunsRequest, ListTestRunsResponse, ListUsersDetails } from '../model/listTestRuns';
import { NgxUiLoaderConfig, NgxUiLoaderService } from 'ngx-ui-loader';

@Injectable()
export class LoadTestService {
  ngxLoaderConfig: NgxUiLoaderConfig;

  constructor(private ngxUiLoaderService: NgxUiLoaderService, private httpClient: HttpClient, private configService: ConfigService) {
    this.ngxLoaderConfig = this.ngxUiLoaderService.getDefaultConfig();
  }
  
  public getLoadTestExecution(loadTestExectuionRequest: LoadTestExecutionRequest) {
    const baseUrlWithVersion = this.configService.omtestSuiteAPI.baseUrl + '/api/' + this.configService.omtestSuiteAPI.apiVersion;
    return this.httpClient.post<LoadTestExecutionResponse>(baseUrlWithVersion + '/loadtest/summary', loadTestExectuionRequest);
  }

  public storeCreateLoadTest(createLoadTestRequest: CreateLoadTestRequest) {
    const baseUrlWithVersion = this.configService.omtestSuiteAPI.baseUrl + '/api/' + this.configService.omtestSuiteAPI.apiVersion;
    return this.httpClient.post<CreateLoadTestResponse>(baseUrlWithVersion + '/loadtest/create', createLoadTestRequest);
  }

  public getExecuteStep(executeStepRequest: ExecuteStepRequest) {
    const baseUrlWithVersion = this.configService.omtestSuiteAPI.baseUrl + '/api/' + this.configService.omtestSuiteAPI.apiVersion;
    return this.httpClient.post<ExecuteStepResponse>(baseUrlWithVersion + '/loadtest/executeStep', executeStepRequest);
  }

  public getListTestRuns(listTestRunsRequest: ListTestRunsRequest) {
    const baseUrlWithVersion = this.configService.omtestSuiteAPI.baseUrl + '/api/' + this.configService.omtestSuiteAPI.apiVersion;
    return this.httpClient.post<ListTestRunsResponse>(baseUrlWithVersion + '/loadtest/list', listTestRunsRequest);
  }

  public getUsersList() {
    const baseUrlWithVersion = this.configService.omtestSuiteAPI.baseUrl + '/api/' + this.configService.omtestSuiteAPI.apiVersion;
    return this.httpClient.post<ListUsersDetails[]>(baseUrlWithVersion + '/loadtest/GetUsersDetails', {});
  }
}
