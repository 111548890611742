export class TestRunList {
    public totalCount: number;
    public testRunListItems: TestRunListItem[];
}

export class TestRunListItem {
    public testRunId: string;
    public testRunName: string;
    public orderCount: number;
    public CreatedAt: Date;
    public LastModifiedAt: Date;
    public status: string;
}
