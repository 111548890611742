<div style="width: 100%; overflow: hidden;">
  <div class="row no-margin">
    <div class="col-lg-12 no-padding">
      <div style="padding: 0px 10px;">
        <div class="row no-margin padding-top-10 padding-bottom-10 steps-statusinfo ">
          <div class="no-padding" style="width: 2000px;">
            <div class="row no-margin">
              <div class="col-lg-9 no-padding">
                <a aria-disabled="false" aria-label="Back" role="button" class="btn btn-primary" style="font-size: 14px; padding: 10px; line-height: 24px;" type="button" href="/loadtest">&#11160; Back</a>
                <h3>Load Test Execution</h3>
                <br />
                <p class="text-left no-padding-right no-padding-left"><b>Load Test Name: </b> {{loadTestName}}</p>
                <p><b>Testrun Id: </b>{{loadTestRunId}} </p>
                <div *ngFor="let loadtest of loadTestExectuionResponse.response; index as i" class="text-right bold" style="padding-right:15px">Load Size:{{loadtest.orders}}</div>
                <div class="text-left bold no-padding-right no-padding-left status-info">
                  <div>Status:</div>
                  <div>
                    <i class="fa md-step-icon fa-hourglass md-step-circle-waiting"
                       aria-hidden="true"></i>Waiting
                  </div>
                  <div>
                    <i class="fa md-step-icon fa-cog md-step-circle-processing"
                       aria-hidden="true"></i>Processing
                  </div>
                  <div>
                    <i class="fa md-step-icon fa-clock-o md-step-circle-pending" aria-hidden="true"></i>Pending or Pending on Partner action
                  </div>
                  <div>
                    <i class="fa md-step-icon fa-check md-step-circle-complete" aria-hidden="true"></i>Complete
                  </div>
                  <!--<div>
                    <i class="fa md-step-icon fa-exclamation md-step-circle-error" aria-hidden="true"></i>Error
                  </div>
                  <div>
                    <i class="fa md-step-icon fa-fast-forward md-step-circle-skipped" aria-hidden="true"></i>Skipped
                  </div>-->
                </div>
              </div>
              <div class="col-lg-3 no-padding font-14">
                <p style="margin-bottom:10px"><b>Created By:</b> {{name}}</p>
                <p style="margin-bottom:10px"><b>Date:</b> {{CreatedAt | date: 'MM/dd/yyyy hh:mm a'}}</p>
                <div>
                  <button aria-label="Generate Results View" (click)="openDialog()" class="margin-top-10 btn btn-primary" style="font-size: 14px; padding: 5px 10px; line-height: 24px;">Generate Results View</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <hr class="no-margin" />
  <div class="row padding-top-10 padding-bottom-10 no-margin pos-rel"
       style="height: calc(100vh - 235px); overflow-y: auto;">
    <div class="col-lg-12 no-padding">
      <ng-container>
        <div class="row no-margin">
          <div class="col-lg-12 col-md-12"
               style="overflow: hidden; overflow-y: auto;">
            <div class="timeline-centered" style="padding-top: 10px">
              <article class="timeline-entry"
                       *ngFor="let steps of stepSummary; index as i" left-aligned>
                <div class="timeline-entry-inner">
                  <div class="timeline-icon bg-warning"
                       *ngIf="steps.status == 'PENDING'"
                       style="background: #D4D4D4;">
                    <i class="fa fa-clock-o"
                       style="height: 30px; vertical-align: middle;"></i>
                  </div>
                  <div class="timeline-icon bg-warning"
                       *ngIf="steps.status == 'PENDING ON PARTNER ACTION'"
                       style="background: #D4D4D4;">
                    <i class="fa fa-clock-o"
                       style="height: 30px; vertical-align: middle;"></i>
                  </div>
                  <div class="timeline-icon"
                       *ngIf="steps.status == 'COMPLETE'" style="background-color: #1b5e20;">
                    <i class="fa fa-check"
                       style="height: 30px;vertical-align: middle; color:white;"></i>
                  </div>
                  <!--<div class="timeline-icon bg-danger"
                       *ngIf="steps.StepStatus == 'Error'">
                    <i class="fa fa-exclamation"
                       style="height: 30px; vertical-align: middle;"></i>
                  </div>-->
                  <div class="timeline-icon bg-secondary"
                       *ngIf="steps.status == 'WAITING'">
                    <i class="fa fa-hourglass"
                       style="height: 30px; vertical-align: middle;"></i>
                  </div>
                  <div class="timeline-icon bg-primary"
                       *ngIf="steps.status == 'PROCESSING'">
                    <i class="fa fa-cog"
                       style="height: 30px; vertical-align: middle;"></i>
                  </div>
                  <div class="timeline-label">
                    <div class="row no-margin" style="font-size: 14px;">
                      <div class="col-lg-3 no-padding">
                        <p><b>Name: </b>{{steps.name}}</p>
                        <p><b>Processed: </b>{{steps.processed}}</p>
                        <p><b *ngIf="steps.eventStartedAt != 'null'">EventStart: </b>{{steps.eventStartedAt | date: 'MM/dd/yyyy hh:mm a'}}</p>
                      </div>
                      <div div class="col-lg-3 no-padding">
                        <p><b>Status: </b> {{steps.status}}</p>
                        <p><b>Remaining: </b>{{steps.remaining}}</p>
                        <p *ngIf="steps.eventStartedAt != '' && steps.remaining == 0"><b>EventEnd: </b>{{steps.eventLastModifiedAt | date: 'MM/dd/yyyy hh:mm a'}}</p>
                        <p *ngIf="steps.eventStartedAt != '' && steps.remaining > 0"><b>LastUpdated: </b>{{steps.eventLastModifiedAt | date: 'MM/dd/yyyy hh:mm a'}}</p>
                      </div>
                    </div>

                    <!-- If Mock is false then disable Start and Retry -->
                    <div *ngIf="steps.name == 'Create Load Plan'">
                      <input type="submit" class="btn-success" *ngIf="(steps.isMockFMV) || steps.canPause" [disabled]=" !(steps.isMockFMV)  || (steps.canPause && steps.isPaused)" (click)="start(steps.stepIdentifier)" value="Start" style="margin-top:15px" />
                      <input type="submit" class="btn-success" *ngIf="(steps.isMockFMV) || steps.canPause" [disabled]="( !(steps.isMockFMV) || !(steps.canPause && steps.isPaused) || steps.remaining < 1)" (click)="openRetryDialog(steps.stepIdentifier)" value="Retry" style="margin-left:15px; margin-top:15px" />
                    </div>
                    <div *ngIf="steps.name == 'Create Shipment'">
                      <input type="submit" class="btn-success" *ngIf="(steps.isMockDC) || steps.canPause" [disabled]="!(steps.isMockDC) || steps.canPause && steps.isPaused" (click)="start(steps.stepIdentifier)" value="Start" style="margin-top:15px" />
                      <input type="submit" class="btn-success" *ngIf="(steps.isMockDC) || steps.canPause " [disabled]="(!(steps.isMockDC) || !(steps.canPause && steps.isPaused) || steps.remaining < 1)" (click)="openRetryDialog(steps.stepIdentifier)" value="Retry" style="margin-left:15px; margin-top:15px" />
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
