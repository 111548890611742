import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { MsalService, MsalBroadcastService } from "@azure/msal-angular";
import { InteractionStatus } from "@azure/msal-browser/dist/utils/BrowserConstants";
import { Observable, Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class RoleGuard implements CanActivate {
  private readonly _destroying$ = new Subject<void>();

  constructor(
    private router: Router,
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    var expectedRoles = route.data.expectedRole;
    return new Promise((resolve) => {
      this.msalBroadcastService.inProgress$
        .pipe(
          filter(
            (status: InteractionStatus) => status === InteractionStatus.None
          ),
          takeUntil(this._destroying$)
        )
        .subscribe(() => {
          var isRolePresent = false;
          if (this.msalService.instance.getAllAccounts().length > 0) {
            var userRoles =
              this.msalService.instance.getAllAccounts()[0].idTokenClaims.roles;
            isRolePresent = expectedRoles.some((role: any) =>
              userRoles.includes(role)
            );
            if (isRolePresent) {
              resolve(true);
            } else {
              this.router.navigate["/unauthorized"];
              resolve(false);
            }
          } else {
            this.router.navigate[state.url];
            resolve(false);
          }
        });
    });
  }
}
