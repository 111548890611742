export class Constants {
  public static SortOrderDescending = 'DESC';
  public static SortOrderAscending = 'ASC';
  public static DefaultPageSize = 10;
  public static ShipmentModeSync = 'SYNC';
  public static ShipmentModeLoad = 'LOAD';
  public static LoadPlanModeSync = 'SYNC';
  public static LoadPlanModeLoad = 'LOAD';
  public static DOEntityType = 'deliveryOrder';
  public static POEntityType = 'purchaseOrder';
  public static EnvironmentCI = 'CI';
  public static EnvironmentUAT = 'UAT';
  public static EnvironmentProd = 'Production';
  public static RoleOMTestSuiteTester = 'OMTestSuiteTester';
  public static RoleOMTestSuiteAdmin = 'OMTestSuiteAdmin';
  public static RoleOMTestSuiteRead = 'OMTestSuiteRead';
}
