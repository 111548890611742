export enum ShipmentMockRequestType {
    DC,
    CM,
    ARD
}

/**
 *  Required for all different types of requests: Mode, Type
 *  Used if the Type is DC: TenantId
 *  Used if the Type is CM: CMShipmentCreatePayloadParams
 */
export class ShipmentMockRequest {
    public mode: string;
    public shipmentType: string;
    public Entities: Entity[];
    public tenantId: string;
    public shipmentParams: ShipmentParams;
}

export class Entity {
    public EntityNumber: string;
    public EntityType: string;
    public LoadId: string;
    public LineItems: LineItem[];
}

export class LineItem {
    public SKU: string;
    public HardwarePartNumber: string;
    public Quantity: string;
    public IsSerialized: string;
    public COO: COO[]
}

export class COO {
  public Name: string;
  public Quantity: string
}

export class ShipmentParams {
  public FlavorType: string;
  public ProcessType: string;
  public SubProcessType: string;
  public IsConsolidated: boolean;
  public IsSerializedScenario: boolean;
  public Sender: string;
  public SenderId: string;
  public Receiver: string;
  public ReceiverId: string;
  public LoadForm: string;
  public ScacCode: string;
  public PackageScenario: string;
  public ItemsPerCarton: number;
  public ReceiverPartnerId: string;
  public PartnerId: string;
  public SerialNumber:string;
  public skipPostShipment:boolean;
}

export class OutboundDC {
    public PartnerId: string;
    public TenantId: string;
    public SupportedModes: string[];
    public Platform: string[];
}

//#region Mixed COO support for Shipment
export class CooStateItem {
  orderNumber: string;
  firstCoo: string;
  firstQuantity: string;
  secondCoo: string;
  secondQuantity: string;
  sku: string;
  firstSplitPercent: number;
  secondSplitPercent: number;
}

export class ItemDetails {
  lineItem: string;
  orderNumber: string;
  lineNum: string;
  material: string;
  qty: string;
  plant: string;
  unitsPerMP: string;
  totalMP: string;
  isMasterPackLoaded: boolean;
}

export class OrderLine {
  public orderNumber: string;
  public sku: string;
  public plant: string;
  public quantity: string;
  public masterPackQuantity: string;
}


//#endregion
