<div id="deviceCareLookupModal">
    <h5 class="modal-title" id="modal-basic-title" style="width: 100% !important;">
        <span>Select a data from the table</span>
        <button type="button" class="close" aria-label="Close" (click)="this.dismiss('Cross click')"
            role="button">
            <span aria-hidden="true">&times;</span>
        </button>
    </h5>
    <div class="modal-body modal-xl">
        <table class="table table-hover hwpn-lookup">
            <thead>
                <th *ngFor="let heading of this.tableData.tableHeading">{{heading}}</th>
                
            </thead>
            <div class="margin-bottom-2"></div>
            <tbody>
                
                <tr class="cursor-pointer" *ngFor="let eachRow of this.tableData.tableRows;let i=index;" (click)="onSelect(eachRow,i)" (keyup)="onKeyup($event,eachRow, i)" tabindex="0">
                    <td *ngFor="let part of eachRow">{{part}}</td>
                    
                </tr>
            </tbody>
            <tbody  *ngIf="this.tableData.tableRows.length==0">
                <h4 class="text-center p-20"> No Data Available</h4>
            </tbody>
        </table>
    </div>
</div>
