import { Component, OnInit } from '@angular/core';
import { LoadTestDetail, InputsModel } from '../model/loadtesting.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { ViewChild, ElementRef } from '@angular/core';
import { LoadTestService } from '../service/loadtest.service';
import { element } from 'protractor';
import { FormControl, Validators } from '@angular/forms';
;
import { MasterDataService } from '../service/masterdata.service';
import { DescriptionService } from '../service/description.service';

import { ConfigService } from '../service/config.service';

@Component({
  selector: 'app-privacynotice',
  templateUrl: './privacynotice.component.html',
  styleUrls: ['./privacynotice.component.css']
})
export class PrivacyNoticeComponent implements OnInit {

  pageDetails: any[];
  showMessage: boolean;
  showPreloader: boolean;
  msgTxt: string;


  constructor(private masterDataService: MasterDataService, private descriptionService: DescriptionService) { }

  ngOnInit() {
    this.descriptionService.privacyDetailsTools().subscribe((response: any[]) => {
      this.pageDetails = response;
    }, (error: any) => {
      try {
        this.showMessage = true;
        this.showPreloader = false; 
        this.msgTxt = error.error.errors[0].message;
      } catch (ex) {
        this.msgTxt = 'Some error occured while loading Page Name and Description';
      }
    });
  }

}
