<div style="width: 100%; height: calc(100vh - 46px); ">
  <h3>Orders</h3>
  <!-- <nav class="breadcrumb">
    <a class="breadcrumb-item" href="#">Orders</a>
    <span class="breadcrumb-item active">Test Run ({{ testRunId }})</span>
    <a class="breadcrumb-item active" href="#">Test Run ({{ testRunId }})</a>
</nav>   -->
  <br>
  <div class="row">
    <div class="col-sm-5">
      <div class="input-group">
        <input class="form-control" placeholder="From date (yyyy-mm-dd)" name="fromDate" [(ngModel)]="fromDate"
          [maxDate]="toDate" ngbDatepicker #f="ngbDatepicker" aria-label="Order From Date">
        <div class="input-group-append">
          <button class="btn btn-outline-secondary fa fa-calendar" (click)="f.toggle()" type="button"
            aria-label="Calender Button"></button>
        </div>
      </div>
    </div>
    <div class="col-sm-5">
      <div class="input-group">
        <input class="form-control" placeholder="To date (yyyy-mm-dd)" name="toDate" [(ngModel)]="toDate" ngbDatepicker
          #t="ngbDatepicker" aria-label="Order To Date">
        <div class="input-group-append">
          <button class="btn btn-outline-secondary fa fa-calendar" (click)="t.toggle()" type="button"
            aria-label="Calender Button"></button>
        </div>
      </div>
    </div>
  </div>
  <br>
  <div class="row">
    <!-- <div class="col-sm-5">
    <input type="text" class="form-control" placeholder="Test Run Name"
    [(ngModel)]="testRunName">
</div> -->
    <div class="col-sm-10">
      <input type="text" class="form-control" placeholder="Customer Purchase Order" [(ngModel)]="customerPurchaseOrder"
        aria-label="Customer Purchase Order">
    </div>
    <div><button type="button" class="btn btn-primary"
        (click)="onSearchClick()">Search</button></div>
  </div>
  <br>
  <table class="table">
    <thead class="thead-dark">
      <tr>
        <th scope="col">Customer Purchase Order</th>
        <!-- <th scope="col">Created </th> -->
        <th scope="col">Timestamp</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>
          <app-preloader *ngIf="showLoader"></app-preloader>
        </td>
      </tr>
      <tr *ngFor="let order of orderList.orderListItems" (click)="onTestRunItemClick()">
        <td><a [routerLink]="['/order-summary', order.customerPurchaseOrderNumber]"
            [queryParams]="{env: this.configService.selectedEnvironment}"
            class="page-link">{{ order.customerPurchaseOrderNumber }}</a>
        </td>
        <!-- <td>{{ order.createdAt | date:'medium'}}</td> -->
        <td>{{ order.lastModifiedAt | date:'medium'}}</td>
      </tr>
    </tbody>
  </table>

  <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [maxSize]="7" [boundaryLinks]="true"
    (pageChange)="onPageChanged()" *ngIf="!showLoader" class="d-flex justify-content-center"></ngb-pagination>
</div>
