import { TestRunList } from "../model/testrunlist.model";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ConfigService } from "./config.service";
import { SerialNumberResponse } from "../model/serial-number-response.model";
import { SerialNumberSearchRequest } from "../model/serialnumbersearchrequest.model";
import { SerialNumberRequest } from "../model/serial-number-request.model";
import { SkuNumberSearchRequest } from "../model/skunumbersearchrequest.model";
import { SerialNumberList } from "../model/serialnumberlist.model";
import { ProductCodeListItem, CountryList, LoadPlanPartner, Vendor, Plant, Page } from "../model/masterdata.model";
import { SKUNumberList } from "../model/skunumberlist.model";
import { PartNumberDetail } from "../model/part-number-detail";
import { UATAvailability } from "../model/uatAvailability.model";
import { CloneSerialNumberResponse } from "../model/clone-serial-number-response.model";
import { ProductRequestList } from "../model/productrequest.model";


@Injectable()
export class MasterDataService {

  constructor(private httpClient: HttpClient, private configService: ConfigService) {
  }

  generateSerialNumbers(serialNumberRequest: SerialNumberRequest) {
    const baseUrlWithVersion = this.configService.omtestSuiteAPI.baseUrl + '/api/' + this.configService.omtestSuiteAPI.apiVersion;
    return this.httpClient.post<SerialNumberResponse>(baseUrlWithVersion + '/masterdata/serialnumber', serialNumberRequest);
  }

  getCloneUATSerialNumber(serialNumberRequest: SerialNumberRequest) {
    const baseUrlWithVersion = this.configService.omtestSuiteAPI.baseUrl + '/api/' + this.configService.omtestSuiteAPI.apiVersion;
    return this.httpClient.get<CloneSerialNumberResponse>(baseUrlWithVersion + '/masterdata/cloneserialnumber?serialNumber=' + serialNumberRequest.serialNumber + '&environment=' + serialNumberRequest.environment);

  }

  getSerialNumberList(serialNumberSearchRequest: SerialNumberSearchRequest) {
    const baseUrlWithVersion = this.configService.omtestSuiteAPI.baseUrl + '/api/' + this.configService.omtestSuiteAPI.apiVersion;
    return this.httpClient.post<SerialNumberList>(baseUrlWithVersion + '/masterdata/serialnumberlist', serialNumberSearchRequest);
  }

  public getProductCode() {
    return this.httpClient.get<ProductCodeListItem[]>('./assets/configuration/productcode.json');
  }

  public getCountry() {
    return this.httpClient.get<CountryList[]>('./assets/configuration/country.json');
  }

  public getVendors() {
    return this.httpClient.get<Vendor[]>('./assets/configuration/vendors.json');
  }

  public getPlants() {
    return this.httpClient.get<Plant[]>('./assets/configuration/plantsDC.json');
  }

  validateSku(skuList: any[]) {
    var baseUrlWithVersion = this.configService.omtestSuiteAPI.baseUrl + '/api/' + this.configService.omtestSuiteAPI.apiVersion;
    return this.httpClient.post<string []>(baseUrlWithVersion + '/masterdata/validateproduct', skuList);
  }

  getSkuNumberList(skuSearchInput: SkuNumberSearchRequest) {
    var baseUrlWithVersion = this.configService.omtestSuiteAPI.baseUrl + '/api/' + this.configService.omtestSuiteAPI.apiVersion;
    return this.httpClient.post<SKUNumberList>(baseUrlWithVersion + '/masterdata/skunumberlist', skuSearchInput);
  }

  getChildPartNumbers(partNumber: string){
    var baseUrlWithVersion = this.configService.omtestSuiteAPI.baseUrl + '/api/' + this.configService.omtestSuiteAPI.apiVersion;
    return this.httpClient.get<PartNumberDetail>(baseUrlWithVersion + '/masterdata/childpartnumbers?partNumber=' + encodeURIComponent(partNumber));
  }

  public getLoadPlanPartnerIds() {
    return this.httpClient.get<LoadPlanPartner[]>('./assets/configuration/loadplanpartners.json');
  }

  getSelectOptionsFromFiles(fileName: string) {
    return this.httpClient.get('./assets/configuration/' + fileName + '.json');
  }

}
