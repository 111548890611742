import { TestRunList } from "../model/testrunlist.model";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ConfigService } from "./config.service";
import { SerialNumberList } from "../model/serialnumberlist.model";
import { ProductCodeListItem, CountryList, LoadPlanPartner, Vendor, Plant, Page } from "../model/masterdata.model";

@Injectable()
export class DescriptionService {

  constructor(private httpClient: HttpClient, private configService: ConfigService) {
  }

  public getGlossary() {
    return this.httpClient.get<Page[]>('./assets/configuration/glossary.json');
  }

  public pageDetailsMocker() {
    return this.httpClient.get<Page[]>('./assets/configuration/pageDescriptionMocker.json');
  }

  public pageDetailsTools() {
    return this.httpClient.get<Page[]>('./assets/configuration/pageDescriptionTools.json');
  }

  public privacyDetailsTools() {
    return this.httpClient.get<Page[]>('./assets/configuration/privacyDescription.json');
  }
}
