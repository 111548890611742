import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TestRunsFetchInputs, UpdateScenarioStepInput } from '../model/manage-testruns.model';
import { SharedOutputRequest, SNValidationRequest, TestCaseInstanceRequest, TestPlanInstanceRequest, TestPlanPreStepInstance } from '../model/project-view.model';
import { ConfigService } from './config.service';

@Injectable()
export class TestCaseProjectService {

    constructor(private httpClient: HttpClient, private configService: ConfigService) {
    }

    getTestPlanInstance(testPlanInstanceId: any) {
        const baseUrlWithVersion = this.configService.omtestSuiteAPI.baseUrl + '/api/' + this.configService.omtestSuiteAPI.apiVersion;
        return this.httpClient.get<any[]>(baseUrlWithVersion + `/TestCaseProjectView/gettestplanInstance?TestPlanInstanceId=${testPlanInstanceId}`);
    }

    getTestPlanInstances(testPlanId: any) {
        const baseUrlWithVersion = this.configService.omtestSuiteAPI.baseUrl + '/api/' + this.configService.omtestSuiteAPI.apiVersion;
        return this.httpClient.get<any[]>(baseUrlWithVersion + `/TestCaseProjectView/gettestplanInstances?TestPlanId=${testPlanId}`);
    }

    getTestPlanPreSteps(testPlanId: any) {
        const baseUrlWithVersion = this.configService.omtestSuiteAPI.baseUrl + '/api/' + this.configService.omtestSuiteAPI.apiVersion;
        return this.httpClient.post<any[]>(baseUrlWithVersion + `/TestCaseProjectView/getcustomsteps?TestPlanId=${testPlanId}`, { TestPlanId: testPlanId });
    }

    getProjectTestCaseView(projectID: number) {
        const baseUrlWithVersion = this.configService.omtestSuiteAPI.baseUrl + '/api/' + this.configService.omtestSuiteAPI.apiVersion;
        return this.httpClient.get<any>(baseUrlWithVersion + `/TestCaseProjectView/getprojecttestcaseview?projectID=${projectID}`);
    }

    validateSku(skuList: any[]) {
        //TODO: add testplanID
        var baseUrlWithVersion = this.configService.omtestSuiteAPI.baseUrl + '/api/' + this.configService.omtestSuiteAPI.apiVersion;
        return this.httpClient.post<string[]>(baseUrlWithVersion + '/masterdata/validateproduct', skuList);
    }


    assignSku(testPlanPreStepsInstance:TestPlanPreStepInstance) {
        var baseUrlWithVersion = this.configService.omtestSuiteAPI.baseUrl + '/api/' + this.configService.omtestSuiteAPI.apiVersion;
        return this.httpClient.post<any>(baseUrlWithVersion + `/TestCaseProjectView/assignsku`, testPlanPreStepsInstance);
    }

    getValidatedSkus(testPlanInstanceId: any) {
        var baseUrlWithVersion = this.configService.omtestSuiteAPI.baseUrl + '/api/' + this.configService.omtestSuiteAPI.apiVersion;
        return this.httpClient.get<any[]>(baseUrlWithVersion + `/masterdata/validatedSkus?testPlanInstanceId=${testPlanInstanceId}`);
    }

    storeTestPlanInstance(input: TestPlanInstanceRequest) {
        var baseUrlWithVersion = this.configService.omtestSuiteAPI.baseUrl + '/api/' + this.configService.omtestSuiteAPI.apiVersion;
        return this.httpClient.post<any>(baseUrlWithVersion + '/TestCaseProjectView/storetestplaninstance', input);
    }

    storeTestCaseInstance(input: TestCaseInstanceRequest) {
        var baseUrlWithVersion = this.configService.omtestSuiteAPI.baseUrl + '/api/' + this.configService.omtestSuiteAPI.apiVersion;
        return this.httpClient.post<any>(baseUrlWithVersion + '/TestCaseProjectView/storetestcaseinstance', input);
    }

    triggerValidation(input: UpdateScenarioStepInput) {
        var baseUrlWithVersion = this.configService.omtestSuiteAPI.baseUrl + '/api/' + this.configService.omtestSuiteAPI.apiVersion;
        return this.httpClient.post<any>(baseUrlWithVersion + '/TestCaseProjectView/TriggerValidation', input);

    }

    postSNValidation(input: SNValidationRequest) {
        var baseUrlWithVersion = this.configService.omtestSuiteAPI.baseUrl + '/api/' + this.configService.omtestSuiteAPI.apiVersion;
        return this.httpClient.post<any>(baseUrlWithVersion + '/TestCaseProjectView/PostSNValidation', input);
    }

    getDetailLink(detailLink: string) {
        var baseUrlWithVersion = this.configService.omtestSuiteAPI.baseUrl + '/api/' + this.configService.omtestSuiteAPI.apiVersion;
        return this.httpClient.get<any>(baseUrlWithVersion + detailLink);
    }

    getPreReqInstanceSteps(testPlanInstanceId: string) {
        var baseUrlWithVersion = this.configService.omtestSuiteAPI.baseUrl + '/api/' + this.configService.omtestSuiteAPI.apiVersion;
        return this.httpClient.get<any[]>(baseUrlWithVersion + `/TestCaseProjectView/getprereqInstancesteps?TestPlanInstanceId=${testPlanInstanceId}`);
    }

    storePreReqSteps(request: TestPlanPreStepInstance[]) {
        var baseUrlWithVersion = this.configService.omtestSuiteAPI.baseUrl + '/api/' + this.configService.omtestSuiteAPI.apiVersion;
        return this.httpClient.post<any>(baseUrlWithVersion + '/TestCaseProjectView/storeprereqsteps', request);
    }

    populateExcel(testPlanInstanceId:string){
        var baseUrlWithVersion = this.configService.omtestSuiteAPI.baseUrl + '/api/' + this.configService.omtestSuiteAPI.apiVersion;
        return this.httpClient.get(baseUrlWithVersion + `/TestCaseProjectView/populateExcelFile?testPlanInstanceId=${testPlanInstanceId}`, {
            responseType: "arraybuffer",
        });
    }

    getEmailBody(){
        var baseUrlWithVersion = this.configService.omtestSuiteAPI.baseUrl + '/api/' + this.configService.omtestSuiteAPI.apiVersion;
        return this.httpClient.get<string>(baseUrlWithVersion + `/TestCaseProjectView/getEmailBody`);
    }

    downloadSampleInput(testPlanPreStepCode:string){
        var baseUrlWithVersion = this.configService.omtestSuiteAPI.baseUrl + '/api/' + this.configService.omtestSuiteAPI.apiVersion;
        return this.httpClient.get(baseUrlWithVersion + `/TestCaseProjectView/downloadSampleFile?testPlanPreStepCode=${testPlanPreStepCode}`, {
            responseType: "arraybuffer",
        });
    }


    downloadExcel(testPlanId:number, testPlanPreStepId:number,  testPlanInstanceId:string, testPlanPreStepInstanceId:string){
      var baseUrlWithVersion = this.configService.omtestSuiteAPI.baseUrl + '/api/' + this.configService.omtestSuiteAPI.apiVersion;
      return this.httpClient.get(baseUrlWithVersion + `/TestCaseProjectView/downloadExcel?testPlanId=${testPlanId}&testPlanPreStepId=${testPlanPreStepId}&testPlanInstanceId=${testPlanInstanceId}&testPlanPreStepInstanceId=${testPlanPreStepInstanceId}`, {
            responseType: "arraybuffer",
        });  
    }

}
