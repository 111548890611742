import { Component, ViewEncapsulation } from '@angular/core';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-description',
  styles:[ `table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
   
  td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
   
  tr:nth-child(even) {
    background-color: #dddddd;
  }`],
  template: `
 <div [innerHtml]="field.templateOptions.label"></div>
`,
encapsulation:ViewEncapsulation.None

})
export class FormlyFieldDescription extends FieldType {
   
  isValid(field: FormlyFieldConfig) {
    if (field.key) {
      return field.formControl.valid;
     }

    return field.fieldGroup
      ? field.fieldGroup.every((f) => this.isValid(f))
      : true;
  }
 
}
