import { Component, Input, Output, EventEmitter, OnInit, ViewChild, ViewContainerRef, TemplateRef, AfterViewInit, OnDestroy } from '@angular/core';
import { PartNumberDetail, ChildPartDetail } from '../model/part-number-detail';
import { MasterDataService } from '../service/masterdata.service';
import { NgbActiveModal, NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-hardware-partnumber-lookup',
  templateUrl: './hardware-partnumber-lookup.component.html',
  styleUrls: ['./hardware-partnumber-lookup.component.css']
})

export class HardwarePartnumberLookupComponent implements AfterViewInit, OnDestroy {
  public static readonly className: string = 'HardwarePartnumberLookupComponent';
  // Component, constructor names are uglified in production so we cannot simply call component.name
  //  We must hard-code this part...
  //  Should the component be renamed, this field must be updated, and the formly config must also be updated as well

  @Input() partNumber: string;
  @Output() hardwarePartNumber = new EventEmitter<string>();

  // Modal References
  @ViewChild('hwpnLookupModal', { static: true, read: TemplateRef })
  hwpnLookupModal: TemplateRef<any>;
  @ViewChild('hwpnLookupFailureModal', { static: true, read: TemplateRef })
  failureModal: TemplateRef<any>;

  partNumberDetail: PartNumberDetail;
  hwpnLookupInvalidSku: string;
  hideSkuFormatAlert = true;
  modal: NgbModalRef;
  selectedHwpn: string;
  masterDataSubscription: Subscription;
  httpErrorCode: number = null;
  httpErrorMessage: string;

  constructor(
    private masterDataService: MasterDataService,
    private modalService: NgbModal) { }

  ngAfterViewInit() { }

  ngOnDestroy() { }

  /**
     * Attempts to retrieve Child Part Numbers for a given Part Number (SKU) and enable the corresponding modal
     * (success or failure)
     * @param successContent Success Modal
     * @param failureContent Failure Modal
     */
  public lookup(partNumber?: string) {
    this.httpErrorCode = null;
    this.httpErrorMessage = null;

    if (this.partNumber == null || this.partNumber == '') { // There are 2 places where the component can look for a partNumber
      if (partNumber) {
        this.partNumber = partNumber;
      } else {
        return;
      }
    }

    this.hwpnLookupInvalidSku = '';
    this.masterDataSubscription = this.masterDataService.getChildPartNumbers(this.partNumber).subscribe(
      (response: PartNumberDetail) => {
        this.partNumberDetail = response;
      },
      error => {
        this.httpErrorCode = error.status;
        console.log("error", error)
        this.httpErrorMessage = "";

        let errorMessage1 = "", errorMessage2 = "";
        try {
          errorMessage1 = (error.error || "").trim();
        }
        catch (err) {
        }

        try {
          errorMessage2 = (error.error.errors[0].message || "").trim();
        }
        catch (err) {
        }

        this.httpErrorMessage = errorMessage1.length > 0 ? errorMessage1 : errorMessage2;
        
        this.hardwarePartNumber.emit('');
        this.modal = this.modalService.open(
          this.failureModal,
          {
            size: 'sml',
            centered: true,
            ariaLabelledBy: 'modal-basic-title'
          });
        this.modal.result.then(
          () => { },
          () => {
            this.hardwarePartNumber.emit('');
            this.partNumber = null;
          });
      },
      () => {
        this.partNumberDetail.childPartDetails = this.extractSubstituteParts(this.partNumberDetail.childPartDetails);
        if (this.partNumberDetail != null &&
          this.partNumberDetail.childPartDetails != null &&
          this.partNumberDetail.childPartDetails.length > 0) {
          this.modal = this.modalService.open(
            this.hwpnLookupModal,
            {
              size: 'xl',
              centered: true,
              ariaLabelledBy: 'modal-basic-title'
            });
          this.modal.result.then(
            () => { },
            () => {
              this.hardwarePartNumber.emit('');
              this.partNumber = null;
            });
        } else {
          this.httpErrorCode = 404; // Manually set error code as Resource Not Found
          this.modal = this.modalService.open(
            this.failureModal,
            {
              size: 'sm',
              centered: true,
              ariaLabelledBy: 'modal-basic-title'
            });
          this.modal.result.then(
            () => { },
            () => {
              this.hardwarePartNumber.emit('');
              this.partNumber = null;
            });
        }
      });
  }

  /**
   * Extracts Substitute Parts from Child Parts and add metadata (*) to Part Type
   * @param childParts Array of ChildPartDetail
   * @returns Array of ChildPartDetail with Substitute Parts inserted
   */
  extractSubstituteParts(childParts: ChildPartDetail[]): ChildPartDetail[] {
    for (let i = 0; i < childParts.length; i++) {
      if (childParts[i] == null || childParts[i].substitutePart == null) {
        continue;
      }

      // For denoting part as a "Substitute Part" in Table
      childParts[i].substitutePart.properties.partType = '*'.concat(childParts[i].substitutePart.properties.partType);

      childParts.splice(i + 1, 0, childParts[i].substitutePart);
    }

    return childParts;
  }

  /**
   * Given an hardwarePartNumber, sets hardwarePartNumber in serialNumberRequest and close currently opened modal
   * @param selectedHWPN
   */
  onSelect(selectedHWPN: string) {
    if (selectedHWPN != null && selectedHWPN != '') {
      this.hardwarePartNumber.emit(selectedHWPN); // Emit the HWPN determined in view (user-selected)
      this.modal.close();
      this.partNumber = null;
    }
  }

  /**
   * While table row is selected, invokes onSelectHwpn() with corresponding hardwarePartNumber if user pressed 'Enter'
   * @param event
   * @param selectedHWPN
   */
  onKeyup(event, selectedHWPN: string) {
    if (event.key == "Enter") {
      this.onSelect(selectedHWPN);
    }
  }

  /**
   * Dismiss modal and emit empty string from this.hardwarePartNumber
   * @param event
   * @param selectedHWPN
   */
  dismissModal() {
    this.modal.result.then(() => {
      this.hardwarePartNumber.emit('');
      this.partNumber = null;
    });
    this.modal.close();
  }

  /**
   * Given an HTTP error code, return the correct error message to be displayed to user
   * @param errorCode HTTP Error Code
   */
  getErrorMsg(errorCode: number) {
    switch (errorCode) {
      case 400:
        return 'Could not connect to Test Platform API!';
      case 404:
        return 'The following SKU does not exist in OnePDM Part Service.';
      case 500:
        return 'An internal server error has occurred!';
      case 502:
        return 'OnePDM Part Service API is currently not available at the moment, ' +
          'please try again later! (in a few minutes or so)';
      default:
        return 'An error has occurred.';
    }
  }
}
