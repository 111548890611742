import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular'
import { Router } from '@angular/router';
import { AuthService } from '../service/auth.service';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.css']
})
export class UnauthorizedComponent implements OnInit {

  constructor(private msalService: MsalService, private router: Router, private authService: AuthService) { }

  ngOnInit() {
    if (this.authService.isUserAuthenticated()) {
      //this.router.navigate(['/', 'landing-page']);
    }
  }

  onLoginClick() {
    this.msalService.instance.loginRedirect();
  }
}
