
<div style="width: 100%; height: calc(100vh - 46px); overflow: auto;">
    <div *ngIf="isLoading">
        <app-preloader-small></app-preloader-small>
        <div>
            <div style="margin-left: 50px; padding-top: 7px;">Loading ... Please Wait</div>
        </div>      
    </div>
    <ng-container *ngIf="!isLoading">
        <h3 style="margin: 5px; padding: 15px;">{{projectName}}</h3>
        <div>
            <!-- Top Section --> 
            <div class="row ms-depth-8" style="margin: 5px; padding: 15px; min-height: 120px; padding-left:32px;">
                <div class="col-md-4 bold" style="margin-block:16px;">Project ID:
                    <span style="font-weight: normal; margin-left: 8px;">{{projectID}}</span>
                </div>
                <div class="col-md-4 bold" style="margin-block:16px;">Created on:
                    <span style="font-weight: normal; margin-left:8px">{{projectCreateDate}}</span>
                </div>
                <div class="col-md-4 bold" style="margin-block:16px;">Owner:
                <span style="font-weight:normal;margin-left:8px">{{projectOwner}}</span>
                </div>
                <div *ngIf="this.projectDocumentation" class="col-md-4 bold" style="margin-block:16px;">Project Documentation:
                    <span style="font-weight: normal; margin-left: 8px;"><a href="{{projectDocumentation}}" target="_blank">{{projectName}} ReadMe Doc</a></span>
                </div>
            </div>

            <div class="ms-depth-3">
                <!-- Test Case Grid --> 
                <app-test-grid [column_schema]="TestPlanSchema" [table_data]="TestPlan" [organizationId]="organizationId"></app-test-grid>
            </div>
        </div>
    </ng-container>
</div>