<div  class="container">
  <div class="row">
    <div class="col-6">
      <h4>JSON Input</h4>
      <textarea
        rows="10"
        cols="50"
        (input)="stringFields = ($event.target.value)"
      ></textarea>
      <div class="text-danger" *ngIf="showErrorjson">Please Enter the input in JSON format.</div>
      <br/>
      <button (click)="updateFields(stringFields)">Parse</button>
      <div>
        <mat-slide-toggle *ngIf="showToggle" (change)="onChangeToggle($event)"
         class="margin-top-25">
        Show Model
        </mat-slide-toggle>
      </div>
      <div *ngIf="showModel">
        <pre>{{model|json}}</pre>
      </div>
    </div>
    
    <div  class="col-6">
      <h4>Formly UI</h4>
      <form  [formGroup]="form">
        <formly-form
          [form]="form"
          [fields]="fields"
          [model]="model"
          [options]="options"
        ></formly-form>
      </form>
     
    </div>
  </div>
</div>
