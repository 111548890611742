import { ShipmentMockRequest } from '../model/shipment.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShipmentResponse } from '../model/shipmentresponse.model';
import { ConfigService } from './config.service';

@Injectable()
export class ShipmentService {

  constructor(private httpClient: HttpClient, private configService: ConfigService) {
  }

  postMockShipmentRequest(shipmentMockRequest: ShipmentMockRequest) {
    const baseUrlWithVersion = this.configService.omtestSuiteAPI.baseUrl + '/api/' + this.configService.omtestSuiteAPI.apiVersion;
    return this.httpClient.post<ShipmentResponse[]>(baseUrlWithVersion + '/shipmentmock', shipmentMockRequest);
  }

  getOrderLines(doNumber: string) {
    const baseUrlWithVersion = this.configService.omtestSuiteAPI.baseUrl + '/api/' + this.configService.omtestSuiteAPI.apiVersion;
    return this.httpClient.get(baseUrlWithVersion + '/shipmentmock/deliveryorder/' + doNumber);
  }

  getPurchaseOrderLines(poNumber: string) {
    const baseUrlWithVersion = this.configService.omtestSuiteAPI.baseUrl + '/api/' + this.configService.omtestSuiteAPI.apiVersion;
    return this.httpClient.get(baseUrlWithVersion + '/shipmentmock/PurchaseOrder/' + poNumber);
  }

  getCustomerPurchaseOrderLine(customerPONumber: string) {
    const baseUrlWithVersion = this.configService.omtestSuiteAPI.baseUrl + '/api/' + this.configService.omtestSuiteAPI.apiVersion;
    return this.httpClient.get(baseUrlWithVersion + '/shipmentmock/CustomerPurchaseOrder/' + customerPONumber);
  }
}
