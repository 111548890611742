import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, ErrorHandler, Injector } from '@angular/core';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { SidebarModule } from 'ng-sidebar';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  MsalModule,
  MsalService,
  MsalInterceptor,
  MsalGuard,
  MsalBroadcastService,
  MsalRedirectComponent,
  MSAL_INTERCEPTOR_CONFIG,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MsalGuardAuthRequest
} from '@azure/msal-angular';
import {
LogLevel
} from "@azure/msal-browser";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderModule, NgxUiLoaderConfig } from 'ngx-ui-loader';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMatDatepickerModule } from '@ngx-formly/material/datepicker';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { minValidationMessage, maxValidationMessage, minlengthValidationMessage, maxlengthValidationMessage, MasterPackValidator,jsonValidationMessage } from './model/validation-messages.model';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormlyMatNativeSelectModule } from '@ngx-formly/material/native-select';
import { NgJsonEditorModule } from 'ang-jsoneditor'
import { ExcelFileService } from './service/excelFile.service';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ClipboardModule } from '@angular/cdk/clipboard';

import { NewTestRunComponent } from './new-test-run/new-test-run.component';
import { TestRunService } from './service/testrun.service';
import { NavmenuComponent } from './navmenu/navmenu.component';
import { ShipmentmockerComponent } from './shipmentmocker/shipmentmocker.component';
import { LoadPlanMockerComponent } from './loadplanmocker/loadplanmocker.component';
import { LoadTestComponent } from './loadtest/loadtest.component';
import { LoadTestExecutionComponent } from './loadtestexecution/loadtestexecution.component';
import { AuthenticationGuard } from './common/authentication-guard';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { AuthService } from './service/auth.service';
import { ShipmentService } from './service/shipment.service';
import { LoadPlanService } from './service/loadplan.service';
import { TestRunListComponent } from './test-run-list/test-run-list.component';
import { ManageScenariosComponent } from './manage-scenarios/manage-scenarios.component';
import { ExecuteTestRunsComponent } from './manage-scenarios/execute-testruns/execute-testruns.component';
import { ManageTestRunsComponent } from './manage-scenarios/manage-testruns/manage-testruns.component';
import { TestRunsInputsPanelComponent } from './manage-scenarios/execute-testruns/testruns-input-panel/testruns-input-panel.component';
import { RepeatTypeComponent } from './manage-scenarios/execute-testruns/testruns-input-panel/repeat-section.type';
import { InputWithToolTipComponent } from './manage-scenarios/execute-testruns/testruns-input-panel/inputWithToolTip.type';
import { FormlyButtonComponent } from './manage-scenarios/execute-testruns/testruns-input-panel/formly-button.type';
import { PagerService } from './service/pager.service';
import { OrderService } from './service/order.service';
import { OrderSummaryComponent } from './order-summary/order-summary.component';
import { PreloaderComponent } from './preloader/preloader.component';
import { OrderListComponent } from './order-list/order-list.component';
import { PreloaderSmallComponent } from './preloader-small/preloader-small.component';
import { ConfigService } from './service/config.service';
import { RoleGuard } from './common/role-guard';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { ModalService } from './service/modal.service';
import { ModalModule } from 'ngb-modal';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { MonitoringService } from './service/monitoring.service';
import { BaseComponent } from './base/base.component';
import { GlobalErrorHandler } from './common/error.handler';
import { SerialNumberGeneratorComponent } from './serial-number-generator/serial-number-generator.component';
import { MasterDataService } from './service/masterdata.service';
import { DescriptionService } from './service/description.service';
import { ManageScenariosService } from './service/manage-scenarios.service';
import { SerialNumberListComponent } from './serial-number-list/serial-number-list.component';
import { SkuNumberListComponent } from './sku-number-list/sku-number-list.component';
import { SkuValidationComponent } from './skuvalidation/skuvalidation.component';
import { SapOutputTriggerComponent } from './sapoutputtrigger/sapoutputtrigger.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { ComingSoonComponent } from './comming-soon/comming-soon.component';
import { ScenarioCatalogComponent } from './scenariocatalog/scenariocatalog.component';
import { TestRunOverviewComponent } from './testrunoverview/testrunoverview.component';
import { FilterPipe } from './common/filter.pipe';
import { SAPService } from './service/sap.service';
import { LoadTestService } from './service/loadtest.service';
import { GenerateTestResultsComponent } from './generateTestResults/generateTestResults.component'
import { GoodReceiptMockerComponent } from './goodreceiptmocker/goodreceiptmocker.component'
import { HardwarePartnumberLookupComponent } from './hardware-partnumber-lookup/hardware-partnumber-lookup.component';
import { VerifyRetryLoadTestComponent } from './verifyretry-loadtest/verifyretry-loadtest.component';
import { GoodReceiptService } from './service/goodreceipt.service';
import { EntityTrackingComponent } from './entity-tracking/entitytracking.component';
import { EntityTrackingService } from './service/entitytracking.service';
import { LandingPageService } from './service/landingpage.service';
import { ToolsComponent } from './tools/tools.component';
import { MockersComponent } from './mockers/mockers.component';
import { TestRunComponent } from './testrun/testrun.component';
import { UATAvailabilityComponent } from './uatAvailability/uatAvailability.component';
import { TestDataService } from './service/testdata.service';
import { UatAvailabilityService } from './service/uatavailability.service';
import { PrivacyNoticeComponent } from './privacynotice/privacynotice.component';
import { FormlyReadOnlyViewComponent } from './manage-scenarios/customtypes/formly-custom-view/formly-readonly-listview.component';
import { TestGridComponent } from './project-test-runs/test-grid/test-grid.component';
import { SimpleGridComponent } from './project-test-runs/test-grid/simple-grid/simple-grid.component';
import { EditParameterComponent } from './project-test-runs/edit-parameter/edit-parameter.component';
import { TrackingDetailsComponent } from './project-test-runs/tracking-details/tracking-details.component';
import { FilterBarComponent } from './project-test-runs/filter-bar/filter-bar.component';
import { StatusComponent } from './project-test-runs/status/status.component';
import { ProgressComponent } from './project-test-runs/progress/progress.component';
import { DatePickerComponent } from './project-test-runs/date-picker/date-picker.component';
import { TestProjectViewComponent } from './project-test-runs/project-view/test-project-view.component';
import { TestCaseViewComponent } from './project-test-runs/test-case-view/test-case-view.component';
import { TestCaseProjectService } from './service/test-case-project.service';
import { OrgProjectViewComponent } from './project-test-runs/org-project-view/org-project-view.component';
import { TestProjectService } from './service/testproject.service';
import { CardWrapperComponent } from './project-test-runs/edit-parameter/card-wrapper.component';
import { ItemsWrapperComponent } from './project-test-runs/edit-parameter/line-items-wrapper.component';
import { GenerateBlobSasTokenService } from './service/generate-sas-token.service';
import { ErrorComponent } from './error/error.component';
import { OrgCardComponent } from './org-view/org-card/org-card.component';
import { OrgViewLandingPageComponent } from './landing-page/org-view-landing-page/org-view-landing-page.component';
import { OrgViewService } from './service/org-view.service';
import { ErrorDisplayComponent } from './project-test-runs/error-display/error-display.component';
import { AppRoutingModule } from './app-routing.module';
import { MsalConfig, MSALGuardConfigFactory, MSALInstanceFactory, MSALInterceptorConfigFactory } from './model/msal.config';
import { ProtectedResourceEntry, UIAppSettings } from './model/appconfig.model';
import { SelfServeComponent } from './self-serve/self-serve.component';
import { RepeatTypeButtonComponent } from './self-serve/repeat-section.type';
import { AppComponent, MaxDirective, MinDirective } from './app.component';
import { FormlyFieldTabs } from './self-serve/tabs.type';
import { BlobDownloadService } from './service/blob-download.service';


import { FormlyPlaygroundComponent } from './formly-playground/formly-playground.component';
import { FormlyLinkComponent } from './self-serve/alink.type';
import { GenerateMapperStringComponent } from './generate-mapper-string/generate-mapper-string.component';
import { jsonValidator } from './common/jsonValidator';
import { DevicecareComponent } from './devicecare/devicecare.component';
import { DevicecareService } from './service/devicecare.service';
import { ModaltableComponent } from './modaltable/modaltable.component';
import { MockersformlyComponent } from './mockersformly/mockersformly.component';
import { FormlyFieldDropdown } from './mockersformly/dropdown.type';
import { FormlyFieldFile } from './mockersformly/file-type.component';
import { FileValueAccessor } from './mockersformly/file-value-accessor';
import { FormlyFieldDescription } from './mockersformly/description.type';
import { BlobDownloadComponent } from './blob-download/blob-download.component';


const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  'bgsColor': 'red',
  'bgsOpacity': 0.5,
  // 'bgsPosition': 'bottom-right',
  // 'bgsSize': 60,
  // 'bgsType': 'ball-spin-clockwise',
  'blur': 5,
  'delay': 0,
  'fgsColor': 'red',
  'fgsPosition': 'center-center',
  'fgsSize': 60,
  'fgsType': 'ball-spin-clockwise',
  'gap': 24,
  'logoPosition': 'center-center',
  'logoSize': 120,
  'logoUrl': '../assets/images/MicrosoftLogo.png',
  'masterLoaderId': 'master',
  'overlayBorderRadius': '0',
  'overlayColor': 'rgba(40, 40, 40, 0.8)',
  'pbColor': 'red',
  'pbDirection': 'ltr',
  'pbThickness': 3,
  'hasProgressBar': true,
  'text': 'Welcome to SCE Test Suite',
  'textColor': '#FFFFFF',
  'textPosition': 'center-center',
  'maxTime': -1,
  'minTime': 500
};

//add initialize factory here
function init_app(configService: ConfigService, injector: Injector) {
  return (): Promise<void> =>
    configService.loadConfigFromService().then((uiAppSettings: UIAppSettings) => {
      appInsights.config = {
        instrumentationKey:
          uiAppSettings.Logging.AppInsightsInstrumentationKey,
        enableCorsCorrelation: true,
      };
      configService.uiAppSettings = uiAppSettings;
      configService.auth = uiAppSettings.Auth;
      configService.organizations = uiAppSettings.Organizations;
      configService.msalAngularConfig = uiAppSettings.MsalAngularConfig;
      MsalConfig.auth.clientId = uiAppSettings.Auth.clientId;
      MsalConfig.system = {
        loggerOptions: {
          loggerCallback(logLevel: LogLevel, message: string) {
          },
          logLevel: LogLevel.Verbose,
          piiLoggingEnabled: false,
        },
      };
      uiAppSettings.MsalAngularConfig.protectedResourceMap.forEach((entry: ProtectedResourceEntry) => {
        let scope = entry.scopes[0];
        let protectedResource = entry.protectedResource;
        let resourceScopes = [scope];
        (
          MSALGuardConfigFactory().authRequest as MsalGuardAuthRequest
        ).scopes.push(scope);
        MSALInterceptorConfigFactory().protectedResourceMap.set(
          protectedResource, resourceScopes
        );
      });
    }).catch((error) => {
      console.error('Unable to initialize the application', error);
      throw error;
    });;
}

@NgModule({
  declarations: [
    AppComponent,
    NewTestRunComponent,
    FeedbackComponent,
    NavmenuComponent,
    ShipmentmockerComponent,
    LoadPlanMockerComponent,
    GoodReceiptMockerComponent,
    LoadTestComponent,
    LoadTestExecutionComponent,
    LandingPageComponent,
    TestRunListComponent,
    StatusComponent,
    DatePickerComponent, TestGridComponent, TestProjectViewComponent, TestCaseViewComponent, SimpleGridComponent,
    EditParameterComponent, TrackingDetailsComponent,
    FilterBarComponent,
    ProgressComponent,
    ManageScenariosComponent,
    ExecuteTestRunsComponent,
    ManageTestRunsComponent,
    TestRunsInputsPanelComponent,
    RepeatTypeComponent,
    InputWithToolTipComponent,
    FormlyButtonComponent,
    OrderSummaryComponent,
    PreloaderComponent,
    OrderListComponent,
    PreloaderSmallComponent,
    UnauthorizedComponent,
    BaseComponent,
    SerialNumberGeneratorComponent,
    MinDirective,
    MaxDirective,
    SerialNumberListComponent,
    SkuNumberListComponent,
    SkuValidationComponent,
    SapOutputTriggerComponent,
    ComingSoonComponent,
    ScenarioCatalogComponent,
    TestRunOverviewComponent,
    FilterPipe,
    GenerateTestResultsComponent,
    VerifyRetryLoadTestComponent,
    HardwarePartnumberLookupComponent,
    GoodReceiptMockerComponent,
    EntityTrackingComponent,
    ToolsComponent,
    MockersComponent,
    TestRunComponent,
    UATAvailabilityComponent,
    PrivacyNoticeComponent,
    FormlyReadOnlyViewComponent,
    CardWrapperComponent,
    ItemsWrapperComponent,
    ErrorComponent,
    OrgCardComponent,
    OrgViewLandingPageComponent,
    OrgProjectViewComponent,
    ErrorDisplayComponent,
    SelfServeComponent,
    RepeatTypeButtonComponent,
    FormlyFieldTabs,
    FormlyFieldDropdown,
    FormlyLinkComponent,
    FormlyPlaygroundComponent,
    GenerateMapperStringComponent,
    DevicecareComponent,
    ModaltableComponent,
    MockersformlyComponent,
    FileValueAccessor,
    FormlyFieldFile,
    BlobDownloadComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  entryComponents: [
    GenerateTestResultsComponent,
    HardwarePartnumberLookupComponent,
    VerifyRetryLoadTestComponent,
  ],
  imports: [
    ClipboardModule,
    DragDropModule,
    MatDialogModule,
    MatTabsModule,
    MatSelectModule,
    MatFormFieldModule,
    CommonModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    SidebarModule.forRoot(),
    NgbModule,
    NgJsonEditorModule,
    NgxJsonViewerModule,
    AppRoutingModule,
    RouterModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    ReactiveFormsModule,
    ModalModule,
    FormlyModule.forRoot({
      validators: [

        { name: 'masterpack', validation: MasterPackValidator }
      ],
      validationMessages: [
        { name: 'required', message: 'This field is required' },
        { name: 'minlength', message: minlengthValidationMessage },
        { name: 'maxlength', message: maxlengthValidationMessage },
        { name: 'min', message: minValidationMessage },
        { name: 'max', message: maxValidationMessage },
        {name: 'json', message: jsonValidationMessage}
      ],
      types: [
        { name: 'repeat', component: RepeatTypeComponent },
        { name: 'button', component: FormlyButtonComponent },
        { name: 'listview', component: FormlyReadOnlyViewComponent },
        { name: 'repeat-button', component: RepeatTypeButtonComponent },
        { name: 'tabs', component: FormlyFieldTabs },
        { name: 'link', component: FormlyLinkComponent},
        {name:'dropdown',component:FormlyFieldDropdown},
        {name:'description',component:FormlyFieldDescription},
        { name: 'file', component: FormlyFieldFile, wrappers: ['form-field'] },

      ],
      wrappers: [
        { name: 'panel', component: InputWithToolTipComponent },
        { name: 'card', component: CardWrapperComponent },
        { name: 'line-items', component: ItemsWrapperComponent }
      ],
    }),
    FormlyMatDatepickerModule,
    FormlyMaterialModule,
    MatInputModule,
    MatButtonModule,
    MatNativeDateModule,
    MatTooltipModule,
    MatSelectModule,
    MatDialogModule,
    NgSelectModule,
    FormlyMatNativeSelectModule,
    FormsModule,
    MsalModule,
    MatSlideToggleModule
  ],
 
  providers: [
    TestRunService,
    ExcelFileService,
    AuthenticationGuard,
    RoleGuard,
    {
      provide: APP_INITIALIZER,
      useFactory: init_app,
      deps: [ConfigService, Injector],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    AuthService,
    ShipmentService,
    LoadPlanService,
    LoadTestService,
    GoodReceiptService,
    EntityTrackingService,
    PagerService,
    OrderService,
    ConfigService,
    ModalService,
    MasterDataService,
    DescriptionService,
    ManageScenariosService,
    SAPService,
    TestDataService,
    UatAvailabilityService,
    TestProjectService,
    TestCaseProjectService,
    OrgViewService,
    LandingPageService,
    MonitoringService,
    GenerateBlobSasTokenService,
    jsonValidator,
    BlobDownloadService,
    DevicecareService,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    }],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule {
}
