// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  auth: {
    tenant: "microsoft.onmicrosoft.com",
    clientId: "69db971e-c3ee-41e5-829d-2d577f4b2c5f"    
  },
  CI: {
    omTestSuiteAPI: {
      baseUrl: "https://omtestsuiteservice-ci-westus-wa.azurewebsites.net",
      resourceUrl: 'https://microsoft.onmicrosoft.com/OMTestSuite',
      apiVersion: "v1.0",
    },
    keyVaultAPI: {
      baseUrl: "https://omtestsuiteciwestuskv.vault.azure.net/",
      apiVersion: "2016-10-01",
      resourceUrl: "https://vault.azure.net",
    }
  },
  UAT: {
    omTestSuiteAPI: {
      baseUrl: "https://omtestsuiteservice-uat-westus-wa.azurewebsites.net",
      resourceUrl: 'https://microsoft.onmicrosoft.com/OMTestSuite',
      apiVersion: "v1.0",
    },
    keyVaultAPI: {
      baseUrl: "https://omtestsuiteuat-westus-kv.vault.azure.net",
      apiVersion: "2016-10-01",
      resourceUrl: "https://vault.azure.net",
    }    
  },
  Prod: {
    omTestSuiteAPI: {
      baseUrl: "https://omtestsuiteservice-uat-westus-wa.azurewebsites.net",
      resourceUrl: 'https://supplychainservices.microsoft.com/OMIntegrationTestSuiteAPI',
      apiVersion: "v1.0",
    },
    keyVaultAPI: {
      baseUrl: "https://omtestsuiteuat-westus-kv.vault.azure.net",
      apiVersion: "2016-10-01",
      resourceUrl: "https://vault.azure.net",
    }   
  },
  supportedEnvironments: ["CI", "UAT", "Production"]
};

/*
 * In development mode, for easier debugging, you can ignore zone related error
 * stack frames such as `zone.run`/`zoneDelegate.invokeTask` by importing the
 * below file. Don't forget to comment it out in production mode
 * because it will have a performance impact when errors are thrown
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
