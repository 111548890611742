import { Component, OnInit, Input } from '@angular/core';
import { UatAvailabilityService } from '../../service/uatavailability.service';
import { MasterDataService } from '../../service/masterdata.service';
import { MsalService } from '@azure/msal-angular'
import { ConfigService } from '../../service/config.service';
import * as log from '../../AppInsightsLogger';
import { skip } from 'rxjs/operators';
//mport { NgxUiLoaderService } from 'ngx-ui-loader';
import { ManageScenariosService } from '../../service/manage-scenarios.service';
import { TestPlanInstance } from 'src/app/model/project-view.model';

export enum Status {
  NOT_STARTED = 'NOT_STARTED',
  AT_RISK = 'AT_RISK',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
}

export const STATUS_LABELS: Record<string, string> = {
  [Status.NOT_STARTED]: 'Not Started',
  [Status.AT_RISK]: 'At Risk',
  [Status.IN_PROGRESS]: 'In Progress',
  [Status.COMPLETED]: 'Completed'
};

export const STATUS_LABEL = 'Status';

export interface RowGroup<T> {
  key: string;
  label: string;
  startRowIndex: number;
  rows: Array<T>;
}

//For collapsable testPlanInstance: TestPlanInstance
export class BaseTestPlanInstance {
  TestPlanID: string;
  TestPlanInstanceId: string;
  TestPlanName: string;
  createDate: Date;
  startDate: Date;
  endDate: Date;
  owner: string;
  customerName: string;
  customerType: string;
  testCaseInstances: any[];

}

//For the template TestPlan
export class TestPlan {
  testPlanID: number;
  testPlanName: string;
  testPlanDescription: string;
  testPlanCreateDate: Date;
  testPlanModifiedDate: Date;
  testPlanOwner: string;
  testCases: any[];
  testPlanInstances: BaseTestPlanInstance[];
}


type CheckableRow = {
  isHovered: boolean;
  checked: boolean;
};

class RowGroupTaskGroup extends TestPlan implements RowGroup<BaseTestPlanInstance>, CheckableRow {
  key: string;
  label: string;
  startRowIndex: number;
  rows: (BaseTestPlanInstance & CheckableRow)[];

  isHovered: boolean;
  checked: boolean;
}

@Component({
  selector: 'app-test-grid',
  templateUrl: './test-grid.component.html',
  styleUrls: ['./test-grid.component.css']
})
export class TestGridComponent implements OnInit {

  @Input() organizationId:number;
  @Input() column_schema: any = [];
  @Input() table_data: any;


  gridId: number = 1;

  rowGroups: Array<any> = [];
  totalRows = 0;
  originalTasks: Array<BaseTestPlanInstance> = [];
  isGroupCollapsedMap: Record<number, boolean> = {};
  isCollapsedAll = false;
  isFocusedOnTaskGroupLabel = false;
  project: any;


  allSelected = false; // whether all rows are selected
  isGridHeaderHovered = false;  // is mouse hovered over the grid header
  isMultiSelectMode = false; // true if any task is selected
  selectedTasks: Map<string, BaseTestPlanInstance> = new Map();
  private logger: log.AppInsightsLogger;
  sortingColumns = {};
  


  constructor(
    private msalService: MsalService,
    private configService: ConfigService,
    private uatDataService: UatAvailabilityService,
    private masterDataService: MasterDataService, private manageTestRunsService: ManageScenariosService) {
    this.logger = new log.AppInsightsLogger(configService, msalService);

  }

  updateTestPlan(group: TestPlan): void { }

  get displayColumns() {
    return this.column_schema.filter(column => column.class.length);
  }

  get hasFiltersApplied(): boolean {
    return false; //this.filterService.hasFilters(this.gridId);
  }

  get fxnRowGroups(): any {
    return this.rowGroups;
  }

  displayCheckbox(row: CheckableRow) {
    return row.checked || row.isHovered;
  }

  setAllCheckboxes(isChecked: boolean) {
    this.allSelected = isChecked;
    this.rowGroups.forEach(rowGroup => {
      rowGroup.checked = this.allSelected;
      rowGroup.rows.forEach(row => {
        this.setCheckboxValue(row, this.allSelected);
      });
    });
  }

  toggleTaskGroupCheckbox(testPlanIndex: number) {
    const testPlan = this.rowGroups[testPlanIndex];

    testPlan.checked = !testPlan.checked;

    testPlan.rows.forEach(row => {
      this.setCheckboxValue(row, testPlan.checked);
    });

    //this.recalcAllCheckboxValue();
  }

  toggleTaskCheckbox(taskGroupIndex: number, taskIndex: number) {
    const task = this.rowGroups[taskGroupIndex].rows[taskIndex];

    this.setCheckboxValue(task, !task.checked);
  }

  setCheckboxValue(row: (BaseTestPlanInstance & CheckableRow), checked: boolean) {
    if (row.checked === checked)
      return;

    row.checked = checked;
    if (checked) {
      this.selectedTasks.set(row.TestPlanID, row);
    }
    else {
      this.selectedTasks.delete(row.TestPlanID);
    }

    this.isMultiSelectMode = this.selectedTasks.size > 0;
  }

  taskDrop(event: any): void { }

  ngOnInit() {
    setTimeout(() => {
      this.updateDisplay();
    }, 1000
    );

  }

  updateDisplay(): void {
    this.rowGroups = [];
    let nextRowIndex = 1;
    for (let i = 0; i < this.table_data['testPlans'].length; ++i) {

      let testPlan: TestPlan = this.table_data['testPlans'][i];

      let newGroup = {
        ...this.table_data['testPlans'][i],
        id: i,
        label: testPlan.testPlanName,
        tasks: testPlan.testPlanInstances || [],
        // used for grid rendering
        key: testPlan.testPlanID.toString(),
        startRowIndex: nextRowIndex,
        rows: testPlan.testPlanInstances || []
      } as RowGroupTaskGroup;

      this.rowGroups.push(newGroup);
      const numTasks = !!testPlan.testPlanInstances ? testPlan.testPlanInstances.length : 0;
      nextRowIndex = nextRowIndex + numTasks + 1;
    }
  }

  isGroupCollapsed(id: number): boolean {

    if (this.isGroupCollapsedMap[id] === undefined) {
      this.isGroupCollapsedMap[id] = this.isCollapsedAll;
    }
    return this.isGroupCollapsedMap[id];
  }

  rowsChanged(event: any): void {

  }

  toggleCollapseAll(): void {
    this.isCollapsedAll = !this.isCollapsedAll;
    Object.keys(this.isGroupCollapsedMap).forEach(key => {
      this.isGroupCollapsedMap[key] = this.isCollapsedAll;
    });
  }


  getUrlParams(projectId, testPlanId, testPlanInstanceId){
    return {
      'org':this.organizationId,
      'projectId': projectId,
      'testPlanId': testPlanId,
      'testPlanInstanceId': testPlanInstanceId
    };
  
  }

  /**
   * 
   * @param column : the column that is being sorted.
   */
  sortBy(column:string){
    let keys: string[] = Object.keys(this.sortingColumns);
  
    for(var key of keys){
      if (key != column) {
          this.sortingColumns[key] = 0;
      }
    }

    this.sortingColumns[column] = this.sortingColumns[column] == undefined || this.sortingColumns[column] == 0 ? 1:0;
    

    if(this. sortingColumns[column] == 1){ 
      if(column == "label") column = 'testPlanName';
      var comparator = function(taskA:BaseTestPlanInstance, taskB:BaseTestPlanInstance) {return  taskA[column] < taskB[column]? -1: 1};
    }
    else{
      if(column == "label") column = 'testPlanName';
      var comparator = function(taskA:BaseTestPlanInstance, taskB:BaseTestPlanInstance) {return  taskA[column] > taskB[column]? -1: 1}; 
    }
    
    this.rowGroups = [];
    let nextRowIndex = 1;
    let table_data = JSON.parse(JSON.stringify(this.table_data));

    for (let i = 0; i < table_data['testPlans'].length; ++i) {

      let testPlan: TestPlan = table_data['testPlans'][i]
      let testPlanInstances  = testPlan.testPlanInstances.sort(comparator);

      let newGroup = {
        ...table_data['testPlans'][i],
        id: i,
        label: testPlan.testPlanName,
        tasks: testPlan.testPlanInstances || [],
        // used for grid rendering
        key: testPlan.testPlanID.toString(),
        startRowIndex: nextRowIndex,
        rows: testPlan.testPlanInstances || []
      } as RowGroupTaskGroup;

      this.rowGroups.push(newGroup);
      const numTasks = !!testPlan.testPlanInstances ? testPlan.testPlanInstances.length : 0;
      nextRowIndex = nextRowIndex + numTasks + 1;
    }
  }
}
