import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'app-generateTestResults',
  templateUrl: './generateTestResults.component.html',
  styleUrls: ['./generateTestResults.component.css'],
})
export class GenerateTestResultsComponent implements OnInit {

  id: string;
  title: { text: string, type: string }[] = [];

  constructor(public modalDialog: MatDialogRef<GenerateTestResultsComponent>, @Inject(MAT_DIALOG_DATA) data) {
    this.id = data.id;
    this.title = [];

    for (let idx = 0; idx < data.title.length; ++idx) {
      let rawText: string = data.title[idx];
      let type = "TEXT";
      if (rawText.indexOf("{{{CODE}}}") > -1) {
        rawText = rawText.replace("{{{CODE}}}", "");
        type = "CODE";
      }
      this.title.push({
        text: rawText,
        type: type
      });
    }
  }

  ngOnInit() {
  }

}
