import { Component } from '@angular/core';
import { MonitoringService } from '../service/monitoring.service';

@Component({
  template: ''
})
export class BaseComponent {

  private myMonitoringService: MonitoringService;

  constructor(monitoringService: MonitoringService) {
    // Manually retrieve the monitoring service from the injector
    // so that constructor has no dependencies that must be passed in from child
    // var providers
    // const injector = ReflectiveInjector.resolveAndCreate([ MonitoringService, ConfigService, AdalService]);
    // this.myMonitoringService = injector.get(MonitoringService);
    this.myMonitoringService = monitoringService;
    this.logNavigation();
  }

  private logNavigation() {
    this.myMonitoringService.logPageView();
  }
}
