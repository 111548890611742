import { Injectable } from '@angular/core'

@Injectable ()

export class jsonValidator{
 isValidJson(inputString: string): boolean {
        try {
          JSON.parse(inputString);
          return true;
        } catch (e) {
          return false;
        }
      }
    }