<div>
  <div class="row no-margin">
    <div class="col-lg-11 no-padding">
      <h1 mat-dialog-title>Generate Test Results</h1>
    </div>
    <div class="col-lg-1 no-padding font-14">
      <button style="color: white" class="bg-success" mat-raised-button mat-button mat-dialog-close="true">Close</button>
    </div>
  </div>
  <div>
    <p>
      <b> Follow below steps to get the reconcilled report for this test run </b>
    </p>
    <div *ngFor="let content of title">
      <pre *ngIf="content.type === 'CODE'"><b>{{content.text}}</b></pre>
      <pre *ngIf="content.type === 'TEXT'">{{content.text}}</pre>
    </div>
  </div>
</div>

