import { MsalGuardConfiguration, MsalInterceptorConfiguration } from '@azure/msal-angular';
import { Configuration, BrowserCacheLocation, IPublicClientApplication, PublicClientApplication, InteractionType } from '@azure/msal-browser';
import { ConfigService } from '../service/config.service';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export const MsalConfig: Configuration = {
    auth: {
        clientId: '65c04ccc-a904-43bd-86d7-f7dedf6ad636',
        authority: "https://login.microsoftonline.com/microsoft.onmicrosoft.com/",
        redirectUri: window.location.origin,
        postLogoutRedirectUri: window.location.origin,        
        navigateToLoginRequestUrl: true
    },
    cache: {
        cacheLocation: BrowserCacheLocation.SessionStorage,
        storeAuthStateInCookie: isIE
    }
};

export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication(MsalConfig);
}

const scopes = ["user.read"];
const protectedResourceMap = new Map<string, Array<string>>();
protectedResourceMap.set("https://graph.microsoft.com/v1.0/", ["user.read"]);

const msalInterceptorConfiguration: MsalInterceptorConfiguration = {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
};

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    return msalInterceptorConfiguration;
}

const msalGuardConfiguration: MsalGuardConfiguration = {
    interactionType: InteractionType.Redirect,
    authRequest: {
        scopes
    },
    loginFailedRoute: "/unauthorized"
};

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return msalGuardConfiguration;
}