<div class="container padding-10">
  <div class="card text-center col-sm-15 text-center padding-15 pos-rel margin-bottom-20" style="border: 1px solid rgba(0,0,0,.125); border-radius: .25rem;">
    <h5>Welcome to Test Platform</h5>
    <h5 class="center-align">
      You are not authorized to access this website
      <br /><br />
      <b>For Access:</b> Please join "SCE Test Platform Testers" Security Group in <a class="fa fa-hand-o-right" target="_blank" href="https://aka.ms/idweb">  Idweb</a>
    </h5>
  </div>
  <br />
  <div class="card col-sm-15 text-center padding-15 pos-rel margin-bottom-20" style="border: 1px solid rgba(0,0,0,.125); border-radius: .25rem;">
    For any questions please reach out to <a role="link" href="mailto:sctestsupport@microsoft.com"><i style="vertical-align: bottom;" class="ms-Icon ms-Icon--Mail"></i> Test Platform Support</a>
  </div>
</div>
