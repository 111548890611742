import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'formly-wrapper-items',
  template: `
    <div>
      <h4 class="ms-fontSize-20 ms-fontWeight-bold margin-bottom-15 margin-top-15">{{ to.label }}</h4>
      <div class="card-columns">
        <ng-container #fieldComponent></ng-container>
      </div>
    </div>
  `,
})
export class ItemsWrapperComponent extends FieldWrapper { }
