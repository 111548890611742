<div *ngIf="is500error" style="width: 100%; overflow: hidden;">
  <app-error [navigationPath]='"manage-testruns"'></app-error>
</div>
<div *ngIf="!is500error" style="width: 100%; overflow: hidden;">
  <div class="row no-margin">
    <div class="col-lg-12 no-padding">
      <ng-sidebar-container style="height: calc(100vh - 100px);">
        <ng-sidebar [(opened)]="sidebarOpened" position="right">
          <div class="row no-margin border-radius-5 back-color-white full-height"
            style="width: 680px; border: 1px solid #ADADAD; overflow-y: auto;">
            <div class="col-lg-12 no-padding pos-rel">
              <div class="event-tracking-topbar-container">
                <div id="event-tracker-exit" class="event-tracking-topbar-item cursor-pointer" style="text-align: left;"
                  (click)="toggleSidebar()">
                  &times;
                </div>
                <div class="event-tracking-topbar-item tracking-order-center padding-top-15 padding-bottom-5"
                  style="border-bottom: 1px solid #d4d4d4; overflow: hidden;text-overflow: ellipsis;">
                  <h6>Tracking Details For</h6>
                  <h4>
                    <a class="anc-tracking" style="text-overflow: ellipsis;"
                      (click)="showPayloadDetails(responseModal, 0, 0, 0, -1, -1)">
                      {{trackOrderRequest.entityID}}
                    </a>
                  </h4>
                </div>
                <button type="button" class="event-tracking-topbar-item btn btn-report" id="report-issue-button"
                  mat-flat-button matTooltip="Problem with tracking an order? Send us an email!"
                  (click)="launchReportIssueMailto(true)">
                  Report Tracking Problem
                </button>
              </div>
              <div class="row no-margin" *ngIf="trackingEventsList.length > 0">
                <div class="col-lg-12 padding-15 font-15"
                  style="height: calc(100vh - 145px); overflow: hidden; overflow-y: auto;">
                  <div class="timeline-centered">
                    <article class="timeline-entry" *ngFor="let trackingItem of trackingEventsList" left-aligned>
                      <div class="timeline-entry-inner">
                        <time class="timeline-time" datetime="trackingItem.eventDate">
                          <span>{{getEventTime(trackingItem.eventDate, trackingItem.eventTime)}}</span>
                          <app-date-picker [(date)]="trackingItem.eventDate" (dateChange)="updateFxn()"
                            ariaLabel="Event Date">
                          </app-date-picker>
                        </time>
                        <div class="timeline-icon" *ngIf="trackingItem.eventStatus == 'Pending'"
                          style="background: #D4D4D4;">
                        </div>
                        <div class="timeline-icon bg-success" *ngIf="trackingItem.eventStatus == 'Success'">
                          <i class="fa fa-check" style="height: 30px; vertical-align: middle;"></i>
                        </div>
                        <div class="timeline-icon bg-danger" *ngIf="trackingItem.eventStatus == 'Error'">
                          <i class="fa fa-exclamation" style="height: 30px; vertical-align: middle;"></i>
                        </div>
                        <div class="timeline-label">
                          <h2>{{trackingItem.eventName}}</h2>
                          <p>
                            <span *ngIf="trackingItem.trackingName != ''">
                              {{trackingItem.trackingName}}:
                            </span> {{trackingItem.trackingId}}
                          </p>
                          <p *ngIf="trackingItem.trackingName != 'CorrelationId'">
                            CorrelationId:
                            {{trackingItem.correlationId}}
                          </p>
                          <p *ngIf="trackingItem.eventName != 'Errors'">
                            {{trackingItem.eventMessage}}
                          </p>
                          <div class="col-lg-12 no-padding" *ngIf="trackingItem.eventName == 'Errors'">
                            <ngx-json-viewer [json]="trackingItem.eventMessage">
                            </ngx-json-viewer>
                          </div>
                        </div>
                      </div>
                    </article>
                    <article class="timeline-entry begin">
                      <div class="timeline-entry-inner">
                        <div class="timeline-icon"
                          style="-webkit-transform: rotate(-90deg); -moz-transform: rotate(-90deg);">
                          <i class="entypo-flight"></i>
                        </div>
                      </div>
                    </article>
                  </div>
                </div>
              </div>
              <div class="row no-margin" *ngIf="trackingEventsList.length <= 0">
                <div class="col-lg-12 padding-15 font-15 text-center bold" style="color: #8b0000;">
                  No Events Details found for the Order Number
                </div>
              </div>
            </div>
          </div>
        </ng-sidebar>
        <div ng-sidebar-content style="height: calc(100vh - 100px); padding-right: 10px; overflow-y: hidden;"
          *ngIf="displayDetailedView === false">
          <div class="row no-margin">
            <div class="col-lg-12 no-padding">
              <h3>
                Manage Test Runs
                <span>
                  <i class="fa fa-info-circle" style="font-size: 0.5em;"
                    ngbTooltip="Test run is a systematic execution of a test case. Repeated execution of a test case produces different test runs which might have same or different outcomes. One Test run corresponds to one e2e execution of a Test Case."></i>
                </span>
              </h3>
              <br />
            </div>
          </div>
          <div class="row no-margin padding-top-10 padding-bottom-10 steps-statusinfo ">
            <div class="no-padding" style="width: 2000px;">
              <div class="row no-margin" style="height: 38px; width: 1000px;">
                <div class="text-right bold" style="padding-right:0px">Search by ID :</div>
                <div class="col-lg-3 no-padding bold" style="margin-left:79px; line-height: 28px; max-width: 450px;">
                  <div class="form-group" style="width: 450px;">
                    <input type="text" id="inpPartNo" class="form-control" placeholder="Enter Search ID..."
                      aria-label="TestRunID" [(ngModel)]="searchText" />
                  </div>
                </div>

                <div class="col-lg-3 padding-left-10 padding-right-10 button-even-spacing" style="max-width: 150px;">
                  <button type="button" style="background-color: rgb(0, 120, 212); border-radius: .25rem;"
                    [disabled]="(searchText == null || searchText == '') && (searchTestName == null || searchTestName == '')"
                    (click)="filterTestRuns()" aria-label="Search" #searchByIdButton>
                    Search
                  </button>
                  <button type="button" class="btn btn-success"
                    [disabled]="!loadTrackEvents && (searchText == null || searchText == '') && (searchTestName == null || searchTestName == '')"
                    (click)="loadDBTestRunsItemDetails()" aria-label="Clear">
                    Clear
                  </button>
                </div>
              </div>
            </div>
            <div class="row no-margin padding-top-10 padding-bottom-10" style="width:2000px;">
              <div class="text-right bold" style="padding-right: 30px"> Or Search by Name : </div>
              <div class="col-lg-3 no-padding bold" style="margin-left:0px; line-height: 28px; max-width: 450px;">
                <div class="form-group" style="width: 450px;">
                  <input type="text" class="form-control" placeholder="Enter Test Case Name..." aria-label="TestRunID"
                    [(ngModel)]="searchTestName" />
                </div>
              </div>
            </div>
            <div class="row no-margin padding-top-10 padding-bottom-10">
              <div class="text-right bold" style="padding-right: 40px"> Or Search by user : </div>
              <div style="width: 450px">
                <ng-template #selectUserTooltip>Select test runs by User!</ng-template>
                <ng-select role="option" [items]="testRunsUsersDetails" bindLabel="userName" bindValue="userEmail"
                  placeholder="Select User Test Run" [(ngModel)]="selectedUserEmail"
                  (change)="loadDBTestRunsItemDetails()" *ngIf="testRunsUsersDetails.length > 0"
                  arial-label="Select User" title="Select User" aria-expanded="false" aria-required="true"
                  [ngbTooltip]="selectUserTooltip" placement="right" [openDelay]="500" [closeDelay]="1500">
                </ng-select>
              </div>
            </div>
            <div class="bold no-padding-right no-padding-left status-info" style="padding-top:10px; width:100%;">
              <div>Status:</div>
              <div>
                <i class="fa md-step-icon fa-hourglass md-step-circle-waiting" aria-hidden="true"></i>Waiting
              </div>
              <div>
                <i class="fa md-step-icon fa-cog md-step-circle-processing" aria-hidden="true"></i>Processing
              </div>
              <div>
                <i class="fa md-step-icon fa-clock-o md-step-circle-pending" aria-hidden="true"></i>Pending
              </div>
              <div>
                <i class="fa md-step-icon fa-check md-step-circle-complete" aria-hidden="true"></i>Complete
              </div>
              <div>
                <i class="fa md-step-icon fa-exclamation md-step-circle-error" aria-hidden="true"></i>Error
              </div>
              <div>
                <i class="fa md-step-icon fa-fast-forward md-step-circle-skipped" aria-hidden="true"></i>Skipped
              </div>
            </div>
          </div>
          <hr class="no-margin" />
          <div class="row padding-top-10 padding-bottom-10 no-margin pos-rel" id="testRunsWrapper"
            style="height: calc(100vh - 332px); overflow: hidden; overflow-y: auto;">
            <div class="col-lg-12 no-padding" *ngIf="testRunsList.length > 0">
              <div class="row no-margin margin-bottom-25 full-width border-radius-10 testrun-wrapper"
                [ngStyle]="{'background-color': selectedTestRunId == run.testRunId ? 'rgb(229, 229, 229)' : '#FFFFFF'}"
                *ngFor="let run of testRunsList; index as p;">
                <div class="col-lg-12 no-padding">
                  <div class="row no-margin"
                    style="background-color: rgb(229, 229, 229); border-top-left-radius: 10px; border-top-right-radius: 10px;">
                    <div class="col-lg-4 padding-10 header-section">
                      <label (click)="showTestRunDetails(p)" style="margin: 0; cursor: pointer;">
                        <span>
                          <i class="fa fa-plus-circle" style="color: #007bff; font-size: 25px;"
                            *ngIf="selectedTestRunId != run.testRunId"></i>
                          <i class="fa fa-minus-circle" style="color: #007bff; font-size: 25px;"
                            *ngIf="selectedTestRunId == run.testRunId"></i>
                        </span>
                        <span class="margin-left-5" style="vertical-align: text-bottom;">
                          <span class="font-14">
                            Test&nbsp;Run:&nbsp;
                          </span>
                          <ng-template #testRunNameTooltip>
                            {{run.testRunName.replace("''", "'")}}
                          </ng-template>
                          <span *ngIf="testRunNameExceedsLimit(run.testRunName); else omitTooltip" class="font-15"
                            [ngbTooltip]="testRunNameTooltip" [openDelay]="500" [closeDelay]="1500">
                            <b>{{truncateTestRunName(run.testRunName)}}</b>
                          </span>
                          <ng-template #omitTooltip class="font-15">
                            <b>{{truncateTestRunName(run.testRunName)}}</b>
                          </ng-template>
                          <input type="hidden" value="{{run.testRunId}}" />
                        </span>
                      </label>
                      <a role="button" aria-label="Refresh" class="anc-testrun margin-left-10"
                        *ngIf="run.statusCounts.start > 0 || run.statusCounts.waiting > 0 || run.statusCounts.ready > 0 || run.statusCounts.processing > 0 || run.statusCounts.pending > 0"
                        (click)="refreshTestRuns(p, run.testRunId, false, true)">
                        <i class="fa fa-refresh" placement="left" ngbTooltip="Refresh Test Run Status"
                          tooltipClass="side-tooltip" *ngIf="p == 0"></i>
                        <i class="fa fa-refresh" placement="top" ngbTooltip="Refresh Test Run Status"
                          tooltipClass="top-tooltip" *ngIf="p > 0"></i>
                      </a>
                      <a role="button" aria-label="Restart" class="anc-testrun margin-left-10"
                        *ngIf="run.statusCounts.error > 0" (click)="restartAllErrorSteps(responseModal, p)">
                        <i class="fa fa-repeat" placement="right" ngbTooltip="Restart All Error Steps"
                          tooltipClass="side-tooltip" *ngIf="p == 0"></i>
                        <i class="fa fa-repeat" placement="top" ngbTooltip="Restart All Error Steps"
                          tooltipClass="top-tooltip" *ngIf="p > 0"></i>
                      </a>
                      <a role="button" class="anc-testrun margin-left-10 display-none"
                        (click)="updateSaveStatus(responseModal, p)">
                        <i class="fa fa-floppy-o" placement="right" ngbTooltip="Save this Test Run"
                          tooltipClass="side-tooltip" *ngIf="!run.isSavedRun && p == 0"></i>
                        <i class="fa fa-floppy-o" placement="top" ngbTooltip="Save this Test Run"
                          tooltipClass="top-tooltip" *ngIf="!run.isSavedRun && p > 0"></i>
                        <i class="fa fa-ban" placement="right" ngbTooltip="UnSave this Test Run"
                          tooltipClass="side-tooltip" *ngIf="run.isSavedRun && p == 0"></i>
                        <i class="fa fa-ban" placement="top" ngbTooltip="UnSave this Test Run"
                          tooltipClass="top-tooltip" *ngIf="run.isSavedRun && p > 0"></i>
                      </a>
                    </div>
                    <div class="col-lg-2 padding-10 text-center status-count-details">
                      <div class="row">
                        <div class="col-lg-4 text-right no-padding-right" style="line-height: 28px;">
                          <span class="font-15">
                            Business Process (L4)&nbsp;
                          </span>

                          <!-- Removed the count
                           <span class="cursor-pointer font-17"
                            *ngIf="!(run.isMultiPayload || run.totalScenariosCount > 10)"
                            (click)="filterTestScenarios(p, 'all')">
                            <strong>
                              (<span style="color: #0069da;">{{run.totalScenariosCount}}</span>)
                            </strong>
                          </span> -->
                          <!-- <span class="cursor-pointer font-17"
                            *ngIf="run.isMultiPayload || run.totalScenariosCount > 10" (click)="showTestRunDetails(p)">
                            <strong>
                              (<span style="color: #0069da;">{{run.totalScenariosCount}}</span>)
                            </strong>
                          </span> -->
                        </div>
                        <div class="col-lg-8 text-left no-padding-left"
                          *ngIf="!(run.isMultiPayload || run.totalScenariosCount > 10)">
                          <span class="md-step-count md-step-circle-waiting cursor-pointer"
                            *ngIf="run.statusCounts.waiting > 0" (click)="filterTestScenarios(p, 'waiting')">
                            <i class="fa md-step-icon fa-hourglass" aria-hidden="true"></i>
                            <strong>({{run.statusCounts.waiting}})</strong>
                          </span>
                          <span class="md-step-count md-step-circle-processing cursor-pointer"
                            (click)="filterTestScenarios(p, '')"
                            *ngIf="run.statusCounts.start > 0 || run.statusCounts.ready > 0 || run.statusCounts.processing > 0">
                            <i class="fa md-step-icon fa-cog" aria-hidden="true"></i>
                            <strong>({{(run.statusCounts.start + run.statusCounts.ready +
                              run.statusCounts.processing)}})</strong>
                          </span>
                          <span class="md-step-count md-step-circle-pending cursor-pointer"
                            *ngIf="run.statusCounts.pending > 0" (click)="filterTestScenarios(p, 'pending')">
                            <i class="fa md-step-icon fa-clock-o" aria-hidden="true"></i>
                            <strong>({{run.statusCounts.pending}})</strong>
                          </span>
                          <span class="md-step-count md-step-circle-complete cursor-pointer"
                            *ngIf="run.statusCounts.complete > 0" (click)="filterTestScenarios(p, 'complete')">
                            <i class="fa md-step-icon fa-check" aria-hidden="true"></i>
                            <strong>({{run.statusCounts.complete}})</strong>
                          </span>
                          <span class="md-step-count md-step-circle-error cursor-pointer"
                            *ngIf="run.statusCounts.error > 0" (click)="filterTestScenarios(p, 'error')">
                            <i class="fa md-step-icon fa-exclamation" aria-hidden="true"></i>
                            <strong>({{run.statusCounts.error}})</strong>
                          </span>
                        </div>
                        <div class="col-lg-8 text-left no-padding-left"
                          *ngIf="run.isMultiPayload || run.totalScenariosCount > 10">
                          <span class="md-step-count md-step-circle-waiting" *ngIf="run.statusCounts.waiting > 0">
                            <i class="fa md-step-icon fa-hourglass" aria-hidden="true"></i>
                            <strong>({{run.statusCounts.waiting}})</strong>
                          </span>
                          <span class="md-step-count md-step-circle-processing"
                            *ngIf="run.statusCounts.start > 0 || run.statusCounts.ready > 0 || run.statusCounts.processing > 0">
                            <i class="fa md-step-icon fa-cog" aria-hidden="true"></i>
                            <strong>({{(run.statusCounts.start + run.statusCounts.ready +
                              run.statusCounts.processing)}})</strong>
                          </span>
                          <span class="md-step-count md-step-circle-pending" *ngIf="run.statusCounts.pending > 0">
                            <i class="fa md-step-icon fa-clock-o" aria-hidden="true"></i>
                            <strong>({{run.statusCounts.pending}})</strong>
                          </span>
                          <span class="md-step-count md-step-circle-complete" *ngIf="run.statusCounts.complete > 0">
                            <i class="fa md-step-icon fa-check" aria-hidden="true"></i>
                            <strong>({{run.statusCounts.complete}})</strong>
                          </span>
                          <span class="md-step-count md-step-circle-error" *ngIf="run.statusCounts.error > 0">
                            <i class="fa md-step-icon fa-exclamation" aria-hidden="true"></i>
                            <strong>({{run.statusCounts.error}})</strong>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-2 padding-10 text-bottom text-center"
                      style="font-size: 14px; line-height: 30px;">
                      <span *ngIf="testRunsFetchInputs.userEmail.toLowerCase() == 'all'">
                        Created&nbsp;By:&nbsp;<b>{{run.userName}}</b>
                      </span>
                    </div>
                    <div class="col-lg-2 padding-10 text-center">
                      <div *ngIf="run.partnerOrgName!==null && run.partnerOrgName!==''" class="row">
                        <div style="font-weight: 400;">Partner Info:&nbsp;<span
                            style="font-weight: 500;">{{run.partnerOrgName}}-{{run.businessProcessName}}</span></div>
                      </div>
                    </div>


                    <div class="col-lg-2 padding-10 text-right" style=" line-height: 20px; ">
                      <ng-container *ngIf="!loadTrackEvents">
                        <span class="font-14">
                          Date:&nbsp;<strong>
                            <app-date-picker [(date)]="run.executionDate" (dateChange)="updateFxn()"
                              ariaLabel="Execution Date">
                            </app-date-picker>
                          </strong>
                        </span>
                        <ng-container
                          *ngIf="run.trackingType !== undefined && run.trackingType !== null && run.trackingType !== '' && run.trackingType.length > 0 && (run.trackingIDs || []).length > 0 && run.trackingType.toLocaleLowerCase() != '3ppsalesorder'; else noTrackOrderLink">
                          <br />
                          <a [routerLink]="['/entitytracking', getTrackingUrlParams(p,run)]">
                            <strong>Track Orders</strong>
                          </a>
                        </ng-container>
                        <ng-template #noTrackOrderLink>
                          <br />
                          <span class="text-muted" [ngbTooltip]="noTrackOrderLinkTooltip"
                            tooltipClass="entityIdsWideTooltip" placement="left">
                            Track Orders
                          </span>
                        </ng-template>
                      </ng-container>
                      <ng-template #noTrackOrderLinkTooltip>
                        No suitable Orders available for Tracking.
                      </ng-template>
                      <ng-container *ngIf="loadTrackEvents">
                        <span class="font-14">
                          <ng-container
                            *ngIf="run.trackingType !== undefined && run.trackingType !== null && run.trackingType !== '' && run.trackingType.length > 0 && (run.trackingIDs || []).length > 0 && run.trackingType.toLocaleLowerCase() != '3ppsalesorder'; else noTracking">
                            <a [routerLink]="['/entitytracking', getTrackingUrlParams(p,run)]">
                              Tracking:&nbsp;<strong>{{searchText}}</strong>
                            </a>
                          </ng-container>
                          <ng-template #noTracking>
                            Tracking:&nbsp;<strong>{{searchText}}</strong>
                          </ng-template>
                        </span>
                      </ng-container>
                      <span>
                        <a class="anc-testrun margin-left-10 display-none"
                          *ngIf="((run.statusCounts.complete + run.statusCounts.error) == run.scenariosDetailsList.length)"
                          (click)="downloadTestRunDetails(p)">
                          <i class="fa fa-download" aria-hidden="true" placement="left" ngbTooltip="Download Details"
                            tooltipClass="side-tooltip"></i>
                        </a>
                      </span>
                    </div>
                  </div>
                  <div class="row no-margin padding-top-5 padding-left-15 padding-right-15 padding-bottom-5"
                    *ngIf="!displayDetailedView && selectedTestRunId == run.testRunId && run.scenariosDetailsList.length > 0">
                    <div class="col-lg-12 padding-left-20 padding-right-20">
                      <ng-container *ngFor="let scenario of run.scenariosDetailsList; index as j;">
                        <div *ngIf="scenario.isVisible"
                          class="row no-margin margin-bottom-10 full-width border-radius-10 font-15 testrun-wrapper-child">
                          <div class="col-lg-12 no-padding">
                            <div class="row no-margin"
                              *ngIf="scenario.scenarioName != undefined && scenario.scenarioName != null && scenario.scenarioName != ''">
                              <div class="col-lg-3 padding-15 text-left" style="text-transform: uppercase;">
                                ID:&nbsp;<a
                                  [routerLink]="['/', 'manage-testruns', {org: organizationId.toString(),searchId:scenario.correlationId}]"
                                  target="_blank">{{scenario.correlationId}}</a>
                              </div>
                              <div class="col-lg-3 padding-15 text-center">
                                Business Process (L4):&nbsp;<b>{{scenario.scenarioName}}</b>
                              </div>
                              <div class="col-lg-3 padding-15 text-center">
                                Sub-Process:&nbsp;<b>{{scenario.flavourName}}</b>
                              </div>
                              <div class="col-lg-3 padding-15 text-right">
                                Actual&nbsp;Duration:&nbsp;<b>{{getScenarioExecutionTime(p, j)}} Minutes</b><br>
                                <span class="font-14" *ngIf="shouldShowScenarioETA(p, j)">
                                  ETA:&nbsp;
                                  <b style="color: #996b1c;">
                                    <app-date-picker [(date)]="scenario.estimatedCompletionDateTime"
                                      (dateChange)="updateFxn()" ariaLabel="Estimated Completion Date">
                                    </app-date-picker>
                                  </b>
                                </span>
                              </div>
                            </div>
                            <div class="row no-margin">
                              <div class="col-lg-12 col-md-12" style="max-width: 100%; overflow-y: auto;"
                                id="testruns-wrapper-child" (scroll)="saveScrollPosition($event)">
                                <div class="search-bar" style="width:30%">
                                  <ng-select bindLabel="componentDescription" [addTag]="addTagFn()" addTagText="Search For"
                                    (change)="selectStep($event,scenario,p)" placeholder="Select steps" appendTo="body"
                                    (clear)="filteredStepsDetailsList('',scenario,p)" [searchable]="true"
                                    [(ngModel)]="searchTerm">
                                    <ng-option [value]="step.componentDescription"
                                      *ngFor="let step of scenario.stepsDetailsList">

                                      {{step.componentDescription}}
                                    </ng-option>
                                  </ng-select>
                                  

                                </div>

                                <div class="md-stepper">
                                  <div class="md-step text-center"
                                    *ngFor="let step of scenario.stepsDetailsList; index as x">
                                    <div class="md-step-circle">
                                      <i class="fa md-step-icon cursor-pointer" aria-hidden="true"
                                        matTooltip="Retry is pending."
                                        [matTooltipDisabled]="step.stepStatus != 'Pending'"
                                        [ngClass]="getStepStyleClass(step.stepStatus.toLowerCase(), true)"
                                        (click)="showPayloadDetails(responseModal, p, j, x, -1, -1)"
                                        *ngIf="(step.stepStatus.toLowerCase() == 'complete' || step.stepStatus.toLowerCase() == 'error' || step.stepStatus.toLowerCase() == 'skipped' || step.stepStatus.toLowerCase() == 'pending')"></i>
                                      <i class="fa md-step-icon" aria-hidden="true"
                                        [ngClass]="getStepStyleClass(step.stepStatus.toLowerCase(), true)"
                                        *ngIf="(step.stepStatus.toLowerCase() != 'complete' && step.stepStatus.toLowerCase() != 'error' && step.stepStatus.toLowerCase() != 'skipped' && step.stepStatus.toLowerCase() != 'pending')"></i>
                                    </div>
                                    <div class="md-step-title"
                                      [ngClass]="step.stepStatus != '' ? 'md-step-title-bold' : 'md-step-title-light'">
                                      {{step.componentDescription}}
                                    </div>
                                    <div class="md-step-tracking margin-top-5"
                                      *ngIf="step.displayTracking && step.stepStatus.toLowerCase() == 'complete'">
                                      <div class=bold *ngIf="disableTracking(p, j, x) == true">
                                        Purchase Order Number(s):&nbsp;{{getTrackingID(p, j, x)}}
                                      </div>
                                      <div *ngIf="disableTracking(p, j, x) == false">
                                        <a class="anc-tracking cursor-pointer bold"
                                          (click)="displayTrackingDetails(p, j, x)">Track&nbsp;Events:&nbsp;{{getTrackingID(p,
                                          j, x)}}</a>
                                      </div>
                                    </div>
                                    <div class="margin-top-5" style="font-size:13px" *ngIf="shouldShowStepETA(p, j, x)">
                                      ETA:&nbsp;<b style="color: #996b1c;">
                                        <app-date-picker [(date)]="step.estimatedCompletionDateTime"
                                          (dateChange)="updateFxn()" ariaLabel="Estimated Completion Date">
                                        </app-date-picker>
                                      </b>
                                    </div>
                                    <div class="md-step-bar-left"></div>
                                    <div class="md-step-bar-right"></div>
                                    <div class="row no-margin"
                                      *ngIf="step.childScenariosList.length > 0 && isShowChildScenarios">
                                      <div class="col-lg-12 col-md-12 text-center">
                                        <div class="row"
                                          *ngFor="let childScenario of step.childScenariosList; index as z">
                                          <div class="col-lg-12 col-md-12">
                                            <div class="md-child-stepper" style="width: 50%;">
                                              <div class="md-child-step text-center"
                                                *ngFor="let childStep of childScenario.stepsDetailsList; index as y">
                                                <div class="md-step-circle">
                                                  <i class="fa md-step-icon cursor-pointer" aria-hidden="true"
                                                    [ngClass]="getStepStyleClass(childStep.stepStatus.toLowerCase(), true)"
                                                    (click)="showPayloadDetails(responseModal, p, j, x, z, y)"
                                                    *ngIf="(childStep.stepStatus.toLowerCase() == 'complete' || childStep.stepStatus.toLowerCase() == 'error' || childStep.stepStatus.toLowerCase() == 'skipped')"></i>
                                                  <i class="fa md-step-icon" aria-hidden="true"
                                                    [ngClass]="getStepStyleClass(childStep.stepStatus.toLowerCase(), true)"
                                                    *ngIf="(childStep.stepStatus.toLowerCase() != 'complete' && childStep.stepStatus.toLowerCase() != 'error' && childStep.stepStatus.toLowerCase() != 'skipped')"></i>
                                                </div>
                                                <div class="md-step-title"
                                                  [ngClass]="childStep.stepStatus != '' ? 'md-step-title-bold' : 'md-step-title-light'">
                                                  {{childStep.componentDescription}}
                                                </div>
                                                <div class="md-step-tracking margin-top-5"
                                                  *ngIf="childStep.displayTracking && step.stepStatus.toLowerCase() == 'complete'">
                                                  <a class="anc-tracking cursor-pointer bold"
                                                    (click)="displayTrackingDetails(p, j, x, z, y)">Track&nbsp;Events</a>
                                                </div>
                                                <div class="md-child-step-bar-left">
                                                </div>
                                                <div class="md-child-step-bar-right">
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="row no-margin margin-top-10"
                                      *ngIf="step.childScenariosList.length > 0 && !isShowChildScenarios">
                                      <div class="col-lg-12 col-md-12 no-padding text-center">
                                        <a class="cursor-pointer bold font-14" style="color: #215892;"
                                          (click)="showChildScenarios(p)">
                                          (Show&nbsp;Child&nbsp;Steps)
                                        </a>
                                      </div>
                                    </div>
                                    <div class="row no-margin"
                                      *ngIf="step.childScenariosList.length > 0 && isShowChildScenarios">
                                      <div class="col-lg-12 col-md-12 no-padding text-center">
                                        <a class="cursor-pointer bold font-14" style="color: #215892;"
                                          (click)="showChildScenarios(p)">
                                          Hide&nbsp;Child&nbsp;Steps
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="row margin-bottom-25 margin-top-25 text-center">
                                  <div class="col-md-6"><a
                                      ngbTooltip="Clone the parameters of this test case in the Create Test Run Page"
                                      class="cursor-pointer font-16" style="color: #215892;"
                                      [routerLink]="['/execute-testruns', getCloneRequestParams(scenario.correlationId)]">
                                      <strong>Clone&nbsp;Test&nbsp;Case</strong>
                                    </a></div>
                                  <div class="col-md-6"><a
                                      ngbTooltip="Recreate and submit this test case with same parameters"
                                      class="cursor-pointer font-16" style="color: #215892;"
                                      (click)="retriggerTestRunScenario(run, scenario.correlationId, retriggerResponseModal, retriggerConfirmationModal)">
                                      <strong>Retrigger&nbsp;Test&nbsp;Case</strong>
                                    </a></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                  <div class="row no-margin" *ngIf="selectedTestRunId !== run.testRunId">
                    <div class="col-lg-12 col-md-12 padding-10 text-center">
                      <a class="cursor-pointer bold" style="color: #215892;" (click)="showTestRunDetails(p)">
                        More&nbsp;Details
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row no-margin" *ngIf="testRunsList.length < totalTestRunsCount">
                <div class="col-lg-12 text-center" id="loadMore">
                  <a class="cursor-pointer" (click)="loadMoreTestRuns()">Load More</a>
                </div>
              </div>
              <a role="button" aria-label="scroll" id="scroll" [hidden]="checkOverflow()"
                (click)="scrollToTop()"><span></span></a>
            </div>
            <div class="col-lg-12 no-padding" *ngIf="testRunsList.length <= 0">
              <div class="row no-margin padding-20">
                <div class="col-lg-12 text-center">
                  <div class="margin-top-20 error-text bold" style="color: #8b0000;"
                    *ngIf="testRunsFetchInputs.testCorrelationId !== undefined && testRunsFetchInputs.testCorrelationId!== null && testRunsFetchInputs.testCorrelationId !== ''">
                    No information found for searched Test Run Id. Please check and enter a correct Test Run Id and try
                    again!
                  </div>
                  <div class="margin-top-20 error-text bold" style="color: #8b0000;"
                    *ngIf="(testRunsFetchInputs.testCorrelationId === undefined || testRunsFetchInputs.testCorrelationId === null || testRunsFetchInputs.testCorrelationId === '') && !loadTrackEvents">
                    You have not executed any test runs! To execute a test run, please go to "Execute
                    Test Runs" and follow the procedures to Submit a test run!
                  </div>
                  <div class="margin-top-20 error-text bold" style="color: #8b0000;"
                    *ngIf="(testRunsFetchInputs.testCorrelationId === undefined || testRunsFetchInputs.testCorrelationId === null || testRunsFetchInputs.testCorrelationId === '') && loadTrackEvents">
                    No information found for entered Service Order Number. Please check and enter a
                    correct Service Order Number and try again!
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div ng-sidebar-content style="height: calc(100vh - 100px); padding: 0px 10px;"
          *ngIf="displayDetailedView === true">
          <div class="row no-margin pos-rel margin-bottom-10">
            <div class="col-lg-12 no-padding-left text-left">
              <button aria-disabled="false" aria-label="Back" role="button"
                class="btn btn-primary margin-right-10 padding-10 font-14"
                style="line-height: 16px; vertical-align: middle; margin-top: -8px;" type="button"
                (click)="showTestRunDetails(selectedTestRunIndex)">&#11160;
                Back</button>
              <span class="testrun-namesection">
                <a role="link" [routerLink]="['/manage-testruns', getUrlParams(selectedTestRun.testRunId)]"
                  target="_blank">
                  {{selectedTestRun.testRunName}}
                </a>
              </span>
              <a role="button" aria-label="Refresh" class="anc-testrun margin-left-10"
                *ngIf="selectedTestRun.statusCounts.start > 0 || selectedTestRun.statusCounts.waiting > 0 || selectedTestRun.statusCounts.ready > 0 || selectedTestRun.statusCounts.processing > 0 || selectedTestRun.statusCounts.pending > 0"
                (click)="refreshTestRuns(selectedTestRunIndex, selectedTestRun.testRunId)">
                <i class="fa fa-refresh" placement="left" ngbTooltip="Refresh Test Run Status"
                  tooltipClass="side-tooltip"></i>
              </a>
              <a role="button" aria-label="Restart" class="anc-testrun margin-left-10"
                *ngIf="selectedTestRun.statusCounts.error > 0"
                (click)="restartAllErrorSteps(responseModal, selectedTestRunIndex)">
                <i class="fa fa-repeat" placement="right" ngbTooltip="Restart All Error Steps"
                  tooltipClass="side-tooltip"></i>
              </a>
            </div>
          </div>
          <div id="dvTestRunData" class="row no-margin margin-bottom-15">
            <div class="col-lg-12 testrun-block">
              <div class="row margin-bottom-15">
                <div class="col-lg-4">
                  <span class="ms-fontWeight-bold">Test&nbsp;ID:&nbsp;</span>
                  <span>{{selectedTestRun.testRunId}}</span>
                </div>
                <div class="col-lg-4">
                  <span class="ms-fontWeight-bold">Owner:&nbsp;</span>
                  <span>{{selectedTestRun.userName}}</span>
                </div>
                <div class="col-lg-4" rowspan="2">
                  <span class="ms-fontWeight-bold">Status:&nbsp;</span>
                  <span class="status-count-details">
                    <span class="md-step-count md-step-circle-waiting" *ngIf="selectedTestRun.statusCounts.waiting > 0">
                      <i class="fa md-step-icon fa-hourglass" aria-hidden="true"></i>
                      <strong>({{selectedTestRun.statusCounts.waiting}})</strong>
                    </span>
                    <span class="md-step-count md-step-circle-processing"
                      *ngIf="selectedTestRun.statusCounts.start > 0 || selectedTestRun.statusCounts.ready > 0 || selectedTestRun.statusCounts.processing > 0">
                      <i class="fa md-step-icon fa-cog" aria-hidden="true"></i>
                      <strong>({{(selectedTestRun.statusCounts.start + selectedTestRun.statusCounts.ready +
                        selectedTestRun.statusCounts.processing)}})</strong>
                    </span>
                    <span class="md-step-count md-step-circle-pending" *ngIf="selectedTestRun.statusCounts.pending > 0">
                      <i class="fa md-step-icon fa-clock-o" aria-hidden="true"></i>
                      <strong>({{selectedTestRun.statusCounts.pending}})</strong>
                    </span>
                    <span class="md-step-count md-step-circle-complete"
                      *ngIf="selectedTestRun.statusCounts.complete > 0">
                      <i class="fa md-step-icon fa-check" aria-hidden="true"></i>
                      <strong>({{selectedTestRun.statusCounts.complete}})</strong>
                    </span>
                    <span class="md-step-count md-step-circle-error" *ngIf="selectedTestRun.statusCounts.error > 0">
                      <i class="fa md-step-icon fa-exclamation" aria-hidden="true"></i>
                      <strong>({{selectedTestRun.statusCounts.error}})</strong>
                    </span>
                  </span>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4">
                  <span class="ms-fontWeight-bold">Created&nbsp;On:&nbsp;</span>
                  <span>
                    <app-date-picker [(date)]="selectedTestRun.executionDate" (dateChange)="updateFxn()"
                      ariaLabel="Created On">
                    </app-date-picker>
                  </span>
                </div>
                <div class="col-lg-4">
                  <span class="ms-fontWeight-bold">Last&nbsp;Modified&nbsp;On:&nbsp;</span>
                  <span>
                    <app-date-picker [(date)]="selectedTestRun.lastModifiedDate" (dateChange)="updateFxn()"
                      ariaLabel="Last Modified">
                    </app-date-picker>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div id="dvPreStepDetails" class="row no-margin margin-bottom-15"
            *ngIf="selectedTestRun.testRunPrestepDetails !== undefined && selectedTestRun.testRunPrestepDetails !== null">
            <div class="col-lg-12 testrun-block">
              <div class="row">
                <div class="col-lg-6">
                  <h5>Prerequisites</h5>
                </div>
                <div class="col-lg-6" style="text-align: right;">
                </div>
              </div>
              <div class="row" *ngIf="selectedTestRun.testRunPrestepDetails.stepsDetailsList.length > 0">
                <div class="col-lg-12 prestep-details" style="max-height: 200px; overflow: hidden; overflow-y: auto;">
                  <table class="ms-Table ms-Table--fixed" style="width: 100%;">
                    <thead>
                      <tr class="row no-margin" style="border-bottom: 1px solid #c3c3c3;">
                        <th class="col-2 col-lg-2">
                          Step
                        </th>
                        <th class="col-3 col-lg-3">
                          Step Description
                        </th>
                        <th class="col-2 col-lg-2 text-center">
                          Step Rank
                        </th>
                        <th class="col-1 col-lg-1 text-center">
                          Status
                        </th>
                        <th class="col-2 col-lg-2 text-center">
                          Execution Date
                        </th>
                        <th class="col-2 col-lg-2 text-center">
                          ETA
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="row no-margin"
                        *ngFor="let stepDetails of selectedTestRun.testRunPrestepDetails.stepsDetailsList; let i = index">
                        <td class="col-2 col-lg-2">
                          {{stepDetails['componentName']}}
                        </td>
                        <td class="col-3 col-lg-3">
                          {{stepDetails['componentDescription']}}
                        </td>
                        <td class="col-2 col-lg-2 text-center">
                          {{stepDetails['stepRank']}}
                        </td>
                        <td class="col-1 col-lg-1 text-center">
                          <div class="md-step-circle steps-statusinfo">
                            <i class="fa md-step-icon cursor-pointer" aria-hidden="true" matTooltip="Retry is pending."
                              [matTooltipDisabled]="stepDetails.stepStatus.toLowerCase() != 'pending'"
                              [ngClass]="manageTestRunsService.getStepStyleClass(stepDetails.stepStatus.toLowerCase(), true)"
                              *ngIf="stepDetails.stepStatus.toLowerCase() == 'complete' || stepDetails.stepStatus.toLowerCase() == 'error' || stepDetails.stepStatus.toLowerCase() == 'skipped' || stepDetails.stepStatus.toLowerCase() == 'pending'"
                              (click)="showPayloadDetails(responseModal, selectedTestRunIndex, -1, i, -1, -1, true)"></i>
                            <i class="fa md-step-icon" aria-hidden="true"
                              [ngClass]="manageTestRunsService.getStepStyleClass(stepDetails.stepStatus.toLowerCase(), true)"
                              *ngIf="stepDetails.stepStatus.toLowerCase() != 'complete' && stepDetails.stepStatus.toLowerCase() != 'error' && stepDetails.stepStatus.toLowerCase() != 'skipped' && stepDetails.stepStatus.toLowerCase() != 'pending'"></i>
                          </div>
                        </td>
                        <td class="col-2 col-lg-2 text-center">
                          <app-date-picker [(date)]="stepDetails['componentCreated']" (dateChange)="updateFxn()"
                            ariaLabel="Created On">
                          </app-date-picker>
                        </td>
                        <td class="col-2 col-lg-2 text-center">
                          <app-date-picker [(date)]="stepDetails['estimatedCompletionDateTime']"
                            (dateChange)="updateFxn()" ariaLabel="Estimated Completion Date">
                          </app-date-picker>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div id="dvTestCasesDetails" class="row no-margin">
            <div class="col-lg-12 testrun-block">
              <div class="row">
                <div class="col-lg-6">
                  <h5>Test&nbsp;Cases</h5>
                </div>
                <div class="col-lg-6" style="text-align: right;" *ngIf="!checkAnyPreReqErrorExist()">
                  <span style="display: inline-block;" *ngIf="isDisplayFilterOption()">
                    <h6>Filter:&nbsp;</h6>
                  </span>
                  <span style="display: inline-block; width: 150px; text-align: left;">
                    <ng-select role="option" [items]="filterItems" bindLabel="name" bindValue="id" placeholder="Select"
                      [(ngModel)]="statusFilter" (change)="filterTestScenarios(selectedTestRunIndex, true, false)"
                      [clearable]="false" [searchable]="false" arial-label="Select Filter" title="Select Filter"
                      aria-expanded="false" *ngIf="isDisplayFilterOption()">
                    </ng-select>
                  </span>
                  <span style="display: inline-block;">
                    <button type="button" class="btn btn-primary tool-tip-button" *ngIf="isDisplayExportOption()"
                      (click)="filterTestScenarios(selectedTestRunIndex, true, true)" aria-label="Export"
                      ngbTooltip="Export Test Runs" id="test-run-tool-tip" [closeDelay]="1000" triggers="click"
                      placement="left" style="margin-top: -5px; margin-left: 10px;">
                      <i class="fa fa-download" style="color: #ffffff;"></i>
                    </button>
                  </span>
                </div>
              </div>
              <div class="testrun-details"
                *ngIf="selectedTestRun.scenariosDetailsList.length > 0 && !checkAnyPreReqErrorExist()">
                <table class="ms-Table ms-Table--fixed" style="width: 100%; position: relative;">
                  <thead style="position: absolute; width: 100%;">
                    <tr class="row no-margin padding-right-15" style="border-bottom: 1px solid #c3c3c3;">
                      <th class="col-3 col-lg-3">
                        Test&nbsp;Case&nbsp;Name
                      </th>
                      <th class="col-2 col-lg-2">
                        Scenario&nbsp;Category(L3)
                      </th>
                      <th class="col-2 col-lg-2">
                        Business&nbsp;Process(L4)
                      </th>
                      <th class="col-2 col-lg-2">
                        Step
                      </th>
                      <th class="col-1 col-lg-1 text-center">
                        Status
                      </th>
                      <th class="col-2 col-lg-2 text-center">
                        ETA
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <div class="dvTestCases">
                      <tr class="row no-margin"
                        *ngFor="let scenInfo of selectedTestRun.scenariosDetailsList; let j = index">
                        <td class="col-3 col-lg-3">
                          <span *ngIf="scenInfo['testCaseName'].length <= 50">
                            {{scenInfo['testCaseName']}}
                          </span>
                          <span *ngIf="scenInfo['testCaseName'].length > 50" placement="right"
                            ngbTooltip="{{scenInfo['testCaseName']}}" tooltipClass="side-tooltip">
                            <a role="link" [routerLink]="['/manage-testruns', getUrlParams(scenInfo['correlationId'])]"
                              target="_blank">
                              {{scenInfo['testCaseName'].substring(0, 47) + '...'}}
                            </a>
                          </span>
                        </td>
                        <td class="col-2 col-lg-2">
                          {{scenInfo['scenarioName']}}
                        </td>
                        <td class="col-2 col-lg-2">
                          {{scenInfo['flavourName']}}
                        </td>
                        <td class="col-2 col-lg-2">
                          {{scenInfo['stepsDetailsList'][0]['componentDescription']}}
                        </td>
                        <td class="col-1 col-lg-1 text-center">
                          <div class="md-step-circle steps-statusinfo">
                            <i class="fa md-step-icon cursor-pointer" aria-hidden="true" matTooltip="Retry is pending."
                              [matTooltipDisabled]="scenInfo['stepsDetailsList'][0]['stepStatus'].toLowerCase() != 'pending'"
                              [ngClass]="manageTestRunsService.getStepStyleClass(scenInfo['stepsDetailsList'][0]['stepStatus'].toLowerCase(), true)"
                              *ngIf="scenInfo['stepsDetailsList'][0]['stepStatus'].toLowerCase() == 'complete' || scenInfo['stepsDetailsList'][0]['stepStatus'].toLowerCase() == 'error' || scenInfo['stepsDetailsList'][0]['stepStatus'].toLowerCase() == 'skipped' || scenInfo['stepsDetailsList'][0]['stepStatus'].toLowerCase() == 'pending'"
                              (click)="showPayloadDetails(responseModal, selectedTestRunIndex, j, 0, -1, -1, false)"></i>
                            <i class="fa md-step-icon" aria-hidden="true"
                              [ngClass]="manageTestRunsService.getStepStyleClass(scenInfo['stepsDetailsList'][0]['stepStatus'].toLowerCase(), true)"
                              *ngIf="scenInfo['stepsDetailsList'][0]['stepStatus'].toLowerCase() != 'complete' && scenInfo['stepsDetailsList'][0]['stepStatus'].toLowerCase() != 'error' && scenInfo['stepsDetailsList'][0]['stepStatus'].toLowerCase() != 'skipped' && scenInfo['stepsDetailsList'][0]['stepStatus'].toLowerCase() != 'pending'"></i>
                          </div>
                        </td>
                        <td class="col-2 col-lg-2 text-center">
                          <app-date-picker [(date)]="scenInfo['stepsDetailsList'][0]['estimatedCompletionDateTime']"
                            (dateChange)="updateFxn()" ariaLabel="Estimated Completion Date">
                          </app-date-picker>
                        </td>
                      </tr>
                      <tr class="row no-margin">
                        <td class="col-lg-12">
                          <div class="row no-margin" *ngIf="isDisplayLoadMoreOption()">
                            <div class="col-lg-12 text-center" id="loadMoreScenarios">
                              <a class="cursor-pointer"
                                (click)="filterTestScenarios(selectedTestRunIndex, false, false)">Load More</a>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </div>
                  </tbody>
                </table>
              </div>
              <div
                *ngIf="selectedTestRun.scenariosDetailsList.length <= 0 && statusFilter <= 0 && !checkAnyPreReqErrorExist()"
                class="text-center margin-top-20" style="font-weight: 600;">
                <h6 style="color: #cf5209;">Your test runs are getting prepared now, please check back after sometime!
                </h6>
              </div>
              <div *ngIf="checkAnyPreReqErrorExist()" class="text-center margin-top-20" style="font-weight: 600;">
                <h6 style="color: #cf5209;">The pre-requisite step has some issue, please fix the same and restart the
                  test!
                </h6>
              </div>
              <div *ngIf="selectedTestRun.scenariosDetailsList.length <= 0 && statusFilter > 0" class="text-center"
                style="font-weight: 600;">
                <h6 style="color: #cf5209;">There are no data matching this filter, please change the filter and try
                  again!
                </h6>
              </div>
            </div>
          </div>
        </div>
      </ng-sidebar-container>
    </div>
  </div>
</div>

<ng-template #responseModal class="modal-lg" let-c="close" let-d="dismiss">
  <div class="modal-header" style="flex-wrap: wrap;" aria-describedby="modal-basic-title">
    <h5 class="modal-title" style="width: 90%;" id="modal-basic-title">{{modalInputs.headerText}}</h5>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')" style="outline: 0;">
      <span aria-hidden="true">&times;</span>
    </button>
    <div>
      {{this.modalInputs.componentMethod}}: {{this.modalInputs.componentUrl}}
    </div>
  </div>
  <div class="modal-body " style="width:1295px">
    <div class="row no-margin" *ngIf="modalInputs.modalType == 'response'">
      <div class="no-padding">
        <p>
          {{modalInputs.bodyText}}
        </p>
      </div>
    </div>
    <div class="row no-margin" *ngIf="modalInputs.modalType == 'payload'">
      <div [class.m3]="modalInputs.visibleSections == 3" [class.col-lg-5]="modalInputs.visibleSections == 2"
        [class.col-lg-10]="modalInputs.visibleSections == 1" class="margin-bottom-15"
        style="background-color: #dcdcdc; border-radius: 5px; padding:0px; width: 400px; margin-right:15px"
        *ngIf="modalInputs.requestPayload != '' && modalInputs.isVisibleRequest == true">
        <div class="col-lg-12 no-padding">
          <div class="row no-margin">
            <div class="col-lg-12 padding-top-10 padding-left-10 padding-right-10 ">
              <h6>
                Request&nbsp;Payload&nbsp;Details:
                <button type="button" class="btn btn-primary tool-tip-button" (click)="downloadPayload('request')"
                  *ngIf="this.modalInputs.requestPayload != ''" aria-label="Download"
                  ngbTooltip="Download request Payload" id="request-tool-tip"
                  [class.ml21]="modalInputs.visibleSections == 3" [class.ml1]="modalInputs.visibleSections == 2"
                  [class.ml0]="modalInputs.visibleSections == 1" [closeDelay]="1000" triggers="click" placement="right">
                  <i class="fa fa-download"></i>
                </button>
              </h6>
            </div>
          </div>
          <div class="row no-margin padding-10"
            style="word-break: break-all; max-height: 200px; overflow: hidden; overflow-y: auto; border-top: 1px solid #a2a2a2; padding: 0px">
            <div class="col-lg-12 no-padding">
              <ngx-json-viewer [json]="modalInputs.requestPayload"></ngx-json-viewer>
            </div>
          </div>
        </div>
      </div>

      <div [class.m3]="modalInputs.visibleSections == 3" [class.col-lg-5]="modalInputs.visibleSections == 2"
        [class.col-lg-10]="modalInputs.visibleSections == 1" class=" margin-bottom-15"
        style="background-color: #dcdcdc; border-radius: 5px; width: 400px; margin-right:15px;"
        *ngIf="modalInputs.responsePayload != '' && modalInputs.isVisibleResponse == true">
        <div class="col-lg-12 no-padding">
          <div class="row no-margin">
            <div class="col-lg-12 padding-top-10 padding-left-10 padding-right-10">
              <h6 *ngIf="this.modalInputs.showResponseDownload">
                Response&nbsp;Payload&nbsp;Details:
                <button type="button" class="btn btn-primary tool-tip-button" (click)="downloadPayload('response')"
                  aria-label="Download" id="response-tool-tip" ngbTooltip="Download response Payload"
                  [class.ml22]="modalInputs.visibleSections == 3" [class.ml11]="modalInputs.visibleSections == 2"
                  [class.ml0]="modalInputs.visibleSections == 1" [closeDelay]="1000" triggers="click" placement="right">
                  <i class="fa fa-download"></i>
                </button>
              </h6>
              <h6 *ngIf="(this.modalInputs.adoTestRunId ||  '').length > 0">
                Go to ADO :&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <a href="https://dev.azure.com/MicrosoftIT/Supply%20Chain%20Staging/_testManagement/runs?_a=resultSummary&runId={{modalInputs.adoTestRunId}}&resultId={{modalInputs.adoTestRunResultId}}"
                  target="_blank">
                  Test Result {{modalInputs.adoTestRunId}}
                </a>
                &nbsp;&nbsp;&nbsp;
                <a href="https://dev.azure.com/MicrosoftIT/Supply%20Chain%20Staging/_workitems/edit/{{modalInputs.adoTestCaseId}}"
                  target="_blank">
                  Test Case {{modalInputs.adoTestCaseId}}
                </a>
              </h6>
              <h6 *ngIf="modalInputs.responsePayload != '' && modalInputs.disableFileDownload">
                Download&nbsp;Test&nbsp;Report:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <button type="button" class="btn btn-primary tool-tip-button" (click)="downloadPayload('testrunreport')"
                  aria-label="Download" id="TestRun-tool-tip" ngbTooltip="Download TestRun Report"
                  [class.ml22]="modalInputs.visibleSections == 3" [class.ml11]="modalInputs.visibleSections == 2"
                  [class.ml0]="modalInputs.visibleSections == 1" [closeDelay]="1000" triggers="click" placement="right">
                  <i class="fa fa-download"></i>
                </button>
              </h6>
            </div>
          </div>
          <div *ngIf="this.modalInputs.showResponseJson" class="row no-margin padding-10"
            style="word-break: break-all; max-height: 200px; overflow: hidden; overflow-y: auto; border-top: 1px solid #a2a2a2;">
            <div class="col-lg-12 no-padding">
              <ngx-json-viewer [json]="modalInputs.responsePayload"></ngx-json-viewer>
            </div>
          </div>
        </div>
      </div>
      <div [class.m3]="modalInputs.visibleSections == 3" [class.col-lg-5]="modalInputs.visibleSections == 2"
        [class.col-lg-10]="modalInputs.visibleSections == 1" class="margin-bottom-15"
        style="background-color: #dcdcdc; border-radius: 5px; width: 400px; "
        *ngIf="modalInputs.errorPayload != undefined && modalInputs.errorPayload != null && modalInputs.errorPayload != '' && modalInputs.isVisibleError == true">
        <div class="col-lg-12 no-padding">
          <div class="row no-margin">
            <div class="col-lg-12 padding-top-10 padding-left-10 padding-right-10">
              <h6>
                Error&nbsp;Details:
                <button type="button" class="btn btn-primary tool-tip-button" (click)="downloadPayload('error')"
                  aria-label="Download" id="error-tool-tip" ngbTooltip="Download Error Payload"
                  [class.ml23]="modalInputs.visibleSections == 3" [class.ml13]="modalInputs.visibleSections == 2"
                  [class.ml0]="modalInputs.visibleSections == 1" [closeDelay]="1000" triggers="click" placement="right">
                  <i class="fa fa-download"></i>
                </button>
              </h6>
            </div>
          </div>
          <div class="row no-margin padding-10"
            style="word-break: break-all; max-height: 150px; overflow: hidden; overflow-y: auto; border-top: 1px solid #a2a2a2;">
            <div class="col-lg-12 no-padding">
              <ngx-json-viewer [json]="modalInputs.errorPayload"></ngx-json-viewer>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer"
    *ngIf="(modalInputs.modalType == 'payload' && (modalInputs.enableNextStep || modalInputs.enableRestart  || modalInputs.enableDownload)) || modalInputs.enableForManualStep || modalInputs.modalType == 'response'">
    <button style="background-color: #6a7b00" type="button" class="btn btn-proceed"
      (click)="c();startNextStepComponent(responseModal, 1)"
      *ngIf="modalInputs.modalType == 'payload' && modalInputs.enableNextStep" aria-label="Skip Step">
      Skip Step
      <i class="fa fa-fast-forward"></i>
    </button>
    <button style="background-color: #6a7b00" type="button" class="btn btn-proceed"
      (click)="c();startNextStepComponent(responseModal, 1)" *ngIf="modalInputs.enableForManualStep"
      aria-label="Next Step">
      Next Step
      <i class="fa fa-fast-forward"></i>
    </button>
    <button *ngIf="modalInputs.enableErrorEmail == true" type="button" class="btn btn-report" id="report-issue-button1"
      (click)="launchErrorMailto()">
      Report Issue
    </button>
    <button type="button" class="btn btn-primary" (click)="c();restartIndvErrorStep(responseModal, 1)"
      *ngIf="modalInputs.modalType == 'payload' && modalInputs.enableRestart" aria-label="Restart" role="button"
      [disabled]="modalInputs.refreshRestartDisabled">
      <span *ngIf="modalInputs.enableRefresh">Refresh </span>
      <span *ngIf="!modalInputs.enableRefresh">Restart </span>
      <i class="fa fa-refresh"></i>
    </button>
    <button type="button" class="btn btn-primary" (click)="downloadPayload('response')"
      *ngIf="modalInputs.modalType == 'payload' && modalInputs.enableDownload" aria-label="Download">
      Download
      <i class="fa fa-download"></i>
    </button>
    <button type="button" class="btn btn-primary" ngbAutofocus (click)="c('Cross click')"
      *ngIf="modalInputs.modalType == 'response'" aria-label="OK">
      Ok
    </button>
  </div>
</ng-template>

<ng-template #retriggerResponseModal class="modal-lg" let-c="close" let-d="dismiss">
  <div class="modal-header" aria-describedby="modal-basic-title">
    <h5 class="modal-title" id="modal-basic-title">{{headerText}}</h5>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')" style="outline: 0;">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row no-margin">
      <div class="no-padding">
        {{bodyText}}
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" ngbAutofocus (click)="c('Navigate')" aria-label="OK"
      role="button">OK</button>
  </div>
</ng-template>

<ng-template #retriggerConfirmationModal class="modal-lg" let-c="close" let-d="dismiss">
  <div class="modal-header" aria-describedby="modal-basic-title">
    <h5 class="modal-title" id="modal-basic-title">{{headerText}}</h5>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')" style="outline: 0;">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    Please enter a test run name to retrigger:
    <input type="text" class="form-control font-14" placeholder="Enter a test run name"
      [(ngModel)]="retriggerTestRunName" aria-required="true" aria-label="Enter Test Run Name" required
      style="min-width: 200px;" />
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" ngbAutofocus (click)="c('Cancel')" aria-label="Cancel">Cancel</button>
    <button type="button" class="btn btn-primary" ngbAutofocus (click)="c('Retrigger')"
      arial-label="Retrigger">Retrigger</button>
  </div>
</ng-template>