<div style="width: 100%; height: calc(100vh - 70px); ">
  <h3>Goods Receipt Mocker</h3>
  <br>
  <div class="form-group col-sm-10" style="margin-bottom: 25px; border: 1px solid rgba(0,0,0,.125); border-radius: .25rem;">
    <b>Description:</b>
    <p>{{this.pageDescription}}</p>
  </div>
  <form class="form-horizontal" (ngSubmit)="onPostGoodReceiptClick()" #goodReceiptForm="ngForm">
      <div class="form-group">
          <label aria-label="GR Type" class="control-label col-sm-2" for="MessageType">GR Type</label>
          <div class="col-sm-10" aria-describedby="GR Type" aria-label="GR Type">
              <select role="listbox" class="form-control" id="MessageType" name="MessageType" [(ngModel)]="goodReceiptMockRequest.MessageType" required #grtypeField="ngModel">
                  <option value="Forward">Forward</option>
                  <option value="Return">Return</option>
                  <option value="WTPForwardRSD">CSCPSparesForwardT2.RSD</option>
                  <option value="WTPForwardDC">CSCPSparesForwardRSD.DC</option>
                  <option value="WTPDCGOODSISSUE">CSCPSparesReverseDC.GoodsIssue</option>
                  <option value="WTPReverseDCRSD">CSCPSparesReverseDC.RSD</option>
                  <option value="WTPReverseSIRSD">CSCPSparesReverseSI.RSD</option>
                  <option value="WTPReverseT2RSD">CSCPSparesReverseT2.RSD</option>
              </select>

              <div [hidden]="grtypeField.valid || grtypeField.pristine" class="alert alert-danger">GR Type is required</div>
          </div>
      </div>
      <div class="form-group">
          <label class="control-label col-sm-2" for="SenderId">Sender</label>
          <div aria-describedby="SenderId" aria-label="SenderId" class="col-sm-10">
              <select role="listbox" class="form-control" id="SenderId" name="SenderId" [(ngModel)]="goodReceiptMockRequest.SenderId" required #senderIdField="ngModel">
                  <option *ngFor="let id of plant" value={{id.plantName}}>{{id.plantLabel}}</option>
              </select>
              <div [hidden]="senderIdField.valid || senderIdField.pristine" class="alert alert-danger">SenderId is required</div>
          </div>
      </div>
      <div class="form-group">
          <label class="control-label col-sm-2" for="loadId">LoadId</label>
          <div class="col-sm-10">
              <input class="form-control" id="loadId" name="loadId" [(ngModel)]="goodReceiptMockRequest.LoadId" required #loadIdField="ngModel">
              <div [hidden]="loadIdField.valid || loadIdField.pristine" class="alert alert-danger">LoadId is required</div>
          </div>
      </div>

      <div class="form-group">
          <label class="control-label col-sm-2" for="CarrierTrackingNumber">Carrier Tracking Number</label>
          <div class="col-sm-10">
              <input class="form-control" id="CarrierTrackingNumber" name="CarrierTrackingNumber" [(ngModel)]="goodReceiptMockRequest.CarrierTrackingNumber">
          </div>
      </div>


      <div class="form-group" *ngIf="goodReceiptMockRequest.MessageType == messageType.WTPForwardRSD || goodReceiptMockRequest.MessageType == messageType.WTPReverseSIRSD || goodReceiptMockRequest.MessageType == messageType.WTPReverseT2RSD">
          <label aria-label="Serialized Flag" class="control-label col-sm-2" for="SerializedFlag">Serialized flag</label>
          <div class="col-sm-10" aria-describedby="Serialized Flag" aria-label="Serialized Flag">
              <select role="listbox" class="form-control" id="SerializedFlag" name="SerializedFlag" [(ngModel)]="goodReceiptMockRequest.SerializedFlag" required #grtypeField="ngModel">
                  <option value="No">No</option>
                  <option value="Yes">Yes</option>

              </select>

          </div>
      </div>

      <div class="form-group" *ngIf="goodReceiptMockRequest.MessageType == messageType.WTPForwardRSD || goodReceiptMockRequest.MessageType == messageType.WTPReverseSIRSD || goodReceiptMockRequest.MessageType == messageType.WTPReverseT2RSD">
          <label aria-label="Plant" class="control-label col-sm-2" for="PlantCode">PlantCode</label>
          <div class="col-sm-10" aria-describedby="PlantCode" aria-label="PlantCode">
              <select role="listbox" class="form-control" id="PlantCode" name="PlantCode" [(ngModel)]="goodReceiptMockRequest.PlantCode" required #grtypeField="ngModel">
                  <option value="C001">C001</option>
                  <option value="C002">C002</option>
                  <option value="C003">C003</option>
              </select>
          </div>
      </div>

      <div class="form-group">
          <label class="control-label col-sm-2" for="entityId">Purchase Order Number</label>
          <div class="col-sm-10">
              <input class="form-control" id="entityId" name="entityId" [(ngModel)]="entityId" required #entityIdField="ngModel">
              <div [hidden]="entityIdField.valid || entityIdField.pristine" class="alert alert-danger">Purchase Order Number is required</div>
          </div>


          <div id="divprecedingentityId" *ngIf="goodReceiptMockRequest.MessageType == messageType.WTPForwardDC">
              <label class="control-label col-sm-2" for="precedingentityId">Preceding Order Number</label>
              <div class="col-sm-10">
                  <input class="form-control" id="precedingentityId" name="precedingentityId" [(ngModel)]="precedingentityId" required #precedingentityIdField="ngModel">
                  <div [hidden]="precedingentityIdField.valid || precedingentityIdField.pristine" class="alert alert-danger">Preceding Order Number is required</div>
              </div>
          </div>
          <div class="col-sm-10">
              <input class="m-1" type="checkbox" id="is-sto-order" name="is-sto-order" [(ngModel)]="isSTOorder">
              <label class="m-1" for="is-sto-order">
                  <button style="margin-left:0px" type="button" id="sto-order-tool-tip" class="tool-tip-button"
                          ngbTooltip="Is this a STO Order? If yes, then check here." placement="right">
                      <span style="margin-right:10px">Is STO Order?</span> <i class="fa fa-question-circle text-center tool-tip-icon"></i>
                  </button>
              </label>
          </div>
      </div>
      <div class="form-group">
          <div class="col-sm-offset-2 col-sm-10">
              <div id="goodReceiptForSubmit" class="input-group">
                  <button aria-describedby="goodReceiptForSubmit" type="submit" class="btn btn-primary" [disabled]="!goodReceiptForm.form.valid" value="">Submit</button>
                  <app-preloader-small *ngIf="showLoader"></app-preloader-small>
              </div>
          </div>
      </div>
      <br>
      <div class="form-group">
          <div class="input-group">
              <label class="control-label col-sm-1" for="logs">Logs</label><a [routerLink]="" (click)="clearLogs()"> Clear</a>
          </div>
          <div class="col-sm-offset-2 col-sm-10" style="margin-top:15px;">

              <div class="row no-margin" style="background-color: #dcdcdc; border-radius: 5px;">
                  <div class="col-lg-12 no-padding">
                      <div class="row no-margin">
                          <div class="col-lg-12 padding-top-10 padding-left-10 padding-right-10">
                              <h6>Response&nbsp;Payload&nbsp;Details:</h6>
                          </div>
                      </div>
                      <textarea aria-label="logs" id="logs" *ngIf="goodReceiptMockResponse.message == ''" rows="12" class="form-control" autocomplete="off" spellcheck="false" name="logs"
                                [(ngModel)]="logMessage" readonly></textarea>
                      <div class="row no-margin padding-10" *ngIf="goodReceiptMockResponse.message != ''"
                           style="word-break: break-all; max-height: 350px; overflow: hidden; overflow-y: auto; border-top: 1px solid #a2a2a2;">
                          <div class="col-lg-12 no-padding">
                              <p>{{logMessage}}</p>
                              <p>Good Receipt Message:</p>
                              <ngx-json-viewer [json]="goodReceiptMockResponse.message"></ngx-json-viewer>
                          </div>
                      </div>
                  </div>
              </div>
              <div id="downloadResponsePayload" style="margin-top:15px;">
                  <button aria-disabled="false" aria-describedby="downloadResponsePayload" class="btn btn-primary"
                          (click)="downloadPayloadFile()">
                      Download Response Payload
                  </button>
              </div>
          </div>
      </div>
  </form>
</div>
