import { Component, OnInit } from '@angular/core';
import { MasterDataService } from '../service/masterdata.service';
import { DescriptionService } from '../service/description.service';

@Component({
  selector: 'app-mockers',
  templateUrl: './mockers.component.html',
  styleUrls: ['./mockers.component.css']
})
export class MockersComponent implements OnInit {

  pageDetails: any[];
  showMessage: boolean;
  showPreloader: boolean;
  msgTxt: string;

  constructor(private masterDataService: MasterDataService, private descriptionService: DescriptionService,) { }

  ngOnInit() {
   // window.sessionStorage.setItem("orgSelected", "1");
    this.descriptionService.pageDetailsMocker().subscribe((response: any[]) => {
      // this.logger.trackTrace(`Serial Number Generation Get page Description successfully`);
      this.pageDetails = response;
    }, (error: any) => {
      try {
        this.showMessage = true;
        this.showPreloader = false;
        console.log(error);
        // this.addLogLine(error.error.errors[0].message);
        this.msgTxt = error.error.errors[0].message;
      } catch (ex) {
        this.msgTxt = 'Some error occured while loading Page Name and Description';
        //   this.logger.trackTrace(`Serial Number Generation Error: ${this.msgTxt}`);
        //  this.logger.trackException(ex);
        //  this.addLogLine(error.error);
      }
    });
  }

}
