export class CreateLoadTestRequest {
  constructor() {
    this.Section = 'DC fulfilment';
    this.Vertical = 'Deliver';
    this.FulfillmentMethod = 'DC Ship';
  }
  public Section: string 

  public Vertical: string

  public TypeOfTest: number

  public ReceiverSystem: string

  public FulfillmentMethod: string

  public SalesOrg: string

  public Country: string

  public Region: string

  public MockFMV: boolean

  public MockDC: boolean 

  public NumberOfOrders: number

  public FriendlyName: string

  public Scenario: string 

  public UserName: string 

  public UserEmail: string 

  public NumberOfLinesPerOrder: string

  public QuantityPerLine: number

  public PartnerId: string 

  public TenantId: string 
}

export class OutboundDC {
  public TenantId: string;
  public Scenario: string;
  public PartnerId: string;
}
