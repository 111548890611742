<div style="width: 100%; height: calc(100vh - 46px); ">
  <h3 role="heading">SKU Validation</h3>
  <br>
  <div class="form-group col-sm-10" style="margin-bottom: 25px; border: 1px solid rgba(0,0,0,.125); border-radius: .25rem;">
    <b>Description:</b>
    <p>{{this.pageDescription}}</p>
  </div>
  <form class="form-horizontal" (ngSubmit)="onPostSkuClick()" #skuValidationForm="ngForm">
    <div class="form-group">
      <label class="control-label col-sm-2" for="skuNumbers"><b>SKU List</b></label>
      <div class="col-sm-10">
        <!--<input type='file' accept='text/plain' multiple (change)='openFile($event)' required>-->
        <input aria-label="file" type="file" #fileInput accept=".xlsx" multiple="false" (change)="validateFileType($event)" />
        <button aria-disabled="false" aria-label="Download Sample" type="button" class="btn btn-info float-right cursor-pointer" (click)="downloadSampleFile()"
                style="color: #ffffff; background-color: #757575; border-color: #757575; ">
          <i class="fa fa-file-text-o"></i> Download Sample
        </button>
      </div>
    </div>
    <div class="form-group">
      <div class="col-sm-offset-2 col-sm-10">
        <div class="input-group">
          <button type="submit" class="btn btn-primary"
                  [disabled]="!skuValidationForm.form.valid || excelJsonData?.length <= 0 || showLoader">
            Submit
          </button>
          <app-preloader-small *ngIf="showLoader"></app-preloader-small>
        </div>
      </div>
    </div>
    <br>
    <div class="form-group">
      <div class="input-group">
        <label class="control-label col-sm-1" for="logs">Logs</label><a [routerLink]="" (click)="clearLogs()"> Clear</a>
      </div>
      <div class="col-sm-offset-2 col-sm-10">
        <textarea aria-label="logs" rows="12" class="form-control" autocomplete="off" spellcheck="false" name="logs"
                  [(ngModel)]="logMessage" readonly></textarea>
      </div>
    </div>
  </form>
</div>
