import { Component, OnInit } from '@angular/core';
import { Constants } from '../service/constant.service';
import { FormsModule } from '@angular/forms';
import { MasterDataService } from '../service/masterdata.service';
import { GoodReceiptService } from '../service/goodreceipt.service';
import { GoodReceiptMockResponse } from '../model/goodreceiptresponse.model'
import { GoodReceiptMockRequest, Orders, MessageTypeEnum, SerializedFlagEnum } from '../model/goodreceiptrequest.model'
import { MsalService } from '@azure/msal-angular';
import { DescriptionService } from '../service/description.service';

import { ConfigService } from '../service/config.service';
import * as log from '../AppInsightsLogger';

import { ApplicationInsights } from '@microsoft/applicationinsights-web';

@Component({
  selector: 'app-goodreceiptmocker',
  templateUrl: './goodreceiptmocker.component.html',
  styleUrls: ['./goodreceiptmocker.component.css']
})


export class GoodReceiptMockerComponent implements OnInit {
  name: string;
  goodReceiptMockRequest: GoodReceiptMockRequest;
  goodReceiptMockResponse: GoodReceiptMockResponse;
  showLoader: boolean;
  logMessage = '';
  entityId: string;
  precedingentityId: string;
  messageType = MessageTypeEnum;
  serializedflag = SerializedFlagEnum.No;

  plant: any[];
  payload: any[];
  correlationId = '';
 
  private logger: log.AppInsightsLogger;
  pageDetails: any[];
  pageDescription: string;
  isSTOorder: boolean;

  modalInputs: {
    responsePayload: string};
  ngOnInit() {
    window.sessionStorage.setItem("orgSelected", "1");
    this.showLoader = false;
    this.logMessage = '';
    this.goodReceiptMockResponse = new GoodReceiptMockResponse();
    this.goodReceiptMockRequest = new GoodReceiptMockRequest();
    this.isSTOorder = false;
    this.masterDataService.getPlants().subscribe(Response => {
      this.plant = Response;
    });
    this.goodReceiptMockResponse.message = '';
    this.logger.trackTrace(`Successfully landed to Good Receipt Mocker Page`);
    this.descriptionService.pageDetailsMocker().subscribe((response: any[]) => {
      this.pageDetails = response;
      this.pageDetails.forEach(element => {
        if (element.mockerName == "Goods Receipt Mocker") {
          this.pageDescription = element.mockerDescription
        }
      });
    });
  }

  constructor(private masterDataService: MasterDataService,
    private descriptionService: DescriptionService, 
    private goodReceiptService: GoodReceiptService,
    private msalService: MsalService,
    private configService: ConfigService) {
    this.name = this.msalService.instance.getActiveAccount().name;
    this.logger = new log.AppInsightsLogger(configService, msalService);
    this.pageDescription = '';
  }

  addLogLine(line: string) {
    const currentDateTime = new Date();
    this.logMessage += '[' + currentDateTime.toLocaleString() + '] ' + line + '\n';
  }

  onPostGoodReceiptClick()
  {
    this.showLoader = true;
    this.logMessage = '';

    let order = new Orders();
    order.OrderNumber = this.entityId;
    order.PrecedingOrderNumber = this.precedingentityId;
    
    this.goodReceiptMockRequest.Orders = [];
    this.goodReceiptMockRequest.Orders.push(order);
    this.goodReceiptMockRequest.IsSTOorder = this.isSTOorder;
    this.goodReceiptService.onPostMockLoadPlanRequest(this.goodReceiptMockRequest).subscribe(goodReceiptResponse => {
      if (goodReceiptResponse != null && goodReceiptResponse.length > 0) {
        this.payload = goodReceiptResponse;
        goodReceiptResponse.forEach(element => {
          this.correlationId = element.correlationId;
          console.log(this.getValidJSONObject(element.message))
          
          //console.log(this.modalInputs.requestPayload)
          if (element.correlationId === '') {
            this.clearLogs()
                this.addLogLine(`Good Receipt failed while posting to GoodsReceipt service`);
                this.goodReceiptMockResponse.message = this.getValidJSONObject(element.message);
            this.logger.trackTrace(`Good Receipt failed. correlationId is null`);
              } else {
            if (element.isSuccessful == true) {
              this.clearLogs()
              this.addLogLine(`Message created and posted to GoodsReceipt service successfully.` + 
                    ` Good Receipt Correlation Id: ${element.correlationId}`);
                  this.goodReceiptMockResponse.message = this.getValidJSONObject(element.message);
              this.logger.trackTrace(`Message created and posted to GoodsReceipt service successfully. ` +
                    ` Good Receipt Correlation Id: ${element.correlationId}`);
                }
                //Good Receipt Message: ${ this.getValidJSONObject(element.message) },
              }
            });
            this.showLoader = false;
      } else {
        this.clearLogs()
        this.addLogLine(`Some error occured: ${goodReceiptResponse}`);
        this.logger.trackTrace(`Good Receipt Mocker, Some error occured`);
            this.showLoader = false;
          }
    }, (error) => {
        this.clearLogs()
        this.addLogLine(`Some error occured: ${JSON.stringify(error)}`);
        this.logger.trackException(error);
          this.showLoader = false;
        });
  }

  getValidJSONObject(object: string) {
    object = object.replace('{""', '{"').replace(',""', ',"');
    if (object == '') {
      return object;
    } else {
      if (/^[\],:{}\s]*$/.test(object.replace(/\\["\\\/bfnrtu]/g, '@').replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').replace(/(?:^|:|,)(?:\s*\[)+/g, ''))) {
        return JSON.parse(object);
      } else if (object.charAt(0).toLowerCase() != '[' && object.charAt(0).toLowerCase() != '{') {
        return { object };
      } else {
        try {
          return JSON.parse(object);
        } catch {
          return { object };
        }
      }
    }
  }

  downloadPayloadFile() {
    if (this.payload) {
      this.downloadTextFile(JSON.stringify(this.payload), this.correlationId + '.json');
    }
  }

  downloadTextFile(text: any, name: string) {
    const a = document.createElement('a');
    const type = name.split('.').pop();
    a.href = URL.createObjectURL(new Blob([text], { type: `text/${type === 'txt' ? 'plain' : type}` }));
    a.download = name;
    a.click();
  }

  clearLogs() {
    this.logMessage = '';
    this.goodReceiptMockResponse.message = '';
  }
}
