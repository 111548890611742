import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TableItem } from '../model/devicecaretable.model';

@Component({
  selector: 'app-modaltable',
  templateUrl: './modaltable.component.html',
  styleUrls: ['./modaltable.component.css']
})
export class ModaltableComponent implements OnInit {
  
  @Output() selectedEvent = new EventEmitter<String>();
  @Input() public tableData:TableItem;
  @ViewChild('deviceCareLookupModal', { static: true, read: TemplateRef })
  deviceCareLookupModal: TemplateRef<any>;
  constructor(public activeModal: NgbActiveModal) { }

ngOnInit(): void {
    console.log(this.tableData);
}
  
  onSelect(selectedCode:any,index:String){
    console.log(selectedCode)
    if (selectedCode != null && selectedCode != '') {
      // Emit the HWPN determined in view (user-selected)
    this.selectedEvent.emit(index);
    this.activeModal.close(index)
      
     
    }
  }
  dismiss(ms){
    this.activeModal.close(ms)
    this.selectedEvent.emit('');
  }
  onKeyup(event, selectedCode: string,index) {
    if (event.key == "Enter") {
      this.onSelect(selectedCode,index);
    }
  }
}
