<div style="    width: 100%; height: calc(100vh - 46px); overflow: hidden;">
  <h3>Mockers And Descriptions</h3>
  <div style="    width: 95%; height: calc(100vh - 46px); overflow: hidden; margin-top: 25px">
    <table class="table">
      <tbody>
        <tr class="thead-dark">
          <th role="columnheader">Name</th>
          <th roel="columnheader">Description</th>
        </tr>
        <tr *ngFor="let page of pageDetails">
          <td style="    width: 17%"><a class="cursor-pointer" href="{{page.mockerLink}}">{{page.mockerName}}</a></td>
          <td>{{page.mockerDescription}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
