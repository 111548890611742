import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ConfigService } from './config.service';
import { LandingPageResponse, WarningBoxDetail} from '../model/landingpage.model';
import { NgxUiLoaderConfig, NgxUiLoaderService } from 'ngx-ui-loader';

@Injectable()
export class LandingPageService {
  ngxLoaderConfig: NgxUiLoaderConfig;

  constructor(private ngxUiLoaderService: NgxUiLoaderService, private httpClient: HttpClient, private configService: ConfigService) {
    this.ngxLoaderConfig = this.ngxUiLoaderService.getDefaultConfig();
  }
  
  public getLandingPageExecution() {
    const baseUrlWithVersion = this.configService.omtestSuiteAPI.baseUrl + '/api/' + this.configService.omtestSuiteAPI.apiVersion;
    return this.httpClient.get<LandingPageResponse>(baseUrlWithVersion + '/WarningBox/GetWarningDetails');
  }

}
