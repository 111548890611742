<div *ngIf="JsonResult">
    <h5>Blob Json</h5>
    <div style="border:2px solid">
        <div style="float:right;margin-right: 20px; margin-top:10px;">
            <span style="margin-right:10px;"><button [cdkCopyToClipboard]="JsonResult" (click)="copyText()">{{copyTextName}}</button></span>
            <span style="margin-right:10px;"><button (click)="downloadFile()">Download</button></span>
        </div>
        <div >
            <pre>{{JsonResult|json}}</pre>
        </div> 
        
    </div>
</div>


