import { Component, OnInit } from '@angular/core';
import { LoadTestDetail, InputsModel } from '../model/loadtesting.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MsalService } from '@azure/msal-angular';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { ViewChild, ElementRef } from '@angular/core';
import { LoadTestService } from '../service/loadtest.service';
import { element } from 'protractor';
import { FormControl, Validators } from '@angular/forms';
//import { TestObject } from 'protractor/built/driverProviders';
import { CreateLoadTestRequest, OutboundDC } from '../model/createloadtestrequest.model';

import { CreateLoadTestResponse } from '../model/createloadtestresponse.model';
import { ListTestRunsRequest, ListTestRunsResponse, ListUsersDetails, Response } from '../model/listTestRuns';
import { ManageScenariosService } from '../service/manage-scenarios.service';
import { TestRunsUsersDetails } from 'src/app/model/manage-testruns.model';

import { ConfigService } from '../service/config.service';
import * as log from '../AppInsightsLogger';

import { ApplicationInsights } from '@microsoft/applicationinsights-web';

@Component({
  selector: 'app-loadtest',
  templateUrl: './loadtest.component.html',
  styleUrls: ['./loadtest.component.css']
})
export class LoadTestComponent implements OnInit {

  sidebarOpened: boolean;
  loadTestDetail: LoadTestDetail;
  createLoadTestRequest: CreateLoadTestRequest;
  createLoadTestResponse: CreateLoadTestResponse;
  listTestRunsRequest: ListTestRunsRequest;
  listTestRunsResponse: ListTestRunsResponse;
  dcOutboundPartnerList: OutboundDC[] = [
    {
      TenantId: "9000000013",
      Scenario: "OnlineStore",
      PartnerId: "ARVATO",
    },
    {
      TenantId: "8000000001",
      Scenario: "OnlineStore_Perf",
      PartnerId: "ARVATOPERF",
    },
  ]

  partnerProfile: OutboundDC;

  msgTxt: string;
  name: string;
  wrapperHeight: number;
  sidebarWidthClass: string;
  email: string;
  Vertical: string;
  fulfillmentMethod: string;
  Section: string;
  responseData: Response[];
  selectedUserEmail: string;
  usersDetails: ListUsersDetails[] = [];
  mockDCnoclick: boolean;
  mockFMVnoclick: boolean;
  

  private logger: log.AppInsightsLogger;

  ngOnInit() {
    this.setOrgData();
    this.usersDetails = [];
    this.loadTestDetail = new LoadTestDetail();
    this.createLoadTestRequest = new CreateLoadTestRequest();
    this.listTestRunsRequest = new ListTestRunsRequest();
    this.listTestRunsResponse = new ListTestRunsResponse();
    this.loadTestRunsUsersDetails();
    this.createLoadTestRequest.PartnerId = ''
   
    this.wrapperHeight = document.getElementById('testRunsWrapper').scrollHeight;
    this.getLoadTestDetails();
    this.logger.trackTrace("Successfully landed to Load Test Page");
    
  }


  setOrgData() {
    window.sessionStorage.setItem("orgSelected", "1");
  }

  constructor(private configService: ConfigService, private manageTestRunsService: ManageScenariosService, private ngxLoader: NgxUiLoaderService, private modalService: NgbModal, private msalService: MsalService, private loadTestService: LoadTestService) {
    this.sidebarOpened = false;
    this.name = this.msalService.instance.getActiveAccount().name;
    this.email = this.msalService.instance.getActiveAccount().username;
    this.Vertical = "Deliver";
    this.fulfillmentMethod = "DC Ship";
    this.Section = "DC fulfilment";
    this.logger = new log.AppInsightsLogger(configService, msalService);
  }

  loadTestRunsUsersDetails() {
    this.loadTestService.getUsersList().subscribe((response: ListUsersDetails[]) => {
      this.usersDetails = [];
      if (response != undefined && response != null && response.length > 0) {
        this.usersDetails = [{ email: 'all', userName: 'All' }].concat(response.filter(x => {
          if (x.userName != null && x.userName != '' &&
            x.email != null && x.email != '') {
            return x;
          }
        }));
        this.logger.trackTrace(`Load Test Runs Users Details Successfully`);
      }
    }, (error: any) => {
        this.ngxLoader.stop();
        this.logger.trackTrace(`Some error occured while load Test Runs Users Details`);
        this.logger.trackException(error);
    });
  }
  getLoadTestCreateDetails(){
    this.loadTestService.getListTestRuns(this.listTestRunsRequest).subscribe((response) => {
     
       this.listTestRunsResponse = response;
       this.responseData = this.listTestRunsResponse.response;
       this.logger.trackTrace(`getLoadTestDetails successfully`);
       this.responseData.sort(function (a, b): any {
         return (Date.parse(b.createdAt) - Date.parse(a.createdAt));
       });
     }, (error: any) => {
         this.logger.trackTrace(`Some error occured while loading Load Test`);
         this.logger.trackException(error);
       try {
         this.ngxLoader.stop();
       } catch (ex) {
         this.msgTxt = 'Some error occured while loading';
         this.logger.trackTrace(`Some error occured while loading Load Test`);
         this.logger.trackException(ex);
         this.ngxLoader.stop();
       }
     });
  }
  getLoadTestDetails() {
   this.manageTestRunsService.ngxLoaderConfig.text = 'Loading Test Details! Please wait!';
   this.ngxLoader.start();
    this.responseData = [];
    if (this.selectedUserEmail == null || this.selectedUserEmail == '') {
      this.listTestRunsRequest.UserEmail = this.email;
    } else if (this.selectedUserEmail == 'All' || this.selectedUserEmail == 'all') {
      this.listTestRunsRequest.UserEmail = "";
    } else {
      this.listTestRunsRequest.UserEmail = this.selectedUserEmail;
    }
    this.loadTestService.getListTestRuns(this.listTestRunsRequest).subscribe((response) => {
     this.ngxLoader.stop();
      this.listTestRunsResponse = response;
      this.responseData = this.listTestRunsResponse.response;
      this.logger.trackTrace(`getLoadTestDetails successfully`);
      this.responseData.sort(function (a, b): any {
        return (Date.parse(b.createdAt) - Date.parse(a.createdAt));
      });
    }, (error: any) => {
        this.logger.trackTrace(`Some error occured while loading Load Test`);
        this.logger.trackException(error);
      try {
        this.ngxLoader.stop();
      } catch (ex) {
        this.msgTxt = 'Some error occured while loading';
        this.logger.trackTrace(`Some error occured while loading Load Test`);
        this.logger.trackException(ex);
        this.ngxLoader.stop();
      }
    });
  }

  toggleSidebar() {
    this.sidebarOpened = !this.sidebarOpened;
  }

  checkOverflow() {
    return document.getElementById('testRunsWrapper').scrollHeight <= this.wrapperHeight;
  }

  scrollToTop() {
    document.getElementById('testRunsWrapper').scrollTop = 0;
  }


  checkBoxMockFMV(event) {
    //this.createLoadTestRequest.MockFMV = true;  // this updates the model
    //event.target.checked = true; //this updates the view
    this.mockFMVnoclick = event.target.checked;
    this.createLoadTestRequest.MockFMV = event.target.checked;
    console.log(this.createLoadTestRequest.MockFMV)
  }

  checkBoxMockDC(event) {
    //this.createLoadTestRequest.MockDC = true;  // this updates the model
    //event.target.checked = true; //this updates the view
    this.mockDCnoclick = event.target.checked;
    this.createLoadTestRequest.MockDC = event.target.checked;
    console.log(this.createLoadTestRequest.MockDC)
  }

  orgChanged(org: string) {
    if(org === 'LDCA'){
      this.createLoadTestRequest.Country = 'CA';
    }
    else if(org === 'WST'){
      this.createLoadTestRequest.Country = 'US';
    }
  }

  onSubmitClick() {
    for (var i = 0; i < this.dcOutboundPartnerList.length; i++) {
      let partner = this.dcOutboundPartnerList[i];
      if (partner.Scenario === this.createLoadTestRequest.Scenario) {
        this.createLoadTestRequest.TenantId = partner.TenantId;
        this.createLoadTestRequest.PartnerId = partner.PartnerId;
        break;
      }
    }

    //if (this.createLoadTestRequest.Scenario == 'Online_Perf') {
    //  this.createLoadTestRequest.PartnerId = 'ARVATOPERF'
    //  this.createLoadTestRequest.TenantId = '8000000001'
    //} else if (this.createLoadTestRequest.Scenario == 'Online') {
    //  this.createLoadTestRequest.PartnerId = 'ARVATO'
    //  this.createLoadTestRequest.TenantId = '9000000013'
    //}


    if (this.createLoadTestRequest.TypeOfTest == 1) {
      if (this.createLoadTestRequest.Scenario == 'OnlineStore_Perf') {
        if (this.mockDCnoclick == undefined) {
            this.createLoadTestRequest.MockDC = true;
          console.log(this.createLoadTestRequest.MockDC)
        }
      }
    }

    if (this.createLoadTestRequest.TypeOfTest == 1) {
      if (this.createLoadTestRequest.Scenario == 'OnlineStore_Perf') {
        if (this.mockFMVnoclick == undefined) {
          this.createLoadTestRequest.MockFMV = true;
          console.log(this.createLoadTestRequest.MockFMV)
        }
      }
    }

    console.log(this.createLoadTestRequest.MockFMV)

    this.createLoadTestRequest.QuantityPerLine = 1;

    this.createLoadTestRequest.UserEmail = this.email;
    this.createLoadTestRequest.UserName = this.name;
    
    this.loadTestService.storeCreateLoadTest(this.createLoadTestRequest).subscribe((response) => {
      console.log("req", this.createLoadTestRequest)
      console.log(this.createLoadTestResponse)
      this.createLoadTestResponse = response;
      this.logger.trackTrace(`Load Test Created Successfully`);
      this.createLoadTestRequest = new CreateLoadTestRequest();
      this.getLoadTestCreateDetails();
      },  (error: any) => {

        this.logger.trackTrace(`Some error occured while Creating new load test ${error}`);
        this.logger.trackException(error);

      try {
      } catch (ex) {
        this.msgTxt = 'Some error occured while loading';
        this.logger.trackTrace(`Some error occured while while Creating new load test ${ex}`);
        this.logger.trackException(ex);
      }
     
    });
   
  }

}
