<!-- welcome -->
<!DOCTYPE html>
<div class="container padding-10 justify-content-center" style="width:100%;  height: calc(100vh - 46px);">
    <div class="card text-center col-sm-15 text-center padding-15 pos-rel margin-bottom-20" style="border: 1px solid rgba(0,0,0,.125); border-radius: .25rem;" *ngIf="isEnabled()">
      <h4 class="ms-fontWeight-bold">Welcome to Test Platform</h4>
      <p style="padding-top:12px;">Lean on automated testing to - </p>
      <div class ='row' style="align-self:center;">
          <div aria-label="manualEffort" class="img_container"><i class="ms-Icon ms-Icon--Bullseye" style="font-size:300%"></i><br>Increase efficiency & ​ decrease manual effort​</div>
          <div aria-label="reduceCycleTime" class="img_container"><i class="ms-Icon ms-Icon--Clock" style="font-size:300%"></i><br>Reduce cycle time</div>
          <div aria-label="reduction" class="img_container"><i class="ms-Icon ms-Icon--Money" style="font-size:300%"></i><br>Save cost based on efficiency & toil reduction</div>
          <div aria-label="error" class="img_container"><i class="ms-Icon ms-Icon--IncidentTriangle" style="font-size:300%"></i><br>Minimize incidents and human error</div>
          <div aria-label="automated tests" class="img_container"><i class="ms-Icon ms-Icon--UserFollowed" style="font-size:300%"></i><br>Leverage user friendly UI for running automated tests</div>
      </div>
    </div>
    <!-- Card View Of Orgs  -->
    <div class ="row padding-15 justify-content-center">
      <p>Select an Organization below</p><i class="fa fa-info-circle" style="margin: 0.4em" ngbTooltip="{{organizationDescription}}"></i>
      <p class="ms-fontWeight-bold ms-fontSize-16" style="color:#e50000">Returning Supply Chain Users: Please click 'Supply Chain (Devices)' in the widget below. Please open a new tab if the widget is not visible.</p>
    </div>
    <div class ="row padding-15 justify-content-center"><app-org-card *ngIf="organizationsLoaded" [organizations]=organizations></app-org-card></div>
    

    <div class="footer">
        <div id=footer *ngIf="isEnabled()">
            <p aria-label="securityGroup" style="padding-top:12px;">Please join <span class="ms-fontWeight-bold">SCE Test Platform Testers</span> Security Group in <a target="_blank" href="https://aka.ms/idweb"><i class="ms-Icon ms-Icon--NavigateExternalInline"></i>Idweb</a> for access.</p>
            <p aria-label="contact" class="ms-fontSize-16 ms-fontWeight-regular"><a href="mailto:sctestsupport@microsoft.com"style="color:#0078D4"> <i style="vertical-align: bottom;" class="ms-Icon ms-Icon--Mail"></i>  Contact us</a> to discuss more on how our team can help you.</p>
        </div>
    </div>
</div>    
<!-- footer  -->

