import { Injectable } from '@angular/core';
import { Constants } from './constant.service';

@Injectable({
  providedIn: 'root'
})
export class LoadplanmockerService {

  constructor() { }
  getEntityNumberArray(entityNumbers: string,model:any): string[] {
    let entityNumberArrayUntrimmed: string[];
    let entityNumberArrayTrimmed: string[];

    if (model.loadplanMocker.loadplanMode == Constants.LoadPlanModeSync) {
      entityNumberArrayUntrimmed = entityNumbers.split(',');

    } else if (model.loadplanMocker.loadplanMode == Constants.LoadPlanModeLoad) {
      entityNumberArrayUntrimmed = entityNumbers.replace(/\r/g, '').split('\n');
    }

    entityNumberArrayTrimmed = [];
    entityNumberArrayUntrimmed.forEach(element => {
      if (element != null) {
        entityNumberArrayTrimmed.push(element.trim());
      }
    });

    return entityNumberArrayTrimmed;
  }
  getBatchedArrays(array: string[], batchSize: number) {
    const batchedArrays = [];

    while (array.length > 0) {
      batchedArrays.push(array.splice(0, batchSize));
    }

    return batchedArrays;
  }
}
