

<div style="margin-right:40px;" *ngFor="let org of organizations">
    <div class="card ms-depth-4"  (click)="setOrg(org)" [routerLink]="['/landing-page', getUrlParams(org)]" (keyup.enter)="setOrg(org)" [title]="org.organizationName">
        <div class="row">
            <div class="col-md-6">    
                <div class="data-initials">
                    <span class="initials">{{org.initials}}</span>
                </div>
            </div>
            <div class="col-md-6"><span>{{org.organizationName}}</span></div>
        </div>
        <div class="btn-group" role="group">
            <a  [routerLink] = "['/testscenario', getUrlParams(org)]"  (click)="setOrg(org)" aria-label="Test Scenario" title="Test Scenario Catalog"><i class="ms-Icon ms-Icon--CubeShape"></i></a>
            <a  [routerLink] = "['/landing-page', getUrlParams(org)]" (click)="setOrg(org)" aria-label="Landing Page" title="Home"><i class="ms-Icon ms-Icon--Home"></i></a>
        </div>
    </div>
</div>
