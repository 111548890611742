import { NumberValueAccessor } from '@angular/forms';
import { Response } from './response.model';

export class Conditions {
  constructor() {
    this.showAddButton = false;
    this.sidebarOpened = false;
    this.isBulkUploadMode = false;
    this.isEditMode = false;
    this.isEditModeLoadComplete = false;
    this.validateMode = false;
    this.disableSerialNumber = false;
    this.allowMultipleTestRuns = false;
  }
  showAddButton: boolean;
  sidebarOpened: boolean;
  isBulkUploadMode: boolean;
  isEditMode: boolean;
  isEditModeLoadComplete: boolean;
  validateMode: boolean;
  disableSerialNumber: boolean;
  allowMultipleTestRuns: boolean;
}

export class VerticalsDetails {
  public verticalName: string;
  public componentsList: ComponentDetails[];
  public sectionsList: SectionsDetails[];
}
export class PartnerDetails{
  public id:number;
  public partnerOrgName:string;
  public businessProcessName:string;
  public onboardingName : string;
  public scenarioId : number;
  public vertical:string;
  public section:string
}


export class ComponentDetails {
  public componentId: number;
  public componentName: string;
  public componentDescription: string;
  public userInputJson: string;
}

export class SectionsDetails {
  constructor() {
    this.allowMultipleTestRuns = false;
  }
  public sectionName: string;
  public tableColumnsJson: string;
  public enableBulkUploadOption: boolean;
  public allowMultipleTestRuns: boolean;
  public scenariosList: ScenariosDetails[];
}

export class ScenariosDetails {
  public scenarioId: number;
  public scenarioName: string;
  public scenarioDescription: string;
  public businessProcessName: string;
  public businessProcessDefinition: string;
  public includeAllOption: boolean;
  public userInputJson: string;
  public flavoursList: FlavoursDetails[];
}

export class FlavoursDetails {
  public flavourId: number;
  public flavourName: string;
}

export class FormInputsModel {
  constructor() {
    this.id = 0;
    this.scenarioId = -1;
    this.flavourId = -1;
    this.scenarioName = '';
    this.flavourName = '';
  }
  public id: number;
  public scenarioId: number;
  public flavourId: number;
  public scenarioName: string;
  public flavourName: string;
}

export class ScenariosInputsModel {
  constructor() {
    this.id = 0;
    this.scenarioId = -1;
    this.flavourId = -1;
    this.scenarioName = '';
    this.flavourName = '';
    this.inputsModel = {};
  }
  public id: number;
  public scenarioId: number;
  public flavourId: number;
  public scenarioName: string;
  public flavourName: string;
  public inputsModel: {};
}

export class TestRunInputsDetails {
  constructor() {
    this.verticalName = '-1';
    this.sectionName = '-1';
  }
  public testRunName: string;
  public userName: string;
  public userEmail: string;
  public verticalName: string;
  public sectionName: string;
  public partnerOrgName: string;
  public businessProcessName: string;
  public onboardingName: string;

  public inputsJsonDetails: string;
}

export class TestRunBulkUploadInput {
  public testRunName: string;
  public userName: string;
  public userEmail: string;
  public sheetJsonData: string;
  public testPlanInstanceId?: string;
  public testPlanPreStepInstanceId?: string;
  public projectId?: string;
}

export class TestRunsBulkUploadResponse extends Response {

}

export class TestRunsFetchInputs {
  constructor() {
    this.pageNumber = 1;
    this.pageSet = 5;
    this.isExport = false;
  }
  userEmail: string;
  testRunName: string;
  testRunId: string;
  pageNumber: number;
  pageSet: number;
  searchId: string;
  organizationId: number;
  isExport: boolean;
}

export class TestRunsDetails {
  public totalCount: number;
  public testRunsItemDetailsList: TestRunsItemDetails[];
}

export class TestRunsItemDetails {
  constructor() {
    this.isReload = false;
  }
  testRunId: string;
  testRunName: string;
  userName: string;
  userEmail: string;
  isSavedRun: boolean;
  executionDate: string;
  statusCounts: TestRunStatusCount;
  isReload: boolean;
  scenariosDetailsList: TestRunScenariosDetails[];

  /** 
   * Tracking type for this test run.
   */
  trackingType: string;

  /**
   * Order IDs which can be tracked for events in this Test Run
   */
  trackingIDs: string[];
}

export class TestRunStatusCount {
  waiting: number;
  start: number;
  ready: number;
  processing: number;
  pending: number;
  complete: number;
  error: number;
}

export class TestRunScenariosDetails {
  correlationId: string;
  verticalName: string;
  sectionName: string;
  scenarioId: number;
  scenarioName: string;
  flavourId: number;
  flavourName: string;
  executionDate: string;
  estimatedCompletionDateTime: string;
  status: string;
  isVisible: boolean;
  stepsDetailsList: TestRunStepsDetails[];
}

export class TestRunStepsDetails {
  componentId: number;
  componentName: string;
  componentDescription: string;
  displayTracking: boolean;
  trackingType: string;
  customErrorMessages: string;
  restartDisplayCondition: string;
  enableRefresh: boolean;
  enableErrorEmail: boolean;
  errorEmaillink: string;
  allowProceedOnError: boolean;
  downloadSuccessKey: string;
  downloadErrorKey: string;
  stepRank: number;
  userInput: string;
  stepStatus: string;
  stepResponse: string;
  eventsList: Events[];
  withPartner: boolean;
  isManual: boolean;
  componentCreated: string;
  componentLastModified: string;
  estimatedCompletionDateTime: string;
  isChildStep: boolean;
  parentCorrelationID: string;
  parentStepRank: number;
  childScenariosList: TestRunScenariosDetails[];
}

export class Events {
  eventId: number;
  eventName: string;
  eventDate: string;
  eventTime: string;
  trackingId: string;
  trackingName: string;
  correlationId: string;
  eventStatus: string;
  eventMessage: any;
}
