import { Component, OnInit } from '@angular/core';
import { MasterDataService } from '../service/masterdata.service';
import { DescriptionService } from '../service/description.service';

@Component({
  selector: 'app-tools',
  templateUrl: './tools.component.html',
  styleUrls: ['./tools.component.css']
})
export class ToolsComponent implements OnInit {

  pageDetails: any[];
  showMessage: boolean;
  showPreloader: boolean;
  msgTxt: string;

  constructor(private descriptionService: DescriptionService, private masterDataService: MasterDataService) { }

  ngOnInit() {
    this.setOrgData();
    this.descriptionService.pageDetailsTools().subscribe((response: any[]) => {
      this.pageDetails = response;
    }, (error: any) => {
      try {
        this.showMessage = true;
        this.showPreloader = false;
        console.log(error);
        this.msgTxt = error.error.errors[0].message;
      } catch (ex) {
        this.msgTxt = 'Some error occured while loading Page Name and Description';
      }
    });
  }

  setOrgData() {
    window.sessionStorage.setItem("orgSelected", "1");
  }
}