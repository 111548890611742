export class ExecuteStepRequest {
  public LoadTestRunId: string;

  public StepIdentifier: string;

  public BatchSize: number;
}

export class ExecuteStepResponse {
  public status: string;
}
