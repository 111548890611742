import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { BaseComponent } from '../base/base.component';
import { MonitoringService } from '../service/monitoring.service';
import * as uuid from 'uuid';
const uuidv1 = require('uuid/v1');  // guid generation

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class FeedbackComponent extends BaseComponent implements OnInit {
  isInitialized: boolean = false;

  constructor(private monitoringService: MonitoringService) {
    super(monitoringService);
  }

  ngOnInit() {
  }

  private initOCV() {
    (<any> window).OfficeBrowserFeedback = (<any> window).OfficeBrowserFeedback || {};

    (<any> window).OfficeBrowserFeedback.initOptions = {
      appId: 2372, // Replace by your own app id
      stylesUrl: '/assets/@ms-ofb/officebrowserfeedback/styles/officebrowserfeedback.min.css', // Replace by where you have hosted the .css
      intlUrl: '/assets/@ms-ofb/officebrowserfeedback/intl/', // Replace by where you have hosted the intl files.
      // intlFilename is an optional property for using a custom filename for the internationalized strings, the default filename will be used if it is not specified
      // intlFilename: "/scripts.js", // (optional) Here the default filename is used for this field, simply to demonstrate the syntax for this property
      environment: 0, // 0 - Prod, 1 - Int
      primaryColour: '#1B5E20', // Replace by a colour which goes with your website.
      secondaryColour: '#107180', // Replace by a colour which goes with your website.
      userEmail: '',
      customResourcesSetExternally: 1,
      isShowThanks: true,
      sessionId: uuidv1(), // (optional) If specified, the value needs to be a valid GUID
      // cid: '1234567890_LiveId', // (optional)
      // build: '123456789', // (optional) Another example: 99.1.1.123456789
      onError: function onError(err) { this.monitoringService.logEvent('OCV SDK encountered error: ' + err); }, // (optional) Callback which gets executed when SDK errors
      bugForm: true, // (optional) false by default
      // userVoice is optional, basic form will be displayed if not specified.
      /*
      userVoice: {
        url: 'http://go.microsoft.com/fwlink/?LinkID=786935',
        termsOfServiceUrl: 'http://go.microsoft.com/fwlink/?LinkID=786896',
        privacyPolicyUrl: 'http://go.microsoft.com/fwlink/?LinkID=786895'
      }*/
    };

  }

  private handlePromise(promise: any): void {
    promise.then(
      function onFulfilled() {
        this.monitoringService.logEvent('OCV Initialization Success');
      }
    )
    .catch(
      function onRejected(err) {
        this.monitoringService.logEvent('OCV Initialization Failed. See error in properties', err);
      }
    );
  }

  public showFeedbackPopup(): void {
    if (!this.isInitialized) {
      this.initOCV();
      this.isInitialized = true;
    }

    this.handlePromise((<any> window).OfficeBrowserFeedback.multiFeedback());
  }
}
