
<div *ngIf="is500error" style="width: 100%; overflow: hidden;">
  <app-error [navigationPath]='"testscenario"'></app-error>
</div>
<div *ngIf="!is500error" class="container padding-10" style="  width:100%;  height: calc(100vh - 46px);">
  <div [hidden]="!hasWarningResponse" class="card text-center col-sm-15 text-center padding-15 pos-rel margin-bottom-20" style="background-color:#ffe100; border: 1px solid rgba(0,0,0,.125); border-radius: .25rem;">
    <div *ngFor="let res of warningResponse">
      <span class="bold">Important notice:</span>
      <p>{{res?.title}}</p>
      <span class="bold">Impacted Time : From UTC {{res?.notificationStart | date: 'MM/dd/yyyy hh:mm a'}} to {{res?.notificationEnd | date: 'MM/dd/yyyy hh:mm a'}}. </span>
      <p>{{res?.warningMessage}}</p>
    </div>
  </div>
  <div class="card text-center col-sm-15 text-center padding-15 pos-rel margin-bottom-20" style="border: 1px solid rgba(0,0,0,.125); border-radius: .25rem;" *ngIf="isEnabledBoolean">
    <h5>Welcome to Test Platform:</h5>
    <p>Here is the new and improved look of Test Platform. The new changes are primarily to help with User’s self-serve experience.</p>
    <p *ngIf="!isAuthenticated()"><b>For Access:</b> Please join "SCE Test Platform Testers" Security Group in <a role="link" target="_blank" href="https://aka.ms/idweb"><i class="ms-Icon ms-Icon--NavigateExternalInline"></i>Idweb</a></p>
  </div>
  <div class="card col-sm-15  padding-15 pos-rel margin-bottom-20" style="border: 1px solid rgba(0,0,0,.125); border-radius: .25rem;" *ngIf="isEnabledBoolean && isSupplyChain()">
    <h5 role="heading" class="text-center">MST SAP Refresh : <span style="font-size:16px;"><a target="_blank" href="https://microsoft.sharepoint.com/teams/SAPWeb/ReleaseMgt"><i class="ms-Icon ms-Icon--NavigateExternalInline"></i>SAP Refresh Calendar</a></span> </h5>
    <p class="text-center">
      [<span class="fa fa-warning text-center"></span> Attention users: SAP refresh is happening in the below dates.]
    </p>
    <img alt="calenderSap" class="center" src="../assets/images/calendarSAP.PNG" style="border: black 3px solid;width: 806px;margin-left: 200px;margin-right: 50px;">
    <h6 class="text-center" style="margin-top:10px; text-decoration: underline;">Impact of Refresh</h6>
    <p class="text-center">Please note that the Test Platform will be impacted by MST SAP Refresh cycles.</p>
    <ol style="width: 650px;margin-left: 280px;">
      <li>Test Runs started before the Refresh cannot be resumed / rerun.</li>
      <li>Test Runs that were “In progress” should be rerun from start.</li>
      <li>Tracking of any Test Order created before refresh will not be successful.</li>
      <li>Master Data created in UAT for any specific testing need to be recreated after successful Refresh.</li>
    </ol>
  </div>
  <div class="card text-center col-sm-15 text-center padding-15 pos-rel margin-bottom-20" style="border: 1px solid rgba(0,0,0,.125); border-radius: .25rem;" *ngIf="isOrderViewEnabled() && isSupplyChain()">
    <div class="card-body">
      <h5 class="card-title">Orders</h5>
      <p class="card-text">
        Track test orders E2E in a timeline view
      </p>
      <a  role="link" [routerLink]="['/entitytracking']"><i class="ms-Icon ms-Icon--NavigateExternalInline"></i>Track Orders</a>
    </div>
  </div>
  <div class="card text-center col-sm-15 text-center padding-15 pos-rel margin-bottom-20" style="border: 1px solid rgba(0,0,0,.125); border-radius: .25rem; " *ngIf="isEnabledBoolean && isSupplyChain()">
    <h5  role="heading" class="card-title">Learn More</h5>
    <p class="card-text">Check out below video to understand how to do different things in Test Platform</p>
    <a role="link" href="https://microsoft.sharepoint.com/teams/DevX/_layouts/15/stream.aspx?id=%2Fteams%2FDevX%2FShared%20Documents%2FTest%20Platform%2FIntro%20to%20Sail%20and%20Demo%20SC%20Test%20Platform.mp4&ga=1"  target="_blank">
      <i class="ms-Icon ms-Icon--NavigateExternalInline"></i>Test Platform Demo</a>
  </div>
  <div class="card col-sm-15 text-center padding-15 pos-rel margin-bottom-20" style="border: 1px solid rgba(0,0,0,.125); border-radius: .25rem;" *ngIf="isEnabledBoolean">
    <h5 class="card-title">Feedback</h5>
    <p>Please send <a role="link" [routerLink]="" (click)="feedbackComponent.showFeedbackPopup()"><i style="vertical-align: bottom;" class="ms-Icon ms-Icon--Emoji2"></i> feedback</a></p>
    <p>Or send support to  <a role="link" href="mailto:sctestsupport@microsoft.com"><i  style="vertical-align: bottom;" class="ms-Icon ms-Icon--Mail"></i> Test Platform Support</a></p>
  </div>
  <div class="col-sm-1"></div>
  <br>
  <div class="card text-center col-sm-12" style="width: 68rem;" *ngIf="!isEnabledBoolean">
    <div class="card-body">
      <h5 class="card-title">Integrate via API</h5>
      <p class="card-text">Use OM Test Suite API for integration and performance testing</p>
      <a [href]="swaggerUrl" target="_blank" class="btn btn-info">View API</a>
    </div>
  </div>
</div>
