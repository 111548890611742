import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { JwtHelperService } from '@auth0/angular-jwt';
import { from } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigService } from './config.service';
import { Constants } from './constant.service';
import { threadId } from 'worker_threads';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { CurrencyPipe } from '@angular/common';

@Injectable()
export class AuthService {

  jwtHelper: JwtHelperService;
  expectedRoles: any

  constructor(private msalService: MsalService, private httpClient: HttpClient, private configService: ConfigService) {
    this.jwtHelper = new JwtHelperService();
    this.expectedRoles = [Constants.RoleOMTestSuiteAdmin, Constants.RoleOMTestSuiteRead, Constants.RoleOMTestSuiteTester];
  }

  async getAccessToken(resource: string) {
    var currentAccount = this.msalService.instance.getActiveAccount();
    var silentRequest  = {
      scopes:null,  
      forceRefresh: false, 
      account:currentAccount
    }
    var request = {
      scopes: null,// For v1 endpoints, use upn from idToken claims
  };
    return await this.msalService.instance.acquireTokenSilent(silentRequest).catch(error => {
      if (error instanceof InteractionRequiredAuthError) {
          return this.msalService.instance.acquireTokenPopup(request);
      }
  });
   
  }

  async getTokenFromService(environment: string): Promise<any> {
     return await this.getAccessToken(this.configService.auth.resourceUrl);
  }

  isUserAuthenticated() {
    return this.msalService.instance.getAllAccounts().length>0;
  }

  login() {
    this.msalService.instance.loginRedirect();
  }

  logout() {
    this.msalService.instance.logout();
  }

  isShipmentMockEnabled() {
    var isShipmentMockEnabled = false;
    var roles = this.msalService.instance.getActiveAccount().idTokenClaims.roles;
    if (roles && this.configService.selectedEnvironment != Constants.EnvironmentProd) {
      roles.forEach((element: any) => {
        if ((element == Constants.RoleOMTestSuiteAdmin || element == Constants.RoleOMTestSuiteTester)) {
          isShipmentMockEnabled = true;
        }
      });
    }

    return isShipmentMockEnabled;
  }

  isLoadPlanMockEnabled() {
    var isLoadPlanMockEnabled = false;
    var roles = this.msalService.instance.getActiveAccount().idTokenClaims.roles;
    if (roles && this.configService.selectedEnvironment != Constants.EnvironmentProd) {
      roles.forEach((element: any) => {
        if ((element == Constants.RoleOMTestSuiteAdmin || element == Constants.RoleOMTestSuiteTester)) {
          isLoadPlanMockEnabled = true;
        }
      });
    }

    return isLoadPlanMockEnabled;
  }

  isGoodreceiptMockEnabled() {
    var isGoodreceiptMockEnabled = false;
    var roles = this.msalService.instance.getActiveAccount().idTokenClaims.roles;
    if (roles && this.configService.selectedEnvironment != Constants.EnvironmentProd) {
      roles.forEach((element: any) => {
        if ((element == Constants.RoleOMTestSuiteAdmin || element == Constants.RoleOMTestSuiteTester)) {
          isGoodreceiptMockEnabled = true;
        }
      });
    }
    return isGoodreceiptMockEnabled;
  }
  isMockerV2() {
    var isMockerV2Enabled = false;
    var roles = this.msalService.instance.getActiveAccount().idTokenClaims.roles;
    if (roles && this.configService.selectedEnvironment != Constants.EnvironmentProd) {
      roles.forEach((element: any) => {
        if ((element == Constants.RoleOMTestSuiteAdmin || element == Constants.RoleOMTestSuiteTester)) {
          isMockerV2Enabled = true;
        }
      });
    }
    return isMockerV2Enabled;
  }
  isEnabled() {
    var isEnabled = false;
    var roles = this.msalService.instance.getActiveAccount().idTokenClaims.roles;
    if (roles && this.configService.selectedEnvironment != Constants.EnvironmentProd) {
      roles.forEach((element: any) => {
        if ((element == Constants.RoleOMTestSuiteAdmin || element == Constants.RoleOMTestSuiteTester)) {
          isEnabled = true;
        }
      });
    }

    return isEnabled;
  }

  isManageTestRunEnabled() {
    var isManageTestRunEnabled = false;
    var roles = this.msalService.instance.getActiveAccount().idTokenClaims.roles;
    if (roles && this.configService.selectedEnvironment != Constants.EnvironmentProd) {
      roles.forEach((element: any) => {
        if (element == Constants.RoleOMTestSuiteAdmin || element == Constants.RoleOMTestSuiteTester) {
          isManageTestRunEnabled = true;
        }
      });
    }
    return isManageTestRunEnabled;
  }

  isSerialNumberGenerationEnabled() {
    var isSerialNumberGenerationEnabled = false;
    var roles = this.msalService.instance.getActiveAccount().idTokenClaims.roles;
    if (roles && this.configService.selectedEnvironment != Constants.EnvironmentProd) {
      roles.forEach((element: any) => {
        if (element == Constants.RoleOMTestSuiteAdmin || element == Constants.RoleOMTestSuiteTester) {
          isSerialNumberGenerationEnabled = true;
        }
      });
    }
    return isSerialNumberGenerationEnabled;
  }

  isSerialNumberSearchEnabled() {
    var isSerialNumberSearchEnabled = false;
    var roles = this.msalService.instance.getActiveAccount().idTokenClaims.roles;
    if (roles && this.configService.selectedEnvironment != Constants.EnvironmentProd) {
      roles.forEach((element: any) => {
        if (element == Constants.RoleOMTestSuiteAdmin || element == Constants.RoleOMTestSuiteTester) {
          isSerialNumberSearchEnabled = true;
        }
      });
    }
    return isSerialNumberSearchEnabled;
  }

  isSkuValidationEnabled() {
    var isSkuValidationEnabled = false;
    var roles = this.msalService.instance.getActiveAccount().idTokenClaims.roles;
    if (roles && this.configService.selectedEnvironment != Constants.EnvironmentProd) {
      roles.forEach((element: any) => {
        if (element == Constants.RoleOMTestSuiteAdmin || element == Constants.RoleOMTestSuiteTester) {
          isSkuValidationEnabled = true;
        }
      });
    }
    return isSkuValidationEnabled;
  }

  isSAPOutputTriggerEnabled() {
    var isSAPOutputTriggerEnabled = false;
    var roles = this.msalService.instance.getActiveAccount().idTokenClaims.roles;
    if (roles && this.configService.selectedEnvironment != Constants.EnvironmentProd) {
      roles.forEach((element: any) => {
        if (element == Constants.RoleOMTestSuiteAdmin || element == Constants.RoleOMTestSuiteTester) {
          isSAPOutputTriggerEnabled = true;
        }
      });
    }
    return isSAPOutputTriggerEnabled;
  }

  isSkuSearchEnabled() {
    var isSkuSearchEnabled = false;
    var roles = this.msalService.instance.getActiveAccount().idTokenClaims.roles;
    if (roles && this.configService.selectedEnvironment != Constants.EnvironmentProd) {
      roles.forEach((element: any) => {
        if (element == Constants.RoleOMTestSuiteAdmin || element == Constants.RoleOMTestSuiteTester) {
          isSkuSearchEnabled = true;
        }
      });
    }
    return isSkuSearchEnabled;
  }

  isAPIDocEnabled() {
    var isAPIDocEnabled = false;
    var roles = this.msalService.instance.getActiveAccount().idTokenClaims.roles;
    if (roles && this.configService.selectedEnvironment != Constants.EnvironmentProd) {
      roles.forEach((element: any) => {
        if (element == Constants.RoleOMTestSuiteAdmin || element == Constants.RoleOMTestSuiteTester) {
          isAPIDocEnabled = true;
        }
      });
    }
    return isAPIDocEnabled;
  }

  isOrderViewEnabled() {
    var isOrderViewEnabled = false;
    var roles = this.msalService.instance.getActiveAccount().idTokenClaims.roles;
    if (!roles) {
      return false;
    }
    if (this.configService.selectedEnvironment == Constants.EnvironmentProd) {
      roles.forEach((element: any) => {
        if (element == Constants.RoleOMTestSuiteAdmin) {
          isOrderViewEnabled = true;
        }
      });
    }
    else if (this.configService.selectedEnvironment == Constants.EnvironmentUAT) {
      roles.forEach((element: any) => {
        if (element == Constants.RoleOMTestSuiteAdmin || element == Constants.RoleOMTestSuiteTester || element == Constants.RoleOMTestSuiteRead) {
          isOrderViewEnabled = true;
        }
      });
    }

    return isOrderViewEnabled;
  }

  isAuthenticatedWithRoles() {
    var isAuthenticatedWithRolesValid = false;
    if (!(this.msalService.instance.getAllAccounts().length>0)) {
      isAuthenticatedWithRolesValid = false;
    }
    else {
      if (this.msalService.instance.getActiveAccount().idTokenClaims.roles) {
        for (var i = 0; i < this.expectedRoles.length; i++) {
          var isRolePresent = this.msalService.instance.getActiveAccount().idTokenClaims.roles.includes(this.expectedRoles[i]);
          if (isRolePresent) {
            isAuthenticatedWithRolesValid = true;
            break;
          }
        }
      }
      else {
        isAuthenticatedWithRolesValid = false;
      }
    }

    return isAuthenticatedWithRolesValid;
  }
}
