import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Console } from 'console';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.css']
})
export class DatePickerComponent implements OnInit {

  @Input() date: string;
  @Output() dateChange = new EventEmitter<Date>();
  @Input() ariaLabel: string;
  @Input() disabled = true;
  dateString: string;
  localTime:string;

  constructor() { }

  ngOnInit(): void {
    //console.log(this.date);
    var date;
    if (!this.date.endsWith('Z')) {
      date = new Date(this.date + 'Z');
    } else {
      date = new Date(this.date);
    }
    this.dateString = date.toLocaleString([], { year:'2-digit', month:'2-digit', day:'2-digit', hour: '2-digit', minute: '2-digit' });

  }

  handleDateChange(value: string) {
    // const date = new Date(value);
    // date.setMinutes(date.getMinutes() + date.getTimezoneOffset());

    // this.date = date;
    // this.dateChange.emit(date);
  }
}
