<!DOCTYPE html>
<div *ngIf="is500error" style="width: 100%; overflow: hidden;">
  <app-error [navigationPath]='"execute-testruns"'></app-error>
</div>
<div *ngIf="!is500error" style="width: 100%">
  <div class="input-group">
    <h3>Execute Test Runs
      <span>
        <i class="fa fa-info-circle" style="font-size: 0.5em;"
          ngbTooltip="Test run is a systematic execution of a test case. Repeated execution of a test case produces different test runs which might have same or different outcomes. One Test run corresponds to one e2e execution of a Test Case."></i>
      </span>
    </h3>
    <div>&nbsp;</div>
  </div>
  <br>
  <div class="row no-margin">
    <div class="col-lg-12 no-padding">
      <ng-sidebar-container style="height: calc(100vh - 100px);">
        <ng-sidebar [(opened)]="conditions.sidebarOpened" position="right" style="left: 590px">
          <div class="row no-margin border-radius-5 back-color-white full-height overflow-hidden"
            [ngClass]="sidebarWidthClass"
            style="border: 1px solid #ADADAD; overflow-y: auto; margin-right: 2px !important; width:650px">
            <div class="col-lg-12 no-padding" style="background-color: rgb(229, 229, 229); ">
              <div class="row no-margin padding-10" style="background-color: #676767; color: #FFFFFF;">
                <div class="col-lg-6 no-padding text-left bold">
                  {{ !showBulkUploadPanel ? 'SELECT SCENARIO DETAILS' : 'BULK UPLOAD' }}
                </div>
                <div class="col-lg-6 no-padding text-right" style="line-height: 15px;">
                  <span class="font-28 bold cursor-pointer" (click)="toggleSidebar()">
                    &times;
                  </span>
                </div>
              </div>
              <div class="row no-margin" *ngIf="!showBulkUploadPanel">
                <div class="col-lg-12 padding-15 font-15"
                  style="height: calc(100vh - 200px); overflow: hidden; overflow-y: auto;">
                  <div class="row no-margin">
                    <div class="col-lg-12 no-padding">
                      <mat-form-field style="display: block;">
                        <select matNativeControl placeholder="--Select Business Process (L4)--"
                          [(ngModel)]="model.scenarioId" (ngModelChange)="loadFlavoursList()">
                          <option styzzzze="padding:16px" *ngFor="let scenario of scenariosList; index as i"
                            [value]="scenario.scenarioId">
                            {{scenario.scenarioName}}
                          </option>
                        </select>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row no-margin">
                    <div class="col-lg-12 no-padding" *ngIf="flavoursList.length > 1">
                      <mat-form-field style="display: block;">
                        <select matNativeControl *ngIf="flavoursList.length > 1" placeholder="--Select Sub-Process--"
                          [(ngModel)]="model.flavourId" (ngModelChange)="loadUserInputJson()">
                          <option *ngFor="let flav of flavoursList; index as i" [value]="flav.flavourId">
                            {{flav.flavourName}}
                          </option>
                        </select>
                      </mat-form-field>
                    </div>
                  </div>
                  <app-testruns-panel *ngIf="fields && model" [isEditMode]="conditions.isEditMode" [fields]="fields"
                    [model]="model" [formState]="loadedConfig" (add)="addScenarios($event)"
                    (update)="updateScenarios($event)" (cancel)="cancelScenarioUpdate($event)">
                  </app-testruns-panel>
                  <div class="row no-margin">
                    <div>
                      <button type="submit" class="btn btn-primary margin-top-5 border-radius: .25rem;"
                        aria-label="Switch to Bulk Upload Mode"
                        *ngIf="!showBulkUploadPanel && enableBulkUploadOption && model.scenarioId < 0 && inputsList.length == 0"
                        (click)="switchInputMode()">
                        Switch to Bulk Upload Mode
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row no-margin" *ngIf="showBulkUploadPanel">
                <div class="col-lg-12 padding-15 font-15"
                  style="height: calc(100vh - 145px); overflow: hidden; overflow-y: auto;">
                  <div class="row no-margin" *ngIf="inputsList.length === 0 && enableBulkUploadOption">
                    <div class="col-lg-12 no-padding padding-top-10 padding-bottom-5">
                      <input type="file" #fileInput accept=".xlsx, .csv" multiple="false"
                        (change)="validateFileType($event, responseModal)"
                        style="height: 37px; cursor: pointer; margin-top: 5px" aria-label="Choose Files" />
                      <button type="button" class="btn btn-info float-right cursor-pointer"
                        (click)="downloadSampleFile()"
                        style="color: #ffffff; background-color: #757575; border-color: #929292;">
                        <i class="fa fa-file-text-o"></i>
                        Download Sample
                      </button>
                    </div>
                    <div>
                      <button type="submit" class="btn btn-primary margin-top-5"
                        aria-label="Switch to Manual Input Mode" *ngIf="!conditions.isBulkUploadMode"
                        (click)="switchInputMode()">
                        Switch to Manual Input Mode
                      </button>
                    </div>
                    <div>
                      <button type="submit" class="btn btn-primary margin-top-5" aria-label="Add Scenarios"
                        *ngIf="conditions.isBulkUploadMode && !conditions.isEditMode"
                        (click)="addOrUpdateScenariosBulkMode(responseModal)">
                        ADD
                      </button>
                    </div>
                    <div>
                      <button type="submit" class="btn btn-primary margin-top-5" aria-label="Update Scenarios"
                        *ngIf="conditions.isBulkUploadMode && conditions.isEditMode"
                        (click)="addOrUpdateScenariosBulkMode(responseModal)">
                        UPDATE
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-sidebar>
        <div ng-sidebar-content style="overflow: hidden; height: calc(100vh - 100px);">
          <div class="row no-margin padding-left-15 padding-top-15 padding-right-15 no-padding-bottom">
            <div class="col bold no-padding text-right font-14" style="line-height: 35px; max-width: 9em;">
              SCOR Function:<span style="color: #8b0000;">*</span>
              <i class="fa fa-info-circle" style="margin: 0.1em" title="{{verticalDescription}}"></i>
            </div>
            <div class="col form-group">
              <select class="form-control font-14" [(ngModel)]="verticalName" [disabled]="verticalName != '-1'"
                style="outline: 0;" aria-label="Select Vertical" aria-required="true" aria-expanded="false" required
                (change)="loadSectionsList()">
                <option value="-1" selected="selected">--Select SCOR Function--</option>
                <option *ngFor="let vertical of verticalsList; index as i" value="{{vertical.verticalName}}">
                  {{vertical.verticalName}}
                </option>
              </select>
            </div>
            <div class="col bold no-padding text-right font-14" style="line-height: 35px; max-width: 13em;">
              <span *ngIf="verticalName != '-1'">
                Scenario Category (L3):<span style="color: #8b0000;">*</span>
                <i class="fa fa-info-circle" style="margin: 0.1em" title="{{sectionsDescription}}"></i>
              </span>
            </div>
            <div class="col form-group">
              <select class="form-control font-14" [(ngModel)]="sectionName" style="outline: 0;"
                aria-label="Select Section" aria-required="true" aria-expanded="false" required
                (change)="loadScenariosList()" [disabled]="sectionName != '-1'" *ngIf="verticalName != '-1'">
                <option value="-1" selected="selected">--Select Scenario Category (L3)--</option>
                <option *ngFor="let section of sectionsList; index as i" value="{{section.sectionName}}">
                  {{section.sectionName}}
                </option>
              </select>
            </div>
            <div class="col bold no-padding text-right font-14" style="line-height: 35px; max-width: 13em;">
              <span *ngIf="sectionName != '-1' && partnerExist">
                Partner Info:<span style="color: #8b0000;">*</span>
                <i class="fa fa-info-circle" style="margin: 0.1em" title="{{sectionsDescription}}"></i>
              </span>
            </div>
            <div *ngIf="sectionName != '-1' && partnerExist" style="margin-left:5px;">
              <ng-select (change)="loadToggleSidebar($event)" style="max-width: 20em;overflow-y: scroll;"
                [items]="allPartners" bindLabel="name" placeholder="Select Partners" appendTo="body" [multiple]="true"
                [(ngModel)]="partnerName" [virtualScroll]="true">
              </ng-select>
            </div>



            <div class="col no-padding-left" style="min-width: 15.8em;">
              <a class="add-scenario bold" (click)="toggleSidebar()"
                *ngIf="(inputsList.length > 0 || conditions.showAddButton) && conditions.allowMultipleTestRuns">
                <span class="circle plus no-margin-top"></span>
                <span class="font-14" style="color: #006ee5; line-height: 35px;">Add Business Process (L4)(s)</span>
                <i class="fa fa-info-circle" style="margin: 0.1em" title="{{scenariosDescription}}"></i>
              </a>
            </div>
            <div class="col no-padding text-right font-14 bold"
              style="line-height: 35px; display: inline-block; max-width: 8.5em;">
              Test Run Name:<span style=" color: #8b0000;">*</span>
            </div>
            <div class="col form-group padding-right-15" style="display: inline-block;">
              <input type="text" class="form-control font-14" placeholder="Enter Test Run Name..."
                [(ngModel)]="testRunName" aria-required="true" aria-label="Enter Test Run Name" required
                style="min-width: 200px;" />
            </div>
            <div class="col no-padding"
              style="display: inline-block; line-height: 30px; vertical-align: top; margin-top: -1px; max-width: 9em;">
              <button type="button" class="margin-right-10" style="background-color:rgb(0, 80, 169);"
                [disabled]="testRunName == undefined || testRunName.length < 3 || inputsList.length == 0"
                [hidden]="conditions.isBulkUploadMode" aria-label="Submit" role="button"
                (click)="submitTestRunInputs(responseModal)"
                style="font-size: 14px; padding: 5px 10px; line-height: 24px;">Submit</button>
              <button type="button" class="margin-right-10" style="background-color:rgb(0, 80, 169);"
                [disabled]="testRunName == undefined || testRunName.length < 3 || !sheetJsonData"
                [hidden]="!conditions.isBulkUploadMode" aria-label="Submit" role="button"
                (click)="submitTestRunBulkUploadInput(responseModal)"
                style="font-size: 14px; padding: 5px 10px; line-height: 24px;">Submit</button>
              <button type="button" class="btn btn-success" [disabled]="verticalName == '-1'" aria-label="Reset"
                role="button" (click)="clearForm()"
                style="font-size: 14px; padding: 5px 10px; line-height: 24px;">Reset</button>
            </div>
          </div>
          <hr class="no-margin" />
          <div class="row no-margin overflow-hidden" *ngIf="inputsList.length > 0">
            <div class="col-lg-12">
              <table class="table table-fixed" role="grid">
                <thead>
                  <tr class="row no-margin font-14" role="row" [ngClass]="{'padding-right-15': inputsList.length > 10}">
                    <th role="columnheader" aria-label="" [ngClass]="header.class" [hidden]="!header.display"
                      *ngFor="let header of tableHeaderJson; index as r">
                      {{header.label}}
                    </th>
                    <th role="columnheader" aria-label="Actions" class="col-lg-1 text-center">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody style="height: calc(100vh - 240px);overflow: scroll;">
                  <tr class="row no-margin font-14 text-center" *ngFor="let input of inputsList; index as a">
                    <td *ngFor="let field of tableHeaderJson; index as b" [ngClass]="field.class"
                      [hidden]="!field.display" role="gridcell" style="overflow-wrap: break-word;">
                      <div class="form-group"
                        *ngIf="field['type'] != undefined && field['type'] != null && field['type'] == 'checkbox'">
                        <label class="chkContainer display-inblock" style="vertical-align: top;"
                          attr.aria-label="{{field['label']}} Check" title="{{field['label']}} Check">
                          <input type="checkbox" [checked]="getColumnValue(input, field)" data-md-icheck
                            (change)="setCheckboxValue($event, a, field['model'])"
                            attr.aria-label="{{field['label']}} Check" title="{{field['label']}} Check" />
                          <span class="checkmark" style="left: -12px;"></span>
                        </label>
                      </div>
                      <span *ngIf="field['type'] != 'checkbox'">
                        {{getColumnValue(input, field)}}
                      </span>
                    </td>
                    <td class="col-lg-1 text-center" role="gridcell">
                      <a class="ancAction margin-right-5" style="font-weight: bold;" (click)="editScenario(input.id)">
                        <i class="fa fa-edit font-24 cursor-pointer" aria-hidden="true"></i>
                      </a>
                      <a class="ancAction margin-left-5 margin-right-5" style="font-weight: bold;"
                        (click)="deleteScenario(input.id)">
                        <i class="fa fa-trash font-24 cursor-pointer" aria-hidden="true" style="color: #8b0000;"></i>
                      </a>
                      <a class="ancAction margin-left-5" style="font-weight: bold;" (click)="copyScenario(input.id)">
                        <i class="fa fa-copy font-24 cursor-pointer" aria-hidden="true"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="row no-margin overflow-hidden" *ngIf="sheetJsonData">
            <div class="col-lg-12">
              <table class="table table-fixed" role="grid">
                <thead>
                  <tr class="row no-margin font-14" role="row">
                    <th role="columnheader" aria-label="Vertical" class="col-lg-3 text-center">Vertical</th>
                    <th role="columnheader" aria-label="Section" class="col-lg-3 text-center">Section</th>
                    <th role="columnheader" aria-label="Section" class="col-lg-3 text-center">File</th>
                    <th role="columnheader" aria-label="Actions" class="col-lg-3 text-center">Actions</th>
                  </tr>
                </thead>
                <tbody style="height: calc(100vh - 215px);">
                  <tr class="row no-margin font-14 text-center">
                    <td class="col-lg-3" role="gridcell" style="overflow-wrap: break-word;">
                      <span>
                        {{verticalName}}
                      </span>
                    </td>
                    <td class="col-lg-3" role="gridcell" style="overflow-wrap: break-word;">
                      <span>
                        {{sectionName}}
                      </span>
                    </td>
                    <td class="col-lg-3" role="gridcell" style="overflow-wrap: break-word;">
                      <span>
                        {{sheetFileName}}
                      </span>
                    </td>
                    <td class="col-lg-3 text-center" role="gridcell">
                      <a class="ancAction margin-right-5" style="font-weight: bold;" (click)="editScenario(-1)">
                        <i class="fa fa-edit font-24 cursor-pointer" aria-hidden="true"></i>
                      </a>
                      <a class="ancAction margin-left-5" style="font-weight: bold;" (click)="deleteScenario(-1)">
                        <i class="fa fa-trash font-24 cursor-pointer" aria-hidden="true" style="color: #8b0000;"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="row no-margin" *ngIf="inputsList.length == 0">
            <div class="col-lg-12 no-padding bold font-16 text-center margin-top-20" style="color: #8b0000;">
              No Business Process (L4) is added for testing! Please add at least one Business Process (L4) to test!
            </div>
            <div class="col-lg-12 no-padding bold font-16 text-center margin-top-20" style="color: #8b0000;">
              Please click here to track the tests
              <a class="cursor-pointer bold" href="/manage-testruns?env=UAT">Manage Test Run page</a>
            </div>
          </div>
        </div>
      </ng-sidebar-container>
    </div>
  </div>
</div>

<ng-template #responseModal class="modal-lg" let-c="close" let-d="dismiss">
  <div class="modal-header" aria-describedby="modal-basic-title">
    <h5 class="modal-title" id="modal-basic-title">{{headerText}}</h5>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')" style="outline: 0;">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row no-margin">
      <div class="no-padding">
        {{bodyText}}
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" ngbAutofocus (click)="c('Cross click')" aria-label="OK">Ok</button>
  </div>
</ng-template>