export class AsnMockerRequest {
    MessageType: string
    Parameters: ParameterASN
  }
  
  export class ParameterASN {
   
    MessageBusinessChannel: string
    MessageSubBusinessChannel: string
    MessageAction: string
    MessagePartyId: string
    DeliveryCID: string
  }
  
 
  