export class SerialNumberList {
  public totalCount: number;
  public serialNumberListItems: SerialNumberListItem[];
}

export class SerialNumberListItem {
  public serialNumber: string;
  public sku: string;
  public inMDS: boolean;
  public inOER: boolean;
  public inUSTRL: boolean;
  public warrantyStatus: string;
  public warrantyEndDate: string;
  public warrantyUpdatedInOER: boolean;
  public userName: string;
  public link: string;
}
