
<div style="width: 100%; height: calc(100vh - 46px); overflow: auto;">
    <div *ngIf="isLoading">
        <app-preloader-small></app-preloader-small>
        <div>
            <div style="margin-left: 50px; padding-top: 7px;">Loading ... Please Wait</div>
        </div>      
    </div>
    <ng-container *ngIf="!isLoading">
        <h3 style="margin: 5px; padding: 10px;">Projects
          <span>
            <i class="fa fa-info-circle" style="font-size: 0.5em;"
               title="{{projectDescription}}"></i>
          </span>
        </h3>
        <div style="margin: 5px;">
          <div class="form-group ms-fontWeight-regular ms-fontSize-18"
               style="margin-bottom: 25px; border: 1px solid rgba(0,0,0,.125); border-radius: .25rem;">
            <div style="padding: 15px;">
              <b>Description:</b>
              <p>{{projectDescription}}</p>
              <span>View the list of projects that belong to your organization below.</span>
            </div>
          </div>
        </div>
        <div class="ms-depth-4" style="margin: 5px; padding-top:15px; padding-left: 10px;">              <!-- Test Case Grid -->
            <app-simple-grid [(column_schema)]="ProjectSchema" [(table_data)]="Projects" [useCheckBoxForSelection]="false" [organizationId]="organizationID"></app-simple-grid>
        </div>
    </ng-container>
</div>
