<div style="width: 100%; height: calc(100vh - 46px); ">
  <h3>Test Runs</h3>
  <!-- <a [routerLink] = "['/new-test-run']" class="btn btn-primary">New Test Run</a> -->
  <br>
  <div class="row">
    <div class="col-sm-5">
      <div class="input-group">
        <input class="form-control" placeholder="From date (yyyy-mm-dd)" name="fromDate" [(ngModel)]="fromDate"
          [maxDate]="toDate" ngbDatepicker #f="ngbDatepicker" aria-label="Order From Date">
        <div class="input-group-append">
          <button class="btn btn-outline-secondary fa fa-calendar" (click)="f.toggle()" type="button" name="btnFromdate"
            aria-label="Order From Date Calendar Button"></button>
        </div>
      </div>
    </div>
    <div class="col-sm-5">
      <div class="input-group">
        <input class="form-control" placeholder="To date (yyyy-mm-dd)" name="toDate" [(ngModel)]="toDate" ngbDatepicker
          #t="ngbDatepicker" aria-label="Order To Date">
        <div class="input-group-append">
          <button class="btn btn-outline-secondary fa fa-calendar" (click)="t.toggle()" type="button" name="btnToDate"
            aria-label="Order To Date Calendar Button"></button>
        </div>
      </div>
    </div>
    <div class="col-sm-2"><button type="button" class="btn btn-primary" style=" width: 76px; "
        [routerLink]="['/new-test-run']">New</button></div>
  </div>
  <br>
  <div class="row">
    <div class="col-sm-10">
      <input type="text" class="form-control" placeholder="Test Run Name" [(ngModel)]="testRunNameText"
        aria-label="Enter Test Run Name">
    </div>
    <div class="col-sm-2"><button type="button" class="btn btn-primary" style=" width: 76px; "
        (click)="onSearchClick()">Search</button></div>
  </div>
  <br>
  <div class="table-responsive">
    <table class="table">
      <thead class="thead-dark">
        <tr>
          <th scope="col">Name</th>
          <th scope="col">Order Count</th>
          <th scope="col">Status</th>
          <th scope="col">Timestamp <i>(in UTC)</i></th>
        </tr>
      </thead>
      <tbody>
        <app-preloader *ngIf="showLoader"></app-preloader>
        <tr *ngFor="let testRun of testRunList.testRunListItems" (click)="onTestRunItemClick()">
          <td><a [routerLink]="['/orders']" [queryParams]="{ testRunId: testRun.testRunId}"
              style="color: #0e539e;">{{ testRun.testRunName }}</a></td>
          <td>{{ testRun.orderCount }}</td>
          <td>{{ testRun.status }}</td>
          <td>{{ testRun.lastModifiedAt | date:'medium'}}</td>
        </tr>
      </tbody>
    </table>
  </div>

  <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [maxSize]="7" [boundaryLinks]="true"
    (pageChange)="onPageChanged()" *ngIf="!showLoader" class="d-flex justify-content-center"></ngb-pagination>
</div>
