<div style="    width: 100%; height: calc(100vh - 46px); overflow: hidden;">
  <h3>Tools And Descriptions</h3>

  <div style="    width: 95%; height: calc(100vh - 46px); overflow: hidden; margin-top: 25px">
    <table class="table">
      <tbody>
        <tr class="thead-dark">
          <th role="columnheader">Name</th>
          <th role="columnheader">Description</th>
        </tr>
        <tr *ngFor="let page of pageDetails">
          <td style="    width: 17%"> <a class="cursor-pointer" href="{{page.toolLink}}">{{page.toolName}}</a></td>
          <td>{{page.toolDescription}}</td>
        </tr>
      </tbody>
    </table>
  </div>

</div>
