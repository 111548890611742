export class NewTestRunRequest {
    constructor() {
        this.numberOfLines = 1;
        this.quantityPerLine = 1;
        this.locale = 'en-us';
        this.orderCount = 1;
        this.orderScenario = 'StandardOnlineUST';
        this.productType = '';
        this.region = '';
    }
    public name: string;
    public orderScenario: string;
    public orderCount: number;
    public numberOfLines: number;
    public quantityPerLine: number;
    public productType: string;
    public locale: string;
    public region: string;
    public poNumbers: string[];
}
