import * as internal from "assert";

export class Projects{
 projectID:number;
 projectName:string;
 projectOwner:string;
 projectDescriptions:string;
 CreateDate:Date;
 TestPlans: TestPlan[]; 
}


export class TestPlan{

}

export class TestPlanInstance{
  testPlanID:number;
  testPlanInstanceID:string;
  testPlanName:string;
  testPlanDescription:string;
  owner:string;
  startDate:string;
  endDate:string;
  createDate:string;
  testCaseInstances:TestCaseInstance[];
  customerName:string;
  countryCode:string;
  stateCode:string;
  status:string;
  modifiedDate:string;
}



export class TestCase{ 
  //TODO 
}


export class TestCaseInstance{
  testCaseId:number = undefined;
  correlationId:string = undefined;
  testCaseName:string = undefined;
  TestCaseDescription:string = undefined;
  testPlanInstanceId:string;
  inputParameter:string = undefined;
  projectId:string = undefined;
  createDate:string;
  startDate:string;
  endDate:string;
  userName:string;
  userEmail:string;
  status:string;
  overallStatus:string;
  detailLink:string;
  responseResult:string;
}


export class TestPlanInstanceRequest {
  testPlanInstance: TestPlanInstance;
}

export class TestCaseInstanceRequest {
  testCaseInstances: TestCaseInstance [];
}

export class SNValidationRequest{
  Country?:string;
  TestPlanInstanceId:string;
  TestPlanPreStepInstance: TestPlanPreStepInstance;
  RequesterEmail: string;
  RequesterName: string;
}

export class TestPlanPreStepInstance{
  testPlanPreStepInstanceId:string;
  testPlanPreStepId: number;
  testPlanInstanceId: string;
  status:string;
  eta: number;
  delayAfterPreviousStep:number;
  stepRank: number;
  createdAt?:Date;
  lastModifiedAt:Date;
  stepCompleteDate:Date;
  stepStartDate:Date;
  constructor(step){
    this.testPlanPreStepId = step.testPlanPreStepId;
    this.testPlanPreStepInstanceId = step.testPlanPreStepInstanceId;
    this.testPlanInstanceId = step.testPlanInstanceId;
    this.status = step.status;
    this.eta= step.eta;
    this.delayAfterPreviousStep= step.delayAfterPreviousStep;
    this.stepRank= step.stepRank;
    this.createdAt = step.createdAt;
    this.lastModifiedAt = step.lastModifiedAt;
    this.stepStartDate = step.stepStartDate;
    this.stepCompleteDate = step.stepCompleteDate;
  }
}



export class GetCustomSteps {
  TestPlanID : string;
  TestPlanInstanceID: string;
  PreReqSteps: PreReqSteps [];
}

export class PreReqSteps {
  PreReqStepName :string;
  Description: string;
  ApiLink:string;
  IsDownloaded:boolean;
  PreReqStepRank:string;
  CompletionDate:string;
  PreReqStepStatus: string;
}

export class TestPlanExcelUploadInput{
  public testRunName: string;
  public testPlanInstanceId: string;
  public userName: string;
  public userEmail: string;
  public sheetJsonData: string;
}


export class SharedOutputRequest{
  public testPlanPreStepInstanceId : string;
  public testPlanInstanceId: string;
}
