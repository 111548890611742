import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
    name: 'filter',
    pure: false
})
export class FilterPipe implements PipeTransform {
    transform(items: any[], filter: (item: any) => boolean): any {
        if (!items || !filter) {
            return items;
        }
        return items.filter(i => filter(i));
    }
}