export class TradeProformaMockerRequest{
    public UseDummyDoIfNecessary:boolean;
    public TradeProformaParams:TradeProformaParams;
    public DeliveryOrderDetails:DeliveryOrderDetails[];
 }
 export class TradeProformaParams{
 public  BillingType :string;
 public RepeatFlag:string;
 public ConsolidationFlag:string;
 public CountryofOrigin:string;

 
 }
 export class DeliveryOrderDetails{
     public DeliveryOrderNumber: string;
 }
