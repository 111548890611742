export class OutputTriggerRequest {

  constructor() {
    this.outputTriggerFor = '';
    this.entityNumbers = [];
  }

  public outputTriggerFor: string;
  public entityNumbers: string[];
}

