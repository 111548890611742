import { Component, OnInit } from '@angular/core';

@Component({
 // selector: 'app-scenariocatalog',
  templateUrl: './scenariocatalog.component.html',
  styleUrls: ['./scenariocatalog.component.css']
})
export class ScenarioCatalogComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
