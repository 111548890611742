import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subject } from 'rxjs';
import { TestCaseProjectService } from 'src/app/service/test-case-project.service';

export enum Status {
    NOT_STARTED = 'Not Started',
    FAILED = 'Failed',
    IN_PROGRESS = 'In Progress',
    START = 'Start',
    COMPLETE = 'Complete',
    COMPLETED = 'Completed',
    PASS = 'Pass',
    PENDING = 'Pending',
    LOADING = 'Loading',
    ERROR  = 'Error',
    PENDINGUSERACTION = 'Pending User Action',
    PROCESSING = 'Processing',
    
}

export const STATUS_LABELS: Record<string, string> = {
    [Status.NOT_STARTED]: 'Not Started',
    [Status.FAILED]: 'Failed',
    [Status.IN_PROGRESS]: 'In Progress',
    [Status.COMPLETE]: 'Complete',
    [Status.COMPLETED]: 'Completed',
    [Status.START]: 'Start',
    [Status.PASS]: 'Passed',
    [Status.PENDING]: 'Pending',
    [Status.LOADING]:'Loading',
    [Status.ERROR]: 'Error',
    [Status.PENDINGUSERACTION]:'Pending User Action', 
};

export const STATUS_LABEL = 'Status';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.css']
})
export class StatusComponent implements OnInit, OnDestroy {

  @Input() status = Status.NOT_STARTED;
  @Output() statusChange = new EventEmitter<Status>();
  @Input() disabled = true;
  @Input() detailLink?:Map<string, any>;
  @Input() correlationId? = "";
  private destroy$ = new Subject();

  

  statusDisplayMapping: { [index: string]: { class: string; label: string; value: Status} } = {
    [Status.NOT_STARTED]: {
      class: 'not-started-status',
      label: 'Not Started',
      value: Status.NOT_STARTED
    },
    [Status.IN_PROGRESS]: {
      class: 'in-progress-status',
      label: 'In Progress',
      value: Status.IN_PROGRESS
    },
    [Status.COMPLETE]: {
      class: 'complete-status',
      label: 'Completed',
      value: Status.COMPLETE
    },
    [Status.COMPLETED]: {
      class: 'completed-status',
      label: 'Completed',
      value: Status.COMPLETED
    },
    [Status.FAILED]:{
      class:'at-risk-status',
      label: 'Failed',
      value: Status.FAILED
    },
    [Status.ERROR]:{
      class:'at-risk-status',
      label: 'Failed',
      value: Status.ERROR
    },

    [Status.START]:{
      class:'at-start-status',
      label: 'Started',
      value: Status.START
    },
    [Status.PASS]:{
      class:'at-pass-status',
      label: 'Passed',
      value: Status.PASS
    },
    [Status.PENDING]:{
      class:'at-pending-status',
      label: 'Pending',
      value: Status.PENDING,
    },
    [Status.LOADING]:{
      class:'loading-status',
      label: 'Loading',
      value: Status.LOADING,

    },
    //new status for manual step
    [Status.PENDINGUSERACTION]:{
      class:'at-pending-user-action-status',
      label: 'Pending User Action',
      value: Status.PENDINGUSERACTION,
    },
    [Status.PROCESSING]:{
      class:'at-pending-status',
      label: 'Pending',
      value: Status.PENDING,
    }

  };

  constructor(private httpClient: HttpClient,  private ngxLoader: NgxUiLoaderService,
    private testCaseProjectService: TestCaseProjectService) {
  }

  ngOnInit(): void {
      this.fetchStatus();
  }

  ngOnDestroy(): void {
    this.destroy$.next();  // trigger the unsubscribe
    this.destroy$.complete(); // finalize & clean up the subject stream
  }

  get statusDisplay(): { class: string; label: string; value: Status} {
    return this.status != null ? this.statusDisplayMapping[this.status] : this.statusDisplayMapping[Status.NOT_STARTED];
  }

  fetchStatus(){
    if (this.status != Status.NOT_STARTED && this.status != Status.START && this.status != Status.IN_PROGRESS) {
      if(this.detailLink != null){
        if (this.detailLink.get(this.correlationId) != undefined) {
          try {
            var status = this.detailLink.get(this.correlationId)['finalResult'];
            if (status == 'Fail') {
              this.status = Status.FAILED;
              this.statusChange.emit(this.status);
            }
           
            else {
              this.status = status;
              this.statusChange.emit(this.status);
            }
            clearInterval();
          } catch (Error) {
            //this.fetchStatus();
          }
        }
        else {
          this.status = Status.LOADING;
          setInterval(() => {
            this.fetchStatus();
          }, 1000)
        }
      }
    }
    else {
      
      this.statusDisplay;
    }
    
  }



  handleStatusChange(value: Status){
    this.status = value;
    this.statusChange.emit(this.status);
  }
}
