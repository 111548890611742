import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalErrorHandler } from '../common/error.handler';
@Component({
    selector: 'app-error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.css']
})
export class ErrorComponent {

    @Input() navigationPath: string;
    constructor(private router: Router) {
        // this.router.routeReuseStrategy.shouldReuseRoute = function() {
        //     return false;
        // };
    }
    onRefreshClicked() {
        window.location.reload();
        // console.log(this.navigationPath);
        // this.router.navigate(['/', this.navigationPath], {skipLocationChange:false}); 
    }
}