export class InventoryAdjMockRequest{
    public MessageType:string;
    public Parameters:Parameter;
}
export class Parameter{
    public Action:string;
    public Sender:string;
    public SenderPlant:string;
    public AdjustmentDetail:AdjDetail[];

}

export class AdjDetail{
 public  AdjustmentExplanation:string;
 public  AdjustmentTypeName :string;
 public  FromLocation :string;
 public  ItemSKU :string;
 public QuantityAdjusted :string;
 public ToLocation :string;
 public TypeOfAdjustment :string;
 public VendorItemSKU :string;

}