import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TemplateRef } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import * as log from "../../AppInsightsLogger";
import {  MsalService } from "@azure/msal-angular";
import { ConfigService } from "../../service/config.service";
import { Subject } from "rxjs";
import { TestCaseProjectService } from "src/app/service/test-case-project.service";
import { saveAs } from "file-saver";
import { ExcelFileService } from "../../service/excelFile.service";
import { Status } from "../status/status.component";
import { STATUS_LABELS } from "../status/status.component";
@Component({
  selector: "app-tracking-details",
  templateUrl: "./tracking-details.component.html",
  styleUrls: ["./tracking-details.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class TrackingDetailsComponent implements OnInit, OnDestroy {
  @Input() buttonText = "View Details";
  @Input() trackingDetails: Map<string, any>;
  @Input() correlationId = "";
  @Input() testCaseIdentifier = "";
  @Input() testPlanName = "";
  @Input() disabled = false;

  @ViewChild("trackingModal")
  trackingModal: TemplateRef<any>;
  private modalRef;
  private rawData;
  private logger: log.AppInsightsLogger;
  isCollapsed: boolean = false;
  private destroy$ = new Subject();
  private tableSchema;
  private excel: Blob;
  rawDataDisable: boolean = false;
  status: string;
  sorter: any = {};

  constructor(
    private excelService: ExcelFileService,
    private httpClient: HttpClient,
    private modalService: NgbModal,
    private msalService: MsalService,
    private configService: ConfigService,
    private testCaseProjectService: TestCaseProjectService
  ) {
    this.logger = new log.AppInsightsLogger(configService, msalService);
  }

  ngOnInit(): void {
    this.setTableSchema();
    this.rawData = [
      {
        TestCaseId: "",
        MergedEvents: [],
        FinalResult: "",
      },
    ];
    this.rawDataDisable = true;
  }

  ngOnDestroy(): void {
    this.destroy$.next(); // trigger the unsubscribe
    this.destroy$.complete(); // finalize & clean up the subject stream
  }

  openModal() {
    try {
      this.rawData = this.trackingDetails.get(this.correlationId)[
        "set_AllEventInfo"
      ];
      if (this.rawData[0]["FinalResult"] == "Fail") this.status = Status.FAILED;
      else this.status = this.rawData[0]["FinalResult"];

      if (this.rawData[0]["MergedEvents"].length == 0) {
        this.rawDataDisable = true;
      } else {
        this.rawDataDisable = false;
      }
    } catch (Error) {
      if (this.rawData[0]["MergedEvents"].length == 0) {
        this.rawDataDisable = true;
      }
    }
    this.modalRef = this.modalService.open(this.trackingModal, {
      size: "xl",
      centered: true,
      backdropClass: "modal-backdrop",
    });
  }

  //Download excel for tracking Details
  downloadExcel() {
    try {
      const testPlanName: string = this.testPlanName.replace(/ /g, "_");
      let fileName: string =
        "SCR_" + testPlanName + "_trackevents_" + this.testCaseIdentifier;
      if (this.rawData[0]["MergedEvents"].length != 0) {
        this.rawDataDisable = false;
        this.excelService.exportAsExcelFile(
          7,
          this.rawData[0]["MergedEvents"],
          fileName
        );
      }
    } catch (error) {
      this.logger.trackTrace(`Some error occured while exportAsExcelFile.`);
      this.logger.trackException(error);
    }
  }

  closeModal() {
    this.modalService.dismissAll(this.modalRef);
  }

  toggleCollapsed() {
    this.isCollapsed = !this.isCollapsed;
  }

  setTableSchema() {
    this.tableSchema = [
      {
        label: "Step",
        key: "Step",
      },
      {
        label: "Correlation ID",
        key: "CorrelationId",
      },
      {
        label: "Tracking ID",
        key: "TrackingId",
      },

      {
        label: "Event Name",
        key: "EventName",
      },
      {
        label: "Event Date",
        key: "EventDate",
      },
      {
        label: "Event Status",
        key: "EventStatus",
      },
      {
        label: "Event Message",
        key: "EventMessage",
      },
    ];
  }

  filterBy(column: string) {
    let keys: string[] = Object.keys(this.sorter);

    for (var key of keys) {
      if (key != column) {
        this.sorter[key] = 0;
      }
    }

    this.sorter[column] =
      this.sorter[column] == undefined || this.sorter[column] == 0 ? 1 : 0;

    if (this.sorter[column] == 1) {
      var comparator = function (taskA, taskB) {
        return taskA[column] < taskB[column] ? -1 : 1;
      };
    } else {
      var comparator = function (taskA, taskB) {
        return taskA[column] > taskB[column] ? -1 : 1;
      };
    }
    this.rawData[0]["MergedEvents"].sort(comparator);
  }
}
