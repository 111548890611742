import { Component, OnInit } from '@angular/core';
import { TestRunService } from '../service/testrun.service';
import { NewTestRunRequest } from '../model/new-testrun-request.model';
import { Router } from '@angular/router';
import { BaseComponent } from '../base/base.component';
import { MonitoringService } from '../service/monitoring.service';

import {  MsalService } from '@azure/msal-angular';

import * as log from '../AppInsightsLogger';
import { ConfigService } from '../service/config.service';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

@Component({
  selector: 'app-new-test-run',
  templateUrl: './new-test-run.component.html',
  styleUrls: ['./new-test-run.component.css']
})
export class NewTestRunComponent extends BaseComponent implements OnInit {

  showPreloader: boolean;
  msgTxt: string;
  showMessage: boolean;
  newTestRun = new NewTestRunRequest();

  name: string;
  private logger: log.AppInsightsLogger;

  ngOnInit() {
    this.msgTxt = '';
    this.showMessage = false;
    this.showPreloader = false;
    this.logger.trackTrace(`Successfully landed to New Test Run Page`);
  }

  constructor(private configService: ConfigService, private testRunService: TestRunService, private router: Router, private monitoringService: MonitoringService,
    private msalService: MsalService) {
    super(monitoringService);
    this.name = this.msalService.instance.getActiveAccount().name;
    this.logger = new log.AppInsightsLogger(configService, msalService);
  }

  onSubmitClick() {
    this.newTestRun.poNumbers = [];
    this.showPreloader = true;
    this.testRunService.createNewTestRun(this.newTestRun).subscribe((response) => {
      this.showMessage = true;
      this.showPreloader = false;
      this.router.navigate(['/', 'testruns']);
      this.logger.trackTrace(`Submited successfully new test run`);
    }, (error) => {
      this.showMessage = true;
      this.showPreloader = false;
        this.msgTxt = 'Some error occured while processing your request';
        this.logger.trackTrace(`In New Test Run, Some error occured while processing your request`);

    });
  }
}
