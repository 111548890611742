import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Location } from '@angular/common';
import {MsalService} from '@azure/msal-angular'
import { environment } from '../../environments/environment.prod';
import { Constants } from '../service/constant.service';
import { ConfigService } from '../service/config.service';
import { Router } from '@angular/router';
import { AuthService } from '../service/auth.service';
import { FeedbackComponent } from '../feedback/feedback.component';
import { OrgViewService } from '../service/org-view.service';
import { Organization } from '../model/org-view.model';
import { MsalBroadcastService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-navmenu',
  templateUrl: './navmenu.component.html',
  styleUrls: ['./navmenu.component.css'],
  providers: [ FeedbackComponent ]
})

export class NavmenuComponent implements OnInit {

  username: string;
  swaggerUrl: string;
  environments = environment.supportedEnvironments;
  selectedEnvironment = Constants.EnvironmentCI;
  isShipmentMockEnabled: boolean
  isLoadPlanMockEnabled: boolean
  isGoodreceiptMockEnabled: boolean
  isManageTestRunEnabled: boolean
  isSerialNumberGenerationEnabled: boolean
  isSerialNumberSearchEnabled: boolean
  isAPIDocEnabled: boolean
  isAuthenticatedWithRolesValid: boolean
  isOrderViewEnabled: boolean;
  isSkuSearchEnabled: boolean;
  isSkuValidationEnabled: boolean;
  isSAPOutputTriggerEnabled: boolean;
  isEnabled: boolean;
  sub: any;
  isDevMode: boolean;
  isCollapsed: boolean;
  OCV: any;
  currentAppName: string;
  currentAppSubOption: string;
  isOrgSelected:boolean = false;

  @Output() messageEvent = new EventEmitter<boolean>();
  orgName: string;
  orgId: string;
  orgMap: Map<number, string> = new Map();
  loginDisplay: boolean = false;
  isMockerV2Enabled: boolean;

  constructor(private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    public configService: ConfigService,
    private router: Router,
    public authService: AuthService,
    private location: Location,
    public feedbackComponent: FeedbackComponent,
    private orgService: OrgViewService) {
    if (window.location.origin.toLowerCase().indexOf('-test') > 0 || window.location.origin.toLowerCase().indexOf('-dev') > 0 || window.location.origin.toLowerCase().indexOf('-sit') > 0) {
      this.isDevMode = true;
    }
    else {
      this.isDevMode = false;
    }
    this.isCollapsed = false;
    this.currentAppName = '';
    // this.OCV = new FeedbackComponent();
  }

  ngOnInit() {

    this.msalBroadcastService.msalSubject$
    .pipe(
      filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
    )
    .subscribe((result: EventMessage) => {
      const payload = result.payload as AuthenticationResult;
      this.msalService.instance.setActiveAccount(payload.account);
    });

  this.msalBroadcastService.inProgress$
    .pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None)
    )
    .subscribe(() => {
      this.router.navigate['/unauthorized'];
    });


    //this.adalService.handleWindowCallback();
    if (!this.authService.isAuthenticatedWithRoles()) {
      this.router.navigate['/unauthorized'];
    }


    this.setOrgMap();
    this.username = this.msalService.instance.getActiveAccount().name;

    this.isShipmentMockEnabled = this.authService.isShipmentMockEnabled();
    this.isLoadPlanMockEnabled = this.authService.isLoadPlanMockEnabled();
    this.isGoodreceiptMockEnabled = this.authService.isGoodreceiptMockEnabled();
    this.isMockerV2Enabled = this.authService.isMockerV2();
    this.isManageTestRunEnabled = this.authService.isManageTestRunEnabled();
    this.isSerialNumberGenerationEnabled = this.authService.isSerialNumberGenerationEnabled();
    this.isSerialNumberSearchEnabled = this.authService.isSerialNumberSearchEnabled();
    this.isSkuSearchEnabled = this.authService.isSkuSearchEnabled();
    this.isSkuValidationEnabled = this.authService.isSkuValidationEnabled();
    this.isSAPOutputTriggerEnabled = this.authService.isSAPOutputTriggerEnabled();
    this.isEnabled = this.authService.isEnabled();
    this.isAPIDocEnabled = this.authService.isAPIDocEnabled();
    this.isAuthenticatedWithRolesValid = this.authService.isAuthenticatedWithRoles();
    this.isOrderViewEnabled = this.authService.isOrderViewEnabled();
    this.swaggerUrl = this.configService.omtestSuiteAPI.baseUrl + '/swagger';
    if (this.isDevMode) {
      this.switchEnvironment('UAT');
    }

   
    this.SelectedOrgName;
    

  }

  async setOrgMap() {
    try {
      const promise = this.orgService.getOrganizations().toPromise();
      const orgs = await promise;
      for (const org of orgs) {
        this.orgMap.set(org.organizationId, org.organizationName);
      }
      this.setOrgData();
    } catch (error) {
      this.setOrgData();
    }
  }
  
  
  setOrgData() {
    this.orgId = window.sessionStorage.getItem("orgSelected");
    let orgName = this.orgMap.get(parseInt(this.orgId));
    if (orgName != undefined) {
      window.sessionStorage.setItem('orgName', orgName);
    }
    this.orgName = window.sessionStorage.getItem("orgName");  
  }




  public get SelectedOrgName() {
    this.orgName = window.sessionStorage.getItem("orgName");
    this.orgId  = window.sessionStorage.getItem("orgSelected");
    return this.orgName;
  }

  public get IsOrgLanding() {
    return window.sessionStorage.getItem("inOrgLanding") != null && this.router.url == "/org-landing";
  }

  public filterNavMenu(option:string) :boolean {

    var selectedOrg = window.sessionStorage.getItem("orgSelected");
   
    
  
    
     // navigation constants.
     const testRuns = 'Test Runs';
     const loadTest = 'Load Test';
     const Tools = 'Tools';
     const Mockers = 'Mockers';
     const Library = 'Library';
     const contactUs = 'Contact Us';
     const Legacy = 'Legacy';
     const privacyNotice = 'Privacy Notice';
     const Admin = 'Admin';
     const Home = 'Home';
     const projectTestRuns = 'ProjectTestRun';


    switch (option) {

      case privacyNotice: {
        return true;
      }

      case Home: {
        return selectedOrg != null && this.IsOrgLanding == false;
      }

      case testRuns: {
        return selectedOrg != null && this.IsOrgLanding == false;
      }
      case contactUs: {
        return selectedOrg !=  null && this.IsOrgLanding == false;
      }

      case projectTestRuns: {
        return selectedOrg != null && this.IsOrgLanding == false;
      }

      //only for supply chain
      case loadTest: {
        return selectedOrg != null && selectedOrg == "1" && this.IsOrgLanding == false;
      }

      case Tools: {
        return selectedOrg != null && selectedOrg == "1" && this.IsOrgLanding == false;
      }

      case Mockers: {
        return selectedOrg != null  && this.IsOrgLanding == false;
      }

      case Library: {
        return selectedOrg != null && selectedOrg == "1" && this.IsOrgLanding == false;
      }

      case Legacy: {
        return selectedOrg != null && selectedOrg == "1" && this.IsOrgLanding == false;
      }

      case Admin: {
        return selectedOrg != null && selectedOrg == "1" && this.IsOrgLanding == false;
      }

      default: {
        return false;
      }
    }
  }

  login() {
     this.msalService.instance.loginRedirect();
  }

  logout() {
    this.msalService.instance.logoutRedirect();
  }

  get authenticated(): boolean {
    return this.msalService.instance.getAllAccounts().length>0;
  }

  

  switchEnvironment(env: string) {
    this.configService.switchEnvironment(env);
    this.selectedEnvironment = env;
    this.isShipmentMockEnabled = this.authService.isShipmentMockEnabled();
    this.isLoadPlanMockEnabled = this.authService.isLoadPlanMockEnabled();
    this.isGoodreceiptMockEnabled = this.authService.isGoodreceiptMockEnabled();
    this.isMockerV2Enabled = this.authService.isMockerV2();
    this.isManageTestRunEnabled = this.authService.isManageTestRunEnabled();
    this.isSkuSearchEnabled = this.authService.isSkuSearchEnabled();
    this.isSkuValidationEnabled = this.authService.isSkuValidationEnabled();
    this.isSAPOutputTriggerEnabled = this.authService.isSAPOutputTriggerEnabled();
    this.isEnabled = this.authService.isEnabled();
    this.isAPIDocEnabled = this.authService.isAPIDocEnabled();
  }
  collapseNavbar(): void {
    this.isCollapsed = !this.isCollapsed;
    this.messageEvent.emit(this.isCollapsed);
  }


  navigateMainApp(newOption: string): void {
    // update app name on screen.
    this.currentAppName = newOption;
  //  this.currentAppSubOption = '';
   
    // navigation constants.
    const testRuns = 'Test Runs';
    const loadTest = 'Load Test';
    const Tools = 'Tools';
    const Mockers = 'Mockers';
    const Library = 'Library';
    const contactUs = 'Contact Us';
    const Legacy = 'Legacy';
    const privacyNotice = 'Privacy Notice';
    const Admin = 'Admin';
    const Home = 'Home';
    const projectTestRuns = 'ProjectTestRun';
    const orgLanding  = 'OrgLanding'
    
  
    // navigate to screen based on selected option.
    switch (newOption) {
     
      case orgLanding:{
        this.router.navigate(['/org-landing']);
        break;
      }
      case Home: {
        this.router.navigate(['/landing-page', this.getUrlParams()]);
        break;
      } case testRuns: {
        //Todo: Once description is provided, change the navigation link to /testrun.
        this.router.navigate(['/testscenario', this.getUrlParams()]);
        break;
      }
      case projectTestRuns:{
        this.router.navigate(['/org-project-view', this.getUrlParams()]);
        break;
      }
      case Tools: {
        this.router.navigate(['/tools']);
        break;
      }
      case Mockers: {
        this.router.navigate(['/mockers']);
        break;
      }
      case Library: {
        this.router.navigate(['/scenariocatalog']);
        break;
      } case Admin: {
        this.router.navigate(['/testrunoverview']);
        break;
      }case Legacy: {
        this.router.navigate(['/testruns']);
        break;
      } case privacyNotice: {
        this.router.navigate(['/privacynotice']);
        break;
      } case loadTest: {
        this.router.navigate(['/loadtest']);
        break;
      } case contactUs: {
        break;
      }
      default: {
        console.log('Invalid option selected in navigateMainMenu.', newOption);
      }
    }
  }
  getUrlParams(): any {
    return {
      'org': this.orgId,
    
    };
  }
}
