import { Component, OnInit } from '@angular/core';
import { MasterDataService } from '../service/masterdata.service';
import { PagerService } from '../service/pager.service';
import { SKUNumberList } from '../model/skunumberlist.model';
import { Constants } from '../service/constant.service';
import { MsalService } from '@azure/msal-angular';
import { SkuNumberSearchRequest } from '../model/skunumbersearchrequest.model';
import { DescriptionService } from '../service/description.service';

import { ConfigService } from '../service/config.service';
import * as log from '../AppInsightsLogger';

import { ApplicationInsights } from '@microsoft/applicationinsights-web';

@Component({
  selector: 'app-sku-number-list',
  templateUrl: './sku-number-list.component.html',
  styleUrls: ['./sku-number-list.component.css']
})
export class SkuNumberListComponent implements OnInit {

  skuNumberList: SKUNumberList;
  skuNumberText: string;
  skuText: string;
  fromDate: any;
  toDate: any;
  collectionSize: number;
  page: number;
  showLoader = true;
  skuNumberSearchRequest: SkuNumberSearchRequest;
  name: string
  private logger: log.AppInsightsLogger;
  pageDetails: any[];
  pageDescription: string;

  constructor(private descriptionService: DescriptionService, private masterDataService: MasterDataService, private pagerService: PagerService, private msalService: MsalService, private configService: ConfigService) {
    this.name = this.msalService.instance.getActiveAccount().name;
    this.logger = new log.AppInsightsLogger(configService, msalService);
  }

  ngOnInit() {
    this.setOrgData();
    this.page = 1;
    this.skuNumberList = new SKUNumberList();
    this.skuNumberList.skuNumberListItems = [];
    this.setDefaultDates();
    this.skuNumberSearchRequest = this.getSkuNumberSearchRequest();
    this.updateSkuNumberSearch();
    this.logger.trackTrace(`Successfully landed to Sku Number List`);

    this.descriptionService.pageDetailsTools().subscribe((response: any[]) => {
      this.pageDetails = response;
      this.pageDetails.forEach(element => {
        if (element.toolName == "Search SKU") {
          this.pageDescription = element.toolDescription
        }
      });
    });
  }


  setOrgData() {
    window.sessionStorage.setItem("orgSelected", "1");
  }
  
  onSearchClick() {
    if (this.skuNumberText) {
      this.fromDate = null;
      this.toDate = null;
    }
    else if (this.skuText) {
      this.fromDate = null;
      this.toDate = null;
    }
    else {
      if (this.fromDate == null && this.toDate == null) {
        this.setDefaultDates();
      }
    }

    this.skuNumberList.skuNumberListItems = [];
    this.skuNumberSearchRequest = this.getSkuNumberSearchRequest();
    this.updateSkuNumberSearch();
  }

  getSkuNumberSearchRequest(skip = 0, take = Constants.DefaultPageSize, sortOrder = Constants.SortOrderDescending) {
    var skuNumberSearchRequest = new SkuNumberSearchRequest();
    if (this.skuNumberText) {
      skuNumberSearchRequest.sku = this.skuNumberText;

    }
    else if (this.skuText) {
      skuNumberSearchRequest.sku = this.skuText;
    }
    else {
      var startDate = this.getFormattedDate(this.fromDate);
      startDate.setUTCHours(0, 0, 0, 0);
      skuNumberSearchRequest.startDate = startDate.toJSON();

      var endDate = this.getFormattedDate(this.toDate);
      endDate.setUTCHours(24, 0, 0, 0);
      skuNumberSearchRequest.endDate = endDate.toJSON();
    }

    skuNumberSearchRequest.skip = skip;
    skuNumberSearchRequest.take = take;
    skuNumberSearchRequest.sortOrder = sortOrder;
    return skuNumberSearchRequest;
  }

  getFormattedDate(inputDate: any): Date {
    var newDate = new Date(Date.UTC(inputDate.year, inputDate.month - 1, inputDate.day));
    return newDate;
  }

  updateSkuNumberSearch() {
    this.showLoader = true;
    this.skuNumberList.skuNumberListItems = [];
    this.masterDataService.getSkuNumberList(this.skuNumberSearchRequest).subscribe((response) => {
      this.skuNumberList = response;
      this.collectionSize = this.skuNumberList.totalCount;
      this.showLoader = false;
    });
  }

  onPageChanged() {
    this.skuNumberSearchRequest.skip = (this.page - 1) * 10;
    this.skuNumberSearchRequest.take = 10;
    this.updateSkuNumberSearch();
  }

  setDefaultDates() {
    var todaysDate = new Date();
    var fifteenDaysOldDate = new Date();
    fifteenDaysOldDate.setDate(fifteenDaysOldDate.getDate() - 7);
    this.fromDate = { 'year': fifteenDaysOldDate.getUTCFullYear(), 'month': fifteenDaysOldDate.getUTCMonth() + 1, 'day': fifteenDaysOldDate.getUTCDate() };
    this.toDate = { 'year': todaysDate.getUTCFullYear(), 'month': todaysDate.getUTCMonth() + 1, 'day': todaysDate.getUTCDate() };
  }
}
