export class ProductRequestList {
  public ProductRequest: ProductRequest[];
}

export class ProductRequest {
  public sku: string;
  public country: string;
  public hardwarePartNumber: string;
  public TestPlanInstanceId?: string;
  public RequestedBy:string;
}
