export class OrderList {
    public totalCount: number;
    public orderListItems: OrderListItem[];
}

export class OrderListItem {
    public customerPurchaseOrderNumber: string;
    public createdAt: number;
    public lastModifiedAt: number;
}
