import { TransportMethod } from "./masterdata.model";

export class LoadPlanMockRequest {

  constructor() {
    this.mode = '';
    this.entityType = '';
    this.partnerId = '';
    this.entityNumbers = [];
    this.isConsolidated = false;
    this.loadPlanParams = new LoadPlanParameters();
  }

  public mode: string;
  public entityType: string;
  public partnerId: string;
  public entityNumbers: string[];
  public isConsolidated: boolean;
  public loadPlanParams: LoadPlanParameters;
}

export class LoadPlanParameters {
  constructor() {
    this.carrierCode = '';
    this.serviceLevelCode = '';
    this.serviceLevelCode = '';
    this.paymentTermsCode = '';

    this.transportMethodCode = '';
    this.transportMethodDescription = '';
    this.transportMethodEnum = '';
  }

  public carrierCode: string;
  public serviceLevelCode: string;
  public transportMethodCode: string;
  public transportMethodDescription: string;
  public transportMethodEnum: string;
  public paymentTermsCode: string;
  public supplierId: string;
  public BusinessChannel: string;
  public ActionCode: string;
  public ProcessType: string;
}

export class LoadPlanMockConfig {
  public methodOfTransport : TransportMethod[];
}

