import { Component, OnInit, Input } from '@angular/core';
import { UatAvailabilityService } from '../../service/uatavailability.service';
import { MasterDataService } from '../../service/masterdata.service';
import { MsalService } from '@azure/msal-angular';
import { ConfigService } from '../../service/config.service';
import * as log from '../../AppInsightsLogger';
import { skip } from 'rxjs/operators';
import { UATAvailability } from '../../model/uatAvailability.model';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ManageScenariosService } from '../../service/manage-scenarios.service';
import { TestProjectService } from '../../service/testproject.service';
import { OrgViewService } from 'src/app/service/org-view.service';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Router } from '@angular/router';
import { Projects, TestPlanInstance } from 'src/app/model/project-view.model';
import { throwMatDialogContentAlreadyAttachedError } from '@angular/material/dialog';
export { BaseTestPlanInstance, TestPlan } from '../test-grid/test-grid.component';
import { Page } from '../../model/masterdata.model';
import { DescriptionService } from '../../service/description.service';

@Component({
    selector: 'app-org-project-view',
    templateUrl: './org-project-view.component.html',
    styleUrls: ['./org-project-view.component.css']
})
export class OrgProjectViewComponent implements OnInit {
    private logger: log.AppInsightsLogger;
    private gridSchema: any = [];
    private project: any;
    orgName: string;
    isLoading:boolean=true;
    orgDescription: any;
    organizationID: number;
  glossaryList: any[];
  is500error: boolean = false;
  projectDescription: string;

    constructor(private ngxLoader: NgxUiLoaderService,
        private msalService: MsalService,
        private configService: ConfigService,
        private uatDataService: UatAvailabilityService,
        private masterDataService: MasterDataService,
        private manageTestRunsService: ManageScenariosService,
        private testProjectService: TestProjectService,
        private orgViewService: OrgViewService,
        private descriptionService: DescriptionService,
        private router: ActivatedRoute) {
        this.logger = new log.AppInsightsLogger(configService, msalService);
    }

    ngOnInit() {
        //this.router.navigate(['/org-project-view']);
      this.loadGlossary();
        this.gridSchema = [
            {
                label: 'Project',
                class: 'col-3 col-sm-3',
                filterType:'link',
                key: 'projectName'
                
            },

            {
                label: "Status",
                class: 'col col-xl',
                filterType: 'status',
                key: 'projectStatus',
                columnParameters: {
                }
            },

            {
                label: 'Owner',
                class: 'col col-xl-2',
                filterType: 'string',
                isEditable:'false',
                key: 'projectOwner',
                filterDisplay: 'string'
            
            },

            {
                label: 'Created',
                class: 'col col-xl',
                key:'projectCreateDate',
                filterType: 'date'
            },
            {
                label: 'Last Modified',
                class: 'col col-xl',
                key: 'projectModifiedDate',
                filterType: 'date',
            },
           
        ];


        this.setOrgData(); 
        
    }

  loadGlossary() {
    this.descriptionService.getGlossary().subscribe((response: Page[]) => {
      console.log("response", response)
      if (response != null) {
        this.glossaryList = response;
        console.log("response", response)
      }
    
      for (var i = 0; i < this.glossaryList.length; i++) {
        if (this.glossaryList[i].name == "Project") {
          this.projectDescription = this.glossaryList[i].description;
        }
      }
    }, (error: any) => {
      if (error.status == 500) {
        this.is500error = true;
      }
      this.logger.trackTrace(`In Project, load Glossary Details Error`);
      this.logger.trackException(error);
      this.ngxLoader.stop();
    });
  }
    setOrgData() {
        this.organizationID = parseInt(window.sessionStorage.getItem("orgSelected") || "0");
        let orgId  = this.router.snapshot.params['org'] || "";
        if(orgId  != "" && this.organizationID != parseInt(orgId)){
            this.organizationID = orgId, 
            window.sessionStorage.setItem("orgSelected", this.organizationID.toString());
        }
        this.orgName = window.sessionStorage.getItem("orgName");
        this.getProjectsByOrg(); 
    }

    //returns project data 
    get Projects() {
        return this.project;
    }

    //returns grid schema
    get ProjectSchema() {
        return this.gridSchema;
    }


    getProjectsByOrg(){
        this.orgViewService.getprojects(this.organizationID).subscribe((response:any[])=> {
            if(response != null) {
                this.project = response;
                this.orgDescription  = this.project.organizationDescription;
                this.isLoading = false; 
                //this.orgName = window.sessionStorage.getItem("orgName");  
            }
        },(error:any)=>{
            this.logger.trackTrace(`In Project View, error getting projects: ${error}`)
            this.logger.trackException(error);
        });
    }


    
}
