import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OrderService } from '../service/order.service';
import { OrderSummary, SalesOrder } from '../model/order-summary.model';
import { OrderSummaryTimeline, OrderSummaryTimelineItem } from '../model/ordersummary-timeline.model';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponent } from '../base/base.component';
import { MonitoringService } from '../service/monitoring.service';
import { ConfigService } from '../service/config.service';
import * as log from '../AppInsightsLogger';

import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-order-summary',
  templateUrl: './order-summary.component.html',
  styleUrls: ['./order-summary.component.css']
})

export class OrderSummaryComponent extends BaseComponent implements OnInit {

  name: string;
  customerPurchaseOrder: string;
  orderSummary: OrderSummary;
  timeline = new OrderSummaryTimeline();
  showLoader = true;
  closeResult: string;
  modalJsonPayload: string;
  modalFileId: string;
  sub: any;
  openModal: boolean;
  showModalLoader = false;
  modalMessage = '';
  showModalMessage = false;
  modalTitle = '';
  modalId = '';
  isButtonActive = new Map<string, boolean>();
  private logger: log.AppInsightsLogger;

  constructor(private orderService: OrderService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private msalService: MsalService, 
    private monitoringService: MonitoringService,
    private configService: ConfigService) {
    super(monitoringService);
    this.name = this.msalService.instance.getActiveAccount().name;
    this.logger = new log.AppInsightsLogger(configService, msalService);
  }

  ngOnInit() {
    this.logger.trackTrace(`Successfully landed to Order summary `);
    this.timeline.orderTimelineItems = [];
    this.customerPurchaseOrder = this.route.snapshot.params['customerPurchaseOrder'];
    this.orderService.getOrderSummary(this.route.snapshot.params['customerPurchaseOrder']).subscribe(
      (response) => {
        this.logger.trackTrace(`Get Order Summary Successfully`);
        this.orderSummary = response;
        this.orderSummary.salesOrders.forEach(salesOrder => { this.isButtonActive.set(salesOrder.orderConfirmationNumber, false); });
        this.showLoader = false;
        if (this.orderSummary && this.orderSummary.salesOrders && this.orderSummary.salesOrders.length > 0) {
          this.renderTimeline(this.orderSummary.salesOrders[0]);
        }
      });
   
  }

  isActive(salesOrder: SalesOrder) {
    return this.isButtonActive.get(salesOrder.orderConfirmationNumber);
  }

  setActive(orderConfirmationNumber: any) {
    for (let i = 0; i < this.orderSummary.salesOrders.length; i++) {
      if (this.orderSummary.salesOrders[i].orderConfirmationNumber === orderConfirmationNumber) {
        this.isButtonActive.set(this.orderSummary.salesOrders[i].orderConfirmationNumber, true);
      } else {
        this.isButtonActive.set(this.orderSummary.salesOrders[i].orderConfirmationNumber, false);
      }
    }
  }

  renderTimeline(salesOrder: SalesOrder) {
    this.setActive(salesOrder.orderConfirmationNumber);
    this.timeline = new OrderSummaryTimeline();
    let timeline = new OrderSummaryTimeline();
    timeline.orderTimelineItems = [];
    timeline.salesOrderNumber = salesOrder.orderConfirmationNumber;

    if (salesOrder !== null) {
      let timelineItem = new OrderSummaryTimelineItem({
        message: 'Order Confirmation Received',
        messageType: 'OrderConfirmation',
        entityId: salesOrder.orderConfirmationNumber,
        correlationId: salesOrder.orderConfirmationCorrelationId,
        timestamp: salesOrder.timestamp
      });
      timeline.orderTimelineItems.push(timelineItem);
      salesOrder.deliveryOrders.forEach(deliveryOrder => {
        let timelineItem = new OrderSummaryTimelineItem();
        timelineItem.message = 'Delivery Order Received';
        timelineItem.messageType = 'DeliveryOrder';
        timelineItem.entityId = deliveryOrder.deliveryOrderNumber;
        timelineItem.correlationId = deliveryOrder.deliveryOrderCorrelationId;
        timelineItem.timestamp = deliveryOrder.timestamp;
        timeline.orderTimelineItems.push(timelineItem);
      });
      salesOrder.loadPlans.forEach(loadplan => {
        var timelineItem = new OrderSummaryTimelineItem({
          message: 'LoadPlan Received',
          messageType: 'LoadPlan',
          entityId: loadplan.loadId,
          correlationId: loadplan.loadPlanCorrelationId,
          timestamp: loadplan.timestamp
        });
        timeline.orderTimelineItems.push(timelineItem);
      });
      salesOrder.shipments.forEach(shipment => {
        let timelineItem = new OrderSummaryTimelineItem({
          message: 'Shipment Received',
          messageType: 'Shipment',
          entityId: shipment.shipmentNumber,
          correlationId: shipment.shipmentCorrelationId,
          timestamp: shipment.timestamp
        });
        timeline.orderTimelineItems.push(timelineItem);
      });
    }

    this.timeline = timeline;
  }

  fetchOSSPayload() {
    this.showModalLoader = true;
    this.showModalMessage = false;
    this.orderService.getOrderStatus(this.timeline.salesOrderNumber).subscribe(
      (response) => {
        this.showModalLoader = false;
        this.modalJsonPayload = response;
        this.modalTitle = 'Order Status';
        this.modalId = this.timeline.salesOrderNumber;
        this.logger.trackTrace(`In Order Summary  ${this.modalTitle} Successfully`);
      }, (error: any) => {
        this.showModalLoader = false;
        this.showModalMessage = true;
        this.modalMessage = 'An error occured while fetching order status payload.';
        this.logger.trackTrace(`In Order Summary ${this.modalMessage}`);
        this.logger.trackException(error);
      });
  }

  fetchAndOpenPayload(item: OrderSummaryTimelineItem) {
    this.showModalLoader = true;
    this.showModalMessage = false;

    if (item.messageType === 'OrderConfirmation') {
      this.modalTitle = 'Order Confirmation';
      this.modalId = item.correlationId;
      this.orderService.getOrderConfirmationByCorrelationId(item.correlationId).subscribe((response) => {
        this.logger.trackTrace(`In Order Summary  ${this.modalTitle} Successfully`);
        this.showModalLoader = false;
        this.modalJsonPayload = response;
      }, (error: any) => {
        this.showModalLoader = false;
        this.showModalMessage = true;
          this.modalMessage = 'An error occured while fetching payload.';
          this.logger.trackTrace(`In Order Summary ${this.modalMessage} ${error}`);
          this.logger.trackException(error);
      });
    } else if (item.messageType === 'DeliveryOrder') {
      this.modalTitle = 'Delivery Order';
      this.modalId = item.correlationId;
      this.orderService.getDeliveryOrderByCorrelationId(item.correlationId).subscribe((response) => {
        this.logger.trackTrace(`In Order Summary  ${this.modalTitle} Successfully`);
        this.showModalLoader = false;
        this.modalJsonPayload = response;
      }, (error: any) => {
        this.showModalLoader = false;
        this.showModalMessage = true;
          this.modalMessage = 'An error occured while fetching payload.';
          this.logger.trackTrace(`In Order Summary ${this.modalMessage} ${error}`);
          this.logger.trackException(error);
      });
    } else if (item.messageType === 'Shipment') {
      this.modalTitle = 'Shipment';
      this.modalId = item.correlationId;
      this.orderService.getShipmentByCorrelationId(item.correlationId).subscribe((response) => {
        this.logger.trackTrace(`In Order Summary  ${this.modalTitle} Successfully`);
        this.showModalLoader = false;
        this.modalJsonPayload = response;
      }, (error: any) => {
        this.showModalLoader = false;
        this.showModalMessage = true;
          this.modalMessage = 'An error occured while fetching payload.';
          this.logger.trackTrace(`In Order Summary ${this.modalMessage} ${error}`);
          this.logger.trackException(error);
      });
    }
    else if (item.messageType === 'LoadPlan') {
      this.modalTitle = 'LoadPlan';
      this.modalId = item.correlationId;
      this.orderService.getLoadPlanByCorrelationId(item.correlationId).subscribe((response) => {
        this.showModalLoader = false;
        this.modalJsonPayload = response;
        this.logger.trackTrace(`In Order Summary  ${this.modalTitle} Successfully`);
      }, (error: any) => {
        this.showModalLoader = false;
        this.showModalMessage = true;
          this.modalMessage = 'An error occured while fetching payload.';
          this.logger.trackTrace(`In Order Summary ${this.modalMessage} ${error}`);
          this.logger.trackException(error);
      });
    }
  }

  onModalFetchPayloadSuccess(response: any) {
    console.log(response);
    this.showModalLoader = false;
    this.modalJsonPayload = response;
  }

  onModalFetchPAyloadFailure(error: any) {
    this.showModalLoader = false;
    this.showModalMessage = true;
    this.modalMessage = 'An error occured while fetching the payload.';
    this.logger.trackTrace(`In Order Summary ${this.modalMessage}`);
    this.logger.trackException(error);
  }

  downloadOSSPayloadFile() {
    if (this.modalJsonPayload) {
      this.downloadTextFile(JSON.stringify(this.modalJsonPayload), this.modalId + '.json');
    }
  }

  downloadTextFile(text: any, name: string) {
    const a = document.createElement('a');
    const type = name.split('.').pop();
    a.href = URL.createObjectURL(new Blob([text], { type: `text/${type === 'txt' ? 'plain' : type}` }));
    a.download = name;
    a.click();
  }

  open(content: any, type: string, item: OrderSummaryTimelineItem) {
    // this.fetchOSSPayload();
    if (type === 'OrderStatusPayload') {
      this.fetchOSSPayload();
    } else if (type === 'AtomicServicePayload') {
      this.fetchAndOpenPayload(item);
    }

    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  isAtomicServicePayloadDownloadEnabled() {
    return true;
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
