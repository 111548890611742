<div>
  <div style="width: 100%; height: calc(100vh - 70px); ">
  <div class="input-group">
    <h3>Serial Number Generator</h3>
    <div>&nbsp;</div>
  </div>
  <br>
  <div class="form-group col-sm-10" style="margin-bottom: 25px; border: 1px solid rgba(0,0,0,.125); border-radius: .25rem;">
    <b>Description:</b>
    <p>{{this.pageDescription}}</p>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <div class="form-group">
        <div class="col-sm-2">
          <label class="control-label" for="mode">
          
            <button aria-label="mode-tool-tip" style="margin-left:0px" type="button" id="mode-tool-tip" class="tool-tip-button"
                    ngbTooltip="Single or multi-device SN generation"
                    [closeDelay]="1000" triggers="click" placement="right">
              <span style="margin-right:10px">Mode</span><i class="fa fa-question-circle text-center tool-tip-icon"></i>
            </button>
          </label>
        </div>
        <div class="row no-margin">
          <div class="col-sm-10">
            <select aria-label="mode" class="form-control" id="mode" name="mode" [(ngModel)]="mode" required #modeField="ngModel"
                    (change)="resetModels()">
              <option value="SINGLE">SINGLE PROCESSING</option>
              <option value="BATCH">BATCH PROCESSING</option>
            </select>
          </div>
          <div class="col-lg-2">
            <button type="button" class="btn btn-info pos-rel cursor-pointer" (click)="downloadSampleFile()" *ngIf="mode == 'BATCH'"
                    style="color: #ffffff; background-color: #929292; border-color: #929292;">
              Download Sample
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="mode == 'BATCH'">
    <div class="col-lg-12">
      <div class="row" style="padding: 20px 15px;">
        <div class="col-lg-6">
          <span class="margin-right-15">Upload Excel File:</span>
          <input type="file" #fileInput accept=".xlsx" multiple="false" placeholder="Enter DO numbers"
                 (change)="processExcelFile($event, responseModal)" style="height: 37px; cursor: pointer;" required />
        </div>
        <div class="col-lg-6 text-right">
          <button type="button" class="btn btn-info pos-rel cursor-pointer" (click)="fetchSerialNumbers()"
                  [disabled]="responseCount > 0 || serialNumberRequestsStatusList.length <= 0">
            Process Excel
          </button>
          <button type="button" class="btn btn-info pos-rel cursor-pointer margin-left-15" (click)="downloadResultsList()"
                  *ngIf="responseCount > 0 && responseCount == serialNumberRequestsStatusList.length">
            Download Results
          </button>
        </div>
      </div>
      <div class="row" *ngIf="serialNumberRequestsStatusList.length > 0">
        <div class="col-lg-12" #SNResponse>
          <table class="table table-fixed no-margin" role="grid">
            <thead>
              <tr class="row no-margin font-14 text-center" role="row">
                <th class="col-lg-2" role="columnheader" aria-label="">Part Number</th>
                <th class="col-lg-2" role="columnheader" aria-label="">HWPN</th>
                <th class="col-lg-2" role="columnheader" aria-label="">Quantity</th>
                <th class="col-lg-6" role="columnheader" aria-label="Actions">Serial Numbers</th>
              </tr>
            </thead>
            <tbody>
              <tr class="row no-margin font-14 text-center"
                  *ngFor="let snRequest of serialNumberRequestsStatusList; index as a">
                <td class="col-lg-2" role="gridcell" style="overflow-wrap: break-word; word-break: break-all;">
                  {{snRequest.serialNumberRequest.partNumber}}
                </td>
                <td class="col-lg-2" role="gridcell" style="overflow-wrap: break-word; word-break: break-all;">
                  {{snRequest.serialNumberRequest.hardwarePartNumber}}
                </td>
                <td class="col-lg-2" role="gridcell" style="overflow-wrap: break-word; word-break: break-all;">
                  {{snRequest.serialNumberRequest.quantity}}
                </td>
                <td class="col-lg-6" role="gridcell" style="overflow-wrap: break-word; word-break: break-all;">
                  <div *ngIf="(snRequest.responseList == undefined || snRequest.responseList == null || snRequest.responseList == '') && snRequest.showPreloader">
                    <div class="pos-rel"
                         style="display: block; margin: auto; width: 20px; height: 15px; margin-top: -7px;">
                      <app-preloader-small></app-preloader-small>
                    </div>
                  </div>
                  <div *ngIf="snRequest.responseList != undefined && snRequest.responseList != null && snRequest.responseList != ''">
                    {{snRequest.responseList.toString()}}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <ngb-alert *ngIf="snResponseSuccessAlert" style="margin: 15px 15px;" (close)="this.snResponseSuccessAlert = false">
            <span>
              <strong>Note:&nbsp;</strong><strong class="font-italic">Warranty&nbsp;information&nbsp;</strong>can take a few hours to process in
              <strong class="font-italic"> USTRL </strong>(It may not show immediately).
              <br>&nbsp;Please wait a few hours before checking for Warranty of a Serial Number.
            </span>
          </ngb-alert>
        </div>
      </div>
    </div>
  </div>
  <form class="form-horizontal" #serialNumberGeneratorForm="ngForm" (ngSubmit)="onSubmitClick()"
        *ngIf="mode == 'SINGLE'">
    <div class="col-sm-10">
      <p *ngIf="newSerialNumberClick == true" style="color: #e50000">You have selected New Serial Number button</p>
      <p *ngIf="cloneSerialNumber == true" style="color: #e50000">You have selected Clone Serial Number button</p>
    </div>
    <div class="col-sm-10 input-group" style="margin-bottom: 10px">
      <input aria-describedby="onNewSerialNumberClick" *ngIf="newSerialNumberClick == true" style="box-shadow: 0 0 0 0.2rem #268FFF;" type="button" class="btn btn-primary" value="New Serial Number"
             (click)="onNewSerialNumberClick()" />
      <input *ngIf="newSerialNumberClick == false" style="background-color:#66c9ff" type="button" class="btn" value="New Serial Number"
             (click)="onNewSerialNumberClick()" />
      <div class="text-center col-sm-1">
        <span>&ndash;&nbsp;OR&nbsp;&ndash;</span>
      </div>
      <input aria-describedby="cloneSerialNumber" *ngIf="cloneSerialNumber == true" style="box-shadow: 0 0 0 0.2rem #268FFF;" type="button" class="btn btn-primary" value="Clone Serial Number"
             (click)="onCloneSerialNumber()" />
      <input *ngIf="cloneSerialNumber == false" style="background-color:#66c9ff" type="button" class="btn" value="Clone Serial Number"
             (click)="onCloneSerialNumber()" />
    </div>

    <div class="form-group" *ngIf="cloneSerialNumber == false">
      <div class="col-sm-2">
        <label class="control-label" for="productCode">
          <button style="margin-left:0px;" type="button" id="product-code-tool-tip" class="tool-tip-button"
                  ngbTooltip="Select the product type that you want to generate SN for"
                  [closeDelay]="1000" triggers="click" placement="right">
            <span style=" margin-right: 10px">Product Code</span><i class="fa fa-question-circle text-center tool-tip-icon"></i>
          </button>
        </label>
      </div>
      <div class="col-sm-10">
        <select aria-label="Product Code" style="color: #727272" class="form-control" id="productCode" name="productCode" [(ngModel)]="serialNumberRequest.productCode"
                (ngModelChange)="onSelectionDeviceTypeChangeClick()" #productCode="ngModel" required>
          <option value="" disabled selected>Select Product Code</option>
          <option *ngFor="let product of productCodeListItems" [ngValue]="product.productCode">
            {{product.productDescription}}
          </option>
        </select>
      </div>
    </div>
    <div class="form-group" *ngIf="cloneSerialNumber == false">
      <div class="col-sm-3">
        <label class="control-label" for="partNumber">
          
          <button style="margin-left:0px"type="button" id="partnumber-tool-tip" class="tool-tip-button"
                  ngbTooltip="Each device bundle comes with a SKU - this is required for SN Generation"
                  [closeDelay]="1000" triggers="click" placement="right">
            <span style="margin-right:10px">Part Number (SKU)</span><i class="fa fa-question-circle text-center tool-tip-icon"></i>
          </button>
        </label>
      </div>
      <div class="col-sm-10">
        <input type="text" class="form-control" placeholder="Enter part number"
               [(ngModel)]="serialNumberRequest.partNumber" (change)="validateSkuFormat(serialNumberRequest.partNumber)" id="partNumber" name="partNumber" #partNumber="ngModel" required>
        <div [hidden]="partNumber.valid || partNumber.pristine" class="alert alert-danger">Part number is required</div>
        <ngb-alert *ngIf="!hideSkuFormatAlert" class="alert alert-danger" (close)="hideSkuFormatAlert = true;">
          Please enter a valid SKU: Alphanumeric chars only with 1 hyphen allowed.
        </ngb-alert>
      </div>
    </div>
    <div class="form-group" *ngIf="cloneSerialNumber == false">
      <div class="col-sm-4">
        <label class="control-label" for="hardwarePartNumber">
          <button style="margin-left:0px"type="button" id="hardware-partnumber-tool-tip" class="tool-tip-button"
                  ngbTooltip="Each device bundle contains multiple child part numbers, select the primary Part Number, or
          Hardware Part Number"
                  [closeDelay]="1000" triggers="click" placement="right">
            <span style="margin-right:10px">Hardware Part Number (HWPN)</span><i class="fa fa-question-circle text-center tool-tip-icon"></i>
          </button>
        </label>
      </div>
      <div id="hardwarePartNumber" class="input-group">
        <div class="col-sm-10">
          <button type="button"
                  class="btn btn-primary"
                  (click)="this.onClickLookupHwpn()"
                  [disabled]="this.hwpnButtonDisabled()">
            <span>Lookup HWPN</span>
            <app-preloader-small id="hwpn-preloader" *ngIf="showHwpnPreloader"></app-preloader-small>
          </button>
          <div class="text-center">
            <span>&ndash;&nbsp;OR&nbsp;&ndash;</span>
          </div>
          <input type="text" class="form-control" placeholder="Enter a Hardware Part Number"
                 [(ngModel)]="serialNumberRequest.hardwarePartNumber" id="hardwarePartNumberInput" name="hardwarePartNumber"
                 #hardwarePartNumber="ngModel" required>
        </div>
      </div>
    </div>

    <div class="form-group" *ngIf="cloneSerialNumber == true">
      <label class="control-label" style="padding-left:15px" for="environment">Clone from Environment</label>
      <div class="col-sm-10">
        <select class="form-control" [(ngModel)]="serialNumberRequest.environment" placeholder="Select Clone from Environment" name="environment" id="environment"
                aria-label="Select Clone from Environment" #environment="ngModel" required>
          <option value="" disabled selected>Select Environment</option>
          <option value="UAT">UAT</option>
          <option value="PROD">PROD</option>
        </select>
        <!--<div [hidden]="environment.valid || environment.pristine" class="alert alert-danger">environment is required</div>-->
      </div>
    </div>

    <div id="serialNumber" *ngIf="cloneSerialNumber == true && serialNumberRequest.environment != ''">
      <div class="col-sm-4">
        <label class="control-label" for="serialNumber">Serial Number</label>
        <button type="button" id="serial-number-tool-tip" class="tool-tip-button"
                ngbTooltip="Retrieves latest device genealogy from MDS Document Store with entered Serial Number"
                [closeDelay]="1000" triggers="click" placement="right">
          <i class="fa fa-question-circle text-center tool-tip-icon"></i>
        </button>
      </div>
      <div class="col-sm-10  input-group">
        <input type="text" class="form-control" style="margin-right:20px; margin-bottom:10px" [(ngModel)]="serialNumberRequest.serialNumber" placeholder="Enter a Serial Number"
               id="serialNumberInput" name="serialNumber" #serialNumber="ngModel" required>
        <button type="button"
                class="btn btn-primary"
                (click)="this.onCloneLookupSerialNumber()"
                [disabled]="this.clonesnbuttonDisabled()">
          <span>Lookup Clone SN</span>
          <app-preloader-small *ngIf="showCsnPreloader"></app-preloader-small>
        </button>
      </div>
    </div>

    <div class="form-group">
      <div class="col-sm-2">
        <label class="control-label" for="warranty">
          
          <button style="margin-left:0px" type="button" id="warranty-tool-tip" class="tool-tip-button"
                  ngbTooltip="Out Warranty - sets warranty date 90 days back.
          Set Warranty - sets number of days warranty expires in."
                  [closeDelay]="1000" triggers="click" placement="right">
            <span style="margin-right:10px">Warranty</span>  <i class="fa fa-question-circle text-center tool-tip-icon"></i>
          </button>
        </label>
      </div>
      <div class="col-sm-10">
        <select class="form-control" id="warranty" name="warranty" [(ngModel)]="serialNumberRequest.warranty"
                #warranty="ngModel" required>
          <option *ngIf="cloneSerialNumber == true" value="" disabled selected>Select Warranty</option>
          <option value="InWarranty">In Warranty</option>
          <option value="OutWarranty">Out Warranty</option>
          <option value="SetWarranty">Set Warranty</option>
        </select>
        <div [hidden]="warranty.valid || warranty.pristine" class="alert alert-danger">Warranty is required</div>
      </div>
    </div>



    <div class="form-group" *ngIf="serialNumberRequest.warranty == 'SetWarranty'">
      <label class="control-label" style="padding-left:15px" for="warrantyDays">Expires In(Days)</label>
      <div class="col-sm-10">
        <input type="number" min="1" class="form-control" placeholder="Enter no of days for warranty"
               [(ngModel)]="serialNumberRequest.warrantyDays" id="warrantyDays" name="warrantyDays"
               #hardwarePartNumber="ngModel" #warrantyDays="ngModel" required>
      </div>
    </div>

    <div class="col-sm-10" *ngIf="lookupCloneSN == true" style="color: red">
      <p>Below values are auto-filled from Lookup Clone Serial Number</p>
    </div>

    <div class="form-group">
      <label class="control-label col-sm-2" for="country">Country Of Origin</label>
      <div class="col-sm-10">
        <select class="form-control" placeholder="Select Country Of Origin" name="countryOfOrigin" id="countryOfOrigin"
                aria-label="Select Country of Origin" [(ngModel)]="serialNumberRequest.countryOfOrigin"
                #countryOfOrigin="ngModel" required>
          <option value="CN">China</option>
          <option  value="TW">Taiwan</option>
          <option value="TH">Thailand</option>
          <option  value="VN">Vietnam</option>
        </select>
        <div [hidden]="countryOfOrigin.valid || countryOfOrigin.pristine" class="alert alert-danger">Country Of Origin is required</div>
      </div>
    </div>
    <div class="form-group">
      <label class="control-label col-sm-2" for="quantity">Quantity</label>
      <div class="col-sm-10">
        <input type="number" min="1" max="300" class="form-control" placeholder="Enter quantity" id="quantity"
               name="quantity" [(ngModel)]="serialNumberRequest.quantity" #quantity="ngModel" required>
        <div [hidden]="quantity.valid || quantity.pristine" class="alert alert-danger">
          Quantity should be between
          1-100
        </div>
      </div>
    </div>
    <div class="form-group">
      <div class="col-sm-3">
        <label class="control-label" for="serialNumberDigit">
          
          <button style="margin-left:0px" type="button" id="serial-length-tool-tip" class="tool-tip-button"
                  ngbTooltip="12 digit is the mostly used value. Please use other values (> 12) based on specific needs."
                  [closeDelay]="1000" triggers="click" placement="right">
            <span style="margin-right:10px">Serial Number Length</span><i class="fa fa-question-circle text-center tool-tip-icon"></i>
          </button>
        </label>
      </div>
      <div class="col-sm-10">
        <input type="number" min="12" max="16" class="form-control" placeholder="Enter Serial Number Range[12 - 16]"
               id="serialNumberDigit" name="serialNumberDigit" (ngModelChange)="onSerialNumberChange()" [(ngModel)]="serialNumberRequest.serialNumberDigit"
               #serialNumberDigit="ngModel" required>
        <div [hidden]="serialNumberDigit.valid || serialNumberDigit.pristine" class="alert alert-danger">
          Serial Number
          should be between 12-16 length
        </div>
      </div>
    </div>
    <div class="form-group">
      <div class="col-sm-offset-2 col-sm-10">
        <div class="input-group">
          <input aria-describedby="submitSerialNumberGeneratorForm" type="submit" class="btn btn-primary" value="Submit"
                 [disabled]="!serialNumberGeneratorForm.form.valid || showPreloader" (click)="onSubmitClick()" />
          <app-preloader-small *ngIf="showPreloader"></app-preloader-small>
        </div>
      </div>
    </div>
    <div class="form-group" *ngIf="this.snResponseSuccessAlert">
      <div class="col-sm-offset-2 col-sm-10">
        <ngb-alert class="input-group" (close)="this.snResponseSuccessAlert = false">
          <span>
            <strong>Note:&nbsp;</strong><strong class="font-italic">Warranty&nbsp;information&nbsp;</strong>can take a few hours to process in
            <strong class="font-italic"> USTRL </strong>(It may not show immediately).
            <br>&nbsp;Please wait a few hours before checking for Warranty of a Serial Number.
          </span>
        </ngb-alert>
      </div>
    </div>
    <div class="form-group">
      <div class="col-sm-offset-2 col-sm-10">
        <p class="alert alert-danger" style="padding-left: 10px" [hidden]="!showMessage">{{ msgTxt }}
        <p></p>
      </div>
    </div>
    <div class="form-group">
      <div class="input-group">
        <label class="control-label col-sm-1" for="logs">Logs</label><a [routerLink]=""
                                                                        (click)="clearLogs()">Clear</a>
      </div>
      <div class="col-sm-offset-2 col-sm-10" style="margin-top:15px;">
        <div class="row no-margin" style="background-color: #e9ecef; border-radius: 5px;">
          <div class="col-lg-12 no-padding">
            <pre style="height:400px; margin-bottom:0px" class="form-control" autocomplete="off" spellcheck="false" name="logs" [innerHTML]="logMessage" aria-label="textarea" readonly></pre>
            <div style="padding: 40px 0px 10px 0; background-color: white;">
            </div>
            <div *ngIf="payload != ''">
              <div class="row no-margin padding-10" *ngIf="payload != undefined"
                   style="word-break: break-all; max-height: 350px; overflow: hidden; overflow-y: auto;">
                <div class="col-lg-12 no-padding">
                  <p>Sample Genealogy used:</p>
                  <ngx-json-viewer [json]="payload"></ngx-json-viewer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<app-hardware-partnumber-lookup
  [partNumber]="this.serialNumberRequest.partNumber"
  (hardwarePartNumber)="this.onHardwarePartnumberSelect($event)">
</app-hardware-partnumber-lookup>
<ng-template #responseModal class="modal-lg" let-c="close" let-d="dismiss">
  <div class="modal-header" aria-describedby="modal-basic-title">
    <h5 class="modal-title" id="modal-basic-title">{{headerText}}</h5>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click');reloadPage()" role="button">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row no-margin">
      <div class="no-padding" *ngIf="bodyText != ''">
        <p>
          {{bodyText}}
        </p>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" *ngIf="bodyText != ''" ngbAutofocus (click)="c();reloadPage()"
      aria-label="OK" role="button">OK</button>
  </div>
</ng-template>
  </div>

