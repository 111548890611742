export class OutboundDeliveryMockerRequest{
    public MessageType:string;
    public Parameters:ParameterOBD;
}
export class ParameterOBD{
   
    public MessageBusinessChannel:string;
    public MessageSubBusinessChannel:string;
    public MessageAction:string;
    public MessagePartyId:string;
    public OrderCID:string;
    public LoadPlanId:string;

}
