export class TestCasesDataJson {
  public testCaseDataArray: TestCaseDataArray[]; 
}

export class TestCaseDataArray {
  public Id: string;
  public Name: string;
  public Status: string;
  public LastExecuted: string;
}

