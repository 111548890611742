import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { ClaimEligibility, OffersCollection, ShippingOfferRequest } from '../model/devicecare.model';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class DevicecareService {
  baseUrlWithVersion: string;

  constructor(private httpClient: HttpClient, private configService: ConfigService) {
    this.baseUrlWithVersion = this.configService.omtestSuiteAPI.baseUrl + '/api/' + this.configService.omtestSuiteAPI.apiVersion + '/devicecare';
  }

  getClaimEligibilities(serialNumber: string, agentTier: string): Observable<ClaimEligibility> {
    const requestUrl = this.baseUrlWithVersion + '/GetClaimEligibilities/' + serialNumber + '?agentTier=' + agentTier;
    return this.httpClient.get<ClaimEligibility>(requestUrl);
  }

  getRepairOffers(serialNumber: string, agentTier: string, countryCode: string, symptomCode: string): Observable<OffersCollection> {
    const requestUrl = this.baseUrlWithVersion + '/GetRepairOffers/' + serialNumber + '?agentTier=' + agentTier + '&countryCode=' + countryCode + '&symptomCode=' + symptomCode;
    return this.httpClient.get<OffersCollection>(requestUrl);
  }

  getShippingOffer(shippingOffers: ShippingOfferRequest[], agentTier: string, countryCode: string): Observable<OffersCollection[]> {
    const requestUrl = this.baseUrlWithVersion + '/GetShippingOffer?agentTier=' + agentTier + '&countryCode=' + countryCode;
    return this.httpClient.post<OffersCollection[]>(requestUrl, shippingOffers);
  }
}