import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx-js-style';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable()
export class ExcelFileService {

  constructor() { }

  public exportAsExcelFile(headLength, json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    let row = []
    if (headLength == 7) {
      row = ['A', 'B', 'C', 'D', 'E', 'F', 'G']
    } else {
      row = ['A', 'B', 'C', 'D']
    }
 
    for (var i = 0; i < row.length; i++) {
      worksheet[row[i] + '1'].s = {
        fill: {
          fgColor: { rgb: "E9E9E9" }
        },
        font: {
          name: 'Calibri',
          sz: 13,
          bold: true,
          italic: false,
          underline: false
        },
        border: {
          top: { style: "thick", color: { rgb: "000000" } },
          right: { style: "thick", color: { rgb: "000000" } },
          bottom: { style: "thick", color: { rgb: "000000" } },
          left: { style: "thick", color: { rgb: "000000" } }
        },
        alignment: { wrapText: true } 
      };
    }

    var wscols = [
      { wch: 10 },
      { wch: 40 },
      { wch: 40 },
      { wch: 20 },
      { wch: 40 },
      { wch: 20 },
      { wch: 10 },
    ];

    worksheet['!cols'] = wscols;

    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }

}
