import { Component } from '@angular/core';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-dropdown',
  template: `
  

<mat-form-field>
  <mat-label>Select Mockers</mat-label>
  <mat-select>
    <mat-option *ngFor="let mocker of field.fieldGroup; let i = index;" (click)="onChange(mocker)" [value]="mocker.templateOptions.label">
     {{ mocker.templateOptions.label }}
    
    </mat-option>
  </mat-select>
</mat-form-field>
    
     <formly-field [field]="this.formValue"></formly-field>
     <button  *ngIf='enableSubmit' class="btn btn-primary" [disabled]="!formValue.formControl.valid" type="submit" style="margin-top:20px;">
        Submit
      </button>
`,
})
export class FormlyFieldDropdown extends FieldType {
  formValue: any;
    enableSubmit: boolean=false;
  isValid(field: FormlyFieldConfig) {
    if (field.key) {
      return field.formControl.valid;
    }

    return field.fieldGroup
      ? field.fieldGroup.every((f) => this.isValid(f))
      : true;
  }
  onChange(mocker:any){
    
     localStorage.setItem('selectedMocker',mocker.key);
     this.formValue=mocker;
     if(this.formValue){
     
        this.enableSubmit=true;
     }
  }
}
