<div style="width: 100%; height: calc(100vh - 46px); ">
  <h3 role="heading">Serial Number Search</h3>
  <br>
  <div class="form-group col-sm-10" style="margin-bottom: 25px; border: 1px solid rgba(0,0,0,.125); border-radius: .25rem;">
    <b>Description:</b>
    <p>{{this.pageDescription}}</p>
  </div>
  <div class="row">
    <div class="col-sm-4">
      <div class="input-group">
        <input class="form-control" aria-label="fromDate" placeholder="From date (yyyy-mm-dd)" name="fromDate" [(ngModel)]="fromDate" ngbDatepicker #f="ngbDatepicker">
        <div class="input-group-append">
          <button role="presentation" aria-label="Datepicker" class="btn btn-outline-secondary fa fa-calendar" (click)="f.toggle()" type="button"></button>
        </div>
      </div>
    </div>
    <div class="col-sm-4">
      <div class="input-group">
        <input class="form-control" placeholder="To date (yyyy-mm-dd)" aria-label="toDate" name="toDate" [(ngModel)]="toDate" [minDate]="fromDate" ngbDatepicker #t="ngbDatepicker">
        <div class="input-group-append">
          <button role="presentation" aria-label="Datepicker" class="btn btn-outline-secondary fa fa-calendar" (click)="t.toggle()" type="button"></button>
        </div>
      </div>
    </div>
  </div>
  <br>
  <!-- <div class="row">
      <div class="col-sm-8">
          <input type="text" class="form-control inline-v-align-center" placeholder="Enter Serial Number" aria-label="SerialNumber" [(ngModel)]="serialNumberText">
      </div>
      <div class="col-sm-1 flex-shrink-1 align-self-center no-padding-left">
          <ng-template #snTooltip>Search&nbsp;a&nbsp;S/N&nbsp;created by&nbsp;any&nbsp;user!</ng-template>
          <i class="col-sm-1 fa fa-info-circle cursor-pointer info-tooltip inline-v-align-center"  aria-hidden="true"
              [ngbTooltip]="snTooltip" placement="right" triggers="click:blur"></i>
      </div>
  </div>
  <br> -->
  <div class="row">
    <div class="col-sm-5">
      <input type="text" class="form-control inline-v-align-center" placeholder="Enter a Serial Number or SKU to search" aria-label="Enter a Serial Number or SKU to search" [(ngModel)]="searchInputText">
    </div>
    <div class="col-sm-3">
      <select role="listbox" aria-label="Enter a Serial Number or SKU to search" class="form-control"
              [(ngModel)]="this.searchEntityType">
        <option>Serial Number</option>
        <option>SKU</option>
      </select>
    </div>
    <div class="flex-shrink-1 align-self-center no-padding">
      <ng-template #skuTooltip>Search&nbsp;for&nbsp;a&nbsp;S/N&nbsp;created&nbsp;by any&nbsp;user&nbsp;via&nbsp;S/N&nbsp;or&nbsp;SKU!</ng-template>
      <i class="col-sm-1 fa fa-info-circle cursor-pointer info-tooltip inline-v-align-center" aria-hidden="true"
         [ngbTooltip]="skuTooltip" placement="right" triggers="click:blur"></i>
    </div>
    <div class="col-sm-2 flex-grow-1"><button width="200px" type="button" class="btn btn-primary" (click)="onSearchClick()">Search</button></div>
  </div>
  <br>
  <div class="table-responsive">
    <table role="presentation" class="table">
      <thead class="thead-dark cursor-default">
        <tr>
          <th scope="col">Serial Number</th>
          <th scope="col">SKU</th>
          <th scope="col">In MDS</th>
          <th scope="col">In OER</th>
          <th scope="col">In USTRL</th>
          <th scope="col">Warranty Status</th>
          <th scope="col">Warranty End Date</th>
          <th scope="col">Warranty In OER</th>
          <th scope="col">User</th>
        </tr>
      </thead>
      <tbody class="thead-dark">
        <app-preloader *ngIf="showLoader"></app-preloader>
        <tr class="trSNDetails" *ngFor="let serialNumber of serialNumberList.serialNumberListItems" (click)="onSerialNumberItemClick()">
          <td><a href="{{serialNumber.link}}"   target="_blank"> {{ serialNumber.serialNumber }}</a></td>
          <td>{{ serialNumber.sku }}</td>
          <td class="bold" [ngClass]="serialNumber.inMDS == true ? 'success' : 'error'">{{ serialNumber.inMDS }}</td>
          <td class="bold" [ngClass]="serialNumber.inOER == true ? 'success' : 'error'">{{ serialNumber.inOER }}</td>
          <td class="bold" [ngClass]="serialNumber.inUSTRL == true ? 'success' : 'error'">{{ serialNumber.inUSTRL }}</td>
          <td>{{ serialNumber.warrantyStatus }}</td>
          <td>{{ this.getTruncatedDate(serialNumber.warrantyEndDate) }}</td>
          <td class="bold" [ngClass]="serialNumber.warrantyUpdatedInOER == true ? 'success' : 'error'">{{ serialNumber.warrantyUpdatedInOER }}</td>
          <td>{{ serialNumber.userName }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <ngb-pagination class="btn btn-primary" [collectionSize]="collectionSize" [(page)]="page" [maxSize]="7" [boundaryLinks]="true" (pageChange)="onPageChanged()" *ngIf="!showLoader" class="d-flex justify-content-center"></ngb-pagination>
</div>
