import { Component, OnInit } from '@angular/core';
import { VerticalsDetails, ScenariosDetails, TestRunInputsDetails, SectionsDetails, ScenariosInputsModel } from '../model/execute-testruns.model';
import { ManageScenariosService } from '../service/manage-scenarios.service';
import { MasterDataService } from '../service/masterdata.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { element } from 'protractor';
import { ActivatedRoute, ParamMap } from '@angular/router';


@Component({
  selector: 'app-testrun',
  templateUrl: './testrun.component.html',
  styleUrls: ['./testrun.component.css']
})
export class TestRunComponent implements OnInit {
  scenarioInputsModel: ScenariosInputsModel;
  testRunInputsDetails: TestRunInputsDetails;
  verticalsList: VerticalsDetails[];
  sectionsList: SectionsDetails[];
  scenariosList: ScenariosDetails[];
  verticalName: SectionsDetails[];
  verticalNameTest: SectionsDetails[];
  sectionName: string;
  verticalSecionDict = {};
  is500error: boolean = false;
  organizationID: number;


  constructor(private ngxLoader: NgxUiLoaderService, private masterDataService: MasterDataService, private manageTestRunsService: ManageScenariosService, private router:ActivatedRoute) { }

  ngOnInit() {
    this.setOrganizationID();
    this.loadVerticalsDetails();
   
  }
 
 
  setOrganizationID() {

    this.router.queryParamMap.subscribe((params: ParamMap)=>{
        let orgId = this.router.snapshot.params["org"];
        if (orgId != null  && parseInt(orgId) > 0){
          this.organizationID  = parseInt(orgId);
          window.sessionStorage.setItem("orgSelected", orgId);
        } else{
          if (window.sessionStorage.getItem("orgSelected") != null){
            this.organizationID = parseInt(window.sessionStorage.getItem("orgSelected"));
          }
          else {
            this.organizationID = -1;
          }
        }
    })

   
  }

  getExecuteUrlParams(section: string, scenario: string) {

    return {
      'org': this.organizationID,
      'vertical': this.verticalSecionDict[section],
      'section': section,
      'scenario': scenario
    };
  }

  loadVerticalsDetails() {
    this.manageTestRunsService.getVerticalsDetails(this.organizationID).subscribe((response: VerticalsDetails[]) => {
      
      if (response != null) {
        this.verticalsList = response;
        this.verticalName = this.verticalsList[0].sectionsList;
        for (var i = 1; i < this.verticalsList.length; i++) {
          this.verticalName = this.verticalName.concat(this.verticalsList[i].sectionsList);
        }

        for (var i = 0; i < this.verticalsList.length; i++) {
          for (var j = 0; j < this.verticalsList[i].sectionsList.length; j++) {
              this.verticalSecionDict[this.verticalsList[i].sectionsList[j].sectionName] = this.verticalsList[i].verticalName
          }
        }
      } 
      this.ngxLoader.stop();
      
    }, (error: any) => {
      if(error.status  == 500){
        this.is500error = true;
      }
   });
  }
}
