import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Auth, OmTestSuiteAPI, Organization, UIAppSettings, MsalAngularConfig, Environment } from '../model/appconfig.model';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  omtestSuiteAPI: OmTestSuiteAPI;
  auth: Auth;
  uiAppSettings: UIAppSettings;
  selectedEnvironment: string;
  organizations: Organization[];
  msalAngularConfig: MsalAngularConfig;

  constructor(private httpClient: HttpClient) {
  }

  switchEnvironment(env: string) {
    const omTestSuiteApiSettings = this.uiAppSettings.Environments.filter((item: Environment) => (item.environmentName == env))[0];
    this.omtestSuiteAPI = omTestSuiteApiSettings.omTestSuiteAPI;
    this.omtestSuiteAPI.swaggerUrl = this.omtestSuiteAPI.baseUrl + '/swagger';
    this.selectedEnvironment = env;
  }

  async loadConfigFromService(): Promise<UIAppSettings> {
    const url = 'api/Config';
    return this.httpClient.get<UIAppSettings>(url).toPromise();
  }

  isNotification() {
    var isnotification = false;
    if (this.uiAppSettings.Notification.notification === true) {
      isnotification = true
    }
    return isnotification
  }

  getAppInsightsInstrumentationKey() {
    return this.uiAppSettings.Logging.AppInsightsInstrumentationKey;
  }
}
