import { Component, OnInit } from '@angular/core';
import { UatAvailabilityService } from '../service/uatavailability.service';
import { MasterDataService } from '../service/masterdata.service';
import { MsalService } from '@azure/msal-angular';
import { ConfigService } from '../service/config.service';
import * as log from '../AppInsightsLogger';
import { skip } from 'rxjs/operators';
import { UATAvailability } from '../model/uatAvailability.model';
import { ManageScenariosService } from '../service/manage-scenarios.service';
import { DescriptionService } from '../service/description.service';

@Component({
  selector: 'app-uatAvailability',
  templateUrl: './uatAvailability.component.html',
  styleUrls: ['./uatAvailability.component.css']
})
export class UATAvailabilityComponent implements OnInit {

  uatDetails: any[];
  businessProcess: any[];
  showMessage: boolean;
  showPreloader: boolean;
  msgTxt: string;
  pageDescription: string;
  pageDetails: any[];
  tooltipUAT: any[];
  uatDetailsRows: any[];
  businessProcessNamesDetails: any;
  uatRowNames: any;
  private logger: log.AppInsightsLogger;
  selectedBusinessProcessName;
  isLoading: boolean = true;
  isLoadingSelectBusiness: boolean = true;

  constructor(private descriptionService: DescriptionService, private msalService: MsalService,
    private configService: ConfigService,
    private uatDataService: UatAvailabilityService,
    private masterDataService: MasterDataService, private manageTestRunsService: ManageScenariosService) {
    this.logger = new log.AppInsightsLogger(configService, msalService);
  }

  ngOnInit() {
    //setTimeout(() => { this.loadUat(); }, 500);
    window.sessionStorage.setItem("orgSelected", "1");
    this.loadUat();
  }

  loadUat() {
    this.isLoadingSelectBusiness = false;
    let servicesCompleted = 0;;
    this.descriptionService.pageDetailsTools().subscribe((response: any[]) => {
        this.pageDetails = response;
        this.pageDetails.forEach(element => {
          if (element.toolName == "UAT Availability View") {
            this.pageDescription = element.toolDescription
          }
        });
      });

      this.uatDataService.getUATAvailability("All").subscribe((response: any[]) => {
        this.uatRowNames = [];
        this.uatDetails = response;
        this.businessProcess = response;
        var obj = {};
        for (var i = 0, len = this.businessProcess.length; i < len; i++) {
          obj[this.businessProcess[i]['service']] = this.businessProcess[i];
        }

        this.businessProcess = new Array();
        for (var key in obj) {
          this.businessProcess.push(obj[key]);
        }

        this.uatDetailsRows = Object.keys(this.uatDetails[0])
        for (var i = 0; i < this.uatDetailsRows.length; i++) {

          if (this.uatDetailsRows[i] != "businessProcessName") {
            if ((this.uatDetailsRows[i] == "scor") || (this.uatDetailsRows[i] == "dri")) {
              this.uatRowNames.push(this.uatDetailsRows[i]
                .replace(/(_|-)/g, ' ')
                .trim()
                .replace(/\w\S*/g, function (str) {
                  return str.toUpperCase()
                })
                .replace(/([a-z])([A-Z])/g, '$1 $2'))
            } else if (this.uatDetailsRows[i] != "colorCode") {
              this.uatRowNames.push(this.uatDetailsRows[i]
                .replace(/(_|-)/g, ' ')
                .trim()
                .replace(/\w\S*/g, function (str) {
                  return str.charAt(0).toUpperCase() + str.substr(1)
                })
                .replace(/([a-z])([A-Z])/g, '$1 $2'))
            }
          }
        }

        this.businessProcessNamesDetails = [];
        this.uatDetails.forEach(bpN => {
          if (!(this.businessProcessNamesDetails.includes(bpN.businessProcessName))) {
            this.businessProcessNamesDetails.push(bpN.businessProcessName);
            this.businessProcessNamesDetails.sort();
          }
        });

        this.businessProcessNamesDetails = ["All"].concat(this.businessProcessNamesDetails);
        this.isLoading = false;
      }, (error: any) => {
        try {
          this.showMessage = true;
          this.showPreloader = false;
          this.msgTxt = error.error.errors[0].message;
        } catch (ex) {
          this.msgTxt = 'Some error occured while loading UAT Availability Details';
          this.logger.trackTrace(this.msgTxt);
        }
      });
  }

  loadBusinessProcessameDetails(selectedBusinessProcessName) {
    var name = "";
    var count = 0;
    this.isLoadingSelectBusiness = true;
    if ((selectedBusinessProcessName != undefined || selectedBusinessProcessName != null) && count != 1) {
      this.uatDataService.getUATAvailability(selectedBusinessProcessName).subscribe((response: any[]) => {
        this.businessProcess = response;

        var obj = {};
        for (var i = 0, len = this.businessProcess.length; i < len; i++) {
          obj[this.businessProcess[i]['service']] = this.businessProcess[i];
        }

        this.businessProcess = new Array();
        for (var key in obj) {
          this.businessProcess.push(obj[key]);
        }

        this.logger.trackTrace("Successfully loaded UAT Availability View Page");
        this.isLoadingSelectBusiness = false;
      }, (error: any) => {
        try {
          this.showMessage = true;
          this.showPreloader = false;
          this.msgTxt = error.error.errors[0].message;
          this.isLoadingSelectBusiness = false;
        } catch (ex) {

          this.msgTxt = 'Some error occured while loading UAT Availability Details';
          this.logger.trackTrace(this.msgTxt);
          this.isLoadingSelectBusiness = false;
        }
        this.isLoadingSelectBusiness = false;
      });
    }

  }
}
