import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ConfigService } from './service/config.service';
//import * as Commons from './Commons';
import { MsalService } from '@azure/msal-angular';

export class AppInsightsLogger {

  private appInsightsInstance: ApplicationInsights = null;

  name: string;
  email: string;
  alias: string;
  url: string;
  page: string;
  props: {}

  constructor(configService: ConfigService, msalService: MsalService, verticalName?: string, sectionName?: string, flavour?: string, scenario?: string) {
    this.appInsightsInstance = AppInsightsLogger.initializeLogger(configService.getAppInsightsInstrumentationKey());
    this.name = msalService.instance.getActiveAccount().name;
    this.email = msalService.instance.getActiveAccount().username;
    this.alias = "";
    for (var i = 0; i < this.email.length; i++) {
      if (this.email[i] !== '@') {
        this.alias += this.email[i];
      } else {
        break
      }
    }
    this.url = window.location.pathname;
    this.page = '';
    this.page += this.url[0];
    var iChars = "~`!#$%^&*+=[]\\\';,/{}|\":<>?";

    for (var i = 1; i < this.url.length; i++) {
      if (iChars.indexOf(this.url.charAt(i)) != -1) {
        break
      } else {
        this.page += this.url[i];
      }
    }

    if ((verticalName === null || verticalName === '' || verticalName === undefined) && (flavour === null || flavour === '' || flavour === undefined) && (sectionName === null || sectionName === '' || sectionName === undefined) && (scenario === null || scenario === '' || scenario === undefined)) {
      this.props = { 'name': this.name, 'alias': this.alias, 'page': this.page};
    } else {
      this.props = { 'name': this.name, 'alias': this.alias, 'page': this.page, 'verticalName': verticalName, 'sectionName': sectionName, 'flavourName': flavour, 'scenarioName': scenario };
    }
   
  }

  private static initializeLogger(appInsightKey: string): ApplicationInsights {
    var appInsightsInstance = new ApplicationInsights({
      config: {
        instrumentationKey: appInsightKey
      }
    });
    appInsightsInstance.loadAppInsights();
    appInsightsInstance.trackPageView();
    return appInsightsInstance;
  }

  public trackTrace(traceMessage: string): void {
    this.appInsightsInstance.trackTrace({ message: traceMessage, properties: this.props });
  }

  public trackException(exception: string | Error): void {
    if (typeof exception == "string") {
      this.appInsightsInstance.trackException({ exception: new Error(exception), properties: this.props });
    } else {
      this.appInsightsInstance.trackException({ exception: exception, properties: this.props });
    }
  }

  public trackEvent(event: string): void {
    this.appInsightsInstance.trackEvent({ name: event, properties: this.props });
  }
}
