import { LoadPlanMockRequest } from '../model/loadplan.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoadPlanResponse } from '../model/loadplanresponse.model';
import { ConfigService } from './config.service';

@Injectable()
export class LoadPlanService {

  constructor(private httpClient: HttpClient, private configService: ConfigService) {
  }

  postMockLoadPlanRequest(loadPlanMockRequest: LoadPlanMockRequest) {
    const baseUrlWithVersion = this.configService.omtestSuiteAPI.baseUrl + '/api/' + this.configService.omtestSuiteAPI.apiVersion;
    return this.httpClient.post<LoadPlanResponse[]>(baseUrlWithVersion + '/loadplanmock', loadPlanMockRequest);
  }
}
