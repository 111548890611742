<div style="width: 100%; height: calc(100vh - 50px); ">
  <div class="input-group">
    <h3>Scenario Catalog</h3>
    <div>&nbsp;</div>
  </div>
  <br>
  <div style="text-align: right; padding-right: 5px">
    <a target="_blank" rel="noopener noreferrer" href="https://msit.powerbi.com/groups/9aa9a6df-df65-4a15-9983-551017c44e5d/reports/e72ef1b2-2f74-457b-8f23-2288dae0c29e/ReportSection">Click Here for Catalog Report</a>
  </div>

  <div style="border:2px solid #666; border-radius:11px; padding:20px;  height:39vw">
    <iframe aria-label="scenarioCatalog" role="presentation" id="form-iframe" src="https://msit.powerbi.com/reportEmbed?reportId=e72ef1b2-2f74-457b-8f23-2288dae0c29e&autoAuth=true&ctid=72f988bf-86f1-41af-91ab-2d7cd011db47&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9kZi1tc2l0LXNjdXMtcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQvIn0%3D&pageName=ReportSection" allowfullscreen="true" style="margin:0; width:100%; height:100%; border:none; overflow:hidden;" scrolling="no"></iframe>
  </div>
</div>  
