<!-- Grid -->
<div class="ms-depth-4 my-2">
    <!-- <app-surface-control-request-status [status]="getTasksStatus$ | async"></app-surface-control-request-status> -->
  
    <ng-container>
        <!-- grid controls -->
        <!-- <div class="btn-toolbar justify-content-between" role="toolbar" aria-label="Toolbar for grid"
            [attr.aria-controls]="gridId">
            <div>
                <div class="btn-group" role="group">
                    <button *ngIf="isMultiSelectMode" type="button" class="btn" (click)="multiEdit()">
                        <i class="ms-Icon ms-Icon--TripleColumnEdit"></i>
                        <span class="ml-2 ms-fontWeight-semibold">Edit {{ selectedTasks.size }} Task(s)</span>
                    </button>
                </div>
            </div>
        </div> -->

        <!-- <pre>{{rowGroups | json}}</pre> -->
        <div role="grid" [attr.aria-label]="'Test Plans Headers'" [attr.aria-colcount]="displayColumns.length + 1"
            [attr.aria-rowcount]="totalRows" [id]="gridId" class="container-fluid">
            <div role="row" class="row flex-row flex-nowrap heading align-items-center"
                [ngClass]=" {'row-checked': allSelected}" aria-rowindex="1" (mouseenter)="isGridHeaderHovered = true"
                (mouseleave)="isGridHeaderHovered = false">
                <div role="columnheader" aria-label="Select/Unselect All Tasks"
                    class="iconButton d-flex align-items-center justify-content-center">
                </div>
                <div role="columnheader" aria-label="Toggle Collapse Groups" >
                </div>
                <div *ngFor="let column of displayColumns" role="columnheader" [ngClass]="column.class" style="margin-left:10px;">
                <div class="sorter" (click)="sortBy(column.key)"> {{column.label}}
                   <i *ngIf ="sortingColumns[column.key] != 1"  class = "ms-Icon ms-Icon--TriangleSolidDown12 sortingIcon" ></i>
                   <i *ngIf ="sortingColumns[column.key] == 1"  class = "ms-Icon ms-Icon--TriangleSolid sortingIcon"></i></div>
                </div>
            </div>


            <!-- GRID BODY-->
            <div class="dropList">
                <div>
                    <div *ngFor="let group of rowGroups; let i = index" class="dragRow">
                        <div role="row" [attr.aria-rowindex]="group.startRowIndex + 1"
                            class="row flex-row flex-nowrap heading align-items-center"
                            [title]="hasFiltersApplied ? 'You cannot move task groups while filters are applied.' : ''"
                            (mouseenter)="group.isHovered = true" (mouseleave)="group.isHovered = false">
                    
                            <div role="gridcell">
                                <button type="button" class="iconButton"
                                    [attr.aria-expanded]="!isGroupCollapsed(group.id)"
                                    [attr.aria-controls]="gridId + group.key + 'Group'"
                                    [attr.title]="'Toggle Collapse Group '  + group.label"
                                    (click)="this.isGroupCollapsedMap[group.id] = !this.isGroupCollapsedMap[group.id]">
                                    <i class="ms-Icon"
                                        [ngClass]="{'ms-Icon--ChevronDown': !isGroupCollapsed(group.id), 'ms-Icon--ChevronRight': isGroupCollapsed(group.id)}"
                                        aria-hidden="true"></i>
                                </button>
                            </div>
                            <div role="gridcell" class="d-flex align-items-center" [ngClass]="displayColumns[0].class">
                                <textarea autosize rows="1" aria-label="Text area"
                                    [title]="group.label"
                                    class="form-control ms-fontWeight-semibold ms-fontSize-14 hideableTextarea m-1"
                                    [(ngModel)]="group.label"
                                    (blur)=" isFocusedOnTaskGroupLabel = false; updateTaskGroup(group)"
                                    (focus)="isFocusedOnTaskGroupLabel = true">
                                </textarea>
                                <div [attr.title]="group.rows.length + ' Instances'" class="mr-3">({{ group.rows.length }})</div>
                                <div class="iconButton d-flex align-items-center">
                                    <div ngbDropdown class="d-inline-block" container="body">
                                        <button class="iconButton" title="More Options" [id]="'dropdown' + group.id"
                                            ngbDropdownToggle>
                                            <i class="ms-Icon ms-Icon--More" aria-hidden="true"></i>
                                        </button>
                                        <div ngbDropdownMenu [attr.aria-labelledby]="'dropdown' + group.id">
                                             <button *ngIf="!hasFiltersApplied" ngbDropdownItem [routerLink]="['/test-case-view', getUrlParams(table_data.projectID, group.testPlanID, 'newTestPlanInstance')]"
                                                [state]="{internalTaskRouting: true}">
                                                <i class="ms-Icon ms-Icon--Add mr-2" aria-hidden="true"></i>
                                                Add Test Plan
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div role="gridcell" [attr.aria-colspan]="displayColumns.length - 1"
                                [ngClass]="displayColumns[1].class" class="my-auto">
                            </div>
                        </div>
                        <div [id]="gridId + group.key + 'Group'" role="rowgroup"
                            [ngbCollapse]="isGroupCollapsed(group.id)" class="dropList">
                            <div *ngIf="group.rows.length === 0" class="emptyList row" role="presentation">
                                There are no items to show at the moment.
                            </div>
                        
                            <div *ngFor="let row of group.rows; index as j" role="row"
                                [attr.aria-rowindex]="group.startRowIndex + j + 2"
                                class="row flex-row flex-nowrap align-items-center"
                                [title]="hasFiltersApplied ? 'You cannot move tasks while filters are applied.' : ''"
                                (mouseenter)="row.isHovered = true" (mouseleave)="row.isHovered = false">
                                <div class="iconButton d-flex align-items-center justify-content-center">
                                </div>
                                <div class="iconButton"></div>
                                <div role="gridcell" class="d-flex align-items-center justify-content-between"
                                    [ngClass]="displayColumns[0].class">
                                    <div class="flex-grow-1">
                                        <a class="href" [title]="row.testPlanName" [routerLink]="['/test-case-view', getUrlParams(table_data.projectID, group.testPlanID, row.testPlanInstanceID)]"
                                            [state]="{internalTaskRouting: true}">{{row.testPlanName}}</a>
                                    </div>
                                    <div class="iconButton d-flex align-items-center">
                                    </div>
                                </div>
                                <ng-container *ngFor="let column of displayColumns; let i = index;">
                                    <div *ngIf="i > 0" role="gridcell" [ngClass]="column.class">
                                        <div *ngIf="column.filterType === 'string' && column.key !== 'status'" >
                                            <textarea  autosize rows="1" aria-label="text area"
                                                [title]="column.label"
                                                class="form-control ms-fontSize-14 hideableTextarea m-1"
                                                [disabled] = "column.isEditable === false"
                                                [(ngModel)]="row[column.key]"
                                                (blur)=" isFocusedOnTaskGroupLabel = false; updateTaskGroup(group)"
                                                (focus)="isFocusedOnTaskGroupLabel = true">
                                            </textarea>                                       
                                        </div>
                                        <app-status *ngIf="column.filterType === 'string' && column.key === 'status'" [(status)]="row[column.key]" (statusChange)="updateTask(row, group.id)"></app-status>
                                        <app-date-picker *ngIf="column.filterType === 'date'" 
                                            [(date)]="row[column.key]" (dateChange)="updateTask(row, group.id)" ariaLabel="Started On"></app-date-picker>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</div>
