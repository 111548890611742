import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-generate-mapper-string',
  templateUrl: './generate-mapper-string.component.html',
  styleUrls: ['./generate-mapper-string.component.css']
})
export class GenerateMapperStringComponent implements OnInit {
jsonInputValue:any=null;
jsonOutputValue:any=null;
inputJson:any = null;
outputJson:any = null;
inputSelection:any = null;
outputSelection:any = null;
mappings = [];
selectedElements = [];
colorIndex = 0;
colors = ['yellow', 'lightblue', 'lightgreen', 'lightcoral', 'lightpink', 'lightgray'];
inputItems: any;
outputItems: any;
  mappingValue: string;
  constructor() { }

  ngOnInit(): void {
  }
  
  loadJson() {
    try {
        this.inputJson = JSON.parse(this.jsonInputValue);
        this.outputJson = JSON.parse(this.jsonOutputValue);
        this.displayJson();
    } catch (error) {
        alert('Invalid JSON. Please check your input and try again.');
    }
}
displayJson() {
 
this.inputItems=this.renderObj(this.inputJson,[]);
 this.outputItems=this.renderObj(this.outputJson,[]);
 
}
renderObj(obj,items, path='', level=0) {
 
  let html = '';
  for (let key in obj) {
    let newPath = path ? `${path}.${key}` : key;
    if (typeof obj[key] === 'object') {
      items.push({ key: key, value: '', path: newPath, level: level });
      this.renderObj(obj[key], items,newPath, level + 1);
    } else {
      items.push({ key: key, value: obj[key], path: newPath, level: level });
    }
  }
  return items;
}

selectField(event, type) {
  let path = event.target.getAttribute('data-path');
  if (!path) return;  // Ignore clicks that don't correspond to a field
  
  let element = event.target;
  if (type === 'input') {
      this.inputSelection = path;
  } else {
      this.outputSelection = path;
  }

  this.selectedElements.push(element);
  element.style.backgroundColor = this.colors[this.colorIndex];

  if (this.inputSelection && this.outputSelection) {
      this.mappings.push({
          input: { type: "JSON", key: this.inputSelection },
          output: { type: "JSON", paths: [`$.${this.outputSelection.replace(/\.(\d+)/g, '[$1]')}`] }
      });
      this.inputSelection = null;
      this.outputSelection = null;
      this.colorIndex = (this.colorIndex + 1) % this.colors.length;  // Move to the next color in the list
      this.selectedElements = [];
  }
}
generateMapping() {
  this.mappingValue = JSON.stringify(this.mappings, null, 2);
}

clearMappings() {
  this.mappings = [];
  this.mappingValue = JSON.stringify(this.mappings, null, 2);
  this.loadJson();
}
}
