import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Organization } from '../model/org-view.model';
import { ConfigService } from './config.service';

@Injectable()
export class OrgViewService {

    
    constructor(private httpClient: HttpClient, private configService: ConfigService) {
    }

    getOrganizations() {
        const baseUrlWithVersion = this.configService.omtestSuiteAPI.baseUrl + '/api/' + this.configService.omtestSuiteAPI.apiVersion;
        return this.httpClient.get<any>(baseUrlWithVersion + '/OrganizationView/getorganizations');

    }
     
    getOrganizationsFromConfig(){
        return this.httpClient.get('./assets/configuration/organizationData.json', { responseType: 'text' });
    }

    getprojects(organizationId: number){
        const baseUrlWithVersion = this.configService.omtestSuiteAPI.baseUrl + '/api/' + this.configService.omtestSuiteAPI.apiVersion;
        return this.httpClient.get<any>(baseUrlWithVersion + `/OrganizationView/getprojects?OrganizationId=${organizationId}`);

    }

    
    
}
