import { Component, OnInit } from '@angular/core';
import { ConfigService } from '../service/config.service';
import { AuthService } from '../service/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MonitoringService } from '../service/monitoring.service';
import { BaseComponent } from '../base/base.component';
import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ManageScenariosService } from '../service/manage-scenarios.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { MasterDataService } from '../service/masterdata.service';
import { LandingPageService } from '../service/landingpage.service';
import { LandingPageResponse, WarningBoxDetail } from '../model/landingpage.model';
//import { AppInsightsService } from '@markpieszak/ng-application-insights';
import * as log from '../AppInsightsLogger';
import { FeedbackComponent } from '../feedback/feedback.component';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css'],
  providers: [FeedbackComponent]
})
export class LandingPageComponent extends BaseComponent implements OnInit {

  name: string;
  email: string;
  alias: string;
  isDevMode: boolean;
  isNotice: boolean;
  warningResponse: WarningBoxDetail[];
  landingPageResponse: LandingPageResponse;
  page: string;
  private logger: log.AppInsightsLogger;
  message1: string;
  message2: string;
  is500error: boolean = false;
  isEnabledBoolean: boolean = false;
  hasWarningResponse: boolean = false;
  organizationId: any;
  

  constructor(private configService: ConfigService,
    private authService: AuthService,
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private route: ActivatedRoute,
    private monitoringService: MonitoringService,
    private router: Router,
    private ngxLoader: NgxUiLoaderService,
    private modalService: NgbModal,
    private manageTestRunsService: ManageScenariosService,
    public feedbackComponent: FeedbackComponent,
    private masterDataService: MasterDataService,
    private landingPageService: LandingPageService) {
    super(monitoringService);
    this.name = this.msalService.instance.getActiveAccount().name;
    this.email = this.msalService.instance.getActiveAccount().username;
    if (window.location.origin.toLowerCase().indexOf('-test') > 0 || window.location.origin.toLowerCase().indexOf('-dev') > 0 || window.location.origin.toLowerCase().indexOf('-sit') > 0) {
      this.isDevMode = true;
    }
    else {
      this.isDevMode = false;
    }

    this.logger = new log.AppInsightsLogger(configService, msalService);
  }

  ngOnInit() {
    
    this.isAuthenticated();
   if (!this.authService.isAuthenticatedWithRoles()) {
      this.router.navigate(['/', 'unauthorized']);
      
    }
    

    this.logger.trackTrace("Successfully landed to Landing Page");

    this.landingPageResponse = new LandingPageResponse();
    this.landingPageService.getLandingPageExecution().subscribe((response) => {
      if (response != null) {
        this.landingPageResponse = response;
        this.warningResponse = this.landingPageResponse.response;
        if (this.warningResponse?.length > 0) {
          this.hasWarningResponse = true;
        }
      }
    }, (error: any) => {
      if (error.status == 500) {
        this.is500error = true;
      }
    });

    this.isEnabled();
    this.setOrgData();
  }

  setOrgData() {
    this.organizationId = window.sessionStorage.getItem('orgSelected');
    const organizationId = this.route.snapshot.params['org'] || "";
    if (organizationId != "" && this.organizationId != organizationId) {
      this.organizationId = organizationId;
      window.sessionStorage.setItem("orgSelected", this.organizationId);
    }
  }

  isNotification(): boolean {
    return this.configService.isNotification();
  }

  isEnabled() {
    this.isEnabledBoolean = this.authService.isEnabled();
  }

  isOrderViewEnabled(): boolean {
    return this.authService.isOrderViewEnabled();
  }

  reloadPage() {
    window.location.reload();
  }

  isAuthenticated() {
    return this.authService.isUserAuthenticated()
  }

  isSupplyChain() {
    return window.sessionStorage.getItem("orgSelected") == "1";
  }
}
