import { Component, ViewChild, ViewContainerRef, ComponentRef, AfterViewInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { FormlyCustomComponentsService } from '../../../service/formly-custom-components.service';

/**
 * Custom Component for ngx-formly which can fetch an input from another Formly field, process it with a user-passed-in function,
 * to.buttonText: Text to be displayed on button
 * to.inputWidth: Sets the width of the <input> field
 * to.inputText: Field Description & Placeholder-text
 * to.userInputEnabled: Enable optional user-input field (disabled by default)
 * to.inputFieldBinding: Button function will be input-bound to this formly-config/formControl field key e.g. 'partNumber'
 * to.inputFieldBindings: Button function will be input-bound to these formly-config/formControl field keys e.g. ['country', 'tiers']
 * to.outputFieldBinding: Button function will be output-bound to this formly-config/formControl field key e.g. 'hardwarePartNumber'
 * to.componentBinding: Name of the Angular component to be linked
 *   (A component can be added to the formly-custom-components.service.ts to be dynamically imported for use by this button component)
 * to.componentFunction: A function can be passed to the button to perform a mapping of input value to output value
 * to.componentOutputBinding: Binding to a subscribable output field of the component (e.g. EventEmitter<>)
 * i.e. form.controls[inputFieldBinding] -> form.controls[outputFieldBinding]
 */
@Component({
  selector: 'formly-button',
  template: `
    <style>
      .container {
        padding: 0;
        margin: 0;
      }

      .btn-info {
        color: white;
        font-family: inherit;
        font-size: 16px;
        background-color: #258098;
        border-color: #258098;
        position: relative !important;
      }

      .btn-info:hover {
        color: white;
        font-family: inherit;
        font-size: 16px;
        background-color: #1B6B7A;
        border-color: #1B6B7A;
        position: relative !important;
      }

      #formly-button-preloader {
        position: absolute !important;
        margin-left: -16px !important;
        margin-top: -16px !important;
        width: 32px;
        height: 32px;
        left: 50%;
        top: 50%;
      }
    </style>
    <div class="container d-flex justify-content-between">
      <button
        type="button"
        [ngClass]="'btn btn-primary d-inline-block my-auto'"
        (click)="onClick(to.inputFieldBinding, to.outputFieldBinding, to.componentFunction, to.componentOutputBinding);"
        [disabled]="showButtonPreloader || disableButton">
        <span>{{to.buttonText}}</span>
        <app-preloader-small id="formly-button-preloader" *ngIf="showButtonPreloader"></app-preloader-small>
      </button>
      <a
        class="d-inline-block text-center my-auto"
        [hidden]="!to.userInputEnabled">
        &nbsp;—&nbsp;OR&nbsp;—&nbsp;
      </a>
      <mat-form-field
        class="d-inline-block py-auto"
        *ngIf="to.userInputEnabled"
        [style.width]="this.inputWidth">
        <mat-label>{{this.inputText}}</mat-label>
        <input
          matInput
          placeholder="{{this.inputText}}"
          #hardwarePartNumber
          [formControl]="formControl"
          required>
        <mat-error *ngIf="true">This field is required</mat-error>
      </mat-form-field>
    </div>
    <ng-template #dynamicChildComponent></ng-template>
  `
})

export class FormlyButtonComponent extends FieldType implements AfterViewInit {
  @ViewChild('dynamicChildComponent', { read: ViewContainerRef, static: true})
  public dynamicChildComponent: ViewContainerRef;

  dynamicComponentRef: ComponentRef<any>;
  dynamicOutputSubscription: any;
  showButtonPreloader = false;
  disableButton = true;
  inputText: string;
  inputWidth: string;

  constructor(
    public formlyCustomComponentService: FormlyCustomComponentsService) {
    super();
  }

  ngAfterViewInit() {
    this.inputText = this.to.inputText == null ? '' : this.to.inputText;
    this.inputWidth = this.to.inputWidth == null ? '50%' : this.to.inputWidth;

    // Dynamically load components after view init
    this.formlyCustomComponentService
    .loadComponent(this.dynamicChildComponent, this.to.componentBinding)
    .then(x => {
      this.dynamicComponentRef = x;
    });
    this.disableButton = false;
  }

  /**
   * Executes a function from the dynamic component using references provided by input parameters
   * @param outputFieldBinding Output field to which function output is exported to
   * @param inputFieldBinding Input field to which function input is bound to
   * @param inputFieldBindings Input fields to which function input is bound to
   * @param componentFunction Function of the dynamic component to be executed
   * @param componentOutputBinding Output field of the dynamic component's function
   */
  onClick(
    inputFieldBinding: string|string[],
    outputFieldBinding: string,
    componentFunction: string,
    componentOutputBinding: string) {

    this.showButtonPreloader = true;
    let inputFieldVal = null;
    let inputFieldVals = [];
   if (typeof(inputFieldBinding)==='string') {
     inputFieldVal = this.form.get(inputFieldBinding).value;
    }
    if (typeof(inputFieldBinding)==='object') {
     inputFieldBinding.forEach(inputFieldBinding => {
        inputFieldVals.push(this.form.get(inputFieldBinding).value);
      });
    }


    let output = '';
    if (componentFunction == null) {
      output = inputFieldVal; // input maps directly to output, no processing performed
    } else if (inputFieldVal) {
      this.dynamicComponentRef.instance[componentFunction].call(this.dynamicComponentRef.instance, inputFieldVal);
    } else if (inputFieldVals.length > 0) { 
      this.dynamicComponentRef.instance[componentFunction].apply(this.dynamicComponentRef.instance, inputFieldVals);
    } else if (inputFieldVal == null && inputFieldVals.length == 0) {
      this.dynamicComponentRef.instance[componentFunction].call(this.dynamicComponentRef);
    } else {
      throw new Error('Invalid input field binding(s) provided');
    }

    this.dynamicOutputSubscription = this.dynamicComponentRef.instance[componentOutputBinding].subscribe(dynamicOutput => {
      this.form.get(outputFieldBinding).setValue(dynamicOutput);
      this.showButtonPreloader = false;
    });

  }

}
