<div class="container padding-10">
  <h3>New Test Run</h3>
  <br>
  <form class="form-horizontal" #newTestRunForm="ngForm" (ngSubmit)="onSubmitClick()">
    <div class="form-group">
      <label class="control-label col-sm-2" for="testRunName">Name</label>
      <div class="col-sm-10">
        <input type="text" class="form-control" placeholder="Enter user friendly name for test run"
          [(ngModel)]="newTestRun.name" id="testRunName" name="testRunName" #testRunName="ngModel" required>
        <div [hidden]="testRunName.valid || testRunName.pristine" class="alert alert-danger">Test Run Name is required
        </div>
      </div>
    </div>
    <div class="form-group">
      <label class="control-label col-sm-2" for="orderScenario">Scenario</label>
      <div class="col-sm-10">
        <select class="form-control" id="orderScenario" name="orderScenario" [(ngModel)]="newTestRun.orderScenario"
          #orderScenario="ngModel" required>
          <option value="StandardOnlineUST">Online Order (Microsoft Store)</option>
          <option value="StandardOnlinePerf">Online Order (Microsoft Store Perf)</option>
        </select>
        <div [hidden]="orderScenario.valid || orderScenario.pristine" class="alert alert-danger">Scenario is required
        </div>
      </div>
    </div>
    <div class="form-group">
      <label class="control-label col-sm-2" for="numberOfLines">Number of Lines</label>
      <div class="col-sm-10">
        <input type="number" min="1" max="100" class="form-control" placeholder="Enter number of lines"
          id="numberOfLines" name="numberOfLines" [(ngModel)]="newTestRun.numberOfLines" #numberOfLines="ngModel"
          required>
        <div [hidden]="numberOfLines.valid || numberOfLines.pristine" class="alert alert-danger">Number of lines is
          required</div>
      </div>
    </div>
    <div class="form-group">
      <label class="control-label col-sm-2" for="quantityPerLine">Quantity Per Line</label>
      <div class="col-sm-10">
        <input type="number" min="1" max="100" class="form-control" placeholder="Enter quantity for each line"
          id="quantityPerLine" name="quantityPerLine" [(ngModel)]="newTestRun.quantityPerLine"
          #quantityPerLine="ngModel" required>
        <div [hidden]="quantityPerLine.valid || quantityPerLine.pristine" class="alert alert-danger">Quantity per line
          is required.</div>
      </div>
    </div>
    <div class="form-group">
      <label class="control-label col-sm-2" for="locale">Locale</label>
      <div class="col-sm-10">
        <select class="form-control" id="locale" name="locale" [(ngModel)]="newTestRun.locale" #locale="ngModel"
          required>
          <option value="en-us">en-US</option>
        </select>
        <div [hidden]="locale.valid || locale.pristine" class="alert alert-danger">Locale is required</div>
      </div>
    </div>
    <div class="form-group">
      <label class="control-label col-sm-2" for="ordercount">Number of Orders</label>
      <div class="col-sm-10">
        <input type="number" min="1" max="2500" class="form-control" placeholder="Enter number of orders"
          id="ordercount" name="ordercount" [(ngModel)]="newTestRun.orderCount" #orderCount="ngModel" required>
        <div [hidden]="orderCount.valid || orderCount.pristine" class="alert alert-danger">Order count is required</div>
      </div>
    </div>
    <div class="form-group">
      <div class="col-sm-offset-2 col-sm-10">
        <div class="input-group">
          <input type="button" class="btn btn-primary" value="Submit"
            [disabled]="!newTestRunForm.form.valid || showPreloader" (click)="onSubmitClick()" />
          <app-preloader-small *ngIf="showPreloader"></app-preloader-small>
        </div>
      </div>
    </div>
    <div class="form-group">
      <div class="col-sm-offset-2 col-sm-10">
        <p class="alert alert-danger" style="padding-left: 10px" [hidden]="!showMessage">{{ msgTxt }}
          <p></p>
      </div>
    </div>
  </form>
</div>
