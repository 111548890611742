import {  ChangeDetectorRef, Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { MasterDataService } from '../service/masterdata.service';
import {jsonValidator} from '../common/jsonValidator';
@Component({
  selector: 'app-dynamic-form',
  templateUrl: './formly-playground.component.html',
  styleUrls: ['./formly-playground.component.css']
})
export class FormlyPlaygroundComponent {
  form = new FormGroup({});
  model: any = {};
  stringFields:string; 
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[];
  loadedConfig: {} = {}; // name of list => raw value of list as JSON object
  showModel: boolean=false;
  showToggle: boolean=false;
  showErrorjson: boolean=false;
  constructor(private masterDataService: MasterDataService, private chDet: ChangeDetectorRef,private jsonValid: jsonValidator) {}
  updateFields(json:string): void {
    this.model={};
    this.loadedConfig = {};
    this.fields = [];
    this.options = {};
    try {
      if(this.jsonValid.isValidJson(json))
     {
        this.showErrorjson=false;
       this.loadOptionsFiles(json);
      }
      else{
        this.showModel = false;
        this.showErrorjson=true;
      }
     
    } catch (e) {
      console.error('Invalid JSON', e);
    }
  }

  loadOptionsFiles(json:string): void {
    this.showToggle=true;
    var loadFiles = {}; // map of filename => [optionsList], keep track of which all config files are to be under, under which name
    let parsedUserInput: FormlyFieldConfig[] = JSON.parse(json);
    parsedUserInput.forEach(ffc => {
      if ((ffc as any).hasOwnProperty('fileName')) {
        let fn: string = ffc['fileName'];         // file name to be loaded
        let opt: string = ffc['optionsList'];     // option list name, this is the name where from options list within form state in read in formly field config

        loadFiles[fn] = loadFiles[fn] || [];
        loadFiles[fn].push(opt); 
      }
    });

    var loadFilesPromises = [];
    for (let fn in loadFiles) {
      var promise = this.masterDataService.getSelectOptionsFromFiles(fn).toPromise().then((response: any) => {
        loadFiles[fn].forEach((optListName: string) => {
          this.loadedConfig[optListName] = response;
          
        });
        this.options.formState = this.loadedConfig;
      },
        (error) => {
          console.log("Can not fetch config file : " + fn, error);
        });
        loadFilesPromises.push(promise);
        
    }
    Promise.all(loadFilesPromises).then(() => {
      this.fields = JSON.parse(json);
      console.log('Fielding Done');
    });
  }
  onChangeToggle(event:any){
    console.log(event);
    if (event.checked){
      this.showModel = true;
    }
    else{
      this.showModel = false;
    }
  }
}
