import { Component, OnInit } from '@angular/core';
import { OrderService } from '../service/order.service';
import { OrderList } from '../model/order-list.model';
import { OrderListRequest } from '../model/order-list-request.model';
import { Constants } from '../service/constant.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../service/auth.service';
import { ConfigService } from '../service/config.service';
import { BaseComponent } from '../base/base.component';
import { MonitoringService } from '../service/monitoring.service';

@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.css']
})
export class OrderListComponent extends BaseComponent implements OnInit {

  testRunName: string;
  customerPurchaseOrder: string;
  fromDate: any;
  toDate: any;
  collectionSize: number;
  page: number;
  orderList: OrderList;
  orderListRequest: OrderListRequest;
  testRunId: string;
  showLoader = true;
  name: string;
  email: string;
  alias: string;

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.testRunId = params.testRunId;
    });

    if (!this.authService.isOrderViewEnabled()) {
      this.router.navigate(['/', 'landing-page']);
    }

    this.page = 1;
    this.orderList = new OrderList();
    this.orderList.orderListItems = [];

    this.setDefaultDates();
    this.orderListRequest = this.getOrderListRequest();
    this.updateOrderList();
  }

  constructor(private orderService: OrderService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private configService: ConfigService,
    private monitoringService: MonitoringService) {
    super(monitoringService);
  }

  onSearchClick() {
    if (this.testRunId) {
      this.fromDate = null;
      this.toDate = null;
    } else if (this.customerPurchaseOrder) {
      this.fromDate = null;
      this.toDate = null;
    } else {
      if (this.fromDate == null && this.toDate == null) {
        this.setDefaultDates();
      }
    }

    this.orderListRequest = this.getOrderListRequest();
    this.updateOrderList();
  }

  getOrderListRequest(skip = 0, take = Constants.DefaultPageSize, sortOrder = Constants.SortOrderDescending) {
    let orderListRequest = new OrderListRequest();
    orderListRequest.testRunId = null;
    orderListRequest.customerPurchaseOrder = null;
    orderListRequest.startDate = null;
    orderListRequest.endDate = null;

    if (this.testRunId) {
      orderListRequest.testRunId = this.testRunId;
    } else if (this.customerPurchaseOrder) {
      orderListRequest.customerPurchaseOrder = this.customerPurchaseOrder;
    } else {
      let startDate = this.getFormattedDate(this.fromDate);
      startDate.setUTCHours(0, 0, 0, 0);
      orderListRequest.startDate = startDate.toJSON();

      let endDate = this.getFormattedDate(this.toDate);
      endDate.setUTCHours(24, 0, 0, 0);
      orderListRequest.endDate = endDate.toJSON();
    }

    orderListRequest.skip = skip;
    orderListRequest.take = take;
    orderListRequest.sortOrder = sortOrder;

    return orderListRequest;
  }

  getFormattedDate(inputDate: any) {
    let newDate = new Date();
    newDate = new Date(inputDate.year, inputDate.month - 1, inputDate.day);
    return newDate;
  }

  updateOrderList() {
    this.showLoader = true;
    this.orderList.orderListItems = [];
    this.orderService.getOrdersList(this.orderListRequest).subscribe((response) => {
      this.orderList = response;
      this.collectionSize = this.orderList.totalCount;
      this.showLoader = false;
    });
  }

  onPageChanged() {
    this.orderListRequest.skip = (this.page - 1) * 10;
    this.orderListRequest.take = 10;
    this.updateOrderList();
  }

  setDefaultDates() {
    let todaysDate = new Date();
    let fifteenDaysOldDate = new Date();
    fifteenDaysOldDate.setDate(fifteenDaysOldDate.getDate() - 7);

    this.fromDate = { 'year': fifteenDaysOldDate.getUTCFullYear(), 'month': fifteenDaysOldDate.getUTCMonth() + 1, 'day': fifteenDaysOldDate.getUTCDate() };
    this.toDate = { 'year': todaysDate.getUTCFullYear(), 'month': todaysDate.getUTCMonth() + 1, 'day': todaysDate.getUTCDate() };
  }
}
