<textarea id="input-json-text"  [(ngModel)] = "jsonInputValue" placeholder="Input JSON"></textarea>
<textarea id="output-json-text"  [(ngModel)] = "jsonOutputValue"placeholder="Output JSON"></textarea>
<button (click)="loadJson()">Load JSON</button>
<div id="json-container">
    <ng-containers id="input-json" (click)="selectField($event, 'input')">
        <div *ngFor="let item of inputItems" [ngStyle]="{'padding-left.px':item.level*20}" [attr.data-path]="item.path" >
            {{ item.key }}: {{ item.value }}
        </div>
    
    </ng-containers>
    <ng-containers id="output-json" (click)="selectField($event, 'output')">
        <div *ngFor="let item of outputItems" [ngStyle]="{'padding-left.px':item.level*20}" [attr.data-path]="item.path" >
            {{ item.key }}: {{ item.value }}
        </div>
    </ng-containers>
</div>
<button (click)="generateMapping()">Generate Mapping</button>
<pre id="result">{{mappingValue}}</pre>
