import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
//import { FilterService } from 'src/app/V5/services/filter.service';

@Component({
  selector: 'app-filter-bar',
  templateUrl: './filter-bar.component.html',
  styleUrls: ['./filter-bar.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class FilterBarComponent implements OnInit, OnDestroy {
  collapsedByDefault = false;
  @Input() collapseButton: HTMLElement;
  @Input() collapsible = true;
  @Input() columns: Array<any>;
  @Input() id: string;
  @Output() rowsChange = new EventEmitter<Array<any>>();
  @Input() defaultFilters;
  @Input() disabled = false;
  rowsInputSubscription: Subscription;
  rowsInput$ = new BehaviorSubject(null);
  private _rows: Array<any>;
  @Input()
  set rows(rows: Array<any>) {
    this._rows = rows;
    this.rowsInput$.next(null);
  }

  get rows(): Array<any> {
    return this._rows;
  }

  get filterColumns() {
    return this.columns.filter(column => column.filterOptions);
  }

  constructor(
    public filterService: FilterService
  ) { }

  ngOnInit(): void {
    this.setCollapseButtonAttributes();

    // it's important that we trigger filterRows() via a subject subscription because that method depends on several input properties
    // if we trigger it inside the rows (which we need to detect changes for) setter, the other properties may not be defined yet
    // by utilizing the component lifecyle this way, we ensure that all input properties are defined before calling  filterRows()
    // note: input properties are defined in the order in which they are data-bound to this component
    this.rowsInputSubscription = this.rowsInput$.subscribe(_ => {
      const filters = this.defaultFilters || this.filterService.userFilters$.value[this.id];
      this.filterRows(filters);
    });
  }

  ngOnDestroy() {
    if (this.rowsInputSubscription) {
      this.rowsInputSubscription.unsubscribe();
    }
  }

  setCollapseButtonAttributes(): void {
    this.collapsedByDefault =  !this.filterService.hasFilters(this.id);
    if (this.collapseButton) {
      this.collapseButton.setAttribute('data-toggle', 'collapse');
      this.collapseButton.setAttribute('data-target', `#${this.id}FilterBar`);
      this.collapseButton.setAttribute('aria-label', 'Toggle Filter Bar');
      this.collapseButton.setAttribute('title', 'Toggle Filter Bar');
      this.collapseButton.setAttribute('aria-controls', `${this.id}FilterBar`);
      this.collapseButton.setAttribute('aria-expanded', JSON.stringify(!this.collapsedByDefault));
    }
  }

  filterRows(filters:  Record<string, Array<any>>): void {
    // only filters on columns that are not errored
    const filterColumns = this.filterColumns.filter(column => !column.isError);
    const rows = this.filterService.filterRows(this.id, this.rows, filterColumns, filters);
    this.rowsChange.emit(rows);
  }

  filtersChanged(key: string, value: Array<any>): void {
    const filters = this.defaultFilters || this.filterService.userFilters$.value[this.id];
    filters[key] = value;
    this.filterRows(filters);
  }

  clear(key: string): void {
    this.filtersChanged(key, []);
  }

  clearAll(): void {
    const filters = this.filterService.userFilters$.value[this.id];
    Object.keys(filters).forEach(filterKey => {
      filters[filterKey] = [];
    });
    this.filterRows(filters);
  }

  // Used in conjunction with the keyvalue pipe for the HTML template to keep the incoming data key ordering
  originalOrder() {
    return 0;
  }
}



import { Injectable } from '@angular/core';
//import { BehaviorSubject } from 'rxjs';
//import { LocalStorageService } from 'src/app/shared/services/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class FilterService {
  private localStorageKey = 'userFilters';

  private _userFilters$ = new BehaviorSubject<Record<string, Record<string, Array<any>>>>({});
  get userFilters$() {
    return this._userFilters$;
  }

  constructor(private localStorageService: LocalStorageService<Record<string, Record<string, Array<any>>>>) {
    this.initializeUserFilters();
  }

  initializeUserFilters() {
    // read from local storage, and initate the filter subjects
    const local = this.localStorageService.getData(this.localStorageKey) || {} ;
    this._userFilters$.next(local);
  }

  filterRows(gridId: string, originalRows: Array<any>, columns: Array<any>, filters: Record<string, Array<any>>): Array<any> {
    // if no filters defined for the page yet, initiate it based on default values
    if (!filters) {
      filters = columns.reduce((reducer, column) => {
        reducer[column.key] = reducer[column.key] || column.defaultValue || [];
        return reducer;
      }, {});
    }

    // stores the next filters in the userFilters$ Subject and in localStorage
    const userFilters = {...this._userFilters$.value, [gridId]: filters};
    this._userFilters$.next(userFilters);
    this.localStorageService.setData(this.localStorageKey, userFilters);

    // applies the filters
    const filteredRows = this.applyFilter(originalRows, columns, filters);
    return filteredRows;
  }

  getGridColumnFilters(gridId: string, columnKey: string): any[] {
    return (this._userFilters$.value[gridId] || [])[columnKey] || [];
  }

  hasFilters(gridId: string): boolean {
      // TODO: consider using function flat() for 2d array
    return Object.values(this.userFilters$.value[gridId] || {}).length > 0;
  }

  private applyFilter(rows: Array<any>, columns: Array<any>, filters: Record<string, Array<string>>): Array<any> {
    const filterKeys = Object.keys(filters);
    return rows.filter(row => {
      // validates all filter criteria
      return filterKeys.every(key => {
        const columnToFilterOn = columns.find(column => column.key === key);

        // ignores an empty filter or non existent key
        if (!filters[key].length || !columnToFilterOn) return true;

        // compares using strict equality
        if (columnToFilterOn.filterType === 'string' || columnToFilterOn.filterType === 'boolean'){
          return filters[key].findIndex(filter => filter === this.getRowValue(key, row)) === -1 ? false : true;
        }

        // compares using range
        if (columnToFilterOn.filterType === 'date'){
          // apply range filtering
        }

      });
    });
  }

  // gets the value of an object's property given a key
  private getRowValue(key: string, object: any): string {
    const dotIdx = key.indexOf('.');
    if (dotIdx !== -1) {  // if the key has a dot operator (ie. is a path), traverses the path
      const firstKey = key.substring(0, dotIdx);
      const newKey = key.slice(dotIdx+1);
      return this.getRowValue(newKey, object[firstKey]);
    }
    else {
      return object[key].toString();
    }
  }
}

//import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService<T> {
  private _localStorage: Storage;

  constructor() {
    this._localStorage = localStorage;
  }

  getData(key: string): T {
    return JSON.parse(this._localStorage.getItem(key));
  }

  setData(key: string, data: T) {
    this._localStorage.setItem(key, JSON.stringify(data));
  }
}

