import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class TradeporformaService {

  constructor(private httpClient: HttpClient, private configService: ConfigService) { }
  tradePorformaRequest(tradePorformaRequest:any) {
    const baseUrlWithVersion = this.configService.omtestSuiteAPI.baseUrl+ '/api/' + this.configService.omtestSuiteAPI.apiVersion; 
    return this.httpClient.post<any>(baseUrlWithVersion + '/TradeProformaMocker/tradeproformamocker', tradePorformaRequest);
  }
}
