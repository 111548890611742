import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { stepSummary } from '../model/loadtesting.model';

@Component({
  selector: 'app-verifyRetryLoadTest',
  templateUrl: './verifyretry-loadtest.component.html',
  styleUrls: ['./verifyretry-loadtest.component.css'],
})
export class VerifyRetryLoadTestComponent implements OnInit {

  //description: string;

  //constructor(public modalDialog: MatDialogRef<GenerateTestResultsComponent>, @Inject(MAT_DIALOG_DATA) data) {
  //  this.description = data.description;
  //}

  id: string; // test run id
  stepIdentifier: string; // step identifier of the test run
  lastModifiedAt: string; // time when the step was last modified
  friendlyTimeDiffDays: number; // friendly version of time difference
  friendlyTimeDiffHours: number; // friendly version of time difference
  friendlyTimeDiffMinutes: number; // friendly version of time difference
  remainingEntities: number; // remaining entities
  stepTitle: string;
  hasUpdate: boolean; // true if there is an update for this step.

  constructor(public modalDialog: MatDialogRef<VerifyRetryLoadTestComponent>
    , @Inject(MAT_DIALOG_DATA) data
  ) {
    console.log(data);
    let step: stepSummary = data.stepSummary;
    let testRunId: string = data.id;

    let lastModifiedInstant = (step.eventLastModifiedAt || step.eventStartedAt) as unknown;

    if (lastModifiedInstant != null) {
      let now = new Date();
      let diff = now.getTime() - Date.parse(lastModifiedInstant as string);

      let minutesDiff = parseInt((diff / (1000 * 60)).toString());
      let hoursDiff = parseInt((minutesDiff / 60).toString());
      let daysDiff = parseInt((hoursDiff / 24).toString());

      this.friendlyTimeDiffMinutes = minutesDiff % 60;
      this.friendlyTimeDiffHours = hoursDiff % 24;
      this.friendlyTimeDiffDays = daysDiff;

      this.hasUpdate = true;
    }
    else
    {
      this.hasUpdate = false;
    }

    this.remainingEntities = Math.max(step.remaining, 0);

    switch(step.stepIdentifier){
      case "CREATE_LOAD_PLAN" : this.stepTitle = "Load Plan"; break;
      case "CREATE_SHIPMENT" : this.stepTitle = "Shipment"; break;
      default : this.stepTitle = "the Step"; break;
    }
  }

  ngOnInit() {
  }

  onConfirm(){
    this.modalDialog.close("CONFIRM");
  }
}
