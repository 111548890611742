import { Component, OnInit } from '@angular/core';
import { MasterDataService } from '../service/masterdata.service';
import { SerialNumberSearchRequest } from '../model/serialnumbersearchrequest.model';
import { PagerService } from '../service/pager.service';
import { SerialNumberList } from '../model/serialnumberlist.model';
import { Constants } from '../service/constant.service';
import { MsalService } from '@azure/msal-angular';
import { ConfigService } from '../service/config.service';
import * as log from '../AppInsightsLogger';
import { DescriptionService } from '../service/description.service';

import { ApplicationInsights } from '@microsoft/applicationinsights-web';

@Component({
  selector: 'app-serial-number-list',
  templateUrl: './serial-number-list.component.html',
  styleUrls: ['./serial-number-list.component.css']
})

export class SerialNumberListComponent implements OnInit {
  serialNumberList: SerialNumberList;
  serialNumberSearchRequest: SerialNumberSearchRequest;
  searchEntityType = 'Serial Number';
  searchInputText = '';
  fromDate: any;
  email: string;
  toDate: any;
  collectionSize: number;
  page: number;
  showLoader = true;
  name: string;
  private logger: log.AppInsightsLogger;
  pageDetails: any[];
  pageDescription: string;


  constructor(private descriptionService: DescriptionService, private configService: ConfigService, private masterDataService: MasterDataService, private pagerService: PagerService, private msalService: MsalService) {
    this.email = msalService.instance.getActiveAccount().username;
    this.name = msalService.instance.getActiveAccount().name;
    this.logger = new log.AppInsightsLogger(configService, msalService);
  }

  ngOnInit() {
    this.setOrgData();
    this.page = 1;
    this.serialNumberList = new SerialNumberList();
    this.serialNumberList.serialNumberListItems = [];

    this.setDefaultDates();
    this.serialNumberSearchRequest = this.getSerialNumberSearchnRequest();
    this.updateSerialNumberSearch();
    this.logger.trackTrace(`Successfully landed to Serial Number Search`);

    this.descriptionService.pageDetailsTools().subscribe((response: any[]) => {
      this.pageDetails = response;
      this.pageDetails.forEach(element => {
        if (element.toolName == "Serial Number Search") {
          this.pageDescription = element.toolDescription
        }
      });
    });
  }

  setOrgData() {
    window.sessionStorage.setItem("orgSelected", "1");
  }

  onSearchClick() {
    if (this.searchInputText != null && this.searchInputText != '') {
      this.fromDate = null;
      this.toDate = null;
    } else if (this.fromDate == null && this.toDate == null) {
        this.setDefaultDates();
    }

    this.serialNumberList.serialNumberListItems = [];
    this.serialNumberSearchRequest = this.getSerialNumberSearchnRequest();
    this.updateSerialNumberSearch();
  }

  getSerialNumberSearchnRequest(skip = 0, take = Constants.DefaultPageSize, sortOrder = Constants.SortOrderDescending) {
    let serialNumberSearchRequest = new SerialNumberSearchRequest();

    if (this.searchEntityType == 'Serial Number' && this.searchInputText != '') {
      serialNumberSearchRequest.serialNumber = this.searchInputText;
    } else if (this.searchEntityType == 'SKU'  && this.searchInputText != '') {
      serialNumberSearchRequest.sku = this.searchInputText;
    } else {
      let startDate = this.getFormattedDate(this.fromDate);
      startDate.setUTCHours(0, 0, 0, 0);
      serialNumberSearchRequest.startDate = startDate.toJSON();

      let endDate = this.getFormattedDate(this.toDate);
      endDate.setUTCHours(24, 0, 0, 0);
      serialNumberSearchRequest.endDate = endDate.toJSON();
    }

    serialNumberSearchRequest.requestorEmail = this.email;
    serialNumberSearchRequest.skip = skip;
    serialNumberSearchRequest.take = take;
    serialNumberSearchRequest.sortOrder = sortOrder;

    return serialNumberSearchRequest;
  }

  getFormattedDate(inputDate: any): Date {
    let newDate = new Date(Date.UTC(inputDate.year, inputDate.month - 1, inputDate.day));
    return newDate;
  }

  updateSerialNumberSearch() {
    this.showLoader = true;
    this.serialNumberList.serialNumberListItems = [];
    this.masterDataService.getSerialNumberList(this.serialNumberSearchRequest).subscribe((response) => {
      this.serialNumberList = response;
      this.collectionSize = this.serialNumberList.totalCount;
      this.showLoader = false;
    });
  }

  onPageChanged() {
    this.serialNumberSearchRequest.skip = (this.page - 1) * 10;
    this.serialNumberSearchRequest.take = 10;
    this.updateSerialNumberSearch();
  }

  setDefaultDates() {
    let todaysDate = new Date();
    let fifteenDaysOldDate = new Date();

    fifteenDaysOldDate.setDate(fifteenDaysOldDate.getDate() - 7);
    this.fromDate = { 'year': fifteenDaysOldDate.getUTCFullYear(), 'month': fifteenDaysOldDate.getUTCMonth() + 1, 'day': fifteenDaysOldDate.getUTCDate() };
    this.toDate = { 'year': todaysDate.getUTCFullYear(), 'month': todaysDate.getUTCMonth() + 1, 'day': todaysDate.getUTCDate() };
  }

  /** Given a Date string (provided by MasterData Endpoint), returns the truncated date i.e. YYYY-MM-DD string */
  getTruncatedDate(dateTime: string) {
    if (dateTime != null && dateTime != undefined && dateTime != '') {
      return dateTime.substring(0, 10);
    } else {
      return '';
    }
  }
}

