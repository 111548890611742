import { ErrorHandler, Injectable, ReflectiveInjector, Injector } from '@angular/core';
import { MonitoringService } from '../service/monitoring.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

    constructor(private injector: Injector) {
    }

    handleError(error: Error) {
        const monitoringService = this.injector.get(MonitoringService);
        monitoringService.logException(error, 'GlobalErrorHandler');
    }
}
