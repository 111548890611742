<div class="d-inline-flex">
  <a type="button" class="trackingdetails ms-Icon ms-Icon--MiniExpand" [ngClass]="{'isDisabled': disabled}" 
    (click)="openModal()" title="View Details - disabled if the validation is not triggered">
  </a>
</div>

<div class="ms-Fabric">
  <ng-template #trackingModal let-modal class="modal-xl">
    <div class="modal-header p-2">
      <div class="d-flex justify-content-between w-100">
        <h4 id="jsonModalHeader" class="p-2 modal-title ms-fontSize-20 ms-fontWeight-bold">
          Tracking Details
        </h4>
        <button type="button" title="Close" class="ms-Button ms-Button--primary" (click)="modal.close('CrossClick')">
          <i class="ms-Icon ms-Icon--ChromeClose" aria-hidden="true"></i>
        </button>
      </div>
    </div>

    <div class="modal-body" style="max-width: inherit">
      <div>
        <div style="margin-top: 16px; margin-bottom: 16px">
          <span class="ms-fontWeight-semibold ms-fontSize-18">
            Final Result: <app-status [(status)]="status"></app-status></span>
        </div>
      </div>

      <div style="margin-top: 16px; margin-bottom: 16px; max-width: inherit">
        <button type="button" class="iconButton" [attr.aria-expanded]="!isCollapsed" [attr.aria-controls]="tableBody"
          (click)="this.toggleCollapsed()">
          <i class="ms-Icon" [ngClass]="{
              'ms-Icon--ChevronDown': !isCollapsed,
              'ms-Icon--ChevronRight': isCollapsed
            }" aria-hidden="true"></i>
          <span class="ms-fontWeight-semibold ms-fontSize-16" style="margin-left: 8px">Expand Details</span>
        </button>
        <table class="table table-responsive-xl table-striped" [id]="tableBody" [ngbCollapse]="isCollapsed">
          <thead class="thead-light">
            <tr>
              <th scope="col">#</th>
              <!-- dynamic table header -->
              <th *ngFor="let header of tableSchema" (click)="filterBy(header['key'])">
                <span>{{ header["label"] }}
                  <i *ngIf="sorter[header['key']] != 1" class="ms-Icon ms-Icon--TriangleSolidDown12 sortingIcon"
                    style="font-size: 60%"></i>
                  <i *ngIf="sorter[header['key']] == 1" class="ms-Icon ms-Icon--TriangleSolid sortingIcon"
                    style="font-size: 60%"></i>
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let event of rawData[0]['MergedEvents']; index as i">
              <td scope="row"></td>
              <!-- dynamic table body -->
              <ng-container *ngFor="let header of tableSchema">
                <td>
                  <span *ngIf="header.key != 'EventMessage'">{{
                    event[header.key]
                    }}</span>
                  <span *ngIf="
                      event.EventStatus === 'Fail' &&
                      header.key === 'EventMessage'
                    " class="ms-fontWeight-regular" style="color: red">{{ event[header.key] }}</span>
                </td>
              </ng-container>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="modal-footer"></div>
  </ng-template>
</div>