<div class="d-inline-flex">
    <a type="button" class="ms-Icon ms-Icon--MiniExpand" (click)="onEditClicked()"  title="View Error Message">
    </a>
</div>
<div class="ms-Fabric">
    <ng-template #jsonModal let-modal class="modal-xl">
        <div class="modal-header p-2">
            <div class="d-flex justify-content-between w-100">
                <h4 id="jsonModalHeader" class="p-2 modal-title ms-fontSize-20 ms-fontWeight-bold">
                    View Error 
                </h4>
                <button type="button" class="close-button" title="Close" (click)="modal.close('CrossClick')">
                    <i class="ms-Icon ms-Icon--ChromeClose" aria-hidden="true"></i>
                </button>
            </div>
        </div>
        <div class="modal-body" #element>
          <span> {{messageToDisplay}}</span>
        </div>
    </ng-template>
</div>