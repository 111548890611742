export class SerialNumberSearchRequest {
  public serialNumber: string;
  public sku: string;
  public startDate: string;
  public endDate: string;
  public requestorEmail: string;
  public sortOrder: string;
  public skip: number;
  public take: number;
}
