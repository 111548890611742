import { Component, OnInit } from '@angular/core';
import { SAPService } from '../service/sap.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OutputTriggerRequest } from '../model/outputtrigger.model';
import { ConfigService } from '../service/config.service';
import * as log from '../AppInsightsLogger';
import { MasterDataService } from '../service/masterdata.service';

import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { MsalService } from '@azure/msal-angular';
import { DescriptionService } from '../service/description.service';

@Component({
  selector: 'app-sapoutputtrigger',
  templateUrl: './sapoutputtrigger.component.html',
  styleUrls: ['./sapoutputtrigger.component.css']
})
export class SapOutputTriggerComponent implements OnInit {

  outputTriggerRequest: OutputTriggerRequest;
  showLoader: boolean;
  entityNumbers: string;
  sapOutputType: any;
  logMessage: string = '';
  name: string;
  private logger: log.AppInsightsLogger;
  pageDetails: any[];
  pageDescription: string;
 
  ngOnInit() {
    this.showLoader = false;
    this.logMessage = '';
    this.outputTriggerRequest = new OutputTriggerRequest();
    this.logger.trackTrace(`Successfully landed to sapoutputtrigger`);

    this.descriptionService.pageDetailsTools().subscribe((response: any[]) => {
      this.pageDetails = response;
      this.pageDetails.forEach(element => {
        if (element.toolName == "SAP Trigger Output") {
          this.pageDescription = element.toolDescription
        }
      });
    });
  }

  constructor(private sapDataService: SAPService, private masterDataService: MasterDataService,
    private configService: ConfigService,
    private descriptionService: DescriptionService,
    private msalService: MsalService) {
    this.name = this.msalService.instance.getActiveAccount().name;
    this.logger = new log.AppInsightsLogger(configService, msalService);
  }

  onSubmitClick() {
    this.showLoader = true;
    this.logMessage = '';
    this.outputTriggerRequest.entityNumbers = this.getEntityNumberArray();
    this.outputTriggerRequest.outputTriggerFor = this.sapOutputType;
    this.sapDataService.postSAPOutPutTrigger(this.outputTriggerRequest).subscribe((response : string[]) => {
      if (response != null && response.length > 0) {
        response.forEach(element => {
          this.addLogLine(element);
        });
      } else {
        this.addLogLine('Some error occured while submitting the output trigger');
        this.logger.trackTrace(`Sap Out Put Trigger, Some error occured while submitting the output trigger`);
      }
      this.showLoader = false;
    }, (error) => {
        this.addLogLine('Some error occured while submitting the output trigger');
        this.logger.trackTrace(`Sap Out Put Trigger, Some error occured while submitting the output trigger`);
        this.logger.trackException(error);
      this.showLoader = false;
    });
  }



  addLogLine(line: string) {
    var currentDateTime = new Date();
    this.logMessage += '[' + currentDateTime.toLocaleString() + ']' + ' ' + line + '\n';
  }

  getEntityNumberArray() {
    var entityNumberArrayUntrimmed: string[];
    var entityNumberArrayTrimmed: string[];
    entityNumberArrayUntrimmed = this.entityNumbers.replace(/\r/g, '').split(',');
    entityNumberArrayTrimmed = [];
    entityNumberArrayUntrimmed.forEach(element => {
      if (element != null) {
        entityNumberArrayTrimmed.push(element.trim());
      }
    });

    return entityNumberArrayTrimmed;
  }

  clearLogs() {
    this.logMessage = '';
  }
}
