import { Component, OnInit, Input } from '@angular/core';
import { UatAvailabilityService } from '../../service/uatavailability.service';
import { MasterDataService } from '../../service/masterdata.service';
import { MsalService } from '@azure/msal-angular';
import { ConfigService } from '../../service/config.service';
import * as log from '../../AppInsightsLogger';
import { skip } from 'rxjs/operators';
import { UATAvailability } from '../../model/uatAvailability.model';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ManageScenariosService } from '../../service/manage-scenarios.service';
import { TestProjectService } from '../../service/testproject.service';
import { ActivatedRoute, ParamMap, Params } from '@angular/router';
import { Router } from '@angular/router';
import { TestCaseProjectService } from 'src/app/service/test-case-project.service';
export { BaseTestPlanInstance, TestPlan } from '../test-grid/test-grid.component';


@Component({
    selector: 'app-test-project-view',
    templateUrl: './test-project-view.component.html',
    styleUrls: ['./test-project-view.component.css']
})
export class TestProjectViewComponent implements OnInit {
    private logger: log.AppInsightsLogger;
    gridSchema: any = [];
    project: any;
    projectName: string;
    projectOwner: string;
    projectCreateDate: string;
    isLoading:boolean=true;
    projectID: any;
    organizationId:string;
    projectDocumentation: any;

    constructor(private ngxLoader: NgxUiLoaderService,
        private msalService: MsalService,
        private configService: ConfigService,
        private uatDataService: UatAvailabilityService,
        private masterDataService: MasterDataService,
        private manageTestRunsService: ManageScenariosService,
        private testProjectService: TestCaseProjectService,
        private router: Router,
        private activatedRoute: ActivatedRoute,) {
        this.logger = new log.AppInsightsLogger(configService, msalService);
    }

    ngOnInit() {
        
        this.activatedRoute.paramMap.subscribe((params: ParamMap) =>{
            this.processActiveRoute();
        });
        
        //this.router.navigate(['/project-view'], );
        this.gridSchema = [
            {
                label: 'Test Plan',
                class: 'col-3 col-sm-3',
                key: 'label'
            },

            {
                label: 'Owner',
                class: 'col col-xl-2',
                filterType: 'string',
                key: 'owner',
                filterOptions: {}, // populated in initialize()
                filterValues: {}, // populated in initialize()
                isError: false, // if request to populate this fails
                isEditable:false,
                filterDisplay: 'string'
            },

            {
                label: 'Created',
                class: 'col col-xl',
                key: 'createDate',
                filterType: 'date'
            },
        
            {
                label: 'Last Modified',
                class: 'col col-xl',
                key: 'endDate',
                filterType: 'date',
            },
        ];
        
    }

    processActiveRoute() {
        this.organizationId = window.sessionStorage.getItem("orgSelected");
        const organizationId = this.activatedRoute.snapshot.params['org'] || "";
        if(organizationId != "" && this.organizationId != this.organizationId){
            this.organizationId = organizationId; 
            window.sessionStorage.setItem("orgSelected", this.organizationId);;
        }
        this.projectID = this.activatedRoute.snapshot.params['projectId'] || "";
        if(this.projectID != ""){
            this.getProjectData();
        }
        else{
            this.isLoading = false;
        }
        
    }

    //returns project data 
    get TestPlan() {
        //console.log(this.project);
        return this.project;
    }

    //returns grid schema
    get TestPlanSchema() {
        //console.log(this.gridSchema);
        return this.gridSchema;
    }

    //API call to retrieve project
    getProjectData() {
        this.testProjectService.getProjectTestCaseView(this.projectID).subscribe((response: any[]) => {
            if(response != null){
                this.project = response.find(x => x.projectID = this.projectID);
                this.projectName = this.project.projectName;
                this.projectOwner = this.project.projectOwner || "Owner not defined";
                this.projectCreateDate = new Date(this.project.projectCreateDate).toLocaleDateString() + " " + new Date(this.project.projectCreateDate).toLocaleTimeString();
                this.projectDocumentation = this.project.projectDocumentation;
                this.project.testPlans.forEach(testPlan => {
                    let testPlanId = testPlan.testPlanID;
                    this.testProjectService.getTestPlanInstances(testPlanId).subscribe((response: any) => {
                        if(response != null){
                        testPlan["testPlanInstances"] = response; 
                        this.isLoading = false;
                        }  
                    });   
                });
            }
            
        },(error:any)=>{
            this.logger.trackTrace(`In Project Test Plan Page Error getting test plans: ${error}`);
            this.logger.trackException(error);
        });
    }

    //API call to retrieve testPlanInstances
    // getTestPlanInstances() {
    //     let index: any;
    //     this.project.testPlans.forEach(testPlan => {
    //         let testPlanId = testPlan.testPlanID;
    //         this.testProjectService.getTestPlanInstances(testPlanId).subscribe((response: any) => {
    //             if(response != null){
    //             testPlan["testPlanInstances"] = response; 
    //             }  
    //         });
    //     });
        
    // }

}


