export class SKUNumberList {
  public totalCount: number;
  public skuNumberListItems: SKUNumberListItem[];
}

export class SKUNumberListItem {
  public sku: string;
  public hardwarePartNumber: string;
  public countryCode: string;
  public inMDS: boolean;
  public inOER: boolean;
  public inOnePDM: boolean;
  public inPDS: boolean;
  public inECC: boolean;
  public npiValidationMessage: string;
}
