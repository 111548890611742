import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BlobDownloadService } from '../service/blob-download.service';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-blob-download',
  templateUrl: './blob-download.component.html',
  styleUrls: ['./blob-download.component.css']
})
export class BlobDownloadComponent implements OnInit {
  JsonResult: object;
  blobName: any;
 copyTextName:string='Copy';
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private blobDownloadService: BlobDownloadService,
    private clipboard: Clipboard
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      const blobAdapterName = params['blobAdapterName'];
      this.blobName = params['blobName'];

      // Call the service method to download and save the blob
      this.blobDownloadService.getBlobData(blobAdapterName, this.blobName).subscribe(
        (result) => {
          this.JsonResult = result;
          // Download the json as a file
          
        },
        (error) => {
          console.error('Error downloading blob:', error);
          // Handle error as needed
        }
      );
    });
  }
  copyText() {
    
   this.clipboard.copy( JSON.stringify(this.JsonResult,null,2))
   this.copyTextName = 'Copied'
  }
  downloadFile(){ 
    const jsonBlob = new Blob([JSON.stringify(this.JsonResult)], { type: 'application/json' });
    const url = URL.createObjectURL(jsonBlob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${this.blobName}.json`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);

   window.alert(`Blob: ${this.blobName}.json downloaded successfully`);

    this.router.navigate(['/']); 
  }
}
