export const environment = {
  production: true,
  auth: {
    tenant: "microsoft.onmicrosoft.com",
    clientId: "e3e8e619-3608-428c-b340-98b416c30100"    
  },
  CI: {
    omTestSuiteAPI: {
      baseUrl: "https://omtestsuiteservice-ci-westus-wa.azurewebsites.net/api/v1.0",
      resourceUrl: 'https://supplychainservices.microsoft.com/OMIntegrationTestSuiteAPI',
      apiVersion: "v1.0",
    },
    keyVaultAPI: {
      baseUrl: "https://omtestsuiteciwestuskv.vault.azure.net/",
      apiVersion: "2016-10-01",
      resourceUrl: "https://vault.azure.net",
    }
  },
  UAT: {
    omTestSuiteAPI: {
      baseUrl: "https://omtestsuiteservice-uat-westus-wa.azurewebsites.net/api/v1.0",
      resourceUrl: 'https://supplychainservices.microsoft.com/OMIntegrationTestSuiteAPI',
      apiVersion: "v1.0",
    },
    keyVaultAPI: {
      baseUrl: "https://omtestsuiteuat-westus-kv.vault.azure.net",
      apiVersion: "2016-10-01",
      resourceUrl: "https://vault.azure.net",
    }    
  },
  Prod: {
    omTestSuiteAPI: {
      baseUrl: "https://omtestsuiteservice-uat-westus-wa.azurewebsites.net/api/v1.0",
      resourceUrl: 'https://supplychainservices.microsoft.com/OMIntegrationTestSuiteAPI',
      apiVersion: "v1.0",
    },
    keyVaultAPI: {
      baseUrl: "https://omtestsuiteuat-westus-kv.vault.azure.net",
      apiVersion: "2016-10-01",
      resourceUrl: "https://vault.azure.net",
    }   
  },
  supportedEnvironments: ["CI", "UAT", "Production"]
};
