import { GoodReceiptMockRequest } from '../model/goodreceiptrequest.model';
import { GoodReceiptMockResponse } from '../model/goodreceiptresponse.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';

@Injectable()
export class GoodReceiptService {

  constructor(private httpClient: HttpClient, private configService: ConfigService) {
  }

  onPostMockLoadPlanRequest(goodReceiptMockRequest: GoodReceiptMockRequest) {
    const baseUrlWithVersion = this.configService.omtestSuiteAPI.baseUrl + '/api/' + this.configService.omtestSuiteAPI.apiVersion;
    return this.httpClient.post<GoodReceiptMockResponse[]>(baseUrlWithVersion + '/goodsreceiptmock', goodReceiptMockRequest);
  }
}
