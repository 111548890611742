import { Injectable, ComponentFactoryResolver, ViewContainerRef, ComponentRef } from '@angular/core';
import { HardwarePartnumberLookupComponent } from '../hardware-partnumber-lookup/hardware-partnumber-lookup.component';
import { Subject } from 'rxjs';
import { DevicecareComponent } from '../devicecare/devicecare.component';


@Injectable({
  providedIn: 'root'
})

export class FormlyCustomComponentsService {

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {}

  async loadComponent(viewContainerRef: ViewContainerRef, componentName: string) {
    let component;
    switch (componentName) {
      case HardwarePartnumberLookupComponent.className: {
        component = HardwarePartnumberLookupComponent;
        break;
      }
      case DevicecareComponent.className: {
        component = DevicecareComponent;
        break;
      }
     default: {
        console.error("component not found");
        break;
      }

      // Why we're hardcoding a className instead of using component.name or component.constructor.name...
      // TL;DR: The Angular build minifies & uglifies source-code...
      // Potential Solution: https://stackoverflow.com/questions/40115072/how-to-load-component-dynamically-using-component-name-in-angular2
    }

    const ret = viewContainerRef.createComponent(this.componentFactoryResolver.resolveComponentFactory(component));
    return ret;
  }


}
