<div [id]="id + 'FilterBar'" [ngClass]="{'collapse': collapsedByDefault && collapsible, 'show': !collapsedByDefault && collapsible}">
    <!-- We need two divs here because collapsing is broken if the below classes are applied on the parent div -->
    <div class="d-flex flex-row align-items-start flex-wrap ms-bgColor-neutralLighter rounded mx-3 my-1">
        <span class="pr-3 ms-fontSize-14 ms-fontWeight-semibold my-auto ml-3">Filters:</span>
        <div *ngFor="let filterColumn of filterColumns" class="m-1">
            <ng-select
                #ngSelect
                class="filterBarDropdown"
                [ngClass]="{'errorBorder': filterColumn.isError}"
                [items]="filterColumn.filterDisplay === 'mgt-person' ? (filterColumn.filterOptions | keyvalue: originalOrder) : (filterColumn.filterOptions | keyvalue)"
                [multiple]="true"
                bindLabel="value"
                bindValue="key"
                [ngModel]="filterColumn.isError ? [] : (defaultFilters ? defaultFilters[filterColumn.key] :  (filterService.userFilters$ | async)[id][filterColumn.key] || [])"
                (ngModelChange)="filtersChanged(filterColumn.key, $event)"
                [placeholder]="filterColumn.label"
                [searchable]="false"
                [clearable]="false"
                [closeOnSelect]="false"
                [inputAttrs]="{'aria-label': filterColumn.label}"
                [labelForId]="id + filterColumn.key + 'combobox-input'"
                [disabled]="filterColumn.isError || disabled">
                <!-- template for dropdown option -->
                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                    <div class="d-flex align-items-center">
                        <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" [attr.aria-labelledby]="'item-' + index + '-label'"/>
                        <div *ngIf="filterColumn.filterDisplay === 'mgt-person' && !filterColumn.isError; else plainTextBlock">
                            <div id="item-{{index}}-label" class="pl-2" [title]="item.value">
                                <!-- <mgt-person [attr.person-details]="filterColumn.filterValues[item.key] | json" fetch-image="true" avatar-size="small" view="oneline">
                                </mgt-person> -->
                            </div>
                        </div>
                        <ng-template #plainTextBlock>
                            <span id="item-{{index}}-label" class="pl-2 m-0" [title]="item.value">
                                {{item.value}}
                            </span>
                        </ng-template>
                    </div>
                </ng-template>

                <!-- template for dropdown header -->
                <ng-template ng-header-tmp *ngIf="filterColumn.filterDisplay === 'mgt-person' && !filterColumn.isError">
                    <div class="d-flex align-items-center">
                        <i class="ms-Icon ms-Icon--Search mr-2" aria-hidden="true"></i>
                        <input class="form-control form-control-sm border-0 w-100 mr-2 px-2" 
                            type="text" type="search" placeholder="Search" aria-label="Search" 
                            (input)="ngSelect.filter($event.target['value'])">
                    </div>
                </ng-template>

                <!-- template for when dropdown collapsed -->
                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                    <div class="ng-value" *ngFor="let item of items | slice:0:1">
                        <div class="ng-value-label ms-fontWeight-semibold" [title]="item['value'] + (items.length > 1 ? ' (+' + (items.length - 1) + ')' : '')">
                            {{item['value']}}
                            <span *ngIf="items.length > 1">
                                (+{{items.length - 1}})
                            </span>
                        </div>
                    </div>
                </ng-template>

                <!-- template for footer with clear button -->
                <ng-template ng-footer-tmp>
                    <div class="d-flex align-items-center justify-content-end">
                        <button type="button" class="btn px-2 py-1 rounded" (click)="clear(filterColumn.key)">
                            <i class="ms-Icon ms-Icon--ChromeClose ms-fontSize-10" aria-hidden="true"></i>
                            <span class="ms-fontWeight-semibold ms-fontColor-sharedCyanBlue10 pl-1">
                                Clear
                            </span>
                        </button>
                    </div>
                </ng-template>
            </ng-select>
            <span *ngIf="filterColumn.isError" class=" d-flex align-items-center ms-fontSize-12 ms-fontColor-sharedRed20 ml-1">
                <i class="ms-Icon ms-Icon--ErrorBadge mr-1" aria-hidden="true"></i>
                Unavailable
            </span>
        </div>
        <div class="m-1">
            <button type="button" class="btn ms-bgColor-neutralLighter ms-bgColor-neutralQuaternaryAlt--hover border-0 rounded clearAllButton" (click)="clearAll()">
                <span class="ms-fontWeight-semibold ms-fontSize-14 ms-fontColor-sharedCyanBlue10">
                    Clear All
                </span>
            </button>
        </div>
    </div>
</div>