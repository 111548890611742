export class AckknowledgementMockerRequest{
   public MessageType:string;
   public Parameters:ParameterAck;
}
export class ParameterAck{
public  Action :string;
public MessageBusinessChannel:string;
public MessageSubBusinessChannel:string;
public Sender:string;
public Transaction:Transac;

}
export class Transac{
    public Status:string;
    public Type:string;
   public Identifier:Identifiers[];
   public Reasons:Reason[];
}
export class Identifiers{
    public Type: string;
    public Id:string;
}
export class Reason{
    public Name:string;
    public Description:string;
}