import { Component, DoCheck, KeyValueDiffers } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';

@Component({
  selector: 'formly-repeat-section-button',
  template: `
    <div *ngFor="let field of field.fieldGroup; let i = index;" class="row">
        <formly-field class="col" [field]="field"></formly-field>
        <div *ngIf='this.to.defaultValue?.length !== 0 || i != 0' class="col-1 d-flex align-items-center">
          <button class="btn btn-primary" type="button" (click)="remove(i)">-</button>
        </div>
    </div>
    <div style="margin:30px 0;">
        <button class="btn btn-primary" type="button" (click)="add()">{{ to.addText }}</button>
    </div>
  `,
})
export class RepeatTypeButtonComponent extends FieldArrayType implements DoCheck {
  private differ: any;
  private previousDefaultValue: any[];

  constructor(private differs: KeyValueDiffers) {
    super();
    this.differ = this.differs.find({}).create();
  }

  ngOnInit() {
    this.previousDefaultValue = [...(this.to.defaultValue || [])];
    this.initDefaultValue();
  }

  ngDoCheck() {
    const changes = this.differ.diff(this.to);
    if (changes) {
      changes.forEachChangedItem((item: any) => {
        if (item.key === 'defaultValue' && JSON.stringify(this.previousDefaultValue) !== JSON.stringify(this.to.defaultValue)) {
          console.log("DefaultValue changed", this.to.defaultValue);
          this.initDefaultValue();
          this.previousDefaultValue = [...(this.to.defaultValue || [])];
        }
      });
    }
  }

  private initDefaultValue() {
    if (this.to.defaultValue?.length > 0) {
      // Clear existing fields before adding new ones to avoid duplicates
      while (this.field.fieldGroup.length !== 0) {
        this.remove(0);
      }
      // Add new fields based on the updated default values
      this.to.defaultValue.forEach((element: any) => {
        this.add(null, element);
      });
    }
    else{
      this.add();
    }
  }
}
