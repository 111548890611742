import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { JsonEditorComponent, JsonEditorOptions} from 'ang-jsoneditor';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { TemplateRef } from '@angular/core';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
import { FormGroup } from '@angular/forms';
import { isArray } from 'util';

@Component({
  selector: 'app-edit-parameter',
  templateUrl: './edit-parameter.component.html',
  styleUrls: ['./edit-parameter.component.css']
})
export class EditParameterComponent implements OnInit {

  @Input() buttonText = "Edit";
  @Input() jsonText = "{\"JSON\" : true}"
  @Input() formlyFields = "{\"JSON\" : true}"
  @Output() jsonTextChange = new EventEmitter<string>();
  @Input() disabled = false;

  @ViewChild('jsonModal') jsonModal: TemplateRef<any>;

  private form = new FormGroup({});
  private formState: any;
  private formModel: any;
  private formFields: FormlyFieldConfig[];

  constructor(
    private modalService: NgbModal,
  ) {
  }

  ngOnInit(): void {
    
    //console.log(this.jsonText);
    this.formState = JSON.parse(this.jsonText);
    this.formFields = JSON.parse(this.formlyFields);
  }

  onEditClicked() {
    // TODO: Show dialog
    this.loadFormModel();

    let options: NgbModalOptions = {
      size: 'xl',
      centered: true
    };

    this.modalService.open(this.jsonModal, options);  
  }

  loadFormModel() {
    //console.log(this.formState);
    // Create a copy of formState and assign it to formModel
    this.formModel = {
      ...this.formState
    };
  }

  submitJSON(event: any) {
    // Update form state
    this.formState = { ...this.formModel };


    //this ensures that serial numers are passed as an array and not as string.
    this.formState.requests.forEach(request =>{
        request.items.forEach(item =>{
          
          if(item.serialNumbers != null && !Array.isArray(item.serialNumbers)){
            item.serialNumbers = item.serialNumbers.trim().split(",");
          } 
      });
    }); 

    
    this.jsonText = JSON.stringify(this.formState);

    // Emit string event containing jsonText

    this.jsonTextChange.emit(this.jsonText);
    this.modalService.dismissAll();
  }

  showJson() {
    // Method used only for dev. Will remove in future.
    console.log(this.formlyFields);
  }
}
