import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core'; 

@Component({
  selector: 'app-formly-readonly-listview',
  templateUrl: './formly-readonly-listview.component.html',
  styleUrls: ['./formly-readonly-listview.component.css']
})
export class FormlyReadOnlyViewComponent extends FieldType {
  //Flag for checking button toggle. 
  viewCollapseClicked: boolean = false;



 /* function to verify if the list is empty or not*/
  isEmpty(list: any) {
    return Object.keys(list).length === 0;
  }

}
