<div style="width: 100%; height: calc(100vh - 46px); ">
  <h3>Load Plan Mocker</h3>
  <br>
  <div class="form-group col-sm-10" style="margin-bottom: 25px; border: 1px solid rgba(0,0,0,.125); border-radius: .25rem;">
    <b>Description:</b>
    <p>{{this.pageDescription}}</p>
  </div>
  <form class="form-horizontal" (ngSubmit)="onPostLoadPlanClick()" #loadPlanForm="ngForm">
    <div class="form-group">
      <label class="control-label col-sm-2" for="mode">Mode</label>
      <div class="col-sm-10">
        <select class="form-control" id="mode" name="mode" [(ngModel)]="mode" required #modeField="ngModel" (ngModelChange)='onModeChange($event)'>
          <option value="SYNC">SYNC</option>
          <option value="LOAD">LOAD</option>
        </select>
        <div [hidden]="modeField.valid || modeField.pristine" class="alert alert-danger">Mode is required</div>
      </div>
    </div>
    <div class="form-group">
      <label class="control-label" style="padding-left:15px" for="entityType">Entity Type</label>
      <div class="col-sm-10">
        <select class="form-control" id="entityType" name="entityType" [(ngModel)]="loadPlanRequest.entityType"
                (ngModelChange)="onSelectionEntityTypeChangeClick()" #entityTypeField="ngModel" required>
          <option value="deliveryOrder">DeliveryOrder</option>
          <option value="purchaseOrder">PurchaseOrder</option>
        </select>
        <div [hidden]="entityTypeField.valid || entityTypeField.pristine" class="alert alert-danger">
          Entity Type is required
        </div>
      </div>
    </div>
    <div class="form-group" *ngIf="loadPlanRequest.entityType == 'deliveryOrder'">
      <label class="control-label" style="padding-left:15px" for="entityNumbers">Delivery Order Number(s)</label>
      <div class="col-sm-10">
        <input type="text" [hidden]="mode != 'SYNC'" class="form-control" autocomplete="off" spellcheck="false"
               placeholder="Enter DO number(s) - Multiple DOs are separated by &quot;,&quot;" id="entityNumber" name="entityNumber" [(ngModel)]="entityNumbers" required
               #entityNumbersField="ngModel"
               (change)="handleEntityNumbersChange(entityNumbersField)" />
        <div [hidden]="mode != 'SYNC' || entityNumbersField.valid || entityNumbersField.pristine" class="alert alert-danger">
          <a *ngIf="this.entityNumbersValid && this.entityNumbers.length == 0">
            Delivery Order Numbers field is required
          </a>
          <a *ngIf="!this.entityNumbersValid">
            Only a maximum of 10 orders can be consolidated
          </a>
        </div>
        <div *ngIf="mode == 'SYNC'" style="margin-top: 12px;">
          <label style="margin-bottom: 0px;">
            <input type="checkbox" id="isConsolidated" name="isConsolidated"
                   [(ngModel)]="loadPlanRequest.isConsolidated" (change)="handleEntityNumbersChange(entityNumbersField)">
            Consolidate Orders into Single Load Plan
          </label>
          <i style="padding-left: 5px;" class="col-sm-1 fa fa-info-circle cursor-pointer info-tooltip inline-v-align-center" aria-hidden="true" matTooltip="Only DOs shipped from the same plant can be consolidated"></i>
        </div>
        <input *ngIf="mode == 'LOAD'" type='file' accept='text/plain' multiple (change)='openFile($event)' required>
        <button type="button" class="btn btn-info float-right cursor-pointer" (click)="downloadSampleFile()" *ngIf="mode == 'LOAD'"
                style="color: #ffffff; background-color: #929292; border-color: #929292;">
          <i class="fa fa-file-text-o"></i> Download Sample
        </button>
      </div>
    </div>
    <div class="form-group" *ngIf="loadPlanRequest.entityType == 'purchaseOrder'">
      <label class="control-label" style="padding-left:15px" for="entityNumbers">Purchase Order Number(s)</label>
      <div class="col-sm-10">
        <input type="text" [hidden]="mode != 'SYNC'" class="form-control" autocomplete="off" spellcheck="false"
               placeholder="Enter PO number(s). Multiple POs are separated by &quot;,&quot;." id="entityNumbers" name="entityNumbers" [(ngModel)]="entityNumbers" required
               #doNumberField="ngModel" />
        <div [hidden]="doNumberField.valid || doNumberField.pristine" class="alert alert-danger">
          Purchase Order Numbers field is required
        </div>
        <input *ngIf="mode == 'LOAD'" type='file' accept='text/plain' multiple (change)='openFile($event)' required>
        <button type="button" class="btn btn-info float-right cursor-pointer" (click)="downloadSampleFile()" *ngIf="mode == 'LOAD'"
                style="color: #ffffff; background-color: #929292; border-color: #929292;">
          <i class="fa fa-file-text-o"></i> Download Sample
        </button>
      </div>
    </div>
    <div class="form-group"
         *ngIf="loadPlanRequest.entityType == 'deliveryOrder' || loadPlanRequest.entityType == 'purchaseOrder'  ">
      <label class="control-label" style="padding-left:15px" for="partnerId">Partner</label>
      <div class="col-sm-10">
        <select class="form-control" id="partnerId" name="partnerId" [(ngModel)]="partnerId" required
                #partnerIdField="ngModel">
          <option *ngFor="let partner of partnerLists; index as i" [value]="partner.id">{{partner.id}}</option>
        </select>
      </div>
    </div>
    <div class="form-group" [hidden]="mode == 'LOAD' ">
      <label class="control-label" style="padding-left:15px">Carrier Code (Optional)</label>
      <div class="row no-margin">
        <div class="col-sm-8">
          <input type="text" class="form-control" autocomplete="off" spellcheck="false"
                 placeholder="Enter Carrier Code" id="carrierCode" name="carrierCode" [(ngModel)]="loadPlanRequest.loadPlanParams.carrierCode" />
        </div>
        <div class="col-sm-2 display-inblock no-padding-left" style="height:35px">(e.g. 'UPSN')</div>
      </div>
    </div>
    <div class="form-group" [hidden]="mode == 'LOAD' ">
      <label class="control-label" style="padding-left:15px">Service Level Code (Optional)</label>
      <div class="row no-margin">
        <div class="col-sm-8">
          <input type="text" class="form-control" autocomplete="off" spellcheck="false"
                 placeholder="Enter Service Level Code" id="serviceLevelCode" name="carrierCode"
                 [(ngModel)]="loadPlanRequest.loadPlanParams.serviceLevelCode" />
        </div>
        <div class="col-sm-2 display-inblock no-padding-left" style="height:35px">(e.g. '00')</div>
      </div>
    </div>
    <div class="form-group" [hidden]="mode == 'LOAD' ">
      <div>
        <label class="control-label" style="padding-left:15px">Transport Method Code (Optional)</label>
        
        <div class="row no-margin">
          <div class="col-sm-8">
            <select class="form-control" id="transportMethodEnum" name="transportMethodEnum" 
              [(ngModel)]="loadPlanRequest.loadPlanParams.transportMethodEnum"
              (ngModelChange)='onTransportCodeChanged($event)'>
              <option value="">Default (None)</option>
              <option *ngFor="let transpMethod of transportMethodList; index as i" [value]="i">
                {{transpMethod.description}} - {{transpMethod.code}}
              </option>
              <option value="-">Custom</option>
            </select>
          </div>
        </div>

        <div *ngIf="loadPlanRequest.loadPlanParams.transportMethodEnum == '-'" class="row no-margin"
          style="padding: 10px 0 0 20px;">
          <div class="col-sm-3">
            <label class="control-label" style="font-size: 0.9em;">Code</label>
            <input type="text" class="form-control" autocomplete="off" spellcheck="false"
                  placeholder="Enter Transport Method Code (e.g. M)" id="transportMethodCode" name="transportMethodCode" [(ngModel)]="loadPlanRequest.loadPlanParams.transportMethodCode" />
          </div>
          <div class="col-sm-3">
            <label class="control-label" style="font-size: 0.9em;">Description</label>
            <input type="text" class="form-control" autocomplete="off" spellcheck="false"
                  placeholder="Enter Transport Method Description (e.g. TruckLoad)" id="transportMethodDescrption" name="transportMethodDescrption" [(ngModel)]="loadPlanRequest.loadPlanParams.transportMethodDescription" />
          </div>
        </div>
      </div>
    </div>
    <div class="form-group" [hidden]="mode == 'LOAD' ">
      <label class="control-label" style="padding-left:15px">Payment Terms Code (Optional)</label>
      <div class="row no-margin">
        <div class="col-sm-8">
          <input type="text" class="form-control" autocomplete="off" spellcheck="false"
                 placeholder="Enter Payment Terms Code" id="paymentTermsCode" name="paymentTermsCode" [(ngModel)]="loadPlanRequest.loadPlanParams.paymentTermsCode" />
        </div>
        <div class="col-sm-2 display-inblock no-padding-left">(e.g. 'PP')</div>
      </div>
    </div>

    <div class="form-group">
      <label class="control-label col-sm-2" for="batchSize">Batch Size</label>
      <div class="col-sm-10">
        <input id="batchSize" #batchSizeField="ngModel" type="number" min="1" max="200" class="form-control"
               placeholder="Enter batch size" [disabled]="(mode == 'SYNC')" [(ngModel)]="batchSize" name="batchSize"
               required>
        <div [hidden]="batchSizeField.valid || batchSizeField.pristine || mode == 'SYNC'" class="alert alert-danger">
          Batch Size is required
        </div>
      </div>
    </div>

    <div class="form-group">
      <div class="col-sm-offset-2 col-sm-10">
        <div class="input-group">
          <button type="submit" class="btn btn-primary"
                  [disabled]="!loadPlanForm.form.valid || showLoader">
            Submit
          </button>
          <app-preloader-small *ngIf="showLoader"></app-preloader-small>
        </div>
      </div>
    </div>

    <br>
    <div class="form-group">
      <div class="input-group">
        <label class="control-label col-sm-1" for="logs">Logs</label><a [routerLink]="" (click)="clearLogs()"> Clear</a>
      </div>
      <div class="col-sm-offset-2 col-sm-10">
        <textarea id="logs" rows="12" class="form-control" autocomplete="off" spellcheck="false" name="logs"
                  [(ngModel)]="logMessage" readonly></textarea>
      </div>
    </div>
  </form>
</div>
