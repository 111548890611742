import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
 selector: 'formly-link-component',
 template: `
<a routerLink="./generateMapperString">Click here to create Mapper String</a>
 `,
})
export class FormlyLinkComponent extends FieldType {

}