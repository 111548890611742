<div style="    width: 100%; height: calc(100vh - 46px); overflow: hidden;">
  <h3>Privacy Notice</h3>
  <div style="    width: 95%; height: calc(100vh - 46px); overflow: hidden; margin-top: 25px">
    <table class="table">
      <tbody>
        <tr class="thead-dark">
          <th role="columnheader">Name</th>
          <th role="columnheader">Description</th>
        </tr>
        <tr *ngFor="let page of pageDetails">
          <td style="width: 17%">
            <a  target="_blank" class="cursor-pointer" href="{{page.privacylLink}}" >
              {{page.privacyName}}
            </a>
          </td>
          <td>{{page.privacyDescription}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
