import { UATAvailability } from '../model/uatAvailability.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';

@Injectable()
export class UatAvailabilityService {

    constructor(private httpClient: HttpClient, private configService: ConfigService) {
    }

  getUATAvailability(businessProcessameDetails: string) {
    var baseUrlWithVersion = this.configService.omtestSuiteAPI.baseUrl + '/api/' + this.configService.omtestSuiteAPI.apiVersion;
    return this.httpClient.get<any[]>(baseUrlWithVersion + '/uatAvailability/' + businessProcessameDetails);
  }
}
