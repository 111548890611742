<div role="navigation" class="container padding-10" style="display: flex; align-items: center; justify-content: center;">
    <div class="center-align" style="border: 0px solid rgba(0,0,0,.125); border-radius: .25rem;">
        <h3><i class="ms-Icon ms-Icon--Error"></i> There was a problem loading this page.</h3>

        <div style="padding: 40px;">
            <h5>Test Platform Team is working on the issue to solve it.</h5>
            
            <span>Please reach out to <a href="mailto:sctestsupport@microsoft.com" class="ms-Icon ms-Icon--Mail"  title="Test Platform Support"> <span class="ms-fontWeight-semibold"> Test Platform Support</span></a> alias for help.</span>
        </div>
        <div>
            <button type="button" class="ms-Button ms-Button--primary" (click)="onRefreshClicked()">
                <span class="ms-Button-textContainer">
                    <span class="ms-Button-label x-hidden-focus">
                        Reload Page
                    </span>
                </span>
            </button>
        </div>
    </div>

</div>
