import {
  Component,
  OnInit,
  Input,
  ViewChild,
  TemplateRef,
} from "@angular/core";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-error-display",
  templateUrl: "./error-display.component.html",
  styleUrls: ["./error-display.component.css"],
})
export class ErrorDisplayComponent implements OnInit {
  @Input() errorMessage = "";

  @ViewChild("jsonModal") jsonModal: TemplateRef<any>;
    messageToDisplay: string;

  constructor(private modalService: NgbModal) {}

  ngOnInit(): void {
    this.messageToDisplay = this.errorMessage != null && this.errorMessage != "" ? this.errorMessage : "There is no error message for this step.";
  }

  onEditClicked() {
    let options: NgbModalOptions = {
      size: "m",
      centered: true,
    };

    this.modalService.open(this.jsonModal, options);
  }
}
