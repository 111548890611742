<div style="width: 100%; height: calc(100vh - 46px); ">
  <h3 role="heading">SKU Number Search</h3>
  <br>
  <div class="form-group col-sm-10" style="margin-bottom: 25px; border: 1px solid rgba(0,0,0,.125); border-radius: .25rem;">
    <b>Description:</b>
    <p>{{this.pageDescription}}</p>
  </div>
  <div class="row">
    <div class="col-sm-5">
      <div class="input-group">
        <input class="form-control" aria-label="fromDate" placeholder="From date (yyyy-mm-dd)" name="fromDate"
               [(ngModel)]="fromDate" ngbDatepicker #f="ngbDatepicker">
        <div class="input-group-append">
          <button role="presentation" aria-label="datePicker" class="btn btn-outline-secondary fa fa-calendar"
                  (click)="f.toggle()" type="button"></button>
        </div>
      </div>
    </div>
    <div class="col-sm-5">
      <div class="input-group">
        <input class="form-control" placeholder="To date (yyyy-mm-dd)" aria-label="toDate" name="toDate"
               [(ngModel)]="toDate" [minDate]="fromDate" ngbDatepicker #t="ngbDatepicker">
        <div class="input-group-append">
          <button role="presentation" aria-label="datePicker" class="btn btn-outline-secondary fa fa-calendar"
                  (click)="t.toggle()" type="button"></button>
        </div>
      </div>
    </div>
  </div>
  <br>
  <div class="row">
    <div class="col-sm-10">
      <input type="text" class="form-control" placeholder="Enter SKU" aria-label="PartNumber" [(ngModel)]="skuText">
    </div>
    <div class="col-sm-2">
      <button type="button" class="btn btn-primary" (click)="onSearchClick()"
              style="background: #0070F5">
        Search
      </button>
    </div>
  </div>
  <br>
  <div class="table-responsive">
    <table class="table" role="presentation">
      <thead class="thead-dark">
        <tr>
          <th scope="col">SKU</th>
          <th scope="col">HardWarePartNumber</th>
          <th scope="col">Country Code</th>
          <th scope="col">In OnePDM</th>
          <th scope="col">In MDS</th>
          <th scope="col">In OER</th>
          <th scope="col">In PDS</th>
          <th scope="col">In ECC</th>
          <th scope="col">NPI Validation Message</th>
        </tr>
      </thead>
      <tbody>
        <app-preloader *ngIf="showLoader"></app-preloader>
        <tr *ngFor="let skuNumber of skuNumberList.skuNumberListItems" (click)="onSkuNumberItemClick()">
          <td>{{ skuNumber.sku }}</td>
          <td>{{ skuNumber.hardwarePartNumber }}</td>
          <td>{{ skuNumber.countryCode }}</td>
          <td>{{ skuNumber.inOnePDM }}</td>
          <td>{{ skuNumber.inMDS }}</td>
          <td>{{ skuNumber.inOER }}</td>
          <td>{{ skuNumber.inPDS }}</td>
          <td>{{ skuNumber.inECC }}</td>
          <td>{{ skuNumber.npiValidationMessage }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [maxSize]="7" [boundaryLinks]="true"
                  (pageChange)="onPageChanged()" *ngIf="!showLoader" class="d-flex justify-content-center"></ngb-pagination>
</div>
