import { Component, OnInit } from '@angular/core';
import { MasterDataService } from '../service/masterdata.service';
import { SampleFilesService } from '../service/samplefiles.service';
import * as XLSX from 'xlsx';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductRequestList } from '../model/productrequest.model';
import { ConfigService } from '../service/config.service';
import * as log from '../AppInsightsLogger';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { DescriptionService } from '../service/description.service';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-skuvalidation',
  templateUrl: './skuvalidation.component.html',
  styleUrls: ['./skuvalidation.component.css']
})
export class SkuValidationComponent implements OnInit {

  skuNumbers: string;
  partnerProfile: string;
  mode: string;
  batchSize: number;
  showLoader: boolean;
  logMessage: string = '';
  excelJsonData: any;
  name: string;
  private logger: log.AppInsightsLogger;
  pageDetails: any[];
  pageDescription: string;

  ngOnInit() {
    window.sessionStorage.setItem("orgSelected", "1");
    this.showLoader = false;
    this.logMessage = '';
    this.logger.trackTrace(`Successfully logged to SKU Validation`);

    this.descriptionService.pageDetailsTools().subscribe((response: any[]) => {
      this.pageDetails = response;
      this.pageDetails.forEach(element => {
        if (element.toolName == "SKU Validation") {
          this.pageDescription = element.toolDescription
        }
      });
    });
  }

 

  constructor(private descriptionService: DescriptionService, private masterDataService: MasterDataService, private msalService: MsalService, private configService: ConfigService) {
    this.name = this.msalService.instance.getActiveAccount().name;
    this.logger = new log.AppInsightsLogger(configService, msalService);
  }

  downloadSampleFile() {
    SampleFilesService.downloadSampleFile(SampleFilesService.SkuListSample);
  }

  onPostSkuClick() {
    this.showLoader = true;
    this.logMessage = '';
    let jsonObjSource = JSON.parse(this.excelJsonData);
    this.masterDataService.validateSku(jsonObjSource).subscribe((response: string[]) => {
      if (response != null && response.length > 0) {
        response.forEach(element => {
          this.addLogLine(element);
          this.logger.trackTrace(`SKU Validation Successfully`);
        });
      } else {
        this.addLogLine('Some error occured, sku list submitted for validation');
        this.logger.trackTrace(`SKU Validation Some error occured, sku list submitted for validation`);
      }
      this.showLoader = false;
    }, (error) => {
        this.addLogLine('Some error occured, sku list submitted for validation');
        this.logger.trackTrace(`SKU Validation Some error occured, sku list submitted for validation`);
        this.logger.trackException(error)
      this.showLoader = false;
    });
  }

  getBatchedArrays(array: string[], batchSize: number) {
    var batchedArrays = [];
    while (array.length > 0) {
      batchedArrays.push(array.splice(0, batchSize));
    }

    return batchedArrays;
  }

  openFile(event: any) {
    let input = event.target;
    for (var index = 0; index < input.files.length; index++) {
      let reader = new FileReader();
      reader.onload = () => {
        this.skuNumbers = reader.result as string;
      }
      reader.readAsText(input.files[index]);
    };
  }

  validateFileType(event: any) {
    const parent = this;
    if (event.target.files.length > 0 && event.target.files[0].name.toLowerCase().indexOf('xls') > 0) {
      const uploadedFile = event.target.files[0];
      let readFile = new FileReader();
      readFile.onload = () => {
        let storeData: any;
        storeData = readFile.result;
        const exceldata = new Uint8Array(storeData);
        let arr = new Array();
        for (var i = 0; i != exceldata.length; ++i) {
          arr[i] = String.fromCharCode(exceldata[i]);
        }
        const workbook = XLSX.read(arr.join(''), { type: 'binary' });
        parent.excelJsonData = JSON.stringify(XLSX.utils.sheet_to_json(workbook.Sheets['Input'], { raw: false }));
      }
      readFile.readAsArrayBuffer(uploadedFile);
    }
    else {
      //parent.headerText = 'Invalid File!';
      //parent.bodyText = 'You have selected an Invalid File! Please select a valid file and try again!';
      //parent.modalService.open(responseModal, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' }).result.then((result: any) => {
      //});
    }
  }

  addLogLine(line: string) {
    var currentDateTime = new Date();
    this.logMessage += '[' + currentDateTime.toLocaleString() + ']' + ' ' + line + '\n';
  }

  getSKUNumberArray() {
    var skuNumberArrayUntrimmed: string[];
    var skuNumberArrayTrimmed: string[];
    skuNumberArrayUntrimmed = this.skuNumbers.replace(/\r/g, '').split('\n');
    skuNumberArrayTrimmed = [];
    skuNumberArrayUntrimmed.forEach(element => {
      if (element != null) {
        skuNumberArrayTrimmed.push(element.trim());
      }
    });

    return skuNumberArrayTrimmed;
  }

  clearLogs() {
    this.logMessage = '';
  }
}
