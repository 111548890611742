<div style="    width: 100%; height: calc(100vh - 46px); overflow: hidden;">
    <h3>Load Test</h3>
    <p><span class="fa fa-warning text-center"></span> Please run any Load Tests with more than 10 orders only after getting sign off from <b>SCTestSupport@microsoft.com </b> and SAP Deliver team <b>sumitar@microsoft.com</b></p>
    <div class="row no-margin">
        <div class="col-lg-12 no-padding">
            <ng-sidebar-container style="height: calc(100vh - 100px);">
                <ng-sidebar [(opened)]="sidebarOpened" position="right">
                    <div class="row no-margin border-radius-5 back-color-white full-height overflow-hidden" style="width: 600px; border: 1px solid #ADADAD; overflow-y: auto; margin-right: 2px !important;">
                        <div class="col-lg-12 no-padding" style="background-color: rgb(229, 229, 229); width:450px ">
                            <div class="row no-margin padding-5" style="background-color: #676767; color: #FFFFFF;">
                                <div class="col-lg-11 no-padding text-left bold">
                                    <h6>Create New Load Test</h6>
                                </div>
                                <br>
                                <div role="button" class="col-lg-1 no-padding text-right" style="line-height: 15px;">
                                    <span class="font-28 bold cursor-pointer" (click)="toggleSidebar()">
                                        &times;
                                    </span>
                                </div>
                            </div>
                            <div class="row no-margin">
                                <div class="col-lg-12 padding-15 font-15" style="height: calc(100vh - 250px); overflow: hidden; overflow-y: auto;">
                                    <p>Did you get a sign off for running load tests?</p>
                                    <form (ngSubmit)="onSubmitClick()" #loadTestForm="ngForm">
                                        <div class="row no-margin">
                                            <div class="col-lg-12 no-padding ">
                                                <mat-form-field style="display: block;">
                                                    <mat-label>Load Test Name: <span class="tab"></span></mat-label>
                                                    <input matInput id="FriendlyName" name="FriendlyName" placeholder="Enter Load Test Name"
                                                           [(ngModel)]="createLoadTestRequest.FriendlyName" required>
                                                    <mat-error>
                                                        Load Test Name is required
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="row no-margin">
                                            <div class="col-lg-12 no-padding">
                                                <mat-form-field style="display: block;">
                                                    <select matNativeControl placeholder="--Select SCOR Function--" id="Vertical" name="Vertical" [(ngModel)]="createLoadTestRequest.Vertical">
                                                        <option value="Deliver">Deliver</option>
                                                    </select>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="row no-margin">
                                            <div class="col-lg-12 no-padding">
                                                <mat-form-field style="display: block;">
                                                    <select matNativeControl placeholder="--Select Type Of Test--" id="TypeOfTest" name="TypeOfTest" [(ngModel)]="createLoadTestRequest.TypeOfTest" requried>
                                                        <option value=0>Order creation & tracking only </option>
                                                        <option value=1>End to End</option>
                                                    </select>
                                                    <mat-error>
                                                        Type Of Test profile is required
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="row no-margin">
                                            <div class="col-lg-12 no-padding">
                                                <mat-form-field style="display: block;">
                                                    <select matNativeControl placeholder="--Select Business Process (L4)--" id="Scenario" name="Scenario" [(ngModel)]="createLoadTestRequest.Scenario" requried>
                                                        <option value="OnlineStore_Perf">Online Internal Perf</option>
                                                        <option value="OnlineStore">Online External Perf</option>
                                                    </select>
                                                    <mat-error>
                                                        Business Process (L4) profile is required
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                        </div>

                                        <div class="row no-margin" *ngIf="createLoadTestRequest.TypeOfTest == 1">
                                            <div *ngIf="createLoadTestRequest.Scenario != ''">

                                                <div *ngIf="createLoadTestRequest.Scenario == 'OnlineStore_Perf'">
                                                    <label class="m-1"> Mock Partner  </label>
                                                    <div class="col-lg-12 no-padding">
                                                        <input class="m-1" [ngModel]="true" [(ngModel)]="createLoadTestRequest.MockFMV" (change)="checkBoxMockFMV($event)" value="MockFMV" type="checkbox" id="MockFMV" name="MockFMV">
                                                        <label for="MockFMV" aria-label="MockFMV" class="m-1">Mock FMV (TMC)</label>
                                                        <input class="m-1" [ngModel]="true" [(ngModel)]="createLoadTestRequest.MockDC" (change)="checkBoxMockDC($event)" value="MockDC" type="checkbox" id="MockDC" name="MockDC">
                                                        <label for="MockDC" aria-label="MockDC" class="m-1">Mock DC (Arvato)</label>
                                                    </div>
                                                </div>

                                                <div *ngIf="createLoadTestRequest.Scenario == 'OnlineStore'">
                                                    <label class="m-1"> Mock Partner  </label>
                                                    <div class="col-lg-12 no-padding">
                                                        <input class="m-1" [(ngModel)]="createLoadTestRequest.MockFMV" value="MockFMV" type="checkbox" id="MockFMV" name="MockFMV">
                                                        <label for="MockFMV" aria-label="MockFMV" class="m-1">Mock FMV (TMC)</label>
                                                        <input class="m-1" [(ngModel)]="createLoadTestRequest.MockDC" value="MockDC" type="checkbox" id="MockDC" name="MockDC">
                                                        <label for="MockDC" aria-label="MockDC" class="m-1">Mock DC (Arvato)</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row no-margin">
                                            <div class="col-lg-12 no-padding">
                                                <mat-form-field style="display: block;">
                                                    <select matNativeControl placeholder="--Select Order Generator--" id="OrderGenerator" name="OrderGenerator" [(ngModel)]="createLoadTestRequest.ReceiverSystem" requried>
                                                        <option stye="padding:16px" value="SAP_EHC">SAP ECC</option>
                                                        <option stye="padding:16px" value="OC">OC Fulfillment</option>
                                                    </select>
                                                    <mat-error>
                                                        Receiver profile is required
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="row no-margin">
                                            <div class="col-lg-12 no-padding">
                                                <mat-form-field style="display: block;">
                                                    <select matNativeControl placeholder="--Select Fulfillment Method --" id="FulfillmentMethod" name="FulfillmentMethod " [(ngModel)]="createLoadTestRequest.FulfillmentMethod">
                                                        <option value="DC_SHIP">DC Ship</option>
                                                    </select>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="row no-margin">
                                            <div class="col-lg-12 no-padding">
                                                <mat-form-field style="display: block;">
                                                    <mat-label>Number Of Lines Per Order</mat-label>
                                                    <input matInput id="NumberOfLinesPerOrder" name="NumberOfLinesPerOrder" type="number" min="1" max="5"
                                                           [(ngModel)]="createLoadTestRequest.NumberOfLinesPerOrder" placeholder="Enter Number Of Lines Per Order" required>
                                                    <mat-error>
                                                        Number Of Lines Per Order is required
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                        </div>

                                        <!-- todo after we figure out the req -->
                                        <!--<div class="row no-margin">
                                          <div class="col-lg-12 no-padding">
                                            <mat-form-field style="display: block;">
                                              <mat-label>Number Of Lines Per Regions/Countries</mat-label>
                                              <input matInput id="NumberOfLinesPerRegionsCountries" name="NumberOfLinesPerRegionsCountries" type="number" min="1" max="5"
                                                     [(ngModel)]="createLoadTestRequest.NumberOfLinesPerRegionsCountries" placeholder="Enter Number Of Lines Per Regions/Countries" required>
                                              <mat-error>
                                                Number Of Lines Per Regions/Countries is required
                                              </mat-error>
                                            </mat-form-field>
                                          </div>
                                        </div>-->

                                        <div class="row no-margin">
                                            <div class="col-lg-12 no-padding">
                                                <mat-form-field style="display: block;">
                                                    <select matNativeControl placeholder="--Select Region--" id="Region" name="Region" [(ngModel)]="createLoadTestRequest.Region" requried>
                                                        <option value="AOC">AOC</option>
                                                    </select>
                                                    <mat-error>
                                                        Region profile is required
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="row no-margin">
                                            <div class="col-lg-12 no-padding">
                                                <mat-form-field style="display: block;">
                                                    <select matNativeControl placeholder="--Select Sales Org--" id="SalesOrg" name="SalesOrg" 
                                                    [(ngModel)]="createLoadTestRequest.SalesOrg" (change)="orgChanged($event.target.value)" requried>
                                                        <option value="LDCA">LDCA</option>
                                                        <option value="WST">WST</option>
                                                    </select>
                                                    <mat-error>
                                                        Sales Org profile is required
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="row no-margin">
                                            <div class="col-lg-12 no-padding">
                                                <mat-form-field style="display: block;">
                                                    <select matNativeControl placeholder="--Select Ship-To Country--" id="Country" name="Country" [(ngModel)]="createLoadTestRequest.Country" requried>
                                                       
                                                        <option *ngIf="createLoadTestRequest.SalesOrg == 'LDCA'" value="CA">CA</option>
                                                        <option *ngIf="createLoadTestRequest.SalesOrg == 'WST'" value="US">US</option>
                                                    </select>
                                                    <mat-error>
                                                        Country profile is required
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                        </div>

                                        <div class="row no-margin">
                                            <div class="col-lg-12 no-padding">
                                                <mat-form-field style="display: block;">
                                                    <mat-label>Number Of Orders</mat-label>
                                                    <input matInput id="NumberOfOrders" name="NumberOfOrders" type="number" min="1" max="50000"
                                                           [(ngModel)]="createLoadTestRequest.NumberOfOrders" placeholder="Enter Number of Orders" required>
                                                    <mat-error>
                                                        Number Of Orders is required
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <br>
                                        <button type="submit" class="btn btn-primary" (click)="toggleSidebar()" [disabled]="!loadTestForm.form.valid" value="Create">Create </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-sidebar>

                <div ng-sidebar-content style="height: calc(100vh - 150px); padding: 0px 10px; overflow-y: hidden;">
                    <div class="row no-margin padding-top-10 padding-bottom-10 steps-statusinfo ">
                        <div class="no-padding" style="width: 2000px;">
                            <div class="row no-margin">
                                <button type="button" class=" btn btn-primary" (click)="toggleSidebar()">Create New Load Test</button>
                            </div>
                            <div class="row no-margin" style="padding: 10px 20px 0px 0px;">
                                <span class="header-section bold text-center" style="vertical-align: text-bottom; padding-top: 10px; margin-top: 5px;width: 120px;">Search by user :</span>
                                <span><ng-template #selectUserTooltip>Select Load Tests by User!</ng-template></span>
                                <span class="margin-left-5 col-lg-3 text-center status-count-details"
                                      style="vertical-align: text-bottom; padding-top: 10px; margin-top: 5px;">
                                    <ng-select role="menu" [items]="usersDetails" bindLabel="userName" bindValue="email"
                                               placeholder="Select User Load Test" [(ngModel)]="selectedUserEmail"
                                               (change)="getLoadTestDetails()" *ngIf="usersDetails.length > 0"
                                               arial-label="Select User" title="Select User" aria-expanded="false"
                                               aria-required="true" [ngbTooltip]="selectUserTooltip" placement="right"
                                               [openDelay]="500" [closeDelay]="1500">
                                    </ng-select>
                                </span>
                            </div>
                        </div>
                    </div>
                    <hr class="no-margin" />
                    <div class="row padding-top-10 padding-bottom-10 no-margin pos-rel" id="testRunsWrapper"
                         style="height: calc(100vh - 235px); overflow: hidden; overflow-y: auto;">
                        <div class="col-lg-12 no-padding">
                            <div class="row no-margin margin-bottom-25 full-width border-radius-10 testrun-wrapper"
                                 *ngFor="let label of responseData; index as r">
                                <div class="col-lg-12 no-padding">
                                    <div class="row no-margin"
                                         style="background-color: rgb(229, 229, 229); border-top-left-radius: 10px; border-top-right-radius: 10px;">
                                        <span class="margin-left-5 col-lg-5 padding-10 header-section" style="vertical-align: text-bottom;">
                                            <span class="font-14">
                                                Test&nbsp;Name:&nbsp; <b>{{label.name}}</b>
                                            </span>
                                        </span>
                                        <span class="margin-left-5 col-lg-3 padding-10 text-center status-count-details" style="vertical-align: text-bottom;">
                                            <span class="font-14">
                                                Created&nbsp;By:&nbsp; <b>{{label.userName}}</b>
                                            </span>
                                        </span>
                                        <span class="margin-left-5 col-lg-3 padding-10 text-right" style="vertical-align: text-bottom;">
                                            <span class="font-14">
                                                Created&nbsp; <b>{{ (label.createdAt) | date: 'MM/dd/yyyy hh:mm a'}}</b>
                                            </span>
                                        </span>
                                    </div>
                                    <div class="row no-margin" style="background-color: rgb(229, 229, 229);">
                                        <span class="margin-left-5 col-lg-5 padding-10 header-section" style="vertical-align: text-bottom;">
                                            <span class="font-14">
                                                SCOR Function&nbsp; <b>{{Vertical}}</b>
                                            </span>
                                            <span class="font-14 col-lg-3 padding-10 text-center status-count-details">
                                                Business Processes (L4)&nbsp; <b>{{Section}}</b>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div class="row no-margin">
                                    <!-- need to add load test id -->
                                    <div class="col-lg-15 col-md-12 padding-20 text-center" style="padding-right: 50px">
                                        <a class="cursor-pointer bold"
                                           [routerLink]="['/loadtestexecution', label.loadTestRunId]" role="link">
                                            View&nbsp;Details
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-sidebar-container>
        </div>
    </div>
</div>
