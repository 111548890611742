import { Component, OnInit } from '@angular/core';

@Component({
 // selector: 'app-testrunoverview',
  templateUrl: './testrunoverview.component.html',
  styleUrls: ['./testrunoverview.component.css']
})
export class TestRunOverviewComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.sessionStorage.setItem("orgSelected", "1");
  }

}
