import { Component, OnInit } from '@angular/core';
import { TestRunService } from '../service/testrun.service';
import { TestRunRequest } from '../model/testrunrequest.model';
import { PagerService } from '../service/pager.service';
import { TestRunList } from '../model/testrunlist.model';
import { Constants } from '../service/constant.service';
import { AuthService } from '../service/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { ConfigService } from '../service/config.service';
import * as log from '../AppInsightsLogger';

import { ApplicationInsights } from '@microsoft/applicationinsights-web';

@Component({
  selector: 'app-test-run-list',
  templateUrl: './test-run-list.component.html',
  styleUrls: ['./test-run-list.component.css']
})

export class TestRunListComponent implements OnInit {

  testRunList: TestRunList;
  testRunRequest: TestRunRequest;
  testRunNameText: string;
  fromDate: any;
  toDate: any;
  collectionSize: number;
  page: number;
  showLoader = true;
  name: string;
  private logger: log.AppInsightsLogger;

  constructor(private configService: ConfigService, private testRunService: TestRunService, private pagerService: PagerService, private authService: AuthService, private router: Router, private msalService: MsalService) {
    this.name = this.msalService.instance.getActiveAccount().name;
    this.logger = new log.AppInsightsLogger(configService, msalService);
  }

  ngOnInit() {
    this.page = 1;
    this.testRunList = new TestRunList();
    this.testRunList.testRunListItems = [];

    this.setDefaultDates();
    this.testRunRequest = this.getTestRunRequest();
    this.updateTestRuns();

    if (!this.authService.isOrderViewEnabled()) {
      this.router.navigate(['/', 'landing-page']);
    }
    this.logger.trackTrace(`Successfully landed to Test Runs`);
  }

  onSearchClick() {
    if (this.testRunNameText) {
      this.fromDate = null;
      this.toDate = null;
    } else {
      if (this.fromDate == null && this.toDate == null) {
        this.setDefaultDates();
      }
    }

    this.testRunList.testRunListItems = [];
    this.testRunRequest = this.getTestRunRequest();
    this.updateTestRuns();
  }

  getTestRunRequest(skip = 0, take = Constants.DefaultPageSize, sortOrder = Constants.SortOrderDescending) {
    let testRunRequest = new TestRunRequest();

    if (this.testRunNameText) {
      testRunRequest.testRunName = this.testRunNameText;
    } else {
      testRunRequest.testRunName = '';

      let startDate = this.getFormattedDate(this.fromDate);
      startDate.setUTCHours(0, 0, 0, 0);
      testRunRequest.startDate = startDate.toJSON();

      let endDate = this.getFormattedDate(this.toDate);
      endDate.setUTCHours(24, 0, 0, 0);
      testRunRequest.endDate = endDate.toJSON();
    }

    testRunRequest.skip = skip;
    testRunRequest.take = take;
    testRunRequest.sortOrder = sortOrder;

    return testRunRequest;
  }

  getFormattedDate(inputDate: any): Date {
    let newDate = new Date(Date.UTC(inputDate.year, inputDate.month - 1, inputDate.day));
    return newDate;
  }

  updateTestRuns() {
    this.showLoader = true;
    this.testRunList.testRunListItems = [];
    this.testRunService.getTestRuns(this.testRunRequest).subscribe((response) => {
      this.testRunList = response;
      this.collectionSize = this.testRunList.totalCount;
      this.showLoader = false;
    });
  }

  onPageChanged() {
    this.testRunRequest.skip = (this.page - 1) * 10;
    this.testRunRequest.take = 10;
    this.updateTestRuns();
  }

  setDefaultDates() {
    let todaysDate = new Date();
    let fifteenDaysOldDate = new Date();

    fifteenDaysOldDate.setDate(fifteenDaysOldDate.getDate() - 7);
    this.fromDate = { 'year': fifteenDaysOldDate.getUTCFullYear(), 'month': fifteenDaysOldDate.getUTCMonth() + 1, 'day': fifteenDaysOldDate.getUTCDate() };
    this.toDate = { 'year': todaysDate.getUTCFullYear(), 'month': todaysDate.getUTCMonth() + 1, 'day': todaysDate.getUTCDate() };
  }
}

