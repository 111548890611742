import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { ManageTestRunsComponent } from './manage-testruns/manage-testruns.component';

@Component({
  selector: 'app-scenarios',
  templateUrl: './manage-scenarios.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./manage-scenarios.component.css'],
  providers: [ ManageTestRunsComponent ]
})
export class ManageScenariosComponent implements OnInit {

  manageTestRunsComponent: ManageTestRunsComponent;

  tabs = [
    { id: 0, name: 'tabExecuteTestRuns', title: 'Execute Test Runs', active: true, component: '<app-execute-testruns></app-execute-testruns>' },
    { id: 1, name: 'tabManageTestRuns', title: 'Manage Test Runs', active: false, component: '<app-manage-testruns></app-manage-testruns>' }
  ];

  activeTabId: number;

  constructor(_manageTestRunsComponent: ManageTestRunsComponent) {
    this.manageTestRunsComponent = _manageTestRunsComponent;
  }

  ngOnInit() {
    this.activeTabId = 0;
  }

  changeTab(id: number) {
    this.activeTabId = id;
  }
}
