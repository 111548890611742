<div style="width: 100%; height: calc(100vh - 46px); ">
  <div>
    <h6> Customer Purchase Order</h6>
    <h4>{{ customerPurchaseOrder }}</h4>
  </div>
  <br>
  <app-preloader *ngIf="showLoader"></app-preloader>
  <h6> Sales Orders</h6>
  <div>
    <button type="button" class="btn btn-outline-secondary btn-space" *ngFor="let item of orderSummary.salesOrders"
      [ngClass]="{'active': isActive(item)}" (click)="renderTimeline(item)">
      {{ item.orderConfirmationNumber }}
    </button>
  </div>
  <br />
  <br />
  <div class="sales-order-center" *ngIf="!showLoader">
    <h6> Order Timeline </h6>
    <h5><a [routerLink]="" (click)="open(content, 'OrderStatusPayload', '')"> {{ timeline.salesOrderNumber }}</a></h5>
  </div>
  <br>
  <div class="timeline-centered" *ngIf="!showLoader">
    <article class="timeline-entry" *ngFor="let timelineItem of timeline.orderTimelineItems" left-aligned>
      <div class="timeline-entry-inner">
        <time class="timeline-time"
          datetime="timelineItem.timestamp"><span>{{ timelineItem.timestamp | date:'shortTime'}}</span><span>{{
          timelineItem.timestamp | date:'dd/MM/yyyy'}}</span></time>
        <div class="timeline-icon bg-success">
          <i class="entypo-feather"></i>
        </div>
        <div class="timeline-label">
          <h2>{{ timelineItem.message }}</h2>
          <p>{{ timelineItem.entityId }}</p>
          <p *ngIf="isAtomicServicePayloadDownloadEnabled()"><a [routerLink]=""
              (click)="open(content, 'AtomicServicePayload', timelineItem)"> {{ timelineItem.correlationId }}</a></p>
          <p *ngIf="!isAtomicServicePayloadDownloadEnabled()"> {{ timelineItem.correlationId }}</p>
        </div>
      </div>
    </article>
    <article class="timeline-entry begin">
      <div class="timeline-entry-inner">
        <div class="timeline-icon" style="-webkit-transform: rotate(-90deg); -moz-transform: rotate(-90deg);">
          <i class="entypo-flight"></i>
        </div>
      </div>
    </article>
  </div>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="modal-basic-title">{{ modalTitle }} - {{ modalId }}</h5>
    <div class="download-padding"><button type="button" class="btn btn-outline-dark"
        (click)="downloadOSSPayloadFile()">Download</button></div>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <div class="form-group">
        <div class="input-group">
          <div class="input-group-append">
            <p class="text-danger" *ngIf="!showModalLoader && showModalMessage">{{ modalMessage }}</p>
            <app-preloader-small *ngIf="showModalLoader" class="loader-center"></app-preloader-small>
            <ngx-json-viewer *ngIf="!showModalLoader && !showModalMessage" [json]="modalJsonPayload">
            </ngx-json-viewer>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
  </div>
</ng-template>
