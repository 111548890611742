export class LandingPageResponse {
  public status: string;
  public response: WarningBoxDetail[];
}

export class WarningBoxDetail {
  public id: number;
  public notificationStart: Date;
  public notificationEnd: Date;
  public title: string;
  public warningMessage: string;
  public isEnable: boolean;
  public alias: string;
  public createdBy: Date;
  public createdAt: Date;
}
