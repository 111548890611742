
<form [formGroup]="form" (ngSubmit)="submit()">
  
    <formly-form [model]="model" [fields]="fields" [options]="options" [form]="form"></formly-form>
   
</form>


<div class="form-group">
    <div class="input-group">
      <label aria-describedby="log" id="log" role="log" class="control-label col-sm-1" for="logs">Logs</label><a [routerLink]="" (click)="clearLogs()"> Clear</a>
    </div>
    <div class="col-sm-offset-2 col-sm-10">
      <textarea aria-label="logs" id="logs" rows="12" class="form-control" autocomplete="off" spellcheck="false" name="logs"
                [(ngModel)]="logMessage" readonly></textarea>
    </div>
</div>
