
<div style="width: 100%; height: calc(100vh - 46px); overflow: auto;">
    <div *ngIf="isLoading">
        <app-preloader-small></app-preloader-small>
        <div>
            <div style="margin-left: 50px; padding-top: 7px;">Loading ... Please Wait</div>
        </div>      
    </div>
    <ng-container *ngIf="!isLoading">
        <div style="padding: 0.6em; display: inline-flex; align-items: baseline; width: 100%;">
            <h3 style="min-width: 10rem;">Test Plan
                <span>
                    <i class="fa fa-info-circle" style="font-size: 0.5em;" title="{{testPlanDescription}}"></i>
                </span>
                :
            </h3>
            <textarea style="font-weight: 500; overflow:hidden; font-size: 1.75rem;" autosize rows="1" aria-label="Edit Test Plan Name"
                title="Edit Test Plan Name" placeholder="Please enter a test plan name"
                class="form-control hideableTextarea m-1" [(ngModel)]="this.testPlanInstance.testPlanName"
                (blur)=" isFocusedOnTaskGroupLabel = false;" (focus)="isFocusedOnTaskGroupLabel = true">
            </textarea>
        </div>
        <div style="clear: both;"></div>
        <div class="ms-depth-8" style="margin: 5px; padding: 15px;">
            <div>
                <button type="button" class="ms-Button ms-Button--primary" title="Save test plan instance" 
                (click)="onSaveTestPlanInstance()" style="margin-right: 15px;" [disabled] = "saveButtonDisabled">
                    <span class="ms-Button-textContainer">
                        <span class="ms-Button-label x-hidden-focus">
                            Save Test Plan
                        </span>
                    </span>
                </button>       
            </div>
            <div class="ms-Grid" dir="ltr">
               
                <div class="ms-Grid-row" style="padding-top:10px;  padding-bottom: 20px;">
                    <div class="ms-Grid-col" style="width:33%">
                        <span class="ms-fontWeight-bold">Status: </span> 
                        <app-status [(status)]="testPlanStatus" [disabled]="testPlanStatusDisabled" (statusChange)="updateFxn()"></app-status>
                    </div>
                    
                    <div class="ms-Grid-col" style="width:33%">
                        <span class="ms-fontWeight-bold"> Owner: </span><span>{{testPlanOwner}}</span>
                    </div>
                    <div class="ms-Grid-col" style="width:33%">
                        <span class="ms-fontWeight-bold">Test Plan ID:</span> {{testPlanInstanceId}}
                    </div>   
                </div>
                <div class="ms-Grid-row" style="padding-bottom: 20px;">
                    <div class="ms-Grid-col" style="width:33%">
                        <span class="ms-fontWeight-bold">Project Name: </span> <a role="link" [routerLink] = "['/project-view', getUrlParamsForProject()]">{{testPlanProjectName}}</a>
                    </div>
                    <div class="ms-Grid-col" style="width:33%">
                        <span class="ms-fontWeight-bold">Created On: </span>
                        <app-date-picker [(date)]="testPlanCreatedOn" (dateChange)="updateFxn()" ariaLabel="Created On">
                        </app-date-picker>
                    </div>
                    <div class="ms-Grid-col" style="width:33%">
                        <span class="ms-fontWeight-bold">Last Modified: </span> <app-date-picker [(date)]="testPlanLastModified" (dateChange)="updateFxn()" ariaLabel="Last Modified">
                        </app-date-picker>
                    </div>
                </div>
                <div class="ms-Grid-row" style="padding-bottom: 20px;">
                    <div  *ngIf="showPartner" class="ms-Grid-col" style="width:33%">
                        <span class="ms-fontWeight-bold">Partner Name: </span> 
                        <textarea style="font-weight: 500; overflow:hidden; width:50%; display:table-row; margin-bottom: -0.3rem" autosize rows="1" aria-label="edit partner"
                        title="Enter partner name"
                        placeholder="Enter Partner Name"
                        class="form-control ms-fontSize-14 hideableTextarea"
                        [(ngModel)]="this.partnerName"
                        (blur)=" isFocusedOnTaskGroupLabel = false;"
                        (focus)="isFocusedOnTaskGroupLabel = true">
                        </textarea>
                        <!-- <span *ngIf="isPartnerSet" >{{this.partnerName}}</span> -->
                    </div>
                    <div *ngIf="showCountry" class="ms-Grid-col" style="width:33%">
                        <span class="ms-fontWeight-bold">Country Code: </span> 
                        <textarea  *ngIf="!isCountrySet" style="font-weight: 500; overflow:hidden; width:50%; display:table-row; margin-bottom: -0.3rem;" autosize rows="1" aria-label="edit country"
                        title="Enter Country Code"
                        placeholder="Enter Country Code"
                        class="form-control ms-fontSize-14 hideableTextarea"
                        [(ngModel)]="this.country"
                        (blur)=" isFocusedOnTaskGroupLabel = false;"
                        (focus)="isFocusedOnTaskGroupLabel = true">
                        </textarea>
                        <span *ngIf="isCountrySet">{{this.country}}</span>
                    </div>
                    <div *ngIf="showState" class="ms-Grid-col"style="width:33%">
                        <span class="ms-fontWeight-bold">State Code: </span><span>
                            <textarea  *ngIf="!isStateSet" style="font-weight: 500; overflow:hidden; width:50%; display:table-row; margin-bottom: -0.3rem;" autosize rows="1" aria-label="edit state"
                            title="Enter State Code"
                            placeholder="Enter State Code"
                            class="form-control ms-fontSize-14 hideableTextarea"
                            [(ngModel)]="this.stateCode"
                            (blur)=" isFocusedOnTaskGroupLabel = false;"
                            (focus)="isFocusedOnTaskGroupLabel = true">
                            </textarea>
                            <span *ngIf="isStateSet">{{this.stateCode}}</span>
                        </span>
                    </div>

                </div>
            </div>
            
        </div>

        <div *ngIf = "saveSuccess" class="ms-depth-8 m-5 p-15" >
            <h5>Prerequisites
                <span>
                    <i class="fa fa-info-circle" style="margin: 0.4em; font-size: 0.7em;" title="{{prerequisitesDescription}}"></i>
                </span>
            </h5>
            <div style= "float: right; margin-bottom: 8px;">
                <button type="button" class="ms-Button ms-Button--primary"  title="Refresh Pre-Steps"
                (click)="refreshPreStepsData()" style="margin-right: 8px;">
                    <i class="ms-Icon ms-Icon--Refresh" aria-hidden="true"></i>
                </button>
            </div>
            <div>
                <app-simple-grid 
                    [(column_schema)]="PreStepsSchema" 
                    [(table_data)]="this.preStepsData"
                    [useCheckBoxForSelection]="false"
                    [(validationStatus)] = "validationStatus"
                    (onFileSelected)="onFileSelected($event)"
                    (onDownloadSku) ="downloadStepResponse($event)"
                    (onDownloadSampleInput) = "downloadSampleInput($event)">   
                </app-simple-grid>
            </div>
        </div>

        <div *ngIf = "saveSuccess" class="ms-depth-8 m-5 p-12">
            <h5>Test Cases
                <span>
                    <i class="fa fa-info-circle" style="margin: 0.4em; font-size: 0.7em;" title="{{testCaseDescription}}"></i>
                </span>
            </h5>
            <div style="float: right; margin-bottom: 12px;">
                <button *ngIf="showRerun" type="button" class="ms-Button ms-Button--primary" title="Rerun for selected test cases"
                      (click)="onRerunClicked()"
                      style="margin-right: 12px;">
                <span class="ms-Button-textContainer">
                  <span class="ms-Button-label x-hidden-focus">
                    Rerun {{(this.selectedTestCases || []).length > 0 ? "(" + (this.selectedTestCases || []).length + ")" : ""}}
                  </span>
                </span>
                </button>
              <button *ngIf="showTriggerValidation" type="button" class="ms-Button ms-Button--primary" title="Trigger Validation for selected test cases"
                      (click)="onValidationClicked()"
                      style="margin-right: 12px;">
                <span class="ms-Button-textContainer">
                  <span class="ms-Button-label x-hidden-focus">
                    Trigger Validation {{(this.selectedTestCases || []).length > 0 ? "(" + (this.selectedTestCases || []).length + ")" : ""}}
                  </span>
                </span>
              </button>
              <button *ngIf="showTriggerNextStep" type="button" class="ms-Button ms-Button--primary" title="Trigger Validation for selected test cases"
                      (click)="onTriggerNextStepClicked()"
                      style="margin-right: 12px;">
                <span class="ms-Button-textContainer">
                  <span class="ms-Button-label x-hidden-focus">
                    Trigger Next Step{{(this.selectedTestCases || []).length > 0 ? "(" + (this.selectedTestCases || []).length + ")" : ""}}
                  </span>
                </span>
              </button>
              <button type="button" class="ms-Button ms-Button--primary" title="Refresh test cases"
                      (click)="refreshTestCaseData()" style="margin-right: 12px;">
                <i class="ms-Icon ms-Icon--Refresh" aria-hidden="true"></i>
              </button>
              <button type="button" class="ms-Button ms-Button--primary"
                      [disabled]="false" title="Download Test Cases Excel"
                      (click)="downloadExcel()" style="margin-right: 12px;">
                <i class="ms-Icon ms-Icon--Download" aria-hidden="true"></i>
              </button>
              <button *ngIf="showEmailExcelButton" type="button" class="ms-Button ms-Button--primary"
                      [disabled]="false" title="Download Excel"
                      (click)="exportAsExcelFile()" style="margin-right: 12px;">
                <i class="ms-Icon ms-Icon--Mail" aria-hidden="true"></i>
              </button>
            </div>
            <div>
              <app-simple-grid [(column_schema)]="TestCaseGridSchema"
                               [(table_data)]="TestCaseGrid"
                               [organizationId] = "organizationId"
                               [alwaysShowCheckBox]="true"
                               (onSelectionChanged)="onTestCaseSelectionChanged($event)"
                               (onParameterChanged)="updateInputParameters($event)"
                               (testCase)="simpleGridData($event)"
                               [testPlanName]="testPlanName"
                               [detailLink] = "detailLinkMap"></app-simple-grid>
            </div>
        </div>
    </ng-container>
</div>

<ng-template #saveModal let-modal class="modal-xl">
    <div class="modal-header p-2">
        <div class="d-flex justify-content-between w-100">
            <h4 id="saveModalHeader" class="p-2 modal-title ms-fontSize-20 ms-fontWeight-bold">
                Test Plan Instance Stored!
                <button type="button" class="close-button" title="Close" (click)="modal.dismiss('Cross Click')">
                    <i class="ms-Icon ms-Icon--ChromeClose" aria-hidden="true"></i>
                </button>
            </h4>
        </div>
    </div>
    <div class="modal-body">
        <span class="ms-fontSize-16 ms-fontWeight-regular">Changes to "{{this.testPlanInstance.testPlanName}}" stored.</span>
    </div>
    <div class="modal-footer">
        <button type="button" class="ms-Button" (click)="modal.close('OK')">OK</button>
    </div>
</ng-template>

<ng-template #inputFileModal let-modal class="modal-xl">
    <div class="modal-header p-2">
        <div class="d-flex justify-content-between w-100">
            <h4 id="inputFileModalHeader" class="modal-title ms-fontSize-16 ms-fontWeight-bold">
                File Selected:{{selectedFileName}}
            </h4>
            <button type="button" class="close-button" title="Close" (click)="modal.dismiss('Cross Click')">
                <i class="ms-Icon ms-Icon--ChromeClose" aria-hidden="true"></i>
            </button>
        </div>
    </div>
    <div class="modal-body">
        <p class="ms-fontSize-16 ms-fontWeight-regular">Please click "Execute" after submitting the file to run the step.</p>
        <br>
        <p>Do you wish to submit this file?</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="ms-Button" (click)="modal.close('Submit')">Submit</button>
    </div>
</ng-template>

<ng-template #inputTestPlanNameModal class="modal-lg" let-c="close" let-d="dismiss">
    <div class="modal-header" aria-describedby="modal-basic-title">
      <h5 class="modal-title" id="modal-basic-title">Creating a  New Test Plan</h5>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')" role="button" style="outline: 0;">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      Please enter a test plan name:
      <input type="text" class="form-control font-14" placeholder="Enter a test plan name..." [(ngModel)]="testPlanName" aria-required="true" aria-label="Enter Test Plan Name" required style="min-width: 200px;" />
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" ngbAutofocus (click)="c('Cancel')" aria-label="Cancel" role="button">Cancel</button>
      <button type="button" class="btn btn-primary" ngbAutofocus (click)="c('Save')" arial-label="Save" role="button">Save</button>
    </div>

</ng-template>
