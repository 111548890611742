import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { EntityTrackingService } from '../service/entitytracking.service';
import { EntityTrackingMap, TrackingEntitiesResponse, Event } from '../model/entitytracking.model';
import * as XLSX from 'xlsx';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MsalService } from '@azure/msal-angular';
import { ConfigService } from '../service/config.service';
import * as log from '../AppInsightsLogger';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { MasterDataService } from '../service/masterdata.service';
import { DescriptionService } from '../service/description.service';


@Component({
  selector: 'app-entitytracking',
  templateUrl: './entitytracking.component.html',
  styleUrls: ['./entitytracking.component.css']
})
export class EntityTrackingComponent implements OnInit {

  @ViewChild('entityTrackingFormSubmitButton') formSubmitButton: ElementRef;

  scenario: string;
  entityType: string;
  entityNumbers: string;
  name: string;

  showLoader: boolean;
  partialLoadingMessage: string;
  inputMapping: EntityTrackingMap[];

  renderTrackingTable: boolean = false;
  rawResponseEventsPayload: TrackingEntitiesResponse = {};

  allTrackingRawEvents: any = null;

  trackingTable: any = null;

  errorMessage: string = '';

  private logger:any;
  pageDetails: any[];
  pageDescription: string;

  constructor(private entityTrackingService: EntityTrackingService,
    private descriptionService: DescriptionService, 
    private masterDataService: MasterDataService,
    private http: HttpClient,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private msalService: MsalService,
    public dialog: MatDialog,
    private configService: ConfigService) {
    this.name = msalService.instance.getActiveAccount().name;
    this.logger = new log.AppInsightsLogger(configService, msalService);
  }

  ngOnInit() {
    window.sessionStorage.setItem("orgSelected", "1");
    this.showLoader = false;
    this.logger.trackTrace("Successfully landed to Order Tracking Page");

    this.descriptionService.pageDetailsTools().subscribe((response: any[]) => {
      this.pageDetails = response;
      this.pageDetails.forEach(element => {
        if (element.toolName == "Order Tracking") {
          this.pageDescription = element.toolDescription
        }
      });
    });
  }

  ngAfterViewInit() {
    this.getInputMappings();
  }

  onSubmitClick() {

    this.errorMessage = '';

    let entityType = this.entityType;
    let scenario = this.scenario;
    let entityIds = this.getEntityNumberArray();
    let trackingType = this.getTrackingType(scenario, entityType);

    if ((trackingType || "").trim().length <= 0) {
      this.errorMessage = "Tracking Type not mapped. Plese select appropriate Scenario and Order Type.";
      this.logger.trackTrace(`Order ${this.errorMessage}`);
      return;
    }

    this.router.navigate(['/entitytracking', {
      'scenario': this.scenario,
      'type': this.entityType,
      'ids': this.entityNumbers
    }]);

    this.loadEventsCore(trackingType, this.entityNumbers);
  }

  processActiveRoute() {

    let entityType = this.activatedRoute.snapshot.params['type'] || "";
    let scenario = this.activatedRoute.snapshot.params['scenario'] || "";
    let entityIds = this.activatedRoute.snapshot.params['ids'] || "";
    let trackingType = this.getTrackingType(scenario, entityType);

    this.scenario = scenario;
    this.entityType = entityType;
    this.entityNumbers = entityIds;

    if (trackingType.length <= 0) {
      return;
    }

    this.loadEventsCore(trackingType, entityIds);
  }

  loadEventsCore(trackingType: string, entityIds: string) {

    if (this.showLoader) {
      return; // means things are already being loaded, so don't do anything more.
    }

    if (!this.allTrackingRawEvents) {
      this.showLoader = true;

      /// !!!WARNING!!!
      /// TODO: We have to load all events (in raw format) for now only. In future, the underlying /TrackEntities API will be fixed
      ///       to always return all events in correct order. Then we won't need to load this config file.

      this.http.get('./assets/configuration/OrderStatusTrackingConfig.json', { responseType: 'text' })
        .subscribe((response: string) => {
          this.allTrackingRawEvents = JSON.parse(response);
          this.getEventsForEntities(trackingType, entityIds);
          this.logger.trackTrace(`In Order Tracking, all Tracking Raw Events`);
        },
          (error: any) => {
            this.errorMessage = 'Tracking Config could not be loaded';
            this.logger.trackTrace(`Order Tracking ${this.errorMessage}`);
            this.showLoader = false;
          });
    } else {
      this.getEventsForEntities(trackingType, entityIds);
    }
  }

  getInputMappings() {
    this.http.get('./assets/configuration/EntityTrackingMapping.json', { responseType: 'text' })
      .subscribe(
        (response: string) => {
          this.inputMapping = JSON.parse(response);
          this.logger.trackTrace(`Order Tracking input Mapping`);
          this.setUpInputsFromRoute();

          this.http.get('./assets/configuration/OrderStatusTrackingConfig.json', { responseType: 'text' })
            .subscribe((response: string) => {
              this.allTrackingRawEvents = JSON.parse(response);
              this.logger.trackTrace(`In Order Tracking, all Tracking Raw Events`);

              this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
                this.processActiveRoute();
              });
            },
              (error: any) => {
                this.errorMessage = 'Tracking Config could not be loaded';
                this.logger.trackTrace(`Order Tracking ${this.errorMessage}`);
                this.showLoader = false;
              });
        },
        (error: any) => {
          this.errorMessage = 'Tracking Map config could not be loaded.';
          this.logger.trackTrace(`Order Tracking ${this.errorMessage}`);
        });


  }

  setUpInputsFromRoute() {

    let _entityType = this.activatedRoute.snapshot.params['type'] || "";
    let _scenario = this.activatedRoute.snapshot.params['scenario'] || "";
    let _entityNums = this.activatedRoute.snapshot.params['ids'] || "";
    let simulateSubmit = true;  // if true at the end, then the submit button will be programatically clicked

    if (_entityType.length > 0) {
      this.entityType = _entityType;
    } else {
      simulateSubmit = false;
    }

    if (_scenario.length > 0) {
      this.scenario = _scenario;
    } else {
      simulateSubmit = false;
    }

    if (_entityNums.length > 0) {
      this.entityNumbers = _entityNums;
    } else {
      simulateSubmit = false;
    }

    if (simulateSubmit) {
      // simulate click on submit button, leading to validation and submission of form
      let event = new MouseEvent('click', { bubbles: true });
      event.stopPropagation();
      setTimeout(() => {
        this.formSubmitButton.nativeElement.dispatchEvent(event);
      }, 1000);
    }
  }

  /**
   * Gets events by chunks, kindly make sure total number chunks are compatible with backend
   * @param trackingType - Tracking Type
   * @param cleanedEntities - order ids array
   * @param chunkIdx - Index of the chunk to be fetched (0 based)
   * @param maxChunkIdx - max index of the chunk that can be fetched (0 based)
   */
  getEventsByChunks(trackingType: string, cleanedEntities: string[], chunkIdx: number, maxChunkIdx: number){

    if(chunkIdx > maxChunkIdx) {
      this.trackingTable = this.computeDisplayTableFromResponse(trackingType, this.rawResponseEventsPayload);
      this.showLoader = false;
      this.renderTrackingTable = true;
      return;
    }

    this.partialLoadingMessage = "Loading " + (chunkIdx+1) + " / " + (maxChunkIdx +1); 
    this.entityTrackingService.getEntityTrackingInChunks(trackingType, cleanedEntities, chunkIdx)
      .subscribe(
        (response: TrackingEntitiesResponse) => {
          let rawResponse: TrackingEntitiesResponse = this.rawResponseEventsPayload;

          for (let i = 0; i < cleanedEntities.length; ++i) {
            let entity = cleanedEntities[i];
            rawResponse[entity].events = (rawResponse[entity].events || []).concat(response[entity].events || []);
            rawResponse[entity].payload = rawResponse[entity].payload + (response[entity].payload || "");
          }

          this.rawResponseEventsPayload = rawResponse;

          this.getEventsByChunks(trackingType, cleanedEntities, chunkIdx + 1, maxChunkIdx);
        },
        (error: any) => {
          let errorMessage = `Order Tracking could not load Chunk ${chunkIdx}`
            + `, Entities ${cleanedEntities.join(",")}, Tracking Type ${trackingType}`
            + `, Raw Error message ${this.errorMessage}`;
          this.logger.trackTrace(errorMessage);

          for (let i = 0; i < cleanedEntities.length; ++i) {
            let entity = cleanedEntities[i];
            let genericErrorEvent: Event = {
              eventId: 1000,
              eventName: "Errors",
              eventGroup: "Errors",
              eventStatus: "Failure",
              eventMessage: "Some events could not be loaded.",
              correlationId: "",
              eventDate: "",
              eventTime: "",
              trackingId: "",
              trackingName: ""
            }
            this.rawResponseEventsPayload[entity].events = (this.rawResponseEventsPayload[entity].events || []).concat([genericErrorEvent] || []);
          }

          this.getEventsByChunks(trackingType, cleanedEntities, chunkIdx + 1, maxChunkIdx);
        }
      );
  }

  /**
   * Gets events by one order at a time
   * @param trackingType - Tracking type
   * @param cleanedEntities  - order ids array
   * @param entityIdx - index of the entity in the array to be loaded
   */
  getEventsByOrder(trackingType: string, cleanedEntities: string[], entityIdx: number) {
    let entitiesLength = cleanedEntities.length;

    if (entityIdx >= entitiesLength) {
      this.trackingTable = this.computeDisplayTableFromResponse(trackingType, this.rawResponseEventsPayload);
      this.showLoader = false;
      this.renderTrackingTable = true;
      return;
    }

    this.partialLoadingMessage = "Loading " + (entityIdx + 1) + " / " + (entitiesLength);

    this.entityTrackingService.getEntityTracking(trackingType, [cleanedEntities[entityIdx]])
      .subscribe(
        (response: TrackingEntitiesResponse) => {
          let rawResponse: TrackingEntitiesResponse = this.rawResponseEventsPayload;

          for (let i = 0; i < cleanedEntities.length; ++i) {
            let entity = cleanedEntities[i];
            let entityResponse = response[entity] || { events: [], payload: "" };
            let events = entityResponse.events || []; 
            let payload = entityResponse.payload || "";
            rawResponse[entity].events = (rawResponse[entity].events || []).concat(events);
            rawResponse[entity].payload = rawResponse[entity].payload + payload;
          }

          this.rawResponseEventsPayload = rawResponse;

          this.getEventsByOrder(trackingType, cleanedEntities, entityIdx + 1);
        },
        (error: any) => {
          let errorMessage = `Order Tracking could not load Chunk ${(entityIdx + 1)}`
            + `, Entities ${cleanedEntities[entityIdx]}, Tracking Type ${trackingType}`
            + `, Raw Error message ${this.errorMessage}`;
          this.logger.trackTrace(errorMessage);

          let entity = cleanedEntities[entityIdx];
          let genericErrorEvent: Event = {
            eventId: 1000,
            eventName: "Errors",
            eventGroup: "Errors",
            eventStatus: "Failure",
            eventMessage: "Some events could not be loaded.",
            correlationId: "",
            eventDate: "",
            eventTime: "",
            trackingId: "",
            trackingName: ""
          }
          this.rawResponseEventsPayload[entity].events = (this.rawResponseEventsPayload[entity].events || []).concat([genericErrorEvent] || []);

          this.getEventsByOrder(trackingType, cleanedEntities, entityIdx + 1);
        }
      );
  }

  getEventsForEntities(trackingType: string, entityNumbers: string) {
    this.showLoader = true;
    this.partialLoadingMessage = "";
    this.renderTrackingTable = false;

    if ((this.entityNumbers || "").trim().length == 0) {
      // if no entities present, then show raw table with events, without any data
      this.trackingTable = this.computeDisplayTableFromResponse(trackingType, {});
      this.rawResponseEventsPayload = {};
      this.showLoader = false;
      this.renderTrackingTable = true;

      return;
    }

    let entities = this.entityNumbers.split(',');
    let cleanedEntities = [];

    for (let i = 0; i < entities.length; ++i) {
      let element = (entities[i] || "").trim();
      if (element.length > 0) {
        cleanedEntities.push(element);
      }
    }

    if (["3PPPURCHASEORDER", "3PP_DROPSHIP"].indexOf(trackingType) > -1 ) {
      // For 3PP - chunk refers to a select set of events.
      // Here, all orders are passed to the API at once. Chunk Index controls which few events we want to load.
      // This allows us to not overload the API with long running queries.

      this.rawResponseEventsPayload = {};

      for(let i=0; i < cleanedEntities.length; ++i){
        this.rawResponseEventsPayload[cleanedEntities[i]] = { events: [], payload: "" };
      }

      // All orders are loaded at once, chunk index controls set of events to be fetched
      this.getEventsByChunks(trackingType, cleanedEntities, 0, 8); // for 3pp 9 chunks are supported
    } 
    else 
    {
      // Load all events at once, but do one order at a time. Here, chunk refers to a single order itself.
      // Here, we load all events but for one order at a time. This ensures that in UI, user is free to specify multiple orders.
      // However, when calling API, one order at a time ensures that we don't load a API too much.

      this.rawResponseEventsPayload = {};

      for (let i = 0; i < cleanedEntities.length; ++i) {
        this.rawResponseEventsPayload[cleanedEntities[i]] = { events: [], payload: "" };
      }

      this.getEventsByOrder(trackingType, cleanedEntities, 0);
    }
  }

  downloadResponseTable() {

    let tableJson = [];

    for (let i = 0; i < this.trackingTable.data.length; ++i) {
      let row = {};
      for (let j = 0; j < this.trackingTable.headers.length; ++j) {
        let responseTableCell = this.trackingTable.data[i][j];
        let cellText = responseTableCell.displayText || "";
        if (cellText.length == 0) {
          cellText = (responseTableCell.displayTimestamp || "") + "," + (responseTableCell.displayEntity || "");
          if (cellText == ",") {
            cellText = "";
          }
        }

        // if no text found so far, then check if the cell is of type array, then process as such
        if ((cellText || "").trim().length == 0 && Array.isArray(responseTableCell) && responseTableCell.length > 0) {
          for (let k = 0; k < responseTableCell.length; ++k) {
            let singleCellText = "";
            let singleCellData = responseTableCell[k];

            singleCellText = singleCellData.displayText || "";
            if (singleCellText.length == 0) {
              singleCellText = (singleCellData.displayTimestamp || "") + "," + (singleCellData.displayEntity || "");
              if (singleCellText == ",") {
                singleCellText = "";
              }
            }

            if ((singleCellText || "").trim().length > 0) {

              if (cellText.length > 0) {
                cellText = cellText + "\r\n"; // for cells containing multiple events, add line break
              }

              cellText = cellText + singleCellText;
            }
          }
        }

        row[this.trackingTable.headers[j]] = cellText;
      }
      tableJson.push(row);
    }

    let ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(tableJson);
    let wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Order Tracking Table');
    XLSX.writeFile(wb, "OrderTracking.xlsx");
  }

  computeDisplayTableFromResponse(trackingType: string, response: TrackingEntitiesResponse) {
    let colNames = []; // array col name 
    let colNamesDict = {}; // map col names => col index
    let colIndexes = {}; // map event Id => col index

    let rawEvents = this.allTrackingRawEvents[trackingType.toLocaleUpperCase()].EventMapping;

    for (var i = 0; i < rawEvents.length; ++i) {
      let evtName = rawEvents[i].EventGroup || rawEvents[i].EventName;
      let evtId = rawEvents[i].EventId;
      let targetIdx = -1;

      if (evtName in colNamesDict) {
        targetIdx = colNamesDict[evtName];
      }
      else {
        targetIdx = colNames.length;
        colNamesDict[evtName] = targetIdx;
        colNames.push(evtName);
      }

      colIndexes[evtId] = targetIdx;
    }


    let tableResponse: any = {
      headers: [],
      data: []
    }

    let headersToDisplay: string[] = ["Lookup Order Id"];
    colNames.map((colName) => {
      if (colName !== "Errors") {
        headersToDisplay.push(colName);
      }
    });
    tableResponse.headers = headersToDisplay;

    for (var entityId in response) {
      let data = [];
      data.push([{ displayText: entityId, containsData: true }]);
      colNames.map(_ => data.push([]));

      let viewHash = {};
      // double index which keeps track of which events are shown
      // format : viewHash[<event id>][<display entity, can be correlation id, or some combined text>] = true

      for (var i = 0; i < response[entityId].events.length; ++i) {
        let event = response[entityId].events[i];
        if (event.eventName !== "Errors") {

          if (colIndexes[event.eventId] == null) continue;

          let idx = 1 + colIndexes[event.eventId];
          let entityIdText = this.getEntityIdForView(trackingType, event);

          if ((event.eventId in viewHash) && viewHash[event.eventId][entityIdText]) {
            // found the same with older display text, don't show this
            continue;
          } else {
            if (!(event.eventId in viewHash)) {
              viewHash[event.eventId] = {};
            }
            viewHash[event.eventId][entityIdText] = true;
          }

          let timeStamp = "<TBD>";

          if (event != null &&
            ((event.eventDate || "").trim().length > 0 || (event.eventTime || "").trim().length > 0)) {
            timeStamp = this.getEventTime(event.eventDate, event.eventTime);
          }

          let addEvent = {
            displayTimestamp: "Event Time: " + timeStamp,
            isSuccess: (event.eventStatus || "").toLocaleUpperCase() == 'SUCCESS',
            displayEntity: entityIdText,
            containsData: true,
            ...event
          };

          data[idx].push(addEvent);

        } else {
          data[0] = [{
            displayText: entityId,
            containsData: true,
            hasError: true,
            ...event
          }];
        }
      }

      tableResponse.data.push(data);
    }

    return tableResponse;
  }

  getEntityIdForView(trackingType: string, event: Event) {

    let isCreatedEvent = event.eventName.match(/created/i);

    if (isCreatedEvent) {

      if (event.trackingName.length > 0 && event.trackingId.length > 0) {
        return event.trackingName + " : " + event.trackingId;
      }

      if (event.correlationId.length > 0) {
        return "Correlation ID: " + event.correlationId;
      }

      return "";
    }
    else {

      // for non created events, handle exceptions to display
      if (event.trackingName.length > 0 && event.trackingId.length > 0) {
        if ((trackingType == 'RETURNSERVICEORDER' && [2, 5, 6, 12].indexOf(event.eventId) > -1) ||
          (['DCOUTBOUND_SALESORDER', 'OUTBOUNDCUSTPURCHASEORDER', 'DCOUTBOUND_DELIVERYORDER'].indexOf(trackingType) > -1
            && [0].indexOf(event.eventId) > -1)) {
          return event.trackingName + " : " + event.trackingId;
        }

        if ((['ATO_DIRECTSHIP_CUSTPURCHASEORDER', 'ATO_DIRECTSHIP_SALESORDER',
          'ATO_DIRECTSHIP_CMPURCHASEORDER', 'MAKEPURCHASEORDER'].indexOf(trackingType) > -1
          && [6].indexOf(event.eventId) > -1)
        ) {
          return "Correlation ID: " + event.correlationId + " (Load ID: " + event.trackingId + ")";
        }

        if ((['ATO_DIRECTSHIP_CUSTPURCHASEORDER', 'ATO_DIRECTSHIP_SALESORDER',
          'ATO_DIRECTSHIP_CMPURCHASEORDER', 'MAKEPURCHASEORDER'].indexOf(trackingType) > -1
          && [3].indexOf(event.eventId) > -1 && (event.eventMessage || "").length > 0)
        ) {
          return "Correlation ID: " + event.correlationId + " (" + event.eventMessage + ")";
        }

        if ((['ATO_DIRECTSHIP_CUSTPURCHASEORDER', 'ATO_DIRECTSHIP_SALESORDER',
          'ATO_DIRECTSHIP_CMPURCHASEORDER', 'MAKEPURCHASEORDER'].indexOf(trackingType) > -1
          && [16, 18].indexOf(event.eventId) > -1)
        ) {
          return "Ack IDOC: " + event.trackingId + ((event.eventMessage || "").length > 0 ? " (" + event.eventMessage + ")" : "");
        }
      }

      if (event.correlationId.length > 0) {
        return "Correlation ID: " + event.correlationId;
      }
    }
    return "";
  }

  getEntityNumberArray() {
    var entityNumberArrayUntrimmed: string[];
    var entityNumberArrayTrimmed: string[];
    entityNumberArrayUntrimmed = this.entityNumbers.replace(/\r/g, '').split(',');
    entityNumberArrayTrimmed = [];
    entityNumberArrayUntrimmed.forEach(element => {
      if ((element || "").length > 0) {
        let cleanElement = element.trim() || "";
        if (cleanElement.length > 0) {
          entityNumberArrayTrimmed.push(element.trim());
        }
      }
    });

    return entityNumberArrayTrimmed;
  }

  getEventTime(eventDate: string, eventTime: string) {
    let localTime = '';
    if (eventTime != undefined && eventTime != null && eventTime != '') {
      const time = new Date(eventTime);
      localTime = time.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    }
    else if (eventDate != undefined && eventDate != null && eventDate != '') {
      const time = new Date(eventDate);
      localTime = time.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    }

    let dt = new Date(eventDate);
    let dateString = dt.toLocaleDateString();

    return dateString + " " + localTime;
  }

  showErrorInfoDialog(templateRef: any, evtData: any) {

    let dialogRef = this.dialog.open(templateRef, {
      width: '700px',
      data: {
        message: evtData.eventMessage,
        correlationId: "Correlation ID: " + evtData.correlationId
      }
    });
  }

  get getUniqueScenario() {
    let seenScenario: { [key: string]: Boolean } = {};
    let response: string[] = [];

    (this.inputMapping || []).map((item: EntityTrackingMap) => {
      if (!seenScenario[item.Scenario]) {
        seenScenario[item.Scenario] = true;
        response.push(item.Scenario);
      }
    });

    return response;
  }

  get getUniqueEntityTypesForCurrentScenario() {
    let currScenario: string = (this.scenario || "").trim();

    if (currScenario.length <= 0) {
      return ["-"].concat((this.inputMapping || []).map((item: EntityTrackingMap) => {
        return item.EntityType;
      }));
    } else {
      let seenType: { [key: string]: boolean } = {};
      let response: string[] = [];
      this.inputMapping
        .filter((item: EntityTrackingMap) => item.Scenario === currScenario)
        .map((item: EntityTrackingMap) => {
          if (!seenType[item.EntityType]) {
            seenType[item.EntityType] = true;
            response.push(item.EntityType);
          }
        });
      return ["-"].concat(response);
    }
  }

  getTrackingType(scenario: string, entityType: string) {
    scenario = (scenario || "").trim();
    entityType = (entityType || "").trim();

    if (scenario.length <= 0 || entityType.length <= 0) {
      return "";
    }

    for (let i = 0; i < this.inputMapping.length; ++i) {
      if (this.inputMapping[i].Scenario == scenario && this.inputMapping[i].EntityType == entityType) {
        return this.inputMapping[i].TrackingType;
      }
    }

    return "";
  }
}
