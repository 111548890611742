import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
@Injectable()
export class TestProjectService{
    constructor(private httpClient: HttpClient, private configService: ConfigService) {
    }
    getTestPlanInstances(testPlanId: any) {
        const baseUrlWithVersion = this.configService.omtestSuiteAPI.baseUrl + '/api/' + this.configService.omtestSuiteAPI.apiVersion;
        return this.httpClient.get<any[]>(baseUrlWithVersion + `/TestCaseProjectView/gettestplanInstances?TestPlanId=${testPlanId}`);
    }
    getProjectTestCaseView() {
        const baseUrlWithVersion = this.configService.omtestSuiteAPI.baseUrl + '/api/' + this.configService.omtestSuiteAPI.apiVersion;
        return this.httpClient.get<any>(baseUrlWithVersion + '/TestCaseProjectView/getprojecttestcaseview');
    }
}
