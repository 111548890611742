import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { UatAvailabilityService } from '../../../service/uatavailability.service';
import { MasterDataService } from '../../../service/masterdata.service';
import { MsalService } from '@azure/msal-angular'
import { ConfigService } from '../../../service/config.service';
import * as log from '../../../AppInsightsLogger';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ManageScenariosService } from '../../../service/manage-scenarios.service';
import { BehaviorSubject, Subscription } from 'rxjs';;
import { Status } from '../../status/status.component';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';


// NOTE: this eslint comment allows the exported enum to not trigger the no-shadow eslint error
// eslint-disable-next-line
export enum Priority {
  UNDETERMINED = 'UNDETERMINED',
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH'
}

export const PRIORITY_LABELS: Record<string, string> = {
  [Priority.UNDETERMINED]: 'Not Assigned',
  [Priority.LOW]: 'Low',
  [Priority.MEDIUM]: 'Medium',
  [Priority.HIGH]: 'High'
};

export const PRIORITY_LABEL = 'Priority';


export interface RowGroup<T> {
  key: string;
  label: string;
  startRowIndex: number;
  rows: Array<T>;
}

export interface DiffRowGroup<T> {
  key: string;
  label: string;
  startRowIndex: number;
  rows: Array<T>;
}

export class Comment {
  id: string;
  content?: string;
  author?: string;
  listOfTaggedUsers?: string[];
  lastUpdatedOn = new Date();
  parentType?: string;
  parentId?: string;
}

export class ControlRequestStatus {
  type: ControlRequestStatusTypes;
  message: string;

  constructor(type: ControlRequestStatusTypes, message: string = CONTROL_REQUEST_STATUS_MESSAGES[type]) {
      this.type = type;
      this.message = message;
  }

  get isError(): boolean {
      return this.type === ControlRequestStatusTypes.ERROR;
  }

  get isLoading(): boolean {
      return this.type === ControlRequestStatusTypes.LOADING;
  }

  get isSuccess(): boolean {
      return this.type === ControlRequestStatusTypes.SUCCESS;
  }

  static Error(message: string = CONTROL_REQUEST_STATUS_MESSAGES[ControlRequestStatusTypes.ERROR]): ControlRequestStatus {
      return new ControlRequestStatus(ControlRequestStatusTypes.ERROR, message);
  }

  static Loading(message: string = CONTROL_REQUEST_STATUS_MESSAGES[ControlRequestStatusTypes.LOADING]): ControlRequestStatus {
      return new ControlRequestStatus(ControlRequestStatusTypes.LOADING, message);
  }

  static Success(message: string = CONTROL_REQUEST_STATUS_MESSAGES[ControlRequestStatusTypes.SUCCESS]): ControlRequestStatus {
      return new ControlRequestStatus(ControlRequestStatusTypes.SUCCESS, message);
  }
}

// NOTE: this eslint comment allows the enum to not trigger the no-shadow eslint error
// eslint-disable-next-line
enum ControlRequestStatusTypes {
  ERROR,
  SUCCESS,
  LOADING
}

export const CONTROL_REQUEST_STATUS_CLASSES: Record<number, string> = {
  [ControlRequestStatusTypes.ERROR]: 'alert-danger',
  [ControlRequestStatusTypes.SUCCESS]: 'alert-success',
  [ControlRequestStatusTypes.LOADING]: 'alert-light'
};

export const CONTROL_REQUEST_STATUS_MESSAGES: Record<number, string> = {
  [ControlRequestStatusTypes.ERROR]: 'Error!',
  [ControlRequestStatusTypes.SUCCESS]: 'Success!',
  [ControlRequestStatusTypes.LOADING]: 'Loading, please wait...'
};

export const CONTROL_REQUEST_STATUS_ICONS: Record<number, string> = {
  [ControlRequestStatusTypes.ERROR]: 'ms-Icon--ErrorBadge ms-fontColor-sharedRed20',
  [ControlRequestStatusTypes.SUCCESS]: 'ms-Icon--Completed ms-fontColor-sharedGreen20',
  [ControlRequestStatusTypes.LOADING]: 'spinner-border'
};


// import { Comment } from './comment';
// import { Priority } from './priority';
// import { Status } from './status';

export class BaseTask {
    id: string;
    label?: string;
    content?: string;
    capabilityType?: string;
    taskDefinitionId?: string;
    startedOn?: Date;
    completeBy?: Date;
    assignee?: string;
    priority?: Priority;
    collaborators?: string[];
    status?: Status;
    taskGroup?: { id: string };

    constructor(task: BaseTask) {
        this.id = task.id;
        this.label = task.label;
        this.content = task.content;
        this.capabilityType = task.capabilityType;
        this.taskDefinitionId = task.taskDefinitionId;
        this.startedOn = task.startedOn;
        this.completeBy = task.completeBy;
        this.assignee = task.assignee;
        this.priority = task.priority;
        this.collaborators = task.collaborators ? [...task.collaborators] : null;
        this.status = task.status;
        this.taskGroup = task.taskGroup ? { id: task.taskGroup.id } : null;
    }
}

export class Task extends BaseTask {
    comments?: Comment[];
    project?: { id: string; label?: string, coordinator?: string, isPractice?: boolean, type?: string };
    difference?: string;
    reasonForChange?: string;

    constructor(task: Task) {
        super(task);
        this.difference = task.difference;
        this.reasonForChange = task.reasonForChange;
        this.comments = task.comments ? [... task.comments] : null;
        this.project = task.project ?
            {
                id: task.project.id,
                label: task.project.label,
                coordinator: task.project.coordinator,
                isPractice: task.project.isPractice
            } : null;
    }
}

export class TaskRequest extends BaseTask {
    comments: { id: string }[];
    project: { id: string };

    constructor(task: Task) {
        super(task);

        this.project = task.project ? { id: task.project.id } : null;
        this.comments = task.comments.map(c => ({ id: c.id } as { id: string }));
    }
}



// import { Status } from './status';
// import { Task } from './task';

export class BaseTaskGroup {
    id: string;
    label?: string;
    status?: Status;
    taskGroupDefinitionId?: string;
    project?: { id: string };

    constructor(taskGroup: BaseTaskGroup) {
        this.id = taskGroup.id;
        this.label = taskGroup.label;
        this.status = taskGroup.status;
        this.taskGroupDefinitionId = taskGroup.taskGroupDefinitionId;
        this.project = taskGroup.project ? { id: taskGroup.project.id } : null;
    }
}

export class TaskGroup extends BaseTaskGroup {
    tasks: Task[];
    difference?: string;
    reasonForChange?: string;

    constructor(taskGroup: TaskGroup) {
        super(taskGroup);
        this.difference = taskGroup.difference;
        this.reasonForChange = taskGroup.reasonForChange;
        this.tasks = new Array<Task>();
        
        //if(taskGroup.tasks !)
        taskGroup.tasks.forEach(task => { 
          this.tasks.push(new Task(task))
        });
    }
}

export class TaskGroupRequest extends BaseTaskGroup {
    tasks: { id: string }[];

    constructor(taskGroup: TaskGroup) {
        super(taskGroup);

        this.tasks = taskGroup.tasks.map(t => ({ id: t.id } as { id: string }));
    }
}

type CheckableRow = {
  isHovered: boolean;
  checked: boolean;
};

class RowGroupTaskGroup extends TaskGroup implements RowGroup<Task & CheckableRow>, CheckableRow {
    key: string;
    label: string;
    startRowIndex: number;
    rows: (Task & CheckableRow)[];

    isHovered: boolean;
    checked: boolean;
}

@Component({
  selector: 'app-simple-grid',
  templateUrl: './simple-grid.component.html',
  styleUrls: ['./simple-grid.component.css']
})
export class SimpleGridComponent implements OnInit {

  @Input() testPlanName = "";
  @Input() organizationId:number;
  @Input() column_schema: any = [];
  @Input() table_data: any[]= [];
  @Input() useLevels: boolean = false;
  @Input() useCheckBoxForSelection: boolean = true;
  @Input() alwaysShowCheckBox: boolean = false;
  @Input() validationStatus:string;
  @Input() detailLink: Map<string, any>;
  @Output() onSelectionChanged = new EventEmitter<any[]>();
  @Output() onFileSelected  = new EventEmitter<File>();
  @Output() onDownloadSku = new EventEmitter<string>();
  @Output() onDownloadSampleInput  = new EventEmitter<number>();
  @Output() onParameterChanged = new EventEmitter<any[]>();
  @Output() onCustomEvent  = new EventEmitter<any>();
  gridId = 'projectTasksList';
  @Output() testCase = new EventEmitter <any[]>();

  rowGroups: Array<any> = [];
  totalRows = 0;
  originalTasks: Array<Task> = [];

  getTasksStatus$ = new BehaviorSubject<ControlRequestStatus>(null);
  updateRowStatuses$: Record<string, BehaviorSubject<ControlRequestStatus>> = {};
  createTaskGroupStatus$ = new BehaviorSubject<ControlRequestStatus>(null);
  taskChangeSubscription: Subscription;
  isGroupCollapsedMap: Record<string, boolean> = {};
  isCollapsedAll = false;
  isFocusedOnTaskGroupLabel = false;

  allSelected = false; // whether all rows are selected
  isGridHeaderHovered = false;  // is mouse hovered over the grid header
  isMultiSelectMode = false; // true if any task is selected
  selectedTasks: Map<string, Task> = new Map();



  uatDetails: any[];
  businessProcess: any[];
  showMessage: boolean;
  showPreloader: boolean;
  msgTxt: string;
  pageDescription: string;
  pageDetails: any[];
  tooltipUAT: any[];
  uatDetailsRows: any[];
  businessProcessNamesDetails: any;
  uatRowNames: any;
  private logger: log.AppInsightsLogger;
  selectedBusinessProcessName;
  

  constructor(private ngxLoader: NgxUiLoaderService,
    private msalService: MsalService,
    private configService: ConfigService,
    private uatDataService: UatAvailabilityService,
    private masterDataService: MasterDataService, 
    private httpClient:HttpClient,
    private manageTestRunsService: ManageScenariosService,
    private router: Router) {
    this.logger = new log.AppInsightsLogger(configService, msalService);
    this.gridId = "grid-" + Math.floor((Math.random()*6)+1);
  }

  updateTaskGroup(group: TaskGroup): void { }

  get displayColumns() {
    return this.column_schema.filter(column => column.class.length);
  }

  get hasFiltersApplied(): boolean {
    return false; //this.filterService.hasFilters(this.gridId);
  }

  get fxnRowGroups(): any {
    return this.rowGroups;
  }

  displayCheckbox(row: CheckableRow) {
    return row.checked || row.isHovered || this.alwaysShowCheckBox;
  }

  setAllCheckboxes(isChecked: boolean) {
    this.allSelected = isChecked;
    this.rowGroups.forEach(rowGroup => {
      if(rowGroup.status == Status.NOT_STARTED) {
        rowGroup.checked = this.allSelected;
        rowGroup.rows.forEach(row => {
          this.setCheckboxValue(row, this.allSelected);
        });
      }
      
    });

    if(this.onSelectionChanged != null){
      let checkedRows = [];

      for(let i=0;i<this.rowGroups.length; ++i){
        if(this.rowGroups[i].checked){
          checkedRows.push(this.rowGroups[i]);
        }
      }

      this.onSelectionChanged.emit(checkedRows);
    }
  }

  toggleTaskGroupCheckbox(taskGroupIndex: number) {
    const taskGroup = this.rowGroups[taskGroupIndex];

    taskGroup.checked = !taskGroup.checked;

    if(this.onSelectionChanged != null){
      let checkedRows = [];

      for(let i=0;i<this.rowGroups.length; ++i){
        if(this.rowGroups[i].checked){
          checkedRows.push(this.rowGroups[i]);
        }
      }

      this.onSelectionChanged.emit(checkedRows);
    }

    taskGroup.rows.forEach(row => {
      this.setCheckboxValue(row, taskGroup.checked);
    });

    //this.recalcAllCheckboxValue();
  }

  toggleTaskCheckbox(taskGroupIndex: number, taskIndex: number) {
    const task = this.rowGroups[taskGroupIndex].rows[taskIndex];

    this.setCheckboxValue(task, !task.checked);

    //this.recalcTaskGroupCheckboxValue(taskGroupIndex);
  }

  setCheckboxValue(row: (Task & CheckableRow), checked: boolean) {
    if (row.checked === checked)
      return;

    row.checked = checked;
    if (checked) {
      this.selectedTasks.set(row.id, row);
    }
    else {
      this.selectedTasks.delete(row.id);
    }

    this.isMultiSelectMode = this.selectedTasks.size > 0;
  }

  selectFile(event: any){
    if (event.target.files.length > 0) {
      const uploadedFile: File = event.target.files[0];
      this.onFileSelected.emit(uploadedFile);
    }
    
  }

  downloadSkus(stepName:string){
    this.onDownloadSku.emit(stepName);
  }

  downloadSampleInput(testPlanPreStepId:number){
    this.onDownloadSampleInput.emit(testPlanPreStepId);
  }

  taskDrop(event: any): void { }

  ngOnInit() {
    //setTimeout(() => { this.loadUat(); }, 500);
    //this.loadUat();
    this.updateDisplay();
    // this.project.taskGroups.forEach(taskGroup => {
    //   if (!this.updateRowStatuses$[taskGroup.id]) {
    //     this.updateRowStatuses$[taskGroup.id] = new BehaviorSubject<ControlRequestStatus>(null);
    //   }
    //   taskGroup.tasks.forEach(task => {
    //     if (!this.updateRowStatuses$[task.id]) {
    //       this.updateRowStatuses$[task.id] = new BehaviorSubject<ControlRequestStatus>(null);
    //     }
    //   });
    // });
  }

  ngOnChanges(){
     this.updateDisplay();
  }

  timeOut(){
    setTimeout(()=>{
        this.timeOut();
    },
    5000);
  }

  
  updateDisplay(): void {

    this.rowGroups = [];
    let nextRowIndex = 1;
    //this.timeOut();

    for(let i=0; i < this.table_data.length; ++i) {
        let taskGroup = this.table_data[i];

        let newGroup = {
          // original
          id: taskGroup.id,
          label: taskGroup.label,
          status: taskGroup.status,
          project: taskGroup.project,
          tasks: taskGroup.tasks || [],
          // used for grid rendering
          key: taskGroup.id,
          startRowIndex: nextRowIndex,
          rows: taskGroup.tasks || []
        } as RowGroupTaskGroup;

        let newGroup2 = {
            ...this.table_data[i],
            id: taskGroup.id,
          label: taskGroup.label,
          status: taskGroup.status,
          project: taskGroup.project,
          tasks: taskGroup.tasks || [],
          // used for grid rendering
          key: taskGroup.id,
          startRowIndex: nextRowIndex,
          rows: taskGroup.tasks || []
        }

        this.rowGroups.push(newGroup2);

        const numTasks = !!taskGroup.tasks ? taskGroup.tasks.length : 0;
        nextRowIndex = nextRowIndex + numTasks + 1;
    }
 
  }

  isGroupCollapsed(id: string): boolean {
    return false;
    // if (this.isGroupCollapsedMap[id] === undefined) {
    //   this.isGroupCollapsedMap[id] = this.isCollapsedAll;
    // }
    // return this.isGroupCollapsedMap[id];
  }

  rowsChanged(event: any): void {

  }

  toggleCollapseAll(): void {
    this.isCollapsedAll = !this.isCollapsedAll;
    Object.keys(this.isGroupCollapsedMap).forEach(key => {
      this.isGroupCollapsedMap[key] = this.isCollapsedAll;
    });
  }

  multiEdit(){

  }

  deleteTaskGroup(group: any){

  }

  deleteTask(row: any){

  }

  updateTask(row: any, column: string)
  {
    var index = this.table_data.findIndex(x => x.correlationId == row.correlationId);
    this.table_data[index][column] = row[column];
    this.testCase.emit(this.table_data);
  }



   onCustomEventClicked(group:any){
        
   }

  onParameterChange(event: any, group:any){
    const inputParameters:string = event;
    this.onParameterChanged.emit([group.correlationId, inputParameters]);
  }

  onResponseResultFetched(event:any[], group:any){
    const overallStatus  = event[0]

  }

  getUrlParams(group){
    if(group.correlationId != null && group.correlationId != undefined){
      try {
        //open link in a new tab it it's a test run 
        const link = this.router.serializeUrl(this.router.createUrlTree([
          "/",
          "manage-testruns",
          {
            org: this.organizationId.toString(),
            searchId: group.correlationId,
          },
        ]));

        window.open(link, '_blank');
        
      } catch (e) {
        console.log(e);
      } 
    }
    else{
      this.router.navigate(['/', 'project-view',{
        'org': this.organizationId.toString(),
        'projectId': group.projectID
      }]);
    }
   
  }

}
