import { UATAvailability } from '../model/uatAvailability.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';

@Injectable()
export class TestDataService {

    constructor(private httpClient: HttpClient, private configService: ConfigService) {
    }

    //getTestRuns(testRunRequest: any) {
    //    const baseUrlWithVersion = this.configService.omtestSuiteAPI.baseUrl + '/api/' + this.configService.omtestSuiteAPI.apiVersion;
    //    return this.httpClient.post<TestRunList>(baseUrlWithVersion + '/TestRun/list', testRunRequest);
    //}

    //createNewTestRun(newTestRunRequest: any) {
    //    const baseUrlWithVersion = this.configService.omtestSuiteAPI.baseUrl + '/api/' + this.configService.omtestSuiteAPI.apiVersion;
    //    return this.httpClient.post<string>(baseUrlWithVersion + '/TestRun', newTestRunRequest);
    //}

}
