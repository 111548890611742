import { Component, Directive, forwardRef, Inject, Input, OnInit } from '@angular/core';
import {MsalCustomNavigationClient, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG} from '@azure/msal-angular';
import { MsalBroadcastService } from '@azure/msal-angular';
import { ConfigService } from './service/config.service';
import { Validator, AbstractControl, NG_VALIDATORS, Validators, ValidatorFn } from '@angular/forms';
import { Constants } from './service/constant.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from './service/auth.service';
import { ManageScenariosService } from './service/manage-scenarios.service';
import { Location } from "@angular/common";
import { InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'SCE Test Platform';
  sub: any;
  environment: string;
  isNavCollapsed: boolean;
  isIframe = false;
  private readonly _destroying$ = new Subject<void>();
  loginDisplay = false;

  ngOnInit() {
    this.isIframe = window !== window.parent && !window.opener;
    //this.loginRedirect();

    this.msalBroadcastService.inProgress$
    .pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None),
      takeUntil(this._destroying$)
    )
    .subscribe(() => {
      this.checkAndSetActiveAccount();
    })

    this.sub = this.route.queryParams.subscribe(params => {
      this.environment = params['env'];
      if (this.environment == Constants.EnvironmentProd) {
        this.configService.switchEnvironment(Constants.EnvironmentProd)
      } else if (this.environment == Constants.EnvironmentUAT) {
        this.configService.switchEnvironment(Constants.EnvironmentUAT);
      } else if (this.environment == Constants.EnvironmentCI) {
        this.configService.switchEnvironment(Constants.EnvironmentCI);
      }
    });
    this.checkAndSetActiveAccount();
    this.isNavCollapsed = false;
  }

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private configService: ConfigService,
    private route: ActivatedRoute,
    private authService: AuthService,
    public router: Router, 
    private location: Location,
    public manageScenarioService: ManageScenariosService) {

   // custom navigation set for client-side navigation
    const customNavigationClient = new MsalCustomNavigationClient(msalService, this.router, this.location);
    this.msalService.instance.setNavigationClient(customNavigationClient);
    this.configService.switchEnvironment(Constants.EnvironmentUAT);
    this.authService.getTokenFromService(this.configService.selectedEnvironment).then((res) => {
      this.configService.omtestSuiteAPI.accessToken = res.accessToken;
    }, (err) => {
      throw err;
    });
  }

  checkAndSetActiveAccount(){
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.msalService.instance.getActiveAccount();
    setTimeout(()=>{},200000000);
    if (!activeAccount && this.msalService.instance.getAllAccounts().length > 0) {
      let accounts = this.msalService.instance.getAllAccounts();
      this.msalService.instance.setActiveAccount(accounts[0]);
    }
    
  }

  receiveNavCollapseMessage($event: boolean) {
    this.isNavCollapsed = $event;
  }


  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
  }

}
@Directive({
  selector: '[min][formControlName],[min][formControl],[min][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => MinDirective),
      multi: true
    }
  ]
})
export class MinDirective implements Validator {
  private _validator: ValidatorFn;
  @Input() public set min(value: string) {
    this._validator = Validators.min(parseInt(value, 10));
  }

  public validate(control: AbstractControl): { [key: string]: any } {
    return this._validator(control);
  }
}

@Directive({
  selector: '[max][formControlName],[max][formControl],[max][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => MaxDirective),
      multi: true
    }
  ]
})
export class MaxDirective implements Validator {
  private _validator: ValidatorFn;
  @Input() public set max(value: string) {
    this._validator = Validators.max(parseInt(value, 10));
  }

  public validate(control: AbstractControl): { [key: string]: any } {
    return this._validator(control);
  }


}
