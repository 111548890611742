<p>
  <button (click)="viewCollapseClicked =!viewCollapseClicked" class="btn btn-primary" type="button" data-toggle="collapse" data-target="#collapseList" aria-expanded="false" aria-controls="collapseList">
    <span *ngIf="!viewCollapseClicked; else elseBlock"> View </span>
    <ng-template #elseBlock>Collapse</ng-template>
    CM Suppliers
  </button>
</p>
<div class="collapse" id="collapseList" >
  <div class="card card-body bg-primary">
    <div *ngIf ="isEmpty(to.options)">
     <div class="alert alert-danger" role="alert">
       There is no supplier master data available for the specified data combinations. 
     </div>
    </div>
    <div *ngIf ="!isEmpty(to.options)" >
      <table class="table table-hover">
        <thead class="thead-dark table-striped">
          <tr>
            <th scope="col">#</th>
            <th scope="col"> Supplier Code </th>
            <th scope="col"> Supplier Name </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let supplier of to.options;let i = index">
            <th scope="row">{{i + 1}}</th>
            <td> <span>{{supplier.SupplierCode}}</span></td>
            <td> <span>{{supplier.SupplierName}}</span> </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
