
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Organization } from 'src/app/model/org-view.model';

@Component({
    selector: 'app-org-card ',
    templateUrl: './org-card.component.html',
    styleUrls: ['./org-card.component.css']
  })
  export class OrgCardComponent implements OnInit{
  
    @Input () organizations = [];

  constructor(private router: Router){
  }

  ngOnInit(): void {
    this.getInitials();
      
  }
  
  setOrg(org){
      window.sessionStorage.removeItem("inOrgLanding");
      window.sessionStorage.setItem("orgSelected", org.organizationId.toString());
      window.sessionStorage.setItem("orgName",org.organizationName);
      this.router.navigate(['/landing-page', this.getUrlParams(org)]);
  }

  getInitials(){
    for(const org of this.organizations) {
      let initials:string = "";
      org.organizationName.split(" ").forEach(element => {
          const initial  = element.charAt(0);
          if((/[a-zA-Z]/).test(initial)){
              initials = initials.concat(element.charAt(0));
          }   
      });
      org.initials  = initials;
    }  
  }

  getUrlParams(org:Organization){
    return {
      'org': org.organizationId
    };
  }

}
