export class GoodReceiptMockRequest {
  constructor() {
    this.PlantCode = '';
    this.MessageType = MessageTypeEnum.Forward;
    this.SerializedFlag = SerializedFlagEnum.No;
  }
  public SenderId: string 
  public LoadId: string
  public PlantCode: string
  public MessageType: MessageTypeEnum
  public SerializedFlag: SerializedFlagEnum
  public CarrierTrackingNumber: string
  public COO: string
  public Orders: Orders[]
  public IsSTOorder: boolean;
  public Posttoapi: boolean;
}

export class Orders {
  constructor() {
    this.OrderLineNumber = '';
    this.Sku = '';
    this.ReceiptTransactionId = '';
    this.Quantity = 1;
    this.StorageLocationValue = '';
    this.StockStatus = '';
  }
  public OrderNumber: string
  public OrderLineNumber: string
  public Sku: string
  public ReceiptTransactionId: string
  public Quantity: number
  public StorageLocationValue: string
  public StockStatus: string
  public PrecedingOrderLineNumber: string
  public PrecedingOrderNumber: string
}


export enum MessageTypeEnum {
  Forward = "Forward",
  Return = "Return",
  WTPForwardRSD = "WTPForwardRSD",
  WTPForwardDC = "WTPForwardDC",
  WTPReverseDCRSD = "WTPReverseDCRSD",
  WTPReverseSIRSD = "WTPReverseSIRSD",
  WTPReverseT2RSD = "WTPReverseT2RSD",
  WTPDCGOODSISSUE = "WTPDCGOODSISSUE"


}
export enum SerializedFlagEnum {
  Yes = "Yes",
  No = "No",

}


