<!-- <link href=”jsoneditor/dist/jsoneditor.min.css” rel=”stylesheet” type=”text/css”>
 <script src=”jsoneditor/dist/jsoneditor.min.js”></script> -->


<div class="d-inline-flex">
    <a type="button" class="ms-Icon ms-Icon--DoubleColumnEdit" (click)="onEditClicked()" 
    aria-label="Edit Parameters" title="Edit Parameters">
    
    </a>
</div>

<div class="ms-Fabric">
    <ng-template #jsonModal let-modal class="modal-xl">
        <div class="modal-header p-2">
            <div class="d-flex justify-content-between w-100">
                <h4 id="jsonModalHeader" class="p-2 modal-title ms-fontSize-20 ms-fontWeight-bold">
                    Edit Parameters
                </h4>
                <button type="button" title="Close" class="close-button iconButton mr-2" (click)="modal.close('CrossClick')">
                    <i class="ms-Icon ms-Icon--ChromeClose" aria-hidden="true"></i>
                </button>
            </div>
        </div>

        <!--<div class="modal-body" #element>
            <json-editor [options]="editorOptions" [data]="jsonObject" (jsonChange)="handleTextChange($event)" [debug]="true" #editor></json-editor>
        </div>-->

        <div class="modal-body" #element>
          <formly-form [fields]="formFields" [model]="formModel">
          </formly-form>
        </div>

        <div class="modal-footer">
            <button type="button" class="ms-Button" (click)="submitJSON($event)" title="Submit Changes">Submit Changes</button>
        </div>
    </ng-template>
</div>
