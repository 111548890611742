<div style="width: 100%; height: calc(100vh - 46px); ">
  <h3>Order Tracking</h3>
  <br>
  <div class="form-group col-sm-10" style="margin-bottom: 25px; border: 1px solid rgba(0,0,0,.125); border-radius: .25rem;">
    <b>Description:</b>
    <p>{{this.pageDescription}}</p>
  </div>

  <form class="form-horizontal" (ngSubmit)="onSubmitClick()" #entityTracking="ngForm">
    <div class="form-group">
      <label class="control-label col-sm-2" for="entity-scenario">Business Process (L4)</label>
      <div class="col-sm-8">
        <select aria-label="entity-scenario"  class="form-control" id="entity-scenario" name="entityTrackingScenario" [(ngModel)]="scenario" required minlength="2" #entityTrackingScenarioField="ngModel">
          <ng-container *ngFor="let scenarioName of getUniqueScenario; let i = index;">
            <option [value]="scenarioName">{{scenarioName}}</option>
          </ng-container>
        </select>
        <div [hidden]="entityTrackingScenarioField.valid || entityTrackingScenarioField.pristine" class="alert alert-danger">Business Process (L4) is required.</div>
      </div>
    </div>

    <div class="form-group">
      <label class="control-label col-sm-2" for="entity-type">Order Type</label>
      <div class="col-sm-8">
        <select aria-label="entity-type" class="form-control" id="entity-type" name="entityTrackingType" [(ngModel)]="entityType" required minlength="2" #entityTrackingTypeField="ngModel">
          <ng-container *ngFor="let entityTypeName of getUniqueEntityTypesForCurrentScenario">
            <option [value]="entityTypeName">{{entityTypeName}}</option>
          </ng-container>
        </select>
        <div [hidden]="entityTrackingTypeField.valid || entityTrackingTypeField.pristine" class="alert alert-danger">Entity Type is required.</div>
      </div>
    </div>

    <div class="form-group">
      <ng-template #entityIdsDescription>
        Order Ids to be tracked.<br />
        <br />
        More Order Ids can be added (separated by ',') to get tracking information for these new orders as well. Please make sure that the new orders are from the same workflow (Order Type and Scenario).
      </ng-template>
      <label class="control-label col-sm-4" for="entityIds">

        <button aria-label="entityIds" type="button" id="entityIds-tool-tip" class="tool-tip-button" style="margin-left: 0px;" [ngbTooltip]="entityIdsDescription" tooltipClass="entityIdsWideTooltip" placement="right">
          <span style="margin-right:10px">Order ID(s)</span><i class="fa fa-question-circle text-center tool-tip-icon"></i>
        </button>
      </label>
      <div class="col-sm-8">
        <input aria-required="false" type="text" class="form-control" autocomplete="off" spellcheck="false"
               placeholder="Enter Order ids here. Multiple order ids can be specified, separated by ','" id="entityNumbers" name="entityNumbers"
               [(ngModel)]="entityNumbers" #entityNumbersField="ngModel" />
      </div>
      <div class="col-sm-8">
        <ngb-alert aria-label="OrderIdInfo" focusable="true" role="alert" type="info" [dismissible]="false" tabindex="0">
          Maximum of 5 Orders recommended.
        </ngb-alert>
      </div>
    </div>

    <div class="form-group">
      <div class="col-sm-offset-2 col-sm-8">
        <div class="input-group">
          <button type="submit" class="btn btn-primary"
                  [disabled]="!entityTracking.form.valid || showLoader" #entityTrackingFormSubmitButton>
            Preview / Submit
          </button>
          <div *ngIf="showLoader">
            <app-preloader-small ></app-preloader-small>
            <div *ngIf="partialLoadingMessage.length > 0" style="padding: 5px 0 0 40px;">{{partialLoadingMessage}}</div>
          </div>
        </div>
      </div>
    </div>
  </form>

  <div id="entity-tracking-response-container">
    <div *ngIf="showLoader">Loading ...</div>
    <div *ngIf="renderTrackingTable && (trackingTable.data || []).length > 0">
      <button aria-disabled="false" type="button" class="btn btn-primary" (click)="downloadResponseTable()">
        <i class="fa fa-download" aria-hidden="true" placement="left"
           ngbTooltip="Download Details" tooltipClass="side-tooltip"></i>
        Download
      </button>
    </div>
    <div *ngIf="renderTrackingTable" class="table-responsive entity-tracking-table">
      <table class="table table-sm table-striped table-bordered">
        <thead>
          <tr>
            <th scope="col" *ngFor="let column of trackingTable.headers;">{{column}}</th>
          </tr>
        </thead>
        <tbody>
          <tr role="row" *ngFor="let row of trackingTable.data">
            <ng-container *ngFor="let evtData of row; let i = index;">
              <th role="columnheader" *ngIf="i == 0" scope="col">
                {{evtData[0].displayText}}

                <button *ngIf="evtData[0].hasError"
                        type="button" id="entityIds-tool-tip" class="tool-tip-button"
                        style="margin-left: 0px; padding-left: 5px; border:0px;"
                        [ngbTooltip]="evtData[0].eventMessage" tooltipClass="entityIdsWideTooltip" placement="right">
                  <i class="fa fa-exclamation-circle text-center tool-tip-icon"></i>
                </button>
              </th>
              <td *ngIf="i > 0">
                <ng-container *ngFor="let singleEvent of evtData">
                  <div *ngIf="singleEvent.isSuccess" class="entity-tracking-table--cell entity-tracking-table--cell__success">
                    <span>{{singleEvent.displayTimestamp}}</span> <br />
                    <span>{{singleEvent.displayEntity}}</span>
                  </div>
                  <div *ngIf="!singleEvent.isSuccess" class="entity-tracking-table--cell entity-tracking-table--cell__failed alert-danger" (click)="showErrorInfoDialog(errorInfoDialogTemplate,singleEvent)">
                    <span>{{singleEvent.displayTimestamp}}</span> <br />
                    <span>{{singleEvent.displayEntity}}</span>
                  </div>
                </ng-container>
              </td>
            </ng-container>
            <ng-template #errorInfoDialogTemplate let-data>
              <button type="button" class="close" aria-label="Close" title="Close" mat-dialog-close="true" role="button">
                <span aria-hidden="true">&times;</span>
              </button>
              <h4>Error Message</h4>
              <div>{{data.message}}</div>
              <div>{{data.correlationId}}</div>
            </ng-template>
          </tr>
        </tbody>
      </table>
    </div>
    <div *ngIf="errorMessage.length > 0" class="alert alert-danger" role="alert">
      {{errorMessage}}
    </div>
  </div>
</div>
