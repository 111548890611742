import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GoodsreceiptmockerService {

  constructor() { }

  getValidJSONObject(object: string) {
    object = object.replace('{""', '{"').replace(',""', ',"');
    if (object == '') {
      return object;
    } else {
      if (/^[\],:{}\s]*$/.test(object.replace(/\\["\\\/bfnrtu]/g, '@').replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').replace(/(?:^|:|,)(?:\s*\[)+/g, ''))) {
        return JSON.parse(object);
      } else if (object.charAt(0).toLowerCase() != '[' && object.charAt(0).toLowerCase() != '{') {
        return { object };
      } else {
        try {
          return JSON.parse(object);
        } catch {
          return { object };
        }
      }
    }
  }
}
