<ng-template #hwpnLookupModal class>
    <div id="hwpnLookupModal">
        <div class="modal-header modal-xl">
            <h5 class="modal-title" id="modal-basic-title" style="width: 100% !important;">
                <span>Select a Hardware Part Number for <strong>{{this.partNumber}}</strong></span>
                <button type="button" class="close" aria-label="Close" (click)="this.dismissModal()"
                    role="button">
                    <span aria-hidden="true">&times;</span>
                </button>
            </h5>
            <h6 class="modal-title">SKU Description:
                <strong>{{this.partNumberDetail.properties.partDescription}}</strong></h6>
        </div>
        <div class="modal-body modal-xl">
            <table class="table table-hover hwpn-lookup">
                <thead>
                    <th>Hardware Part Number</th>
                    <th>Part Type</th>
                    <th>Description</th>
                </thead>
                <div class="margin-bottom-2"></div>
                <tbody>
                    <tr class="cursor-pointer" *ngFor="let part of this.partNumberDetail.childPartDetails"
                        (click)="onSelect(part.properties.partNumber)"
                        (keyup)="onKeyup($event, part.properties.partNumber)" tabindex="0">
                        <td>{{part.properties.partNumber}}</td>
                        <td>
                            <span
                                *ngIf="part.properties.partType.indexOf('*') == -1; else displaySubstitute">{{part.properties.partType}}</span>
                            <ng-template #displaySubstitute>
                                <span><strong>{{part.properties.partType.substr(0, 1)}}&nbsp;</strong>{{part.properties.partType.substr(1)}}</span>
                            </ng-template>
                        </td>
                        <td>{{part.properties.partDescription}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="modal-footer modal-xl" style="justify-content: flex-start;">
            <h6 style="padding-left: 1em; padding-right: 1em;">
                <strong style="color: #003A70;">*&nbsp;</strong>This Hardware Part is a <i>Substitute Part</i>.
                Substitute Parts are used when
                primary parts are not available to the factory during manufacturing.
            </h6>
        </div>
    </div>
</ng-template>
<ng-template #hwpnLookupFailureModal>
    <div id="hwpnLookupFailureModal">
        <div class="modal-header">
            <h5 class="modal-title" id="modal-basic-title">
                <span class="font-weight-bold" style="color: #DB4949;">
                    <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                    Oops!
                </span>
                <button type="button" class="close" aria-label="Close" (click)="this.dismissModal()"
                    role="button">
                    <span aria-hidden="true">&times;</span>
                </button>
            </h5>
        </div>
        <div class="modal-body">
          <div>
            <p style="color: #0056b3">
              An error has occurred, kindly reach out to <b>OnePDMSupport@microsoft.com</b>
              for help.
            </p>
            <strong><p style="color: black;">Error Details below:</p></strong>
            <div class="alert alert-danger alert-dismissible fade show">
              <strong style="color: #8b0000;word-break: break-word;">
                {{this.httpErrorMessage}}
              </strong>
            </div>
            <div *ngIf="this.httpErrorCode == 404">
              SKU:&nbsp;<strong>{{this.partNumber}}</strong>
              <div class=""></div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" ngbAutofocus
                (click)="this.dismissModal()">Dismiss</button>
        </div>
    </div>
</ng-template>
