import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'formly-wrapper-card',
  template: `
    <div class="card">
      <h6 class="card-header">{{ to.label }}</h6>
      <div class="card-body">
        <ng-container #fieldComponent></ng-container>
      </div>
    </div>
  `,
})
export class CardWrapperComponent extends FieldWrapper { }
