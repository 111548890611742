export class ListTestRunsRequest {
  public UserEmail: string
}

export class ListTestRunsResponse {
  public status: string;
  public response: Response[]
}

export class Response {
  public loadTestRunId: string;
  public name: string;
  public orderCount: number;
  public numberOfLines: number;
  public userName: string;
  public userEmail: string;
  public createdAt: string;
  public lastModifiedAt: string;
}

export class ListUsersDetails {
  public userName: string;
  public email: string;
}
