import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MsalService } from '@azure/msal-angular';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import * as XLSX from 'xlsx';
import { EntityTrackingMap } from 'src/app/model/entitytracking.model';
import { CurrentEventMetadata, ErrorRestartStepInputs, ErrorRestartStepResponse, Events, ModalInputs, SearchEntities, TestRunInputsDetails, TestRunSaveStatusInput, TestRunScenariosDetails, TestRunsDetails, TestRunsFetchInputs, TestRunsItemDetails, TestRunStatusCount, TestRunStepsDetails, TestRunsUsersDetails, TrackOrderRequest, TrackOrderResponse, UpdateScenarioStepInput, TestRunDetails, TestRunsList, TestRunExportResponse } from 'src/app/model/manage-testruns.model';
import { isArray } from 'util';
import * as log from '../../AppInsightsLogger';
import { AuthService } from '../../service/auth.service';
import { ConfigService } from '../../service/config.service';
import { ManageScenariosService } from '../../service/manage-scenarios.service';
import { ScenariosInputsModel } from 'src/app/model/execute-testruns.model';
import { GenerateBlobSasTokenService } from '../../service/generate-sas-token.service';

@Component({
  selector: 'app-manage-testruns',
  templateUrl: './manage-testruns.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./manage-testruns.component.css']
})

export class ManageTestRunsComponent implements OnInit {
  readonly truncateNameStrLength = 57;
  @ViewChild('searchByIdButton', { static: false }) searchButton: ElementRef;
  name: string;
  email: string;
  searchTestName: string;
  searchTerm: string;
  searchText: string;
  wrapperHeight: number;
  loadTrackEvents: boolean;
  selectedUserEmail: string;
  hrefEmail: string;
  testRunsUsersDetails: TestRunsUsersDetails[];
  selectedTestRunId: string;
  selectedTestRunIndex: number = -1;
  selectedTestRun: TestRunDetails;
  sidebarOpened: boolean;
  isShowChildScenarios: boolean;
  displayDetailedView: boolean = false;
  disableLink: boolean;
  componentName: string;
  bodyText: string;
  headerText: string;
  modalInputs: ModalInputs;
  testRunsFetchInputs: TestRunsFetchInputs;
  searchEntities: SearchEntities[];
  trackOrderRequest: TrackOrderRequest;
  trackOrderResponse: TrackOrderResponse;
  totalTestRunsCount: number;
  testRunsList: TestRunDetails[];
  testRunsItemDetails: TestRunsItemDetails;
  testRunScenariosList: TestRunScenariosDetails[];
  trackingEventsList: Events[];
  eventPayload = '';
  usetInputDetails = '';
  responseStep = '';
  correlationId: string;
  testrunCorrelationId: string;
  error: string;
  pageNumber: number = 0;
  childPageNumber: number = 0;
  restartStepRequestsList: ErrorRestartStepInputs[];
  restartStepResponsesList: ErrorRestartStepResponse[];
  eventDetailsId: string;
  currentEventMetadata: CurrentEventMetadata;
  testRuntoRetrigger: TestRunInputsDetails;
  retriggerTestRunName: "";

  private logger: log.AppInsightsLogger;

  /**
   * Map representing mapping between Tracking Type and Scenario - Order Type
   */
  trackingMap: EntityTrackingMap[];
  is500error: boolean = false;
  organizationId: number;
  statusFilter: number = 0;

  filterItems = [
    {
      id: 0,
      name: 'All'
    },
    {
      id: 1,
      name: 'Complete'
    },
    {
      id: 2,
      name: 'Error'
    },
    {
      id: 3,
      name: 'Processing'
    }
  ];
  refreshInterval: any;
  scrollPositionChange: boolean = false;
  scrollPositionSaved: any;
  testRunDetails: TestRunsDetails;
  searchRunIndex: any;
  searchScenario: any;


  constructor(
    private ngxLoader: NgxUiLoaderService,
    private modalService: NgbModal,
    private msalService: MsalService,
    private manageTestRunsService: ManageScenariosService,
    private configService: ConfigService,
    private generateSasTokenService: GenerateBlobSasTokenService,
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private http: HttpClient) {
    this.name = this.msalService.instance.getActiveAccount().name;
    this.email = this.msalService.instance.getActiveAccount().username;
    this.logger = new log.AppInsightsLogger(configService, msalService);
  }

  ngOnInit() {
    this.selectedTestRunIndex = -1;
    this.displayDetailedView = false;
    this.sidebarOpened = false;
    this.isShowChildScenarios = false;
    this.disableLink = false;
    this.searchTestName = null;
    this.searchText = null;
    this.modalInputs = new ModalInputs();
    this.testRunsUsersDetails = [];
    this.trackingEventsList = [];
    this.searchEntities = [];
    this.restartStepRequestsList = [];
    this.restartStepResponsesList = [];
    this.selectedTestRun = new TestRunDetails();
    this.testRunsFetchInputs = new TestRunsFetchInputs();
    this.trackOrderRequest = new TrackOrderRequest();
    this.trackOrderResponse = new TrackOrderResponse();
    this.setOrganizationData();
    this.loadSearchEntities();
    this.loadTestRunsUsersDetails();
    if (this.activatedRoute.snapshot.params['searchId'] !== undefined && this.activatedRoute.snapshot.params['searchId'] != null && this.activatedRoute.snapshot.params['searchId'].trim() != '') {
      this.searchText = this.activatedRoute.snapshot.params['searchId'];
      this.filterTestRuns();
    }
    else {
      this.loadDBTestRunsItemDetails();
    }
    this.loadOrderTrackingMap();
    this.wrapperHeight = document.getElementById('testRunsWrapper') != null ? document.getElementById('testRunsWrapper').scrollHeight : 500;
    this.currentEventMetadata = new CurrentEventMetadata();
    if (!this.authService.isOrderViewEnabled()) {
      this.router.navigate(['/', 'landing-page', {
        'org': this.organizationId.toString()
      }]);
    }
    this.logger.trackTrace("Successfully landed to Manage Testruns Page");
  }

  onSearchTermChange(searchTerm, scenario, testRunIndex) {
    this.searchRunIndex = testRunIndex;
    this.searchScenario = scenario;
    this.searchTerm = searchTerm;
    this.filteredStepsDetailsList(searchTerm, scenario, testRunIndex); // Call the function to filter the list
  }
  selectStep(step, scenario, testRunIndex) {

    this.onSearchTermChange(step, scenario, testRunIndex)
  }
  addTagFn(componentDescription) {
    return { componentDescription: componentDescription, tag: true };
  }
  filteredStepsDetailsList(searchTerm, scenario, testRunIndex) {

    searchTerm = searchTerm.label ? searchTerm.label : searchTerm;


    if (searchTerm || searchTerm !== '') {
      if (scenario.stepsDetailsList.filter(step =>
        step.componentDescription.toLowerCase().includes(this.searchTerm.toLowerCase()))) {
        this.testRunsList[testRunIndex].scenariosDetailsList.forEach(scenario => {
          scenario.stepsDetailsList = this.testRunDetails.testRunsItemDetailsList[0].scenariosDetailsList[0].stepsDetailsList.filter(step => step.componentDescription.toLowerCase().includes(this.searchTerm.toLowerCase()))
        });

      }
      else {
        this.testRunsList[testRunIndex].scenariosDetailsList.forEach(scenario => {
          scenario.stepsDetailsList = this.testRunDetails.testRunsItemDetailsList[0].scenariosDetailsList[0].stepsDetailsList;
        });
      }

    }
    else {
      this.testRunsList[testRunIndex].scenariosDetailsList.forEach(scenario => {
        scenario.stepsDetailsList = this.testRunDetails.testRunsItemDetailsList[0].scenariosDetailsList[0].stepsDetailsList;
      });
    }
  }
  setOrganizationData() {
    let orgId = window.sessionStorage.getItem("orgSelected");
    if (orgId != null && orgId != '') {
      this.organizationId = parseInt(orgId);
    }
    else {
      let organizationID = this.activatedRoute.snapshot.params['org'] || "";
      if (organizationID != "" && this.organizationId != parseInt(organizationID)) {
        this.organizationId = parseInt(organizationID);
        window.sessionStorage.setItem("orgSelected", this.organizationId.toString());
      }
    }
  }

  checkOverflow() {
    return document.getElementById('testRunsWrapper').scrollHeight <= this.wrapperHeight;
  }

  scrollToTop() {
    document.getElementById('testRunsWrapper').scrollTop = 0;
  }

  toggleSidebar() {
    if (this.sidebarOpened) {
      this.eventPayload = '';
      this.trackingEventsList = [];
    }
    this.sidebarOpened = !this.sidebarOpened;
  }

  loadSearchEntities() {
    const parent = this;
    this.manageTestRunsService.getSearchEntitiesDetails().subscribe((response: SearchEntities[]) => {
      if (response != undefined && response != null && response.length > 0) {
        parent.searchEntities = response;
        parent.trackOrderRequest.entityType = parent.searchEntities[0].entityType;
      }
    }, (error: any) => {
      this.logger.trackTrace(`Manage testruns Search Entities Error ${error} load Search Entities`);
      this.logger.trackException(error);
      parent.ngxLoader.stop();
    });
  }

  loadTestRunsUsersDetails() {
    const parent = this;
    parent.testRunsFetchInputs = new TestRunsFetchInputs();
    parent.testRunsFetchInputs.userEmail = parent.email;
    parent.testRunsFetchInputs.organizationId = parent.organizationId;
    parent.manageTestRunsService.getTestRunsUsersDetails(parent.testRunsFetchInputs).subscribe((response: TestRunsUsersDetails[]) => {
      parent.testRunsUsersDetails = [];
      if (response != undefined && response != null && response.length > 0) {
        parent.testRunsUsersDetails = [{ userEmail: 'all', userName: 'All' }].concat(response.filter(x => {
          if (x.userName != null && x.userName != '' && x.userEmail != null && x.userEmail != '') {
            return x;
          }
        }));
      }
    }, (error: any) => {
      parent.logger.trackTrace(`In Manage Testruns, load Test Runs Users Details Error`);
      parent.logger.trackException(error);
      parent.ngxLoader.stop();
    });
  }

  refreshTestRuns(index: number, testRunId: string, disableLoad: boolean) {

    if (disableLoad != true) {
      this.manageTestRunsService.ngxLoaderConfig.text = 'Loading Test Runs List! Please wait!';
      this.ngxLoader.start();
    }
    let isRefresh = true;
    this.refreshTestcaseAuto(index, testRunId, isRefresh, disableLoad)


  }

  refreshTestcaseAuto(index, testRunId, isRefresh, disableLoad) {
    if (disableLoad) {
      if (this.refreshInterval) {
        clearInterval(this.refreshInterval)
      }
      this.refreshInterval = setInterval(() => {
        this.loadTestRunsItemDetails(index, testRunId, false, isRefresh, disableLoad);
        disableLoad = true;

      }, 30000);
    }
    else {
      this.loadTestRunsItemDetails(index, testRunId, false, isRefresh, disableLoad);
      disableLoad = true;
      this.refreshTestcaseAuto(index, testRunId, isRefresh, disableLoad);
    }
  }
  filterTestRuns() {
    const parent = this;
    parent.manageTestRunsService.ngxLoaderConfig.text = 'Loading Test Runs List! Please wait!';
    parent.ngxLoader.start();
    parent.testRunsFetchInputs.searchId = parent.searchText;
    parent.testRunsFetchInputs.testRunName = parent.searchTestName;
    parent.testRunsFetchInputs.organizationId = parent.organizationId;
    parent.testRunsFetchInputs.pageNumber = 1;
    parent.testRunsList = [];
    parent.selectedTestRunIndex = -1;
    parent.selectedTestRunId = undefined;
    parent.selectedTestRun = undefined;
    parent.displayDetailedView = false;
    parent.loadTestRunsItemDetails(-1, '', true, true, false);
    parent.router.navigate(['/manage-testruns', {
      'org': parent.organizationId.toString(),
      'searchId': parent.searchText != null ? parent.searchText.trim() : ""
    }], { relativeTo: parent.activatedRoute });
  }

  loadDBTestRunsItemDetails() {
    this.manageTestRunsService.ngxLoaderConfig.text = 'Loading Test Runs List! Please wait!';
    this.ngxLoader.start();
    this.testRunsList = [];
    this.pageNumber = 0;
    this.testRunsFetchInputs.searchId = '';
    this.loadTrackEvents = false;
    this.searchText = null;
    this.searchTestName = null;
    if (this.searchEntities != undefined && this.searchEntities != null && this.searchEntities.length > 0) {
      this.trackOrderRequest.entityType = this.searchEntities[0].entityType;
    }
    if (this.sidebarOpened) {
      this.toggleSidebar();
    }
    this.loadTestRunsItemDetails(-1, '', true, false, false);
    this.router.navigate(['/manage-testruns', {
      'org': this.organizationId.toString(),
      'searchId': ""
    }], { relativeTo: this.activatedRoute });
  }

  loadMoreTestRuns() {
    this.manageTestRunsService.ngxLoaderConfig.text = 'Loading Test Runs Details! Please wait!';
    this.ngxLoader.start();
    this.loadTestRunsItemDetails(-1, '', false, false, false);
  }

  loadTestRunsItemDetails(index: number, testRunId: string, resetStorage: boolean, isRefresh: boolean, disableLoad: boolean) {
    if (disableLoad != true) {
      this.manageTestRunsService.ngxLoaderConfig.text = 'Loading Test Runs Details! Please wait!';
      this.ngxLoader.start();
    }
    const parent = this;
    parent.testRunsFetchInputs.userEmail = (parent.selectedUserEmail !== undefined && parent.selectedUserEmail !== null && parent.selectedUserEmail !== '') ? parent.selectedUserEmail : parent.email;
    parent.testRunsFetchInputs.testRunId = testRunId;
    parent.testRunsFetchInputs.organizationId = parent.organizationId;
    if (parent.searchTestName != null && parent.searchTestName != '') {
      parent.testRunsFetchInputs.testRunName = parent.searchTestName;
    }
    else {
      parent.testRunsFetchInputs.testRunName = '';
    }
    if (parent.searchText !== undefined && parent.searchText !== null && parent.searchText.trim() !== '') {
      if (index >= 0 && parent.testRunsList.length > 0) {
        parent.testRunsList[index].testRunPrestepDetails = null;
        parent.testRunsList[index].scenariosDetailsList = null;
      }
      parent.testRunsFetchInputs.searchId = parent.searchText !== parent.testRunsFetchInputs.testRunId ? parent.searchText : '';
    }
    if (testRunId !== undefined && testRunId !== null && testRunId.trim() !== '') {
      parent.childPageNumber = (!isRefresh && parent.childPageNumber < 1) ? 1 : (!isRefresh ? parent.childPageNumber + 1 : 1);
      parent.testRunsFetchInputs.pageNumber = parent.childPageNumber;
      parent.manageTestRunsService.getTestRunsDetails(parent.testRunsFetchInputs).subscribe((response: TestRunsDetails) => {
        this.testRunDetails = response;
        if (response !== null && response.totalCount > 0 && response.testRunsItemDetailsList !== null) {
          parent.testRunsList[index].testRunPrestepDetails = JSON.parse(JSON.stringify(response.testRunsItemDetailsList[0].testRunPrestepDetails));
          parent.testRunsList[index].scenariosDetailsList = JSON.parse(JSON.stringify(response.testRunsItemDetailsList[0].scenariosDetailsList));
          parent.testRunsList[index].statusCounts = JSON.parse(JSON.stringify(response.testRunsItemDetailsList[0].statusCounts));
          parent.selectedTestRun = JSON.parse(JSON.stringify(parent.testRunsList[index]));

          if (this.scrollPositionChange) {
            this.restoreScrollPosition();
            this.scrollPositionChange = false;
          }
          if (!isRefresh) {
            parent.displayDetailedView = parent.testRunsList[index].isMultiPayload || parent.testRunsList[index].totalScenariosCount > 10;
          }

          parent.ngxLoader.stop();
          this.onSearchTermChange(this.searchTerm, this.searchScenario, this.searchRunIndex);
        } else {
          parent.ngxLoader.stop();
        }
      }, (error: any) => {
        if (error.status == 500) {
          parent.is500error = true;
        }
        parent.logger.trackTrace(`In Manage testruns, load Test Runs Item Details Error`);
        parent.logger.trackException(error);
        parent.ngxLoader.stop();
      });
    }
    else {
      parent.pageNumber = (!isRefresh && parent.pageNumber < 1) ? 1 : (!isRefresh ? parent.pageNumber + 1 : 1);
      parent.testRunsFetchInputs.pageNumber = parent.pageNumber;
      this.manageTestRunsService.getTestRunsList(this.testRunsFetchInputs).subscribe((response: TestRunsList) => {
        if (response !== null && response.totalTestRunsCount > 0 && response.testRunDetailsList !== null) {
          parent.processTestRunsItemDetails(index, testRunId, response, resetStorage);
          parent.ngxLoader.stop();
        } else {
          parent.ngxLoader.stop();
        }
      }, (error: any) => {
        if (error.status == 500) {
          parent.is500error = true;
        }

        parent.logger.trackTrace(`In Manage testruns, load Test Runs Item Details Error`);
        parent.logger.trackException(error);
        parent.ngxLoader.stop();
      });
    }
  }
  restoreScrollPosition() {
    let savedScrollPosition = this.scrollPositionSaved
    if (savedScrollPosition) {
      setTimeout(() => {
        document.getElementById('testruns-wrapper-child').scrollLeft = +parseInt(savedScrollPosition);
      }, 0);
    }
  }
  saveScrollPosition(event: any) {
    this.scrollPositionSaved = event.target.scrollLeft.toString()
    this.scrollPositionChange = true;
  }
  processTestRunsItemDetails(index: number, testRunId: string, response: TestRunsList, resetStorage: boolean) {
    this.totalTestRunsCount = response.totalTestRunsCount;
    this.selectedTestRunId = undefined;
    const testRunDetails = response.testRunDetailsList;
    this.testRunsList = (testRunId !== undefined && testRunId !== null && testRunId !== '') ? testRunDetails : (!resetStorage ? this.testRunsList.concat(testRunDetails) : testRunDetails);
  }

  /**
   * Load Order Tracking Map config
   */
  loadOrderTrackingMap() {
    this.http.get('./assets/configuration/EntityTrackingMapping.json', { responseType: 'text' }).subscribe((response: string) => {
      this.trackingMap = JSON.parse(response);
    }, (error: any) => {
      this.logger.trackTrace(`In Manage Testruns load Order Tracking Map Error ${error}, ERROR: can't load entity tracking map`);
      this.logger.trackException(error);
      console.log("ERROR: can't load entity tracking map");
    });
  }

  /**
   * Return true if name exceeds the limits that can be displayed on the UI
   * @param testRunName String name of Test Run
   * @returns Boolean
   */
  testRunNameExceedsLimit(testRunName: string) {
    if (testRunName.replace("''", "'").length > this.truncateNameStrLength) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Truncates test run name to be displayed on the UI
   * @param testRunName String name of Test Run
   * @returns Truncated Test Run Name
   */
  truncateTestRunName(testRunName: string) {
    if (testRunName.replace("''", "'").length > this.truncateNameStrLength) {
      return testRunName.substr(0, this.truncateNameStrLength) + '...';
    } else {
      return testRunName;
    }
  }

  displayTrackingDetails(testRunIndex: number, scenarioIndex: number, stepIndex: number, childScenarioIndex: number, childStepIndex: number) {
    this.trackOrderRequest.entityIDs = [];
    const stepDetails = childScenarioIndex > -1 ? this.testRunsList[testRunIndex].scenariosDetailsList[scenarioIndex].stepsDetailsList[stepIndex].childScenariosList[childScenarioIndex].stepsDetailsList[childStepIndex] : this.testRunsList[testRunIndex].scenariosDetailsList[scenarioIndex].stepsDetailsList[stepIndex];
    if (stepDetails.eventsList != undefined && stepDetails.eventsList != null && stepDetails.eventsList.length > 0) {
      this.trackingEventsList = stepDetails.eventsList;
      this.eventPayload = stepDetails.stepResponse;
      this.toggleSidebar();
    }
    else {
      this.trackOrderRequest.entityType = stepDetails.trackingType;
      if (stepDetails.trackingType.toLowerCase() == 'returnserviceorder') {
        let entityId: string = (JSON.parse(stepDetails.stepResponse))['ServiceOrderNumber'];
        this.trackOrderRequest.entityIDs.push(entityId);
      } else if (stepDetails.trackingType.toLowerCase() == 'outboundcustpurchaseorder') {
        let entityId: string = (JSON.parse(stepDetails.stepResponse))['customerPurchaseOrderNumber'];
        this.trackOrderRequest.entityIDs.push(entityId);
      }
      else if (stepDetails.trackingType.toLowerCase() == '3pppurchaseorder') {
        let entityId: string = (JSON.parse(stepDetails.stepResponse))['EX_PO_NUMBERS'][0]['EBELN'];
        this.trackOrderRequest.entityIDs.push(entityId);
      }
      else if (stepDetails.trackingType.toLowerCase() == '3ppsalesorder') {
        let entityId: string = (JSON.parse(stepDetails.stepResponse))['customerPurchaseOrderNumber'];
        this.trackOrderRequest.entityIDs.push(entityId);

      } else if (stepDetails.trackingType.toLowerCase() == 'ato_directship_custpurchaseorder') {
        let entityId: string = (JSON.parse(stepDetails.stepResponse))['customerPurchaseOrderNumber'];
        this.trackOrderRequest.entityIDs.push(entityId);

      }
      else if (stepDetails.trackingType.toLowerCase() == 'marketplacesalesorder') {
        if ((JSON.parse(stepDetails.stepResponse))["TestOutputData"][3]['SOnumber'] != undefined) {
          let entityId: string = this.escapeQuotes((JSON.parse(stepDetails.stepResponse))['TestOutputData'][3]['SOnumber']);
          this.trackOrderRequest.entityIDs.push(entityId);
        }
        else if ((JSON.parse(stepDetails.stepResponse))["TestOutputData"][5]['SOnumber'] != undefined) {
          let entityId: string = this.escapeQuotes((JSON.parse(stepDetails.stepResponse))["TestOutputData"][5]['SOnumber']);
          this.trackOrderRequest.entityIDs.push(entityId);

        }
        else if ((JSON.parse(stepDetails.stepResponse))["TestOutputData"][11]['SalesOrderNumber'] != undefined) {
          let entityId: string = this.escapeQuotes((JSON.parse(stepDetails.stepResponse))["TestOutputData"][11]['SalesOrderNumber']);
          this.trackOrderRequest.entityIDs.push(entityId);
        }
        else {
          let entityId: string = this.escapeQuotes((JSON.parse(stepDetails.stepResponse))["TestOutputData"][13]['SalesOrderNumber']);
          this.trackOrderRequest.entityIDs.push(entityId);
        }
      }
      else if (stepDetails.trackingType.toLowerCase() == 'makepurchaseorder') {
        if (JSON.parse(stepDetails.stepResponse).hasOwnProperty('EX_PO_NUMBERS')) {
          let entityId: string = (JSON.parse(stepDetails.stepResponse))['EX_PO_NUMBERS'][0]['EBELN'];
          this.trackOrderRequest.entityIDs.push(entityId);
        } else if (JSON.parse(stepDetails.stepResponse).hasOwnProperty('customerPurchaseOrderNumber')) {
          return (JSON.parse(stepDetails.stepResponse))['customerPurchaseOrderNumber']
        }
      }
      if (this.trackOrderRequest.entityIDs != undefined && this.trackOrderRequest.entityIDs != null && this.trackOrderRequest.entityIDs.length != 0) {
        this.trackOrderEventsDetails(false);
      }
    }
    this.storeEventMetadata(testRunIndex, scenarioIndex, stepIndex);
  }

  /*
    For CodeQL: [SM02383] Incomplete string escaping or encoding
  */
  private escapeQuotes(s: string) {
    return s.replace(/'/g, "''");
  }

  getTrackingID(testRunIndex: number, scenarioIndex: number, stepIndex: number) {
    const stepDetails = this.testRunsList[testRunIndex].scenariosDetailsList[scenarioIndex].stepsDetailsList[stepIndex];
    return this.computeTrackingID(stepDetails);
  }

  disableTracking(testRunIndex: number, scenarioIndex: number, stepIndex: number) {
    const stepDetails = this.testRunsList[testRunIndex].scenariosDetailsList[scenarioIndex].stepsDetailsList[stepIndex];
    if (stepDetails.trackingType.toLocaleLowerCase() == "marketplacesalesorder") {
      return false;
    }
    if (JSON.parse(stepDetails.stepResponse).hasOwnProperty('TestOutputData')) {
      return true;
    }
    return false;
  }

  /**
   * Get Order ID which can be tracked for events from this step.
   * @param stepDetails Test Run step
   */
  computeTrackingID(stepDetails: TestRunStepsDetails) {
    this.disableLink = false;
    let stepJsonResponse = JSON.parse(stepDetails.stepResponse);
    let stepJsonResponseTestOutputData = (stepJsonResponse)["TestOutputData"]
    if (stepDetails.trackingType.toLowerCase() == 'returnserviceorder') {
      return (stepJsonResponse)['ServiceOrderNumber'];
    } else if (stepDetails.trackingType.toLowerCase() == 'outboundcustpurchaseorder') {
      return (stepJsonResponse)['customerPurchaseOrderNumber'];
    }
    else if (stepDetails.trackingType.toLowerCase() == '3ppsalesorder') {
      return (stepJsonResponse)['customerPurchaseOrderNumber'];
    }
    else if (stepDetails.trackingType.toLowerCase() == 'marketplacesalesorder') {
      let getSoNumber = this.getItemValueBasedOnKey(stepJsonResponseTestOutputData, "SOnumber")
      let getSalesOrderNumber = this.getItemValueBasedOnKey(stepJsonResponseTestOutputData, "SalesOrderNumber")
      if (getSoNumber != undefined) {
        return this.escapeQuotes(getSoNumber);
      }
      else if (getSalesOrderNumber != undefined) {
        return this.escapeQuotes(getSalesOrderNumber);
      }
    } else if (stepDetails.trackingType.toLowerCase() == '3pppurchaseorder') {
      return (stepJsonResponse)['EX_PO_NUMBERS'][0]['EBELN'];
    }
    else if (stepDetails.trackingType.toLowerCase() == 'ato_directship_custpurchaseorder') {
      if (stepJsonResponse.hasOwnProperty('TestOutputData') && Array.isArray(stepJsonResponseTestOutputData)) {
        this.disableLink = true;
        return this.getItemValueBasedOnKey(stepJsonResponseTestOutputData, "PurchaseOrder")
      } else {
        return (stepJsonResponse)["customerPurchaseOrderNumber"]
      }
    }
    else if (stepDetails.trackingType.toLowerCase() == 'makepurchaseorder') {
      if (stepJsonResponse.hasOwnProperty('EX_PO_NUMBERS')) {
        return stepJsonResponse['EX_PO_NUMBERS'][0]['EBELN'];
      } else if (stepJsonResponse.hasOwnProperty('TestOutputData') && Array.isArray(stepJsonResponseTestOutputData)) {
        this.disableLink = true;
        let twoLeg = this.getItemValueBasedOnKey(stepJsonResponseTestOutputData, "2Leg_PO");
        if (twoLeg != undefined) {
          const oneLeg = this.getItemValueBasedOnKey(stepJsonResponseTestOutputData, "1LEG_PO");
          return oneLeg.concat(", ", twoLeg);
        } else {
          return this.getItemValueBasedOnKey(stepJsonResponseTestOutputData, "1LEG_PO");
        }
      }
      else if (stepJsonResponse['response']['resultsSummary'][0]['resultAttributeValues']['Test Output Data'] != undefined) {
        let testOutputDataResponse = stepJsonResponse['response']['resultsSummary'][0]['resultAttributeValues']['Test Output Data'];
        let keyValuePairs = testOutputDataResponse.split(';').map(pair => pair.trim());
        let jsonData = {};
        // Iterate over each key-value pair
        keyValuePairs.forEach(pair => {
          // Split each pair by the first occurrence of ":"
          const index = pair.indexOf(':');
          const key = pair.substring(0, index).trim();
          const value = pair.substring(index + 1).trim();
          // Assign the key-value pair to the jsonData object
          jsonData[key] = value;
        });
        return jsonData['Primary PO or SO Number'] ? jsonData['Primary PO or SO Number'] : "";
      }
    }
    return "";
  }

  /**
  * Get value from array list based on key.
  * @param arrayList List of array from which value is extracted from.
  * @param key Key property used extract value.
  */
  getItemValueBasedOnKey(arrayList: any, key: any) {
    for (let index = 0; index < arrayList.length; index++) {
      const element = arrayList[index];
      if (element.hasOwnProperty(key)) {
        return arrayList[index][key];
      }
    }
    return undefined;
  }

  /**
   * Get url parameters which can be used to navigate to /entitytracking route.
   * @param testRunIdx Index in the master array which represents this Test Run.
   */
  getTrackingUrlParams(testRunIdx: number) {
    let testRun = this.testRunsList[testRunIdx];
    let trackingType = testRun.trackingType;
    let trackingIDs = testRun.trackingIDs;
    let scenario = "";
    let orderType = "";
    trackingType = trackingType.toLocaleLowerCase();
    for (let i = 0; i < this.trackingMap.length; ++i) {
      if (this.trackingMap[i].TrackingType.toLocaleLowerCase() == trackingType) {
        scenario = this.trackingMap[i].Scenario;
        orderType = this.trackingMap[i].EntityType;
        break;
      }
    }
    return {
      'scenario': scenario,
      'type': orderType,
      'ids': trackingIDs
    };
  }

  /** 
  @param correlationId represents the ID of the Test Run case
  */
  getCloneRequestParams(correlationId: string) {
    //find the testRunCorrelationID
    return ({
      org: this.organizationId.toString(),
      searchId: correlationId
    });
  }

  retriggerTestRunScenario(run: TestRunsItemDetails, correlationId: string, retriggerResponseModal: any, retriggerConfirmationModal: any) {
    this.manageTestRunsService.ngxLoaderConfig.text = 'Storing Test Runs Inputs Details! Please Wait!';
    this.testRuntoRetrigger = new TestRunInputsDetails();
    this.testRuntoRetrigger.userEmail = this.email;
    this.testRuntoRetrigger.userName = this.name;
    let scenario: TestRunScenariosDetails = run.scenariosDetailsList.find(x => x.correlationId == correlationId);
    this.testRuntoRetrigger.verticalName = scenario.verticalName;
    this.testRuntoRetrigger.sectionName = scenario.sectionName
    this.testRuntoRetrigger.partnerOrgName = "";
    this.testRuntoRetrigger.businessProcessName = '';
    this.testRuntoRetrigger.onboardingName = "";
    let scenarioInputsModel = new ScenariosInputsModel();

    scenarioInputsModel.scenarioId = scenario.scenarioId;
    scenarioInputsModel.scenarioName = scenario.scenarioName;
    scenarioInputsModel.flavourId = scenario.flavourId;
    scenarioInputsModel.flavourName = scenario.flavourName;

    let userInput: Object = JSON.parse(scenario.stepsDetailsList[0].userInput);
    let fieldsToRemove: string[] = ["actionCode", "orderType", "partner", "receiverSystem", "productType", "fulfillmentMethod", "lineitems"];
    fieldsToRemove.forEach(key => {
      if (userInput.hasOwnProperty(key)) {
        delete userInput[key];
      }
    });
    scenarioInputsModel.inputsModel = userInput
    this.testRuntoRetrigger.inputsJsonDetails = "[" + JSON.stringify(scenarioInputsModel) + "]";
    this.headerText = "Do you want to retrigger this test case with same parameters?"
    this.modalService.open(retriggerConfirmationModal, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' }).result.then((result) => {
      if (this.retriggerTestRunName != "") {
        this.testRuntoRetrigger.testRunName = this.retriggerTestRunName;
      }
      else {
        this.testRuntoRetrigger.testRunName = run.testRunName + "clone";
      }
      if (result == "Retrigger") {
        this.submitTestRun(retriggerResponseModal);
      }
      else {
        //do nothing
      }
    });
  }

  submitTestRun(retriggerResponseModal: any) {
    this.ngxLoader.start();
    this.manageTestRunsService.storeTestRunsDetails(this.testRuntoRetrigger).subscribe((response: string[]) => {
      if (response != null && response.length > 0) {
        this.headerText = 'Test Run Created Successfully!';
        this.bodyText = 'Test Run Created Successfully! Please click OK to navigate to the test run!'
        this.modalService.open(retriggerResponseModal, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' }).result.then((result) => {
          if (result == "Navigate") {
            let correlationId = response[0];
            this.searchText = correlationId;
            this.router.navigate(['/manage-testruns', {
              'org': this.organizationId.toString(),
              'searchId': this.searchText
            }]);
          }
        });
      } else {
        this.headerText = 'Test Run Creation Failed!';
        this.bodyText = 'Test Run Creation Failed! Please try again later!'
        this.modalService.open(retriggerResponseModal, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' }).result.then((result) => {
        });
      }
      this.ngxLoader.stop();
    }, (error: any) => {
      this.ngxLoader.stop();
      this.headerText = 'Test Run Creation Failed!';
      this.bodyText = 'Test Run Creation Failed! Please try again later!'
      this.modalService.open(retriggerResponseModal, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' }).result.then((result) => {
      });
    });
  }

  trackOrderEventsDetails(resetStorage: boolean) {
    const parent = this;
    parent.manageTestRunsService.ngxLoaderConfig.text = 'Loading Order Events Details! Please Wait!';
    parent.ngxLoader.start();
    if (resetStorage) {
      parent.trackOrderRequest.entityIDs.push(parent.searchText);
      if (this.sidebarOpened) {
        this.toggleSidebar();
      }
    }
    if (parent.trackOrderRequest.entityIDs != undefined && parent.trackOrderRequest.entityIDs != null && parent.trackOrderRequest.entityIDs.length > 0) {
      parent.manageTestRunsService.getOrderEventsDetails(parent.trackOrderRequest).subscribe((response: TrackOrderResponse) => {
        if (response != undefined && response != null && response.payload != undefined && response.payload != null && response.payload != '') {
          parent.trackOrderResponse = response;
          if (resetStorage) {
            parent.loadTrackEvents = true;
            parent.totalTestRunsCount = parent.testRunsList.length;
            parent.selectedTestRunId = parent.testRunsList[0].testRunId;
            parent.populateTrackingInfo(parent.testRunsList[0]);
          }
          else {
            parent.loadTrackEvents = false;
            parent.trackingEventsList = parent.formJsonObject(response.events);
            parent.eventPayload = response.payload;
            if (!parent.sidebarOpened) {
              parent.toggleSidebar();
            }
          }
          parent.ngxLoader.stop();
        } else {
          parent.trackingEventsList = [];
          parent.eventPayload = '';
          if (!parent.sidebarOpened) {
            parent.toggleSidebar();
          }
          parent.ngxLoader.stop();
        }
      }, (error: any) => {
        this.logger.trackTrace(`In manage testruns, track Order Events Details Error ${error}`);
        this.logger.trackException(error);
        parent.ngxLoader.stop();
      });
    }
  }

  formJsonObject(events: Events[]) {
    for (let i = 0; i < events.length; i++) {
      if (events[i].eventName == "Errors") {
        events[i].eventMessage = JSON.parse(events[i].eventMessage);
      }
    }
    return events;
  }

  makeRandomString(lengthOfCode: number) {
    let possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890';
    let text = '';
    for (let i = 0; i < lengthOfCode; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  getEventTime(eventDate: string, eventTime: string) {
    let localTime = '';
    if (eventTime != undefined && eventTime != null && eventTime != '') {
      const time = new Date(eventTime);
      localTime = time.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    }
    else if (eventDate != undefined && eventDate != null && eventDate != '') {
      const time = new Date(eventDate);
      localTime = time.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    }
    return localTime;
  }

  showChildScenarios() {
    this.isShowChildScenarios = !this.isShowChildScenarios;
  }

  formTestRunFromTrackEvent() {
    let itemDetails = new TestRunsItemDetails();
    itemDetails.scenariosDetailsList = [];
    let statusCount = new TestRunStatusCount();
    let testRunScenariosDetails = new TestRunScenariosDetails();
    testRunScenariosDetails.stepsDetailsList = [];
    let testRunStepsDetails = new TestRunStepsDetails();
    itemDetails.testRunId = this.makeRandomString(40);
    itemDetails.testRunName = (this.searchEntities.filter(x => x.entityType.toLowerCase() == this.trackOrderRequest.entityType.toLowerCase())[0].entityName + ' Search');
    itemDetails.executionDate = '';
    statusCount.complete = 1;
    itemDetails.statusCounts = statusCount;
    testRunStepsDetails.componentId = 0;
    testRunStepsDetails.componentName = this.searchEntities.filter(x => x.entityType.toLowerCase() == this.trackOrderRequest.entityType.toLowerCase())[0].entityName;
    testRunStepsDetails.componentDescription = this.searchEntities.filter(x => x.entityType.toLowerCase() == this.trackOrderRequest.entityType.toLowerCase())[0].entityDesc;
    testRunStepsDetails.displayTracking = true;
    testRunStepsDetails.stepStatus = 'Complete';
    testRunStepsDetails.stepRank = 1;
    testRunStepsDetails.componentCreated = '';
    testRunStepsDetails.componentLastModified = '';
    testRunStepsDetails.userInput = '';
    testRunStepsDetails.eventsList = this.formJsonObject(this.trackOrderResponse.events);
    testRunStepsDetails.trackingType = this.trackOrderRequest.entityType;
    testRunStepsDetails.stepResponse = this.formStepResponse();
    testRunStepsDetails.isChildStep = false;
    testRunStepsDetails.parentCorrelationID = '';
    testRunStepsDetails.childScenariosList = [];

    testRunScenariosDetails.scenarioId = 1;
    testRunScenariosDetails.flavourId = 1;
    testRunScenariosDetails.scenarioName = '';
    testRunScenariosDetails.flavourName = '';
    testRunScenariosDetails.executionDate = '';
    testRunScenariosDetails.correlationId = '';
    testRunScenariosDetails.isVisible = true;
    testRunScenariosDetails.stepsDetailsList.push(testRunStepsDetails);
    itemDetails.scenariosDetailsList.push(testRunScenariosDetails);
    return itemDetails;
  }

  formStepResponse() {
    let payloadJson = JSON.parse(this.trackOrderResponse.payload);
    if (this.trackOrderRequest.entityType.toLowerCase() == 'returnserviceorder') {
      payloadJson['ServiceOrderNumber'] = [...this.trackOrderRequest.entityIDs];
    }
    else if (this.trackOrderRequest.entityType.toLowerCase() == '3pppurchaseorder') {
      payloadJson['EX_PO_NUMBERS'] = [{ "EBELN": "" }];
      payloadJson['EX_PO_NUMBERS'][0]['EBELN'] = [...this.trackOrderRequest.entityIDs];
    }
    else if (this.trackOrderRequest.entityType.toLowerCase() == 'makepurchaseorder') {
      payloadJson['EX_PO_NUMBERS'] = [{ "EBELN": "" }];
      payloadJson['EX_PO_NUMBERS'][0]['EBELN'] = [...this.trackOrderRequest.entityIDs];
    }
    return JSON.stringify(payloadJson);
  }

  /**
   * Populate additional properties in Test Run after fresh loading it from database
   * @param testRun Test Run item to be updated
   */
  populateTrackingInfo(testRun: TestRunDetails) {
    testRun.trackingType = "";
    testRun.trackingIDs = [];

    for (var i = 0; i < (testRun.scenariosDetailsList || []).length; ++i) {
      for (var j = 0; j < (testRun.scenariosDetailsList[i].stepsDetailsList || []).length; ++j) {
        let step = testRun.scenariosDetailsList[i].stepsDetailsList[j];
        if (step.trackingType) {
          testRun.trackingType = step.trackingType;
        }

        try {
          let trackingId = this.computeTrackingID(step) || "";
          let stepResponse = JSON.parse(step.stepResponse);
          if (trackingId.length > 0) {
            // manage special case of ATO (Direct Ship scenario), we get Cust PO alread earlier, don't need any other Order ID
            if (!stepResponse.hasOwnProperty('TestOutputData')) {
              testRun.trackingIDs.push(trackingId);
              // For the case BTS (Build to Stock), as we are starting the tracking from worksoft response, we are explicity adding trackingId. 
            } else if (step.trackingType.toLowerCase() == "makepurchaseorder") {
              testRun.trackingIDs.push(trackingId);
            }
          }
        } catch (err) {
          this.logger.trackTrace(`Manage Testruns, populate Tracking Info Error ${err}`);
          this.logger.trackException(err);
          /* This is an error in getting the necessary order id from the test run details */
          /* Intentionally ignore this error */
        }
      }
    }
  }

  getTestRunsStatusCounts(testRunsDetails: TestRunsItemDetails[]) {
    if (testRunsDetails.length > 0) {
      for (let i = 0; i < testRunsDetails.length; i++) {
        const testRunDetails = testRunsDetails[i];
        for (let j = 0; j < testRunDetails.scenariosDetailsList.length; j++) {
          const scenarioDetails = testRunDetails.scenariosDetailsList[j];

          const step = scenarioDetails.stepsDetailsList.filter(x => x.stepStatus != '' && x.stepStatus.toLowerCase() != 'waiting').sort((a, b) => b.stepRank - a.stepRank);
          if (step != undefined && step != null && step.length > 0) {
            const stepRankId = step[0].stepRank;
            const status = scenarioDetails.stepsDetailsList.filter(y => y.stepRank == stepRankId)[0].stepStatus.toLowerCase();
            testRunDetails.statusCounts[status] += 1;
          }
          else {
            testRunDetails.statusCounts['waiting'] += 1;
          }
        }
        testRunsDetails[i] = testRunDetails;
      }
    }
    return testRunsDetails;
  }

  showTestRunDetails(testRunIndex: number) {
    this.scrollPositionChange = false;
    this.isShowChildScenarios = false;
    this.manageTestRunsService.ngxLoaderConfig.text = 'Loading Test Run Details! Please wait!';
    this.ngxLoader.start();
    if (this.selectedTestRunId === this.testRunsList[testRunIndex].testRunId) {
      this.selectedTestRunIndex = -1;
      this.selectedTestRunId = undefined;
      this.selectedTestRun = undefined;
      this.displayDetailedView = false;
      this.ngxLoader.stop();
      clearInterval(this.refreshInterval);
    } else {
      this.selectedTestRunIndex = testRunIndex;
      this.selectedTestRunId = this.testRunsList[testRunIndex].testRunId;
      this.childPageNumber = 0;
      this.statusFilter = 0;
      this.testRunsFetchInputs.isExport = false;
      this.testRunsFetchInputs.statusFilter = 'All';
      let disableLoad: boolean = false;
      let isRefresh = true;
      this.refreshTestcaseAuto(testRunIndex, this.selectedTestRunId, isRefresh, disableLoad)



    }
  }

  getScenarioExecutionTime(testRunIndex: number, scenarioIndex: number) {
    const testScenario = this.testRunsList[testRunIndex].scenariosDetailsList[scenarioIndex];
    let startDate: any;
    let endDate: any;
    startDate = new Date(testScenario.stepsDetailsList[0].componentCreated);
    for (let i = testScenario.stepsDetailsList.length - 1; i >= 0; i--) {
      if (testScenario.stepsDetailsList[i].stepStatus.toLowerCase() != 'waiting' && testScenario.stepsDetailsList[i].stepStatus.toLowerCase() != '') {
        endDate = new Date(testScenario.stepsDetailsList[i].componentLastModified);
        break;
      }
    }
    const diffMs = (endDate - startDate); // milliseconds between now & Christmas
    const diffDays = Math.floor(diffMs / 86400000); // days
    const diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    const diffMins = Math.abs(Math.round(diffMs / 60000)); // minutes
    return diffMins;
  }

  // Show ETA for a scenario only if there is no error step and the whole scenario is not completed
  shouldShowScenarioETA(testRunIndex: number, scenarioIndex: number) {
    const testScenario = this.testRunsList[testRunIndex].scenariosDetailsList[scenarioIndex];
    let completedStepsCount = 0;

    for (let i = 0; i < testScenario.stepsDetailsList.length; i++) {
      const stepStatus = testScenario.stepsDetailsList[i].stepStatus.toLowerCase();
      if (stepStatus === 'complete') {
        completedStepsCount += 1;
      } else if (stepStatus === 'error') {
        return false;
      }
    }

    return !(completedStepsCount === testScenario.stepsDetailsList.length);
  }

  shouldShowStepETA(testRunIndex: number, scenarioIndex: number, stepIndex: number) {
    const testScenario = this.testRunsList[testRunIndex].scenariosDetailsList[scenarioIndex];
    const currentStep = testScenario.stepsDetailsList[stepIndex];
    const previousStep = stepIndex > 0 ? testScenario.stepsDetailsList[stepIndex - 1] : null;
    const currentStepStatus = currentStep.stepStatus.toLowerCase();
    const previousStepStatus = previousStep != null ? previousStep.stepStatus.toLowerCase() : '';

    return currentStepStatus === 'start' ||
      currentStepStatus === 'processing' ||
      currentStepStatus === 'ready' ||
      currentStepStatus === 'pending' ||
      (previousStepStatus === 'complete' && currentStepStatus === '');
  }

  getStepStyleClass(status: string, loadIcon: boolean) {
    status = status.toLowerCase();
    let stepClass = '';
    if (loadIcon) {
      switch (status) {
        case "start":
          stepClass = 'fa-cog';
          break;
        case "ready":
          stepClass = 'fa-cog';
          break;
        case "processing":
          stepClass = 'fa-cog';
          break;
        case "pending":
          stepClass = 'fa-clock-o';
          break;
        case "complete":
          stepClass = 'fa-check';
          break;
        case "error":
          stepClass = 'fa-exclamation';
          break;
        case "skipped":
          stepClass = 'fa-fast-forward';
          break;
        default:
          stepClass = 'fa-hourglass';
      }
    }
    stepClass += (' md-step-circle-' + (status == '' ? 'waiting' : status));
    return stepClass;
  }

  filterTestScenarios(testRunIndex: number, isRefresh: boolean = false, isExport: boolean = false) {
    const parent = this;
    parent.manageTestRunsService.ngxLoaderConfig.text = isExport ? 'Preparing Test Runs for Export! Please wait!' : 'Loading Test Run Scenarios Details! Please wait!';
    parent.ngxLoader.start();
    if (parent.statusFilter === undefined || parent.statusFilter === null)
      parent.statusFilter = 0;
    parent.testRunsFetchInputs.userEmail = parent.testRunsList[testRunIndex].userEmail;
    parent.testRunsFetchInputs.testRunId = parent.testRunsList[testRunIndex].testRunId;
    parent.testRunsFetchInputs.organizationId = parent.organizationId;
    parent.testRunsFetchInputs.statusFilter = parent.filterItems.find(x => x.id === parent.statusFilter).name;
    if (isExport) {
      if (parent.testRunsFetchInputs.statusFilter === 'All') {
        for (const [key, value] of Object.entries(this.selectedTestRun.statusCounts)) {
          if (value > 0) {
            parent.testRunsFetchInputs.statusFilter = parent.filterItems.find(x => x.name.toLowerCase() === key.toLowerCase()).name;
            parent.statusFilter = parent.filterItems.find(x => x.name.toLowerCase() === key.toLowerCase()).id;
            break;
          }
        }
      }
      parent.testRunsFetchInputs.isExport = parent.statusFilter > 0 && isExport;
      parent.manageTestRunsService.getTestRunsExportDetails(parent.testRunsFetchInputs).subscribe((response: TestRunExportResponse[]) => {
        if (response != null) {
          parent.prepareExportDetails(response);
          parent.ngxLoader.stop();
        } else {
          parent.ngxLoader.stop();
        }
      }, (error: any) => {
        parent.ngxLoader.stop();
      });
    } else {
      parent.childPageNumber = (parent.testRunsFetchInputs.pageNumber < 1 || isRefresh) ? 1 : parent.childPageNumber + 1;
      parent.testRunsFetchInputs.pageNumber = parent.childPageNumber;
      parent.manageTestRunsService.getTestRunsDetails(parent.testRunsFetchInputs).subscribe((response: TestRunsDetails) => {
        if (response != null) {
          if (isRefresh) {
            parent.testRunsList[testRunIndex].scenariosDetailsList = JSON.parse(JSON.stringify(response.testRunsItemDetailsList[0].scenariosDetailsList));
            parent.selectedTestRun.scenariosDetailsList = JSON.parse(JSON.stringify(response.testRunsItemDetailsList[0].scenariosDetailsList));
          }
          else {
            Array.prototype.push.apply(parent.testRunsList[testRunIndex].scenariosDetailsList, response.testRunsItemDetailsList[0].scenariosDetailsList);
            Array.prototype.push.apply(parent.selectedTestRun.scenariosDetailsList, response.testRunsItemDetailsList[0].scenariosDetailsList);
          }
          if (!parent.testRunsList[testRunIndex].isMultiPayload && parent.testRunsList[testRunIndex].scenariosDetailsList.length <= 10 && parent.selectedTestRunId !== parent.testRunsList[testRunIndex].testRunId) {
            parent.selectedTestRunId = parent.testRunsList[testRunIndex].testRunId;
          }
          parent.ngxLoader.stop();
        } else {
          parent.ngxLoader.stop();
        }
      }, (error: any) => {
        parent.ngxLoader.stop();
      });
    }
  }

  isValidJSON(object: string) {
    try {
      JSON.parse(object);
      return true;
    }
    catch {
      return false;
    }
  }

  getValidJSONObject(object: string) {
    object = object.replace('{""', '{"').replace(',""', ',"');
    if (object == '') {
      return object;
    } else {
      if (/^[\],:{}\s]*$/.test(object.replace(/\\["\\\/bfnrtu]/g, '@').replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').replace(/(?:^|:|,)(?:\s*\[)+/g, ''))) {
        return JSON.parse(object);
      } else if (object.charAt(0).toLowerCase() != '[' && object.charAt(0).toLowerCase() != '{') {
        return { object };
      } else {
        try {
          return JSON.parse(object);
        } catch {
          return { object };
        }
      }
    }
  }

  getMultipathValue(processModel: any, xpath: string, result: string) {
    if (xpath.indexOf('.') >= 0) {
      if (Array.isArray(processModel)) {
        for (let p = 0; p < processModel.length; p++) {
          result = this.getMultipathValue(processModel[p], xpath, result);
        }
      }
      else {
        var array = xpath.split('.');
        for (let i = 0; i < array.length; i++) {
          var element = processModel[array[i]];
          if (element != undefined && element != null) {
            var firstDot = xpath.match(/\./);
            xpath = xpath.substring(firstDot.index + 1);
            if (Array.isArray(element)) {
              result = this.getMultipathValue(element, xpath, result);
            }
            else {
              processModel = element;
            }
          }
          else {
            return result;
          }
        }
      }
    }
    else {
      if (Array.isArray(processModel)) {
        for (let p = 0; p < processModel.length; p++) {
          result += (processModel[p][xpath] != undefined && processModel[p][xpath] != null) ? (processModel[p][xpath] + '~') : '';
        }
      }
      else {
        result += (processModel[xpath] != undefined && processModel[xpath] != null) ? (processModel[xpath] + '~') : '';
      }
    }
    return result.substring(0, result.lastIndexOf('~'));
  }

  getFormattedErrorDetails(stepResponse: string, errorKeys: string, errorMessages: string) {
    let errorDetails = [];
    if (errorKeys != undefined && errorKeys != null && errorKeys != '') {
      const response = this.getValidJSONObject(stepResponse);
      const errorArray = errorKeys.indexOf(',') >= 0 ? errorKeys.split(',') : errorKeys;
      if (isArray(errorKeys)) {
        for (let index = 0; index < errorArray.length; index++) {
          const element = errorArray[index];
          const result = this.getMultipathValue(response, element, '');
          if (result != '') {
            errorDetails.push(result.indexOf('~') >= 0 ? result.split('~') : result);
          }
        }
      }
      else {
        const result = this.getMultipathValue(response, errorKeys, '');
        if (result != '') {
          errorDetails.push(result);
        }
      }
    }
    if (errorMessages != undefined && errorMessages != null && errorMessages != '' && errorDetails.length <= 0) {
      return errorMessages;
    }
    return JSON.stringify(errorDetails);
  }

  getRestartStepStatus(conditions: any, responseObject: string) {
    let status = true;

    if (this.isValidJSON(responseObject)) {
      const response = this.getValidJSONObject(responseObject);
      const path = conditions["path"];
      const op = conditions["operator"];
      const rhs = conditions["value"].toLowerCase();

      let lhs;
      if (response["path"] != null || response["path"] != undefined) {
        lhs = response[path].toLowerCase();
      } else {
        lhs = '';
      }

      switch (op) {
        case "==":
          status = lhs == rhs;
          break;
        case "!=":
          status = lhs != rhs;
          break;
        default:
          status = false;
      }
    }
    return status;
  }

  isRestartStepEnabled(restartCondition: string, responseObject: string) {
    let status = true;
    if (restartCondition != undefined && restartCondition != null && restartCondition != '') {
      const conditions = JSON.parse(restartCondition);
      if (isArray(conditions)) {
        for (let index = 0; index < conditions.length; index++) {
          const element = conditions[index];
          status = this.getRestartStepStatus(element, responseObject);
          if (status == false) {
            break;
          }
        }
      }
      else {
        status = this.getRestartStepStatus(conditions, responseObject);
      }
    }
    return status;
  }

  showPayloadDetails(responseModal: any, testRunIndex: number, scenarioIndex: number, stepIndex: number, childScenarioIndex: number, childStepIndex: number, isPrestep: boolean = false) {
    this.modalInputs.modalType = 'payload';
    this.modalInputs.headerText = 'Request/Response Details';
    this.modalInputs.errorPayload = '';
    this.modalInputs.refreshRestartDisabled = false;
    this.modalInputs.disableFileDownload = false;
    this.modalInputs.isPrestep = isPrestep;
    this.modalInputs.adoTestRunId = "";
    this.modalInputs.adoTestRunResultId = "";
    this.modalInputs.adoTestCaseId = "";
    this.modalInputs.showResponseDownload = true;
    this.modalInputs.showResponseJson = true;

    if (this.eventPayload != null && this.eventPayload != '' && testRunIndex <= 0) {
      this.modalInputs.requestPayload = '';
      this.modalInputs.responsePayload = this.getValidJSONObject(this.eventPayload);
      this.modalInputs.enableRestart = false;
      this.modalInputs.enableDownload = true;
      this.modalInputs.enableNextStep = false;
    }
    else {
      this.modalInputs.testRunIndex = testRunIndex;
      this.modalInputs.scenarioIndex = scenarioIndex;
      this.modalInputs.stepIndex = stepIndex;
      this.modalInputs.childScenarioIndex = childScenarioIndex;
      this.modalInputs.childStepIndex = childStepIndex;
      const stepDetails = isPrestep ? this.testRunsList[testRunIndex].testRunPrestepDetails.stepsDetailsList[stepIndex] : (childScenarioIndex > -1 ? this.testRunsList[testRunIndex].scenariosDetailsList[scenarioIndex].stepsDetailsList[stepIndex].childScenariosList[childScenarioIndex].stepsDetailsList[childStepIndex] : this.testRunsList[testRunIndex].scenariosDetailsList[scenarioIndex].stepsDetailsList[stepIndex]);
      const scenariosList = isPrestep ? this.testRunsList[testRunIndex].testRunPrestepDetails : (childScenarioIndex > -1 ? this.testRunsList[testRunIndex].scenariosDetailsList[scenarioIndex].stepsDetailsList[stepIndex].childScenariosList[childScenarioIndex] : this.testRunsList[testRunIndex].scenariosDetailsList[scenarioIndex]);
      this.testrunCorrelationId = scenariosList.correlationId;
      this.componentName = stepDetails.componentName;
      this.usetInputDetails = stepDetails.userInput;
      this.responseStep = stepDetails.stepResponse;
      this.modalInputs.requestPayload = this.getValidJSONObject(stepDetails.userInput);
      this.modalInputs.responsePayload = this.getValidJSONObject(stepDetails.stepResponse);
      this.modalInputs.componentMethod = stepDetails.componentMethod;
      this.modalInputs.componentUrl = stepDetails.componentUrl;
      this.modalInputs.enableDownload = false;
      const stepStatus = stepDetails.stepStatus.toLowerCase();
      this.modalInputs.enableForManualStep = stepDetails.isManualStep;
      if (stepStatus == 'error' || stepStatus == 'pending') {
        if (stepStatus == 'error') {
          this.modalInputs.enableNextStep = stepDetails.allowProceedOnError;
          this.modalInputs.enableRestart = this.isRestartStepEnabled(stepDetails.restartDisplayCondition, stepDetails.stepResponse);
          this.modalInputs.enableRefresh = stepDetails.enableRefresh;
          this.modalInputs.enableErrorEmail = stepDetails.enableErrorEmail;
          this.modalInputs.errorEmaillink = stepDetails.errorEmaillink;
        }
        this.modalInputs.errorPayload = this.getValidJSONObject(this.getFormattedErrorDetails(stepDetails.stepResponse, stepDetails.downloadErrorKey, stepDetails.customErrorMessages));
        this.error = this.getFormattedErrorDetails(stepDetails.stepResponse, stepDetails.downloadErrorKey, stepDetails.customErrorMessages);
      }
      else if (stepStatus == 'complete') {
        this.modalInputs.enableRestart = true;
        this.modalInputs.enableRefresh = this.modalInputs.componentMethod == 'GET' ? true : false;

      }
      else {
        this.modalInputs.enableRestart = false;
        this.modalInputs.enableNextStep = false;
      }
    }

    this.modalInputs.visibleSections = 0;

    if (this.modalInputs.requestPayload != '') {
      this.modalInputs.isVisibleRequest = true;
      this.modalInputs.visibleSections += 1;
    } else {
      this.modalInputs.isVisibleRequest = false;
    }

    if (this.modalInputs.responsePayload != '') {
      this.modalInputs.isVisibleResponse = true;
      this.modalInputs.visibleSections += 1;
    } else {
      this.modalInputs.isVisibleResponse = false;
    }

    if (this.modalInputs.errorPayload != undefined && this.modalInputs.errorPayload != null && this.modalInputs.errorPayload != '') {
      this.modalInputs.isVisibleError = true;
      this.modalInputs.visibleSections += 1;
    } else {
      this.modalInputs.isVisibleError = false;
    }

    try {
      //Cannot always be parsed to a valid JSON
      /** Disabling Restart/Refresh button based on specific Worksoft Testrun status */
      var responseObject = JSON.parse(this.responseStep);
      if (responseObject.hasOwnProperty("Status") && (responseObject["Status"] == 'WorksoftInfraIssue') || (responseObject.hasOwnProperty("TestOutputData") && responseObject["Status"] == 'failed')) {
        this.modalInputs.refreshRestartDisabled = true;
      }

      //Toggle enable/disable excel download button based on if file exists [FilePath property] or not in response result
      if ((responseObject).hasOwnProperty('TestOutputData')) {
        (responseObject)['TestOutputData'].forEach(r => {
          if (r.hasOwnProperty("FilePath")) { this.modalInputs.disableFileDownload = true; }
        });
      }

      // enable Test Run Link to ADO
      if (responseObject.hasOwnProperty("ADO_Test_Run_Id")) {
        this.modalInputs.adoTestRunId = responseObject.ADO_Test_Run_Id;
        this.modalInputs.adoTestRunResultId = responseObject.ADO_Test_Run_Result_Id;
        this.modalInputs.adoTestCaseId = responseObject.ADO_Test_Case_Id;
        this.modalInputs.showResponseDownload = false;
        this.modalInputs.showResponseJson = false;
        this.modalInputs.isVisibleRequest = false;
        this.modalInputs.visibleSections = 1;
      } else {
        this.modalInputs.adoTestRunId = "";
        this.modalInputs.adoTestRunResultId = "";
        this.modalInputs.adoTestCaseId = "";
        this.modalInputs.showResponseDownload = true;
        this.modalInputs.showResponseJson = true;
      }
    } catch (Error) {
      this.logger.trackException(Error.message);
    }

    this.modalService.open(responseModal, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', windowClass: "myCustomModalClass" }).result.then((result) => {
      this.logger.trackTrace(`In Manage Testruns, show Payload Details  ${result} `);
    });
  }

  downloadPayload(event: string) {
    let fileName = '';
    if (this.eventPayload != null && this.eventPayload != '' && event == 'response') {
      let fileName = (this.searchText != undefined && this.searchText != null && this.searchText != '') ? this.searchText : [...this.trackOrderRequest.entityIDs];
      this.downloadTextFile(this.eventPayload, fileName + '.json');
    } else if (this.responseStep != null && this.responseStep !== '' && event == 'response') {
      fileName = this.componentName + this.testrunCorrelationId;
      console.log(this.responseStep)
      this.downloadTextFile(this.responseStep, "response" + fileName + '.json');
    } else if (this.usetInputDetails != null && this.usetInputDetails !== '' && event == 'request') {
      fileName = this.componentName + this.testrunCorrelationId;
      this.downloadTextFile(this.usetInputDetails, "request" + fileName + '.json');
    } else if (this.modalInputs.errorPayload != null && this.modalInputs.errorPayload !== '' && event == 'error') {
      fileName = this.componentName + this.testrunCorrelationId;
      this.downloadTextFile(this.error, "error" + fileName + '.json');
    } else if (this.responseStep != null && this.responseStep != '' && event == 'testrunreport') {
      var responseStepObject = JSON.parse(this.responseStep);
      if (responseStepObject.hasOwnProperty('TestOutputData')) {
        responseStepObject['TestOutputData'].forEach(e => {
          if (e.hasOwnProperty("FilePath")) {
            fileName = e.FilePath.toString().split('\\').pop();
            this.downloadFileUsingSAS(fileName);
          }
        });
      }
    }
  }

  downloadFile(fileName: string) {
    if (fileName != '' && fileName != undefined) {
      const link = document.createElement('a');
      link.setAttribute('type', 'hidden');
      link.setAttribute('download', '');
      link.href = fileName;
      link.click();
    }
  }

  downloadTextFile(text: any, name: string) {
    const a = document.createElement('a');
    const type = name.split('.').pop();
    a.href = URL.createObjectURL(new Blob([text], { type: `text/${type === 'txt' ? 'plain' : type}` }));
    a.download = name;
    a.click();
  }

  startNextStepComponent(messageModal: any) {
    const parent = this;
    parent.manageTestRunsService.ngxLoaderConfig.text = 'Starting the Next Step Component of the Business Process (L4)! Please Wait!';
    parent.ngxLoader.start();
    let stepInput = new UpdateScenarioStepInput();
    const targetScenario = parent.modalInputs.isPrestep ? parent.testRunsList[parent.modalInputs.testRunIndex].testRunPrestepDetails : parent.modalInputs.childScenarioIndex > -1 ? parent.testRunsList[parent.modalInputs.testRunIndex].scenariosDetailsList[parent.modalInputs.scenarioIndex].stepsDetailsList[parent.modalInputs.stepIndex].childScenariosList[parent.modalInputs.childScenarioIndex] : parent.testRunsList[parent.modalInputs.testRunIndex].scenariosDetailsList[parent.modalInputs.scenarioIndex];
    stepInput.correlationId = targetScenario.correlationId;
    parent.correlationId = stepInput.correlationId
    stepInput.stepRank = targetScenario.stepsDetailsList[parent.modalInputs.stepIndex].stepRank;
    parent.modalInputs.modalType = 'response';
    if (!parent.modalInputs.isPrestep && parent.testRunsList[parent.modalInputs.testRunIndex].scenariosDetailsList[parent.modalInputs.scenarioIndex].stepsDetailsList[parent.modalInputs.stepIndex].isManualStep && parent.testRunsList[parent.modalInputs.testRunIndex].scenariosDetailsList[parent.modalInputs.scenarioIndex].stepsDetailsList[parent.modalInputs.stepIndex].stepStatus === 'Complete') {
      stepInput.completeCurrentStep = true;
    }
    parent.manageTestRunsService.startScenarioStep(stepInput).subscribe((response) => {
      if (response) {
        parent.testRunsList[parent.modalInputs.testRunIndex].statusCounts.error -= 1;
        parent.testRunsList[parent.modalInputs.testRunIndex].statusCounts.start += 1;
        if (parent.modalInputs.isPrestep) {
          parent.testRunsList[parent.modalInputs.testRunIndex].testRunPrestepDetails.stepsDetailsList[parent.modalInputs.stepIndex].stepStatus = 'Skipped';
          parent.testRunsList[parent.modalInputs.testRunIndex].testRunPrestepDetails.stepsDetailsList[parent.modalInputs.stepIndex + 1].stepStatus = 'Start';
        }
        else {
          if (parent.testRunsList[parent.modalInputs.testRunIndex].scenariosDetailsList[parent.modalInputs.scenarioIndex].stepsDetailsList[parent.modalInputs.stepIndex].isManualStep) {
            stepInput.completeCurrentStep = true;
            parent.testRunsList[parent.modalInputs.testRunIndex].scenariosDetailsList[parent.modalInputs.scenarioIndex].stepsDetailsList[parent.modalInputs.stepIndex].stepStatus = 'Complete';
          }
          else {
            parent.testRunsList[parent.modalInputs.testRunIndex].scenariosDetailsList[parent.modalInputs.scenarioIndex].stepsDetailsList[parent.modalInputs.stepIndex].stepStatus = 'Skipped';
          }
          parent.testRunsList[parent.modalInputs.testRunIndex].scenariosDetailsList[parent.modalInputs.scenarioIndex].stepsDetailsList[parent.modalInputs.stepIndex + 1].stepStatus = 'Start';
        }
        this.refreshTestRuns(parent.modalInputs.testRunIndex, parent.testRunsList[parent.modalInputs.testRunIndex].testRunId, false);
        parent.ngxLoader.stop();
        parent.modalInputs.headerText = 'Step Start Successful!';
        parent.modalInputs.bodyText = 'Next Step Component Started Successfully!';
        parent.modalService.open(messageModal, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' }).result.then((result) => {
          this.logger.trackTrace(`In Manage testruns, start Next Step Component ${parent.modalInputs.headerText}, ${parent.modalInputs.bodyText} ${result} `);
        });
      }
      else {
        parent.ngxLoader.stop();
        parent.modalInputs.headerText = 'Step Start Failed!';
        parent.modalInputs.bodyText = 'Next Step Component Start Failed!';
        parent.modalService.open(messageModal, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' }).result.then((result) => {
          this.logger.trackTrace(`In Manage testruns, start Next Step Component ${parent.modalInputs.headerText}, ${parent.modalInputs.bodyText} ${result} `);
        });
      }
    }, (error: any) => {
      parent.ngxLoader.stop();
      parent.modalInputs.headerText = 'Step Start Failed!';
      parent.modalInputs.bodyText = 'Next Step Component Start Failed! Error Details: ' + error;
      parent.modalService.open(messageModal, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' }).result.then((result) => {
        this.logger.trackTrace(`In Manage testruns, start Next Step Component ${parent.modalInputs.headerText}, ${parent.modalInputs.bodyText} ${result} `);
      });
    });
  }

  restartIndvErrorStep(messageModal: any) {
    this.manageTestRunsService.ngxLoaderConfig.text = this.modalInputs.enableRefresh ? 'Refreshing Current Step! Please Wait!' : 'Restarting Current Step! Please Wait!';
    this.ngxLoader.start();
    this.restartStepRequestsList = [];
    this.restartStepResponsesList = [];
    const targetScenario = this.modalInputs.isPrestep ? this.testRunsList[this.modalInputs.testRunIndex].testRunPrestepDetails : this.testRunsList[this.modalInputs.testRunIndex].scenariosDetailsList[this.modalInputs.scenarioIndex];
    this.buildErrorStepInputsList(messageModal, targetScenario, this.modalInputs.stepIndex, this.modalInputs.isPrestep);
    if (this.restartStepRequestsList.length > 0) {
      this.submitErrorStepRestart(messageModal, this.modalInputs.testRunIndex);
    }
  }

  restartAllErrorSteps(messageModal: any, testRunIndex: number) {
    const parent = this;
    parent.manageTestRunsService.ngxLoaderConfig.text = 'Restarting All Error Steps of the Test Run! Please Wait!';
    parent.ngxLoader.start();
    if (parent.testRunsList[testRunIndex].isMultiPayload || parent.testRunsList[testRunIndex].totalScenariosCount > 10) {
      let errorStepInput = new ErrorRestartStepInputs();
      errorStepInput.correlationId = parent.testRunsList[testRunIndex].testRunId;
      errorStepInput.stepRank = 0;
      parent.manageTestRunsService.restartAllScenarioStep(errorStepInput).subscribe((response) => {
        if (response) {
          parent.loadTestRunsItemDetails(testRunIndex, errorStepInput.correlationId, false, true, false);
          parent.ngxLoader.stop();
        }
        else {
          parent.ngxLoader.stop();
          parent.modalInputs.modalType = 'response';
          parent.modalInputs.headerText = 'Restart Failed!';
          parent.modalInputs.bodyText = 'Test Runs Business Process (L4) Step Restart Failed!';
          parent.modalService.open(messageModal, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' }).result.then((result) => {
            this.logger.trackTrace(`In Manage testruns, submit Error Step Restart ${parent.modalInputs.headerText}, ${parent.modalInputs.bodyText} ${result} `);
          });
        }
      }, (error: any) => {
        parent.ngxLoader.stop();
        parent.modalInputs.modalType = 'response';
        parent.modalInputs.headerText = 'Restart Failed!';
        parent.modalInputs.bodyText = 'Test Runs Business Process (L4) Step Restart Failed!';
        parent.modalService.open(messageModal, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' }).result.then((result) => {
          this.logger.trackTrace(`In Manage testruns, submit Error Step Restart ${parent.modalInputs.headerText}, ${parent.modalInputs.bodyText} ${result} `);
        });
      });
    }
    else {
      this.restartStepRequestsList = [];
      this.restartStepResponsesList = [];
      if (this.testRunsList[testRunIndex].scenariosDetailsList.length > 0) {
        for (let index = 0; index < this.testRunsList[testRunIndex].scenariosDetailsList.length; index++) {
          const targetScenario = this.testRunsList[testRunIndex].scenariosDetailsList[index];
          const stepIndex = targetScenario.stepsDetailsList.findIndex(x => x.stepStatus.toLowerCase() == 'error')
          this.buildErrorStepInputsList(messageModal, targetScenario, stepIndex, false);
        }
      }
      if (this.restartStepRequestsList.length > 0) {
        this.submitErrorStepRestart(messageModal, testRunIndex);
      }
    }
  }

  buildErrorStepInputsList(messageModal: any, targetScenario: TestRunScenariosDetails, stepIndex: number, isPreReqStep: boolean = false) {
    if (stepIndex < 0) {
      return;
    }

    const parent = this;
    let errorStepInput = new ErrorRestartStepInputs();
    errorStepInput.isPrestep = isPreReqStep;
    errorStepInput.correlationId = targetScenario.correlationId;
    errorStepInput.stepRank = targetScenario.stepsDetailsList[stepIndex].stepRank;
    parent.restartStepRequestsList.push(errorStepInput);

    if (targetScenario.stepsDetailsList[stepIndex].childScenariosList.length > 0) {
      parent.buildErrorChildStepInputsList(targetScenario, stepIndex);
    }
  }

  buildErrorChildStepInputsList(targetScenario: TestRunScenariosDetails, stepIndex: number) {
    const childsList = targetScenario.stepsDetailsList[stepIndex].childScenariosList;
    childsList.filter(x => x.stepsDetailsList.filter(y => y.stepStatus.toLowerCase() == 'error'))
    for (let index = 0; index < childsList.length; index++) {
      const childScenario = childsList[index];
      if (childScenario.stepsDetailsList.filter(y => y.stepStatus.toLowerCase() == 'error').length > 0) {
        let errorChildStepInput = new ErrorRestartStepInputs();
        errorChildStepInput.correlationId = childScenario.correlationId;
        errorChildStepInput.stepRank = childScenario.stepsDetailsList.filter(y => y.stepStatus.toLowerCase() == 'error')[0].stepRank;
        errorChildStepInput.parentCorrelationId = targetScenario.correlationId;
        errorChildStepInput.parentStepRank = targetScenario.stepsDetailsList[stepIndex].stepRank;
        this.restartStepRequestsList.push(errorChildStepInput);
      }
    }
  }

  async submitErrorStepRestart(messageModal: any, testRunIndex: number) {
    const parent = this;
    parent.restartStepResponsesList = [];
    for (let index = 0; index < parent.restartStepRequestsList.length; index++) {
      const currRequest = parent.restartStepRequestsList[index];
      parent.restartStepResponsesList.push(await parent.manageTestRunsService.restartScenarioStep(currRequest));
      parent.restartStepResponsesList[parent.restartStepResponsesList.length - 1].isPrestep = currRequest.isPrestep;
    }
    if (parent.restartStepResponsesList.length > 0) {
      try {
        parent.restartStepRequestsList = [];
        for (let i = 0; i < parent.restartStepResponsesList.length; i++) {
          const element = parent.restartStepResponsesList[i];
          if (element.parentCorrelationId != '' && element.parentStepRank >= 0) {
            parent.testRunsList[testRunIndex].scenariosDetailsList.filter((x: any) => x.correlationId == element.parentCorrelationId)[0].stepsDetailsList.filter((y: any) => y.stepRank == element.parentStepRank)[0].childScenariosList.filter((z: any) => z.correlationId == element.correlationId)[0].stepsDetailsList.filter((a: any) => a.stepRank == element.stepRank)[0].stepStatus = 'Start';
            parent.selectedTestRun.scenariosDetailsList.filter((x: any) => x.correlationId == element.parentCorrelationId)[0].stepsDetailsList.filter((y: any) => y.stepRank == element.parentStepRank)[0].childScenariosList.filter((z: any) => z.correlationId == element.correlationId)[0].stepsDetailsList.filter((a: any) => a.stepRank == element.stepRank)[0].stepStatus = 'Start';
          }
          else {
            if (element.isPrestep) {
              parent.testRunsList[testRunIndex].testRunPrestepDetails.stepsDetailsList.filter((y: any) => y.stepRank == element.stepRank)[0].stepStatus = 'Start';
              parent.selectedTestRun.testRunPrestepDetails.stepsDetailsList.filter((y: any) => y.stepRank == element.stepRank)[0].stepStatus = 'Start';
            }
            else {
              parent.testRunsList[testRunIndex].scenariosDetailsList.filter((x: any) => x.correlationId == element.correlationId)[0].stepsDetailsList.filter((y: any) => y.stepRank == element.stepRank)[0].stepStatus = 'Start';
              parent.selectedTestRun.scenariosDetailsList.filter((x: any) => x.correlationId == element.correlationId)[0].stepsDetailsList.filter((y: any) => y.stepRank == element.stepRank)[0].stepStatus = 'Start';
            }
            parent.testRunsList[testRunIndex].statusCounts.error -= 1;
            parent.selectedTestRun.statusCounts.error -= 1;
            parent.testRunsList[testRunIndex].statusCounts.start += 1;
            parent.selectedTestRun.statusCounts.start += 1;
          }
        }
      }
      catch (error) {
        parent.ngxLoader.stop();
        parent.modalInputs.modalType = 'response';
        parent.modalInputs.headerText = 'Restart Failed!';
        parent.modalInputs.bodyText = 'Test Runs Business Process (L4) Step Restart Failed!';
        parent.modalService.open(messageModal, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' }).result.then((result) => {
          this.logger.trackTrace(`In Manage testruns, submit Error Step Restart ${parent.modalInputs.headerText}, ${parent.modalInputs.bodyText} ${result} `);
        });
      }
      parent.restartStepResponsesList = [];
      parent.ngxLoader.stop();
      parent.modalInputs.modalType = 'response';
      parent.modalInputs.headerText = 'Restart Successful!';
      parent.modalInputs.bodyText = 'Test Runs Business Process (L4) Step(s) Restarted Successfully!';
      parent.modalService.open(messageModal, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' }).result.then((result) => {
        this.logger.trackTrace(`In Manage testruns, submited Error Step Restart ${parent.modalInputs.headerText}, ${parent.modalInputs.bodyText} ${result} `);
      });
    }
    else {
      parent.ngxLoader.stop();
      parent.modalInputs.modalType = 'response';
      parent.modalInputs.headerText = 'Restart Failed!';
      parent.modalInputs.bodyText = 'Test Runs Business Process (L4) Step Restart Failed!';
      parent.modalService.open(messageModal, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' }).result.then((result) => {
        this.logger.trackTrace(`In Manage testruns, submit Error Step Restart ${parent.modalInputs.headerText}, ${parent.modalInputs.bodyText} ${result} `);
      });
    }
  }
  handleInput(event: KeyboardEvent): void {
    event.stopPropagation();
  }
  updateSaveStatus(messageModal: any, index: number) {
    const parent = this;
    parent.manageTestRunsService.ngxLoaderConfig.text = 'Updating the Save Status of the Test Run! Please Wait!';
    parent.ngxLoader.start();
    parent.modalInputs = new ModalInputs();
    let updateInput = new TestRunSaveStatusInput();
    parent.modalInputs.modalType = 'response';
    updateInput.testRunId = parent.testRunsList[index].testRunId;
    updateInput.saveStatus = !parent.testRunsList[index].isSavedRun;
    parent.manageTestRunsService.updateTestRunSaveStatus(updateInput).subscribe((response) => {
      if (response != undefined && response != null && response == true) {
        parent.testRunsList[index].isSavedRun = !parent.testRunsList[index].isSavedRun;
        parent.ngxLoader.stop();
        parent.modalInputs.headerText = 'Saved Successfullly!';
        parent.modalInputs.bodyText = 'This Test Runs is Saved Successfully!';
        parent.modalService.open(messageModal, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' }).result.then((result) => {
          this.logger.trackTrace(`In Manage testruns, update Save Status ${parent.modalInputs.headerText}, ${parent.modalInputs.bodyText} ${result} `);
        });
      } else {
        parent.ngxLoader.stop();
        parent.modalInputs.headerText = 'Status Update Failed!';
        parent.modalInputs.bodyText = 'This Test Runs Save Status Update Failed!';
        this.logger.trackTrace(`In Manage testruns update Save Status ${parent.modalInputs.headerText}, ${parent.modalInputs.bodyText} `);
        parent.modalService.open(messageModal, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' }).result.then((result) => {
        });
      }
    }, (error: any) => {
      parent.ngxLoader.stop();
      parent.modalInputs.headerText = 'Status Update Failed!';
      parent.modalInputs.bodyText = 'This Test Runs Save Status Update Failed!';
      this.logger.trackTrace(`In Manage testruns update Save Status Error ${error} ${parent.modalInputs.headerText}, ${parent.modalInputs.bodyText} `);
      this.logger.trackException(error);
      parent.modalService.open(messageModal, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' }).result.then((result) => {
      });
    });
  }

  /**
   * Stores the current event metadata (indices of current test run, scenario of test run, and step within scenario) that are being tracked
   * @param testRunIndex Index of Test Run within TestRunsList[]
   * @param scenarioIndex Index of Scenario within TestRunScenariosDetails[]
   * @param stepIndex Index of Step within TestRunStepsDetails[]
   */
  storeEventMetadata(testRunIndex: number, scenarioIndex: number, stepIndex: number): void {
    this.currentEventMetadata.testRunIndex = testRunIndex;
    this.currentEventMetadata.scenarioIndex = scenarioIndex;
    this.currentEventMetadata.stepIndex = stepIndex;
  }

  /**
   * Generates the "mailto" email template with Test Run information pre-populated - metadata is current to event being displayed
   */
  generateMailtoString(testRunName: string, testRunScenarioName: string, testRunScenarioStepName: string, entityId: string[]): string {
    const mailto = 'mailto:';
    const recipient = this.configService.uiAppSettings.IssueReportAlias.alias + '@microsoft.com';
    const subject = 'Report an Issue: <Enter Your Issue Here>';
    const cc = ' ';   // Cannot be empty string
    const bcc = ' ';  // Cannot be empty string
    const body = `Test name: ${testRunName}\nScenario Name: ${testRunScenarioName}\nStep Name: ${testRunScenarioStepName}\nEntity #: ${entityId}\nError: <Please fill out the error you encountered>`;
    const mailURL = mailto.concat(recipient, '?cc=', cc, '&bcc=', bcc, '&subject=', subject.replace(/ /g, '%20'), '&body=', body.replace(/ /g, '%20').replace(/\n/g, '%0d%0a'));
    return mailURL;
  }

  /**
   * Launches the "mailto" email with Test Run information pre-populated - metadata is current to event being displayed
   */
  public launchReportIssueMailto(fetchMetadata: boolean): void {
    let testRun: any;
    let testRunName: string;
    let testRunScenario: any;
    let testRunScenarioName: string;
    let testRunScenarioStepName: any;
    if (fetchMetadata &&
      this.currentEventMetadata.testRunIndex >= 0 &&
      this.currentEventMetadata.scenarioIndex >= 0 &&
      this.currentEventMetadata.stepIndex >= 0) {
      testRun = this.testRunsList[this.currentEventMetadata.testRunIndex];
      testRunName = testRun.testRunName;
      testRunScenario = testRun.scenariosDetailsList[this.currentEventMetadata.scenarioIndex];
      testRunScenarioName = testRunScenario.scenarioName;
      testRunScenarioStepName = testRunScenario.stepsDetailsList[this.currentEventMetadata.scenarioIndex].componentName;
      window.location.assign(this.generateMailtoString(testRunName, testRunScenarioName, testRunScenarioStepName, [...this.trackOrderRequest.entityIDs]));
    } else {
      window.location.assign(this.generateMailtoString('<Enter Test Name Here>', '<Enter Scenario Name Here>', '<Enter Step Name Here>', ['<Enter Entity ID Here>']));
    }
  }

  /**Launch Error Email  */
  public launchErrorMailto(): void {
    var email = this.modalInputs.errorEmaillink;
    let response: any;
    response = this.modalInputs.responsePayload;//.runId;
    if (response != null) {
      this.hrefEmail = email.replace("xxx", response.runId);
    }
    window.location.assign(this.hrefEmail);
  }

  downloadFileUsingSAS(fileName: string): void {
    this.generateSasTokenService.getBlobUrl(fileName).subscribe((response: any) => {
      if (response != undefined && response != null) {
        this.testRunsFetchInputs.isExport = false;
        this.downloadFile(response.response);
      }
    }, (error: any) => {
      this.testRunsFetchInputs.isExport = false;
      this.logger.trackTrace('Error occuring in generating SAS token');
      this.logger.trackException(error);
    });
  }

  prepareExportDetails(responseJson: TestRunExportResponse[]) {
    const excelFileName = `TestRunsDetails_${(new Date()).getTime().toString()}.xlsx`;
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(responseJson);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    XLSX.writeFile(workbook, excelFileName);
  }

  checkAnyPreReqErrorExist() {
    return this.selectedTestRun.testRunPrestepDetails !== undefined && this.selectedTestRun.testRunPrestepDetails !== null && this.selectedTestRun.testRunPrestepDetails.stepsDetailsList.some((step) => {
      return step.stepStatus === 'Error';
    });
  }

  isDisplayFilterOption() {
    let distinctStatusCount = 0;
    for (const [key, value] of Object.entries(this.selectedTestRun.statusCounts)) {
      if (value > 0) {
        distinctStatusCount++;
      }
    }
    return !this.checkAnyPreReqErrorExist() && distinctStatusCount > 1;
  }

  isDisplayExportOption() {
    let distinctStatusCount = 0;
    for (const [key, value] of Object.entries(this.selectedTestRun.statusCounts)) {
      if (value > 0) {
        distinctStatusCount++;
      }
    }
    return !this.checkAnyPreReqErrorExist() && this.selectedTestRun.scenariosDetailsList.length > 0 && (distinctStatusCount == 1 || this.statusFilter > 0);
  }

  isDisplayLoadMoreOption() {
    if (this.statusFilter !== undefined && this.statusFilter !== null && this.statusFilter > 0) {
      const processingCount = this.selectedTestRun.statusCounts.processing + this.selectedTestRun.statusCounts.pending + this.selectedTestRun.statusCounts.start + this.selectedTestRun.statusCounts.waiting + this.selectedTestRun.statusCounts.ready;
      return this.statusFilter == 3 ? this.selectedTestRun.scenariosDetailsList.length < processingCount : this.selectedTestRun.scenariosDetailsList.length < this.selectedTestRun.statusCounts[this.filterItems.find(x => x.id === this.statusFilter).name.toLowerCase()];
    }
    else {
      return this.selectedTestRun.scenariosDetailsList.length < this.selectedTestRun.totalScenariosCount;
    }
  }

  getUrlParams(searchId: string = '') {
    return {
      "org": this.organizationId,
      "searchId": searchId
    }
  }
}
