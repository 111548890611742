import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { SerialNumberRequest, SerialNumberRequestsStatus } from '../model/serial-number-request.model';
import { CloneSerialNumberResponse } from '../model/clone-serial-number-response.model';
import { MasterDataService } from '../service/masterdata.service';
import { MonitoringService } from '../service/monitoring.service';
import { SampleFilesService } from '../service/samplefiles.service';
import { BaseComponent } from '../base/base.component';
import { MsalService } from '@azure/msal-angular';
import * as XLSX from 'xlsx';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ManageScenariosService } from '../service/manage-scenarios.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { SerialNumberResponse } from '../model/serial-number-response.model';
import { ConfigService } from '../service/config.service';
import { HardwarePartnumberLookupComponent } from '../hardware-partnumber-lookup/hardware-partnumber-lookup.component';
import * as log from '../AppInsightsLogger';

import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { element } from 'protractor';
import { setTimeout } from 'timers';
import { request } from 'https';
import { DescriptionService } from '../service/description.service';

@Component({
  selector: 'app-serial-number-generator',
  templateUrl: './serial-number-generator.component.html',
  styleUrls: ['./serial-number-generator.component.css']
})

export class SerialNumberGeneratorComponent extends BaseComponent implements OnInit, AfterViewInit {
  @ViewChild(HardwarePartnumberLookupComponent)
  public hardwarePartnumberLookup: HardwarePartnumberLookupComponent;
  viewedChildHwpnLookup = false;

  serialNumberRequest = new SerialNumberRequest();
  productCodeListItems: any[];
  countryList: any[];
  payload: any[];
  showPreloader: boolean;
  msgTxt: string;
  showMessage: boolean;
  logMessage = '';
  name: string;
  email: string;
  deviceType: string;
  productCode: string;
  max: number;
  min: number;
  productName = '';
  newSerialNumberClick: boolean;
  cloneSerialNumber: boolean;

  mailtoScTestSupport: string;
  snResponseSuccessAlert = false;
  showHwpnPreloader = false;
  showCsnPreloader: boolean;
  hwpnLookupInvalidSku: string;
  hideSkuFormatAlert = true;
  invalidSkuFormatRegex = new RegExp('[^a-zA-Z0-9-]|-.*?-');

  headerText = '';
  bodyText = '';
  mode: string;
  excelJsonData: any;
  responseCount: number;
  duplicateSkuList: string;
  serialNumberRequestsStatus: SerialNumberRequestsStatus;
  serialNumberRequestsStatusList: SerialNumberRequestsStatus[];
  fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  fileExtension = '.xlsx';
  private logger: log.AppInsightsLogger;
  pageDetails: any[];
  pageDescription: string;
  partNumber: string;
  hardwarePartNumber: string;
  deviceSerialNumberLength: string;
  lookupCloneSN: boolean;

  constructor(
  
    private masterDataService: MasterDataService,
    private monitoringService: MonitoringService,
    private msalService: MsalService,
    private ngxLoader: NgxUiLoaderService,
    private manageTestRunsService: ManageScenariosService,
    private modalService: NgbModal,
    private descriptionService: DescriptionService,
    private configService: ConfigService) {
    super(monitoringService);
    this.name = msalService.instance.getActiveAccount().name;
    this.email = msalService.instance.getActiveAccount().username;
    this.logger = new log.AppInsightsLogger(configService, msalService);
    this.mailtoScTestSupport = 'mailto:' + this.configService.uiAppSettings.IssueReportAlias.alias + '@microsoft.com';
  }

  ngOnInit() {
    this.logger.trackTrace(`Successfully landed to Serial Number Generator`);
    this.setOrgData();
    this.newSerialNumberClick = true;
    this.cloneSerialNumber = false;
    if (this.cloneSerialNumber == false) {
      this.serialNumberRequest.warranty = "InWarranty";
    } else {
      this.serialNumberRequest.warranty = "";
    }
    this.max = 0;
    this.min = 0;
    this.mode = 'SINGLE';
    this.productCode = '';
    this.duplicateSkuList = '';
    this.responseCount = 0;
    this.masterDataService.getProductCode().subscribe((response: any[]) => {
      this.logger.trackTrace(`Serial Number Generation response ProductCode successfully`);
      this.productCodeListItems = response;
    }, (error: any) => {
      try {
        this.showMessage = true;
        this.showPreloader = false;
        this.addLogLine(error.error.errors[0].message);
        this.msgTxt = error.error.errors[0].message;
      } catch (ex) {
        this.msgTxt = 'Some error occurred while loading productcode';
        this.logger.trackTrace(`Serial Number Generation Error: ${this.msgTxt}`);
        this.logger.trackException(ex);
        this.addLogLine(error.error);
      }
    });

    this.descriptionService.pageDetailsTools().subscribe((response: any[]) => {
      this.pageDetails = response;
      this.pageDetails.forEach(element => {
        if (element.toolName == "Serial Number Generator") {
          this.pageDescription = element.toolDescription
        }
      });
    });
  }

  ngAfterViewInit() {
    this.viewedChildHwpnLookup = true;
  }

  setOrgData() {
    window.sessionStorage.setItem("orgSelected", "1");
  }

  resetModels() {
    this.headerText = '';
    this.bodyText = '';
    this.excelJsonData = '';
    this.serialNumberRequest = new SerialNumberRequest();
    this.serialNumberRequestsStatus = new SerialNumberRequestsStatus();
    this.serialNumberRequestsStatusList = [];
  }

  onClickLookupHwpn() {
    this.hardwarePartnumberLookup.lookup(this.serialNumberRequest.partNumber);
    this.showHwpnPreloader = true;
  }

  hwpnButtonDisabled(): boolean {
    return this.viewedChildHwpnLookup &&
      this.serialNumberRequest.partNumber == null ||
      this.serialNumberRequest.partNumber == '' ||
      this.serialNumberRequest.partNumber.match(this.invalidSkuFormatRegex) != null ||
      this.showHwpnPreloader;
  }

  clonesnbuttonDisabled(): boolean {
    return this.serialNumberRequest.serialNumber == null ||
      this.serialNumberRequest.serialNumber == '' ||
      this.serialNumberRequest.serialNumber.match(this.invalidSkuFormatRegex) != null ||
      this.showCsnPreloader;
  }

  onHardwarePartnumberSelect(selectedHwpn: string) {
    this.serialNumberRequest.hardwarePartNumber = selectedHwpn;
    this.showHwpnPreloader = false;
  }

  /**
   * Given a Part Number (SKU), sets SkuFormatAlert as true or false
   * @param sku Part Number (SKU)
   */
  validateSkuFormat(sku: string) {
    if (sku.match(this.invalidSkuFormatRegex) != null) {
      // if there is a match for non-alphanumeric char or more than 1 hyphen
      this.hideSkuFormatAlert = false;
    } else {
      this.hideSkuFormatAlert = true;
    }
  }

  onNewSerialNumberClick() {
    this.cloneSerialNumber = false
    this.newSerialNumberClick = true
    this.lookupCloneSN = false;
    this.serialNumberRequest.warranty = "InWarranty";
    this.logMessage = "";
    this.payload = [];
    this.serialNumberRequest.quantity = 1;
    this.serialNumberRequest.partNumber = "";
    this.serialNumberRequest.hardwarePartNumber = "";
    this.serialNumberRequest.productCode = "";
    this.serialNumberRequest.productName = "";
    this.serialNumberRequest.countryOfOrigin = "CN";
    this.serialNumberRequest.serialNumberDigit = 12;
      this.serialNumberRequest.serialNumber = "";
    this.serialNumberRequest.environment = "UAT";
    this.serialNumberRequest.cloneSN = this.cloneSerialNumber;
    this.serialNumberRequest.quantity = 1;
  }

  onCloneSerialNumber() {
       this.lookupCloneSN = false;
    this.serialNumberRequest.warranty = "";
    this.newSerialNumberClick = false
    this.cloneSerialNumber = true
    this.logMessage = "";
    this.payload = [];
    this.serialNumberRequest.quantity = 1;
    this.serialNumberRequest.partNumber = "";
    this.serialNumberRequest.hardwarePartNumber = "";
    this.serialNumberRequest.productCode = "";
    this.serialNumberRequest.productName = "";
    this.serialNumberRequest.countryOfOrigin = "CN";
    this.serialNumberRequest.serialNumberDigit = 12;
    this.serialNumberRequest.serialNumber = "";
    this.serialNumberRequest.environment = "UAT";
    this.serialNumberRequest.cloneSN = this.cloneSerialNumber;
    this.serialNumberRequest.quantity = 1;
  }

  onCloneLookupSerialNumber() {
    this.showMessage = false;
    this.showCsnPreloader = true;
    this.lookupCloneSN = false;
    this.logMessage = "";
    this.payload = [];
    this.masterDataService.getCloneUATSerialNumber(this.serialNumberRequest).subscribe((response: CloneSerialNumberResponse) => {
      this.showCsnPreloader = false;
      this.lookupCloneSN = true;
      this.serialNumberRequest.quantity = 1;
      var cloneSNResonse = response;
      this.deviceSerialNumberLength = cloneSNResonse.DeviceSerialNumber;

      if (this.serialNumberRequest.cloneSN == true) {
        this.serialNumberRequest.serialNumberDigit = this.deviceSerialNumberLength.length;
        this.serialNumberRequest.serialNumber = this.deviceSerialNumberLength;
      }

      this.productName = cloneSNResonse.Project;

      for (var i = 0; i < cloneSNResonse.AssemblyCollection.length; i++) {

        cloneSNResonse.AssemblyCollection[i].Attributes.forEach(element => {
          if (element.AttributeName.toUpperCase() == 'COUNTRYOFORIGIN') {
            this.serialNumberRequest.countryOfOrigin = element.AttributeValue;
          }
        })
        this.logger.trackTrace(`Clone Serial Numbers Genealogy generated Sucessfully for ' + ${this.serialNumberRequest.serialNumber}`);
      }
    }, (error: any) => {
      this.showCsnPreloader = false;
        try {
          this.showMessage = true;
          this.showPreloader = false;
          this.addLogLine(error.error.errors[0].message);
          this.msgTxt = error.error.errors[0].message;
          this.logger.trackTrace(`Clone Serial Number Generation Error: ${this.msgTxt}`);
          this.logger.trackException(error);
        } catch (ex) {
          this.msgTxt = 'Some error occurred while processing your request';
          this.logger.trackTrace(`Clone Serial Number Generation Error: ${this.msgTxt}`);
          this.logger.trackException(ex);
          this.addLogLine(error.error);
          this.snResponseSuccessAlert = false;
        }
      });
  }

  onSubmitClick() {
    this.clearLogs();
    this.showPreloader = true; 
    this.showMessage = false;
    this.serialNumberRequest.requesterName = this.name;
    this.serialNumberRequest.requesterEmail = this.email;
    this.serialNumberRequest.productName = this.productName;

    this.masterDataService.generateSerialNumbers(this.serialNumberRequest).subscribe((response: SerialNumberResponse) => {
      this.showPreloader = false;
      let snResponseLog = '';
      let snResponseFruLog = '';
      if (this.cloneSerialNumber == true) {
        this.addLogLine('Serial Number has been generated based on the Clone Serial Number Lookup. You may not be seeing Sub-Components in the genealogy due to Product/Project Code mismatch.');    
      } else if (this.newSerialNumberClick == true) {
        this.addLogLine('Serial Number has been generated based on the SKU. You may not be seeing Sub-Components in the genealogy due to Product/Project Code mismatch.');
      }
       if (this.serialNumberRequest.quantity > response.serialNumbers.length) {
        this.addLogLine(response.errorMessage);
      }
      let frunDataMissing = '';
      for (let i = 0; i < response.serialNumbers.length; i++) {
        this.payload = response.serialNumbers[0].payload;
        for (let k = 0; k < response.serialNumbers[i].fruNotAvailableMessage.length; k++) {
          if (response.serialNumbers[i].fruNotAvailableMessage.length > 0) {
            frunDataMissing += response.serialNumbers[i].fruNotAvailableMessage[k] + '\n' ;
          }
        }

        snResponseLog += '\n' + this.productName + " " + "'<a  target='_blank' href=" + response.serialNumbers[i].baseLink + response.serialNumbers[i].deviceSerialNumber + ">" + response.serialNumbers[i].deviceSerialNumber + "</a>'";
        for (let j = 0; j < response.serialNumbers[i].fruSerialNumbers.length; j++) {
          if (response.serialNumbers[i].fruSerialNumbers.length > 0) {
            snResponseLog += ' [' + response.serialNumbers[i].productCode[j] + ": " + "'<a  target='_blank' href=" + response.serialNumbers[i].baseLink + response.serialNumbers[i].fruSerialNumbers[j] + ">" + response.serialNumbers[i].fruSerialNumbers[j] + "</a>'" + ']';
          }
          snResponseLog += ', ';
        }
        if (response.serialNumbers[i].fruSerialNumbers.length == 0) {
          snResponseLog += ', ';
        }
      }

      snResponseLog = snResponseLog.trim();
      snResponseLog = snResponseLog.substring(0, snResponseLog.length - 1);

      frunDataMissing = frunDataMissing.trim();
      this.addLogLine(frunDataMissing);
       this.logger.trackTrace(`Total Number of Serial Numbers Generated Sucessfully: ' + ${response.serialNumbers.length}`);
      this.addLogLine('Total Number of Serial Numbers Generated Sucessfully: ' + response.serialNumbers.length);
      if (this.serialNumberRequest.cloneSN == true) {
        this.addLogLine('Generated Serial Numbers: ' + '\n' +snResponseLog);
      } else {
        this.addLogLine('Generated Serial Numbers for ' + this.serialNumberRequest.partNumber + ': ' + '\n' + snResponseLog);

      }
      let currentDateTime = new Date();
      this.snResponseSuccessAlert = true;
      this.logger.trackTrace(`Generated Serial Numbers for ' + ${this.serialNumberRequest.partNumber}`);
    }, (error: any) => {
      try {
        this.showMessage = true;
        this.showPreloader = false;
        this.addLogLine(error.error.errors[0].message);
        this.logger.trackException(`Serial Number Generation Error. User Inputs: Request Name: ${this.serialNumberRequest.requesterName} serialNumberPartNumber: ${this.serialNumberRequest.partNumber} serialNumberHardwarePartNumber: ${this.serialNumberRequest.hardwarePartNumber} serialNumberproductCode ${this.serialNumberRequest.productCode} serialNumberQuantity ${this.serialNumberRequest.quantity} countryOfOrigin: ${this.serialNumberRequest.countryOfOrigin}`);
        this.logger.trackException(error);
      } catch (ex) {
        this.logger.trackException(`Serial Number Generation Error: Some error occurred while processing your request. User Inputs: Request Name: ${this.serialNumberRequest.requesterName} serialNumberPartNumber: ${this.serialNumberRequest.partNumber} serialNumberHardwarePartNumber: ${this.serialNumberRequest.hardwarePartNumber} serialNumberproductCode ${this.serialNumberRequest.productCode} serialNumberQuantity ${this.serialNumberRequest.quantity} countryOfOrigin: ${this.serialNumberRequest.countryOfOrigin}`);
        this.logger.trackException(ex);
        this.addLogLine(error.error);
        this.snResponseSuccessAlert = false;
      }
    });
  }

  onSelectionDeviceTypeChangeClick() {
    this.productName = "";
    this.masterDataService.getProductCode().subscribe((response: any[]) => {
      this.productCodeListItems = response;
      for (let i = 0; i < this.productCodeListItems.length; i++) {
        if (this.productCodeListItems[i].productCode == this.serialNumberRequest.productCode) {
          this.productName = this.productCodeListItems[i].productDescription;
            this.serialNumberRequest.partNumber = this.productCodeListItems[i].country[0].partNumber;
        }

        if (this.productCodeListItems[i].productDescription == "Serialized controller") {
          this.serialNumberRequest.serialNumberDigit = 14;
        }
      }
    }, (error: any) => {
      try {
        this.showMessage = true;
        this.showPreloader = false;
        this.addLogLine(error.error.errors[0].message);
        this.msgTxt = error.error.errors[0].message;
      } catch (ex) {
        this.msgTxt = 'Some error occurred while loading productcode';
        this.logger.trackTrace(`Serial Number Generation Error: ${this.msgTxt}`);
        this.addLogLine(error.error);
      }
    });
  }

  onSerialNumberChange() {

    this.masterDataService.getProductCode().subscribe((response: any[]) => {
      this.productCodeListItems = response;
      for (let i = 0; i < this.productCodeListItems.length; i++) {
        if (this.productCodeListItems[i].productCode == this.serialNumberRequest.productCode) {
          if (this.productCodeListItems[i].productDescription == "Serialized controller") {
            this.serialNumberRequest.serialNumberDigit = 14;
            this.max = 14;
            this.min = 14;
          }
        }
      }
    }, (error: any) => {
      try {
        this.showMessage = true;
        this.showPreloader = false;
        this.addLogLine(error.error.errors[0].message);
        this.msgTxt = error.error.errors[0].message;
      } catch (ex) {
        this.msgTxt = 'Some error occurred while loading productcode';
        this.logger.trackTrace(`Serial Number Generation Error: ${this.msgTxt}`);
        this.addLogLine(error.error);
      }
      });

  }

  addLogLine(line: string) {
    let currentDateTime = new Date();
    this.logMessage += '[' + currentDateTime.toLocaleString() + ']' + ' ' + line + '\n';
  }

  clearLogs() {
    this.logMessage = '';
    this.payload = [];
  }

  downloadSampleFile() {
    SampleFilesService.downloadSampleFile(SampleFilesService.GenerateSNsSample);
  }

  processExcelFile(event: any, responseModal: any) {
    const parent = this;
    parent.manageTestRunsService.ngxLoaderConfig.text = 'Processing Uploaded Excel File! Please Wait!';
    parent.ngxLoader.start();
    if (event.target.files.length > 0 && event.target.files[0].name.toLowerCase().indexOf('xls') > 0) {
      const uploadedFile = event.target.files[0];
      let readFile = new FileReader();
      readFile.onload = () => {
        let storeData: any;
        storeData = readFile.result;
        const exceldata = new Uint8Array(storeData);
        let arr = new Array();
        for (var i = 0; i != exceldata.length; ++i) {
          arr[i] = String.fromCharCode(exceldata[i]);
        }
        const workbook = XLSX.read(arr.join(''), { type: 'binary' });
        parent.excelJsonData = JSON.stringify(XLSX.utils.sheet_to_json(workbook.Sheets['Inputs'], { raw: false }));
        this.processJsonDataToModel(responseModal);
      }
      readFile.readAsArrayBuffer(uploadedFile);
    }
    else {
      parent.headerText = 'Invalid File!';
      parent.bodyText = 'You have selected an Invalid File! Please select a valid file and try again!';
      this.logger.trackTrace(`Serial Number Generation Error: ${parent.headerText} ${parent.bodyText}`);
      parent.modalService.open(responseModal, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' }).result.then((result: any) => {
      });
    }
  }

  processJsonDataToModel(responseModal: any) {
    const parent = this;
    let jsonObjSource = JSON.parse(this.excelJsonData);
    this.serialNumberRequestsStatusList = [];
    this.duplicateSkuList = '';
    if (Array.isArray(jsonObjSource)) {
      for (let i = 0; i < jsonObjSource.length; i++) {
        let jsonObj = jsonObjSource[i];
        const partNo = (jsonObj['PartNumber'] != undefined && jsonObj['PartNumber'] != null) ? jsonObj['PartNumber'] : '';
        const hwpn = (jsonObj['HardwarePartNumber'] != undefined && jsonObj['HardwarePartNumber'] != null) ? jsonObj['HardwarePartNumber'] : '';
        if (partNo != '' || hwpn != '') {
          if (this.serialNumberRequestsStatusList.filter(x => x.serialNumberRequest.partNumber.toLowerCase() == partNo.toLowerCase()).length > 0 && this.duplicateSkuList.toLowerCase().indexOf(partNo.toLowerCase()) < 0) {
            this.duplicateSkuList += partNo + ',';
          }
          else {
            this.serialNumberRequest = new SerialNumberRequest();
            this.serialNumberRequest.countryOfOrigin = (jsonObj['CountryOfOrigin'] != undefined && jsonObj['CountryOfOrigin'] != null && jsonObj['CountryOfOrigin'] != '') ? jsonObj['CountryOfOrigin'] : 'CN';
            this.serialNumberRequest.partNumber = jsonObj['PartNumber'];
            this.serialNumberRequest.hardwarePartNumber = jsonObj['HardwarePartNumber'];
            this.serialNumberRequest.productCode = jsonObj['ProductCode'];
            this.serialNumberRequest.quantity = (jsonObj['Quantity'] != undefined && jsonObj['Quantity'] != null && jsonObj['Quantity'] != '') ? parseInt(jsonObj['Quantity']) : 1;
            this.serialNumberRequest.serialNumberDigit = 12;
            this.serialNumberRequest.warranty = (jsonObj['WarrantyType'] != undefined && jsonObj['WarrantyType'] != null && jsonObj['WarrantyType'] != '') ? jsonObj['WarrantyType'] : "InWarranty";
            this.serialNumberRequest.requesterEmail = this.email;
            this.serialNumberRequest.requesterName = this.name;

            this.serialNumberRequestsStatus = new SerialNumberRequestsStatus();
            this.serialNumberRequestsStatus.serialNumberRequest = this.serialNumberRequest;
            this.serialNumberRequestsStatus.responseList = '';

            this.serialNumberRequestsStatusList.push(this.serialNumberRequestsStatus);
          }
        }
      }
    }
    else if ((jsonObjSource['PartNumber'] != undefined && jsonObjSource['PartNumber'] != null && jsonObjSource['PartNumber'] != '') || (jsonObjSource['HardwarePartNumber'] != undefined && jsonObjSource['HardwarePartNumber'] != null && jsonObjSource['HardwarePartNumber'] != '')) {
      this.serialNumberRequest = new SerialNumberRequest();
      this.serialNumberRequest.countryOfOrigin = (jsonObjSource['CountryOfOrigin'] != undefined && jsonObjSource['CountryOfOrigin'] != null && jsonObjSource['CountryOfOrigin'] != '') ? jsonObjSource['CountryOfOrigin'] : 'CN';
      this.serialNumberRequest.partNumber = jsonObjSource['PartNumber'];
      this.serialNumberRequest.hardwarePartNumber = jsonObjSource['HardwarePartNumber'];
      this.serialNumberRequest.productCode = jsonObjSource['ProductCode'];
      this.serialNumberRequest.quantity = (jsonObjSource['Quantity'] != undefined && jsonObjSource['Quantity'] != null && jsonObjSource['Quantity'] != '') ? parseInt(jsonObjSource['Quantity']) : 1;
      this.serialNumberRequest.serialNumberDigit = 12;
      this.serialNumberRequest.warranty = (jsonObjSource['WarrantyType'] != undefined && jsonObjSource['WarrantyType'] != null && jsonObjSource['WarrantyType'] != '') ? jsonObjSource['WarrantyType'] : "InWarranty";
      this.serialNumberRequest.requesterEmail = this.email;
      this.serialNumberRequest.requesterName = this.name;

      this.serialNumberRequestsStatus = new SerialNumberRequestsStatus();
      this.serialNumberRequestsStatus.serialNumberRequest = this.serialNumberRequest;
      this.serialNumberRequestsStatus.responseList = '';

      this.serialNumberRequestsStatusList.push(this.serialNumberRequestsStatus);
    }
    else {
      parent.headerText = 'Data Storage Failed!';
      parent.bodyText = 'There are some Invalid data rows present in the uploaded excel sheet! Please update/remove in the sheet with valid data and try again! For reference please download the sample excel file from the above download link!';
      this.logger.trackTrace(`Serial Number Generation Error: ${parent.headerText} ${parent.bodyText}`);
      parent.modalService.open(responseModal, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' }).result.then((result: any) => {
      });
    }
    if (this.duplicateSkuList != '') {
      this.duplicateSkuList = this.duplicateSkuList.substring(0, this.duplicateSkuList.lastIndexOf(','));
      const dupsArray = this.duplicateSkuList.split(',');
      dupsArray.forEach((element: any) => {
        const index = this.serialNumberRequestsStatusList.map(function (d) { return d.serialNumberRequest.partNumber; }).indexOf(element);
        this.serialNumberRequestsStatusList.splice(index, 1);
      });
      parent.headerText = 'Duplicate SKU(s) found!';
      parent.bodyText = 'Please make sure to use distinct SKUs in the excel sheet! For now removed all these duplicate SKUs:- ' + this.duplicateSkuList;
      this.logger.trackTrace(`Serial Number Generation Error: ${parent.headerText} ${parent.bodyText}`);
      parent.modalService.open(responseModal, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' }).result.then((result: any) => {
      });
    }
    parent.ngxLoader.stop();
  }

  fetchSerialNumbers() {
    const parent = this;
    parent.responseCount = 0;
    for (let i = 0; i < parent.serialNumberRequestsStatusList.length; i++) {
      let jsonObj = parent.serialNumberRequestsStatusList[i];
      jsonObj.showPreloader = true;
      parent.masterDataService.generateSerialNumbers(jsonObj.serialNumberRequest).subscribe((response: SerialNumberResponse) => {
        const partNo = response.partNumber.toLowerCase();
        const hwpn = response.hardwarePartNumber.toLowerCase();
        const snList = response.serialNumbers;
        let snResponseLog = '';
        for (let i = 0; i < response.serialNumbers.length; i++) {
          snResponseLog += response.serialNumbers[i].deviceSerialNumber;
          if (response.serialNumbers[i].fruSerialNumbers.length > 0) {
            snResponseLog += ' - FRU(s):-[' + response.serialNumbers[i].fruSerialNumbers + ']';
          }
          snResponseLog += ', ';
        }
        snResponseLog = snResponseLog.trim();
        snResponseLog = snResponseLog.substring(0, snResponseLog.length - 1);

        parent.serialNumberRequestsStatusList.filter(x => x.serialNumberRequest.partNumber.toLowerCase() == partNo && x.serialNumberRequest.hardwarePartNumber.toLowerCase() == hwpn)[0].responseList = snResponseLog;
        parent.serialNumberRequestsStatusList.filter(x => x.serialNumberRequest.partNumber.toLowerCase() == partNo && x.serialNumberRequest.hardwarePartNumber.toLowerCase() == hwpn)[0].showPreloader = false;
        parent.responseCount++;
      }, (error) => {
        parent.serialNumberRequestsStatusList[i].responseList = error['error'].toString().replace('["', '').replace('"]', '');
        parent.serialNumberRequestsStatusList[i].showPreloader = false;
        parent.responseCount++;
      });
    }
    this.snResponseSuccessAlert = true;
  }

  downloadResultsList() {
    let downloadJson = [];
    for (let i = 0; i < this.serialNumberRequestsStatusList.length; i++) {
      let downloadJsonObj = {};
      downloadJsonObj['PartNumber'] = this.serialNumberRequestsStatusList[i].serialNumberRequest.partNumber;
      downloadJsonObj['HardwarePartNumber'] = this.serialNumberRequestsStatusList[i].serialNumberRequest.hardwarePartNumber;
      downloadJsonObj['Quantity'] = this.serialNumberRequestsStatusList[i].serialNumberRequest.quantity;
      downloadJsonObj['ProductCode'] = this.serialNumberRequestsStatusList[i].serialNumberRequest.productCode;
      downloadJsonObj['SerialNumbersList'] = this.serialNumberRequestsStatusList[i].responseList;
      downloadJson.push(downloadJsonObj);
    }
    this.exportToExcel(downloadJson, 'SerialNumbersList');
  }

  public exportToExcel(jsonData: any[], fileName: string): void {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonData);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'GeneratedSNsList');
    XLSX.writeFile(wb, fileName + this.fileExtension);
  }
}
