<div style="width: auto; height: 100%; overflow: auto;">
  <div *ngIf="isLoading">
    <app-preloader-small></app-preloader-small>
    <div>
      <div style="margin-left: 50px; padding-top: 7px;">Loading ... Please Wait</div>
    </div>
  </div>
  <div *ngIf="!isLoading">
    <h3>UAT Availability View</h3>
    <div>
      <div class="form-group col-sm-12" style="margin-bottom: 25px; margin-right:4px; margin-top:25px; border: 1px solid rgba(0,0,0,.125); border-radius: .25rem;">
        <b>Description:</b>
        <p>{{this.pageDescription}}</p>
      </div>

      <div class="row no-margin padding-top-10 padding-bottom-10">
        <div class="text-right bold" style="padding-right: 20px"> Search by Business Process Name: </div>
        <div style="width: 450px">
          <ng-template #selectBusinessProcessNameTooltip>Select Business Process Name</ng-template>
          <ng-select [items]="businessProcessNamesDetails" bindLabel="businessProcessName" bindValue="businessProcessName"
                     placeholder="Select Business Process Name" [(ngModel)]="selectedBusinessProcessName"
                     (change)="loadBusinessProcessameDetails(selectedBusinessProcessName)"
                     *ngIf="businessProcessNamesDetails.length > 0"
                     arial-label="Select Business Process Name" title="Select Business Process Name" aria-expanded="false"
                     aria-required="true" [ngbTooltip]="selectBusinessProcessNameTooltip" placement="right"
                     [openDelay]="500" [closeDelay]="1500">
          </ng-select>

        </div>
      </div>

      <div *ngIf="isLoadingSelectBusiness">
        <app-preloader-small></app-preloader-small>
        <div>
          <div style="margin-left: 50px; padding-top: 7px;">Loading ... Please Wait</div>
        </div>
      </div>

      <div *ngIf="!isLoadingSelectBusiness" style="height: calc(100vh - 320px); overflow: hidden; overflow-x: auto; overflow-y: auto; margin-top: 25px">
        <table class="table">
          <tbody>
            <tr class="thead-dark">
              <th role="columnheader" scope="col">#</th>
              <th role="columnheader" scope="col" style="width: 250px" *ngFor="let row of uatRowNames">
                <div *ngIf=" row == 'SCOR'" data-toggle="tooltip" data-placement="right" title="Area where the Services belong to">
                  {{row}}
                </div>
                <div *ngIf=" row == 'Service'" data-toggle="tooltip" data-placement="right" title="All service endpoints that Test Platform integrates with">
                  {{row}}
                </div>
                <div *ngIf=" row == 'Total Request'" data-toggle="tooltip" data-placement="right" title="Total numer of requests Test Platform initiated to each service">
                  {{row}}
                </div>
                <div *ngIf=" row == 'Success Request Count'" data-toggle="tooltip" data-placement="right" title="Number of Successful Requests">
                  {{row}}
                </div>
                <div *ngIf=" row == 'Client Failure Count'" data-toggle="tooltip" data-placement="right" title="Number of requests failed with client side issues">
                  {{row}}
                </div>
                <div *ngIf=" row == 'Service Failure Count'" data-toggle="tooltip" data-placement="right" title="Number of requests failed with service side issues">
                  {{row}}
                </div>
                <div *ngIf=" row == 'Last Failure Time'" data-toggle="tooltip" data-placement="right" title="Last Failure time is based on last failure of Client Failure and Service Failure">
                  {{row}} (UTC)
                </div>
                <div *ngIf=" row == 'Success Percent'" data-toggle="tooltip" data-placement="right" title="Availability Percentage based on Success Request Count">
                  {{row}}
                </div>
                <div *ngIf=" row == 'DRI'" data-toggle="tooltip" data-placement="right" title="DRI Contact for this Service">
                  {{row}}
                </div>
              </th>
            </tr>
            <tr *ngFor="let data of businessProcess; index as p">
              <td></td>
              <td>{{data.scor}}</td>
              <td>{{data.service}}</td>
              <td>{{data.totalRequest}}</td>
              <td>{{data.successRequestCount}}</td>
              <td>{{data.clientFailureCount}}</td>
              <td>{{data.serviceFailureCount}}</td>
              <td>{{data.lastFailureTime}}</td>
              <td *ngIf="data.colorCode == 'Green' " style="background-color: #006600; color: lightgray" data-toggle="tooltip" data-placement="right" title="Availability >= 95% is Green"><b>{{data.successPercent}}</b></td>
              <td *ngIf="data.colorCode == 'Red'" style="background-color: #b90000; color: lightgray" data-toggle="tooltip" data-placement="right" title="Availability < 85% is Red"><b>{{data.successPercent}}</b></td>
              <td *ngIf="data.colorCode == 'Yellow'" style="background-color: yellow; color: gray" data-toggle="tooltip" data-placement="right" title="Availability >= 85% is Yellow"><b>{{data.successPercent}}</b></td>
              <td>{{data.dri}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

