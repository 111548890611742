<ngx-ui-loader [fgsColor]="manageScenarioService.ngxLoaderConfig.fgsColor"
  [fgsPosition]="manageScenarioService.ngxLoaderConfig.fgsPosition"
  [fgsSize]="manageScenarioService.ngxLoaderConfig.fgsSize" [fgsType]="manageScenarioService.ngxLoaderConfig.fgsType"
  [bgsColor]="manageScenarioService.ngxLoaderConfig.bgsColor"
  [bgsOpacity]="manageScenarioService.ngxLoaderConfig.bgsOpacity"
  [bgsPosition]="manageScenarioService.ngxLoaderConfig.bgsPosition"
  [bgsSize]="manageScenarioService.ngxLoaderConfig.bgsSize" [bgsType]="manageScenarioService.ngxLoaderConfig.bgsType"
  [gap]="manageScenarioService.ngxLoaderConfig.gap" [logoPosition]="manageScenarioService.ngxLoaderConfig.logoPosition"
  [logoSize]="manageScenarioService.ngxLoaderConfig.logoSize" [logoUrl]="manageScenarioService.ngxLoaderConfig.logoUrl"
  [overlayColor]="manageScenarioService.ngxLoaderConfig.overlayColor"
  [pbColor]="manageScenarioService.ngxLoaderConfig.pbColor"
  [pbDirection]="manageScenarioService.ngxLoaderConfig.pbDirection"
  [pbThickness]="manageScenarioService.ngxLoaderConfig.pbThickness"
  [hasProgressBar]="manageScenarioService.ngxLoaderConfig.hasProgressBar"
  [text]="manageScenarioService.ngxLoaderConfig.text" [textColor]="manageScenarioService.ngxLoaderConfig.textColor"
  [textPosition]="manageScenarioService.ngxLoaderConfig.textPosition">
</ngx-ui-loader>

<div class="container-fluid no-padding no-margin" ngxUiLoaderBlurred
  [blur]="manageScenarioService.ngxLoaderConfig.blur">
  <header role="banner">
    <app-navmenu (messageEvent)="receiveNavCollapseMessage($event)"></app-navmenu>
  </header>
  <main class="container-fluid no-padding" role="main" style="overflow:scroll;position:relative;height: calc(100vh - 100px); " >
    <div class="{{isNavCollapsed ? 'collapsed_nav' : 'expanded_nav'}}">
       <!--This is to avoid reload during acquireTokenSilent() because of hidden iframe -->
        <router-outlet *ngIf="!isIframe"></router-outlet>
       
    </div>
    
  </main>
  
  <footer class="row" style="justify-content: space-between;background-color:#e5e5e5; position: absolute; bottom: 0; width:100%">
   
    <a   href="https://go.microsoft.com/fwlink/?LinkId=518021" style="padding-left: 260px;">Data Privacy Notice</a>
    <span style="padding-right: 20px;">@ 2023 - Microsoft</span>
  </footer>
</div>
