import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { DevicecareService } from '../service/devicecare.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModaltableComponent } from '../modaltable/modaltable.component';
import { TableItem } from '../model/devicecaretable.model';
@Component({
  selector: 'app-devicecare',
  templateUrl: './devicecare.component.html',
  styleUrls: ['./devicecare.component.css']
})
export class DevicecareComponent implements OnInit {
  
  @Output() symptomCodeEvent = new EventEmitter<string>();
  @Output() offerIdEvent = new EventEmitter<string>();
  @Output() shippingOfferEvent = new EventEmitter<string>();
 

  @ViewChild('deviceCareLookupModal', { static: true, read: TemplateRef })
  deviceCareLookupModal: TemplateRef<any>;
  modal: NgbModalRef;
  tabledatatomodal: TableItem;
 errMessage: any;
  errorExist: boolean;
  shippingOffersList: any=[];
   
  
  constructor(private deviceCareService:DevicecareService,private modalService: NgbModal) { }
  public static readonly className: string = 'DevicecareComponent';
  ngOnInit(): void {
  }
  
  setTierValue(tier){
    if(tier==null || tier.length==0){
      var Tier :string = '';
    }
    else{
      var Tier :string = tier[0];
    }
      return Tier
    }
    
   getSymptomTableData(data:any){
      this.tabledatatomodal=new TableItem();
      this.tabledatatomodal.tableHeading=["Code","Name","Description"];
      this.tabledatatomodal.tableRows=[];
      data.forEach((y:any)=>{
        this.tabledatatomodal.tableRows.push([y.symptomCode,y.name,y.description])
      })
      
    }
    getOfferTableData(data:any){
      this.tabledatatomodal=new TableItem();
      this.tabledatatomodal.tableHeading=["Code","Name","Description"];
      this.tabledatatomodal.tableRows=[];
      data.forEach((y:any)=>{
        this.tabledatatomodal.tableRows.push([y.offerId,y.name,y.description])
      })
      
    }

   public onClickClaimEligibilities(serialNumber?:string,tier?:any){
    var agentTier = this.setTierValue(tier)
    this.deviceCareService.getClaimEligibilities(serialNumber,agentTier).subscribe((data:any)=>{
       var symptonCodeList = data.eligibilityOptions;
       this.errorExist=false;
       this.getSymptomTableData(symptonCodeList);
       this.modal = this.modalService.open(
        ModaltableComponent,
          {
            size: 'xl',
            centered: true,
            ariaLabelledBy: 'modal-basic-title'
          }
        );
        this.modal.componentInstance.tableData = this.tabledatatomodal;
        this.modal.componentInstance.selectedEvent.subscribe((receivedEntry:any) => {
          var CodeSelected = symptonCodeList[receivedEntry].symptomCode;
          this.symptomCodeEvent.emit(CodeSelected);
          });
       },err => {
        this.errorExist=true;
        this.errMessage = err.message;
        this.symptomCodeEvent.emit('');
      });
    }
  
  public onClickRepairOffers(serialNumber:string,tier:any,countryCode:string,symptomCode:string){
      var agentTier =this.setTierValue(tier)
      this.deviceCareService.getRepairOffers(serialNumber,agentTier,countryCode,symptomCode).subscribe((data:any)=>{
      this.errorExist=false;
      var offersList = data.offers;
      this.getOfferTableData(offersList);
      this.modal = this.modalService.open(
        ModaltableComponent,
          {
            size: 'xl',
            centered: true,
            ariaLabelledBy: 'modal-basic-title'
          }
        );
        this.modal.componentInstance.tableData = this.tabledatatomodal;
        this.modal.componentInstance.selectedEvent.subscribe((receivedEntry:any) => {
          var CodeSelected = offersList[receivedEntry].offerId;
          this.offerIdEvent.emit(CodeSelected)
        });
     },err => {
      this.errorExist=true;
      this.errMessage = err.message;
      this.offerIdEvent.emit('');
    })
}
public onClickShippingOffers(serialNumber:string,offerCode:string,symptomCode:string,tier :any,countryCode:string){
  
  var agentTier= this.setTierValue(tier)
  var jsonBody= [
    {serialNumber: serialNumber,
      offerSku: offerCode,
      symptomCode: symptomCode
    }
]
  this.deviceCareService.getShippingOffer(jsonBody,agentTier,countryCode).subscribe((data:any)=>{
    data.forEach((x:any)=>{
      this.shippingOffersList.push(...x.offers);
    })
  
   this.errorExist=false;
   this.getOfferTableData(this.shippingOffersList);
   this.modal = this.modalService.open(
    ModaltableComponent,
      {
        size: 'xl',
        centered: true,
        ariaLabelledBy: 'modal-basic-title'
      }
    );
    this.modal.componentInstance.tableData = this.tabledatatomodal;
    this.modal.componentInstance.selectedEvent.subscribe((receivedEntry:any) => {
      var CodeSelected = this.shippingOffersList[receivedEntry].offerId;
      this.shippingOfferEvent.emit(CodeSelected)
});
},err => {
    this.errorExist=true;
    this.errMessage = err.message;
    this.shippingOfferEvent.emit('');
  })
}

}
