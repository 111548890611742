<div *ngIf="is500error" style="width: 100%; overflow: hidden;">
  <app-error [navigationPath]='"testscenario"'></app-error>
 </div>
<div *ngIf="!is500error" style="width: 100%; height: calc(100vh - 46px); overflow-y:scroll;">
  <h3>Test Scenario Catalog</h3>
      <table style="margin-top:16px" >
        <tbody  class="table">
          <tr class="thead-dark">
            <th role="columnheader" scope="col">#</th>
            <th role="columnheader" scope="col">Scenario Category (L3)</th>
            <th role="columnheader" scope="col">Business Process (L4)</th>
            <th role="columnheader" scope="col">Description</th>
            <th role="columnheader" scope="col">Business Process Flow</th>
          </tr>
          <ng-container *ngFor="let sections of verticalName; index as i">
          <tr *ngFor="let scenariosList of sections.scenariosList; index as i">
            <td></td>
            <td *ngIf="sections.sectionName != ''">{{sections.sectionName}}</td>
            <td *ngIf="sections.sectionName != ''">
              <a [routerLink]="['/execute-testruns', getExecuteUrlParams(sections.sectionName, scenariosList.scenarioName)]">
                {{scenariosList.scenarioName}}
              </a>
            </td>
            <td>{{scenariosList.scenarioDescription}}</td>
            <td><a href={{scenariosList.businessProcessDefinition}} target="_blank" *ngIf=scenariosList.businessProcessDefinition>Business Process</a></td>
          </tr>
        </ng-container>
        </tbody>
      </table>
</div>
