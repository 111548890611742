export class OrderSummaryTimeline {
    public customerPurchaseOrder: string;
    public salesOrderNumber: string;
    public orderTimelineItems: OrderSummaryTimelineItem[];
}

export class OrderSummaryTimelineItem {
    public constructor(init?: Partial<OrderSummaryTimelineItem>) {
        Object.assign(this, init);
    }
    public message: string;
    public messageType: string;
    public entityId: string;
    public correlationId: string;
    public timestamp: Date;
}
