import { Component, OnInit } from '@angular/core';
import { LoadPlanService } from '../service/loadplan.service';
import { LoadPlanMockConfig, LoadPlanMockRequest, LoadPlanParameters } from '../model/loadplan.model';
import { Constants } from '../service/constant.service';
import { FormsModule } from '@angular/forms';
import { MasterDataService } from '../service/masterdata.service';
import { SampleFilesService } from '../service/samplefiles.service';
import { LoadPlanPartner, TransportMethod } from '../model/masterdata.model';
import { MsalService } from '@azure/msal-angular';
import { DescriptionService } from '../service/description.service';

import { ConfigService } from '../service/config.service';
import * as log from '../AppInsightsLogger';

import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-loadplanmocker',
  templateUrl: './loadplanmocker.component.html',
  styleUrls: ['./loadplanmocker.component.css']
})
export class LoadPlanMockerComponent implements OnInit {
  name: string;
  email: string;
  alias: string;
  loadPlanRequest: LoadPlanMockRequest;
  entityNumbers: string;
  partnerProfile: string;
  partnerLists: LoadPlanPartner[];
  transportMethodList: TransportMethod[];
  partnerId: string;
  mode: string;
  batchSize: number;
  showLoader: boolean;
  logMessage = '';
  entityNumbersValid = false;
  pageDetails: any[];
  pageDescription: string;

  private logger: log.AppInsightsLogger;

  constructor(private loadPlanService: LoadPlanService, 
    private masterDataService: MasterDataService,
    private msalService: MsalService,
    private descriptionService: DescriptionService, 
    private http: HttpClient,
    private configService: ConfigService) {
    this.name = this.msalService.instance.getActiveAccount().name;
    this.logger = new log.AppInsightsLogger(configService, msalService);
  }


  ngOnInit() {
   // window.sessionStorage.setItem("orgSelected", "1");
    this.showLoader = false;
    this.logMessage = '';
    this.mode = Constants.LoadPlanModeSync;
    this.loadPlanRequest = new LoadPlanMockRequest();
    this.logger.trackTrace(`Successfully landed to Load Plan Mocker Page`);
    this.descriptionService.pageDetailsMocker().subscribe((response: any[]) => {
      this.pageDetails = response;
      this.pageDetails.forEach(element => {
        if (element.mockerName == "LoadPlan Mocker") {
          this.pageDescription = element.mockerDescription
        }
      });
    });
    
    this.http.get('./assets/configuration/LoadPlanConfig.json', { responseType: 'text' }).subscribe(
      (response: string) => {
        let lpConfig: LoadPlanMockConfig = JSON.parse(response);
        this.transportMethodList = lpConfig.methodOfTransport;
        this.logger.trackTrace(`In LoadPlan mocker, fetched config successfully.`);
      },
      (error: any) => {
        this.logger.trackTrace(`In LoadPlan mocker, could not fetch config, ${error}`);
        this.showLoader = false;
      }
    );
  }

  
  onSelectionEntityTypeChangeClick() {
    if (this.loadPlanRequest.entityType == Constants.POEntityType) {
      this.loadPlanRequest.isConsolidated = false;
    }

    this.partnerLists = [];
    this.masterDataService.getLoadPlanPartnerIds().subscribe((response) => {
      for (let i = 0; i < response.length; i++) {
        {
          if (this.loadPlanRequest.entityType == Constants.POEntityType && response[i].type == "CM") {
            let p: LoadPlanPartner;
            p = response[i];
            this.partnerLists.push(p);
          }
          else if (this.loadPlanRequest.entityType == Constants.DOEntityType && response[i].type == "DC") {
            let p: LoadPlanPartner;
            p = response[i];
            this.partnerLists.push(p);
          }
        }
      }

      if (this.mode == 'LOAD' && this.loadPlanRequest.entityType == Constants.DOEntityType) {
        // only ARVATO and ARVATOPERF
        this.partnerLists = this.partnerLists.filter(p => (p.id || "").indexOf('ARVATO') > -1);
      }

      this.partnerId = this.partnerLists[0].id;
    }, () => {
    });
  }

  downloadSampleFile() {
    SampleFilesService.downloadSampleFile(
      this.loadPlanRequest.entityType == 'deliveryOrder' ?
        SampleFilesService.DeliveryOrdersLoadModeSample :
        SampleFilesService.PurchaseOrdersLoadModeSample);
  }

  onModeChange(newMode: any) {
    if (newMode == 'LOAD') {
      this.loadPlanRequest.isConsolidated = false;
    }

    this.entityNumbers = null;
  }

  onTransportCodeChanged(newSelectedCode: string){
    let currSelectedEnum = this.loadPlanRequest.loadPlanParams.transportMethodEnum;

    if(currSelectedEnum.length == 0) {
      this.loadPlanRequest.loadPlanParams.transportMethodCode = "";
      this.loadPlanRequest.loadPlanParams.transportMethodDescription = "";
    }
    else if(currSelectedEnum != '-') {
      this.loadPlanRequest.loadPlanParams.transportMethodCode = this.transportMethodList[currSelectedEnum].code;
      this.loadPlanRequest.loadPlanParams.transportMethodDescription = this.transportMethodList[currSelectedEnum].description;
    }
  }

   onPostLoadPlanClick() {
    this.showLoader = true;
    let batchCounter = 0;
    this.logMessage = '';

    const entityNumberArray = this.getEntityNumberArray(this.entityNumbers);


    if (this.mode == Constants.LoadPlanModeLoad) {

      const totalNumberOfDos = entityNumberArray.length;
      const batchedArrays = this.getBatchedArrays(entityNumberArray, this.batchSize);

      this.addLogLine(`Selected LoadPlan Mode: ${Constants.LoadPlanModeLoad}, Number of Orders in file: ${totalNumberOfDos}`);
      this.logger.trackTrace(`Selected LoadPlan Mode: ${Constants.LoadPlanModeLoad}, Number of Orders in file: ${totalNumberOfDos}`);

      batchedArrays.forEach(elements => {

        var loadPlanRequest_Batch = new LoadPlanMockRequest();
        loadPlanRequest_Batch.partnerId = this.partnerId;
        loadPlanRequest_Batch.entityNumbers = elements;
        loadPlanRequest_Batch.mode = Constants.LoadPlanModeLoad;
        loadPlanRequest_Batch.entityType = this.loadPlanRequest.entityType;

        this.loadPlanService.postMockLoadPlanRequest(loadPlanRequest_Batch).subscribe(loadPlanResponse => {
          this.addLogLine(`Batch ${++batchCounter} submitted. Batch - ${JSON.stringify(elements)}`);
        }, () => {
            this.addLogLine(`Some error occured for batch : ${batchCounter}". Batch - ${JSON.stringify(elements)}`);
            this.logger.trackTrace(`Loadplan Mocker: Some error occured for batch : ${batchCounter}". Batch - ${JSON.stringify(elements)}`);
        });
      });
      this.showLoader = false;
    }
    else if (this.mode == Constants.LoadPlanModeSync) {
      var totalNumberOfDos = entityNumberArray.length;

      this.addLogLine(`Selected LoadPlan Mode: ${Constants.LoadPlanModeSync}, Number of Orders: ${totalNumberOfDos}`);

      this.loadPlanRequest.partnerId = this.partnerId;
      this.loadPlanRequest.entityNumbers = entityNumberArray;
      this.loadPlanRequest.mode = Constants.LoadPlanModeSync;
      this.loadPlanService.postMockLoadPlanRequest(this.loadPlanRequest).subscribe(loadPlanResponse => {
        if (loadPlanResponse != null && loadPlanResponse.length > 0) {
          loadPlanResponse.forEach(element => {
            this.addLogLine(`LoadPlan submitted. Order: ${element.doNumber}, LoadPlan Correlation Id: ${element.correlationId}, Message: ${element.message}`);
            this.logger.trackTrace(`LoadPlan submitted. Order: ${element.doNumber}, LoadPlan Correlation Id: ${element.correlationId}, Message: ${element.message}`);
          });
          this.showLoader = false;
        } else {
          this.addLogLine(`Some error occured: ${loadPlanResponse}`);
          this.logger.trackTrace(`Loadplan Mocker ${this.addLogLine}`);
          this.showLoader = false;
        }
      }, (error) => {
        this.addLogLine(`Some error occured: ${JSON.stringify(error)}`);
          this.logger.trackTrace(`Loadplan Mocker ${this.addLogLine}`);
        this.showLoader = false;
      });
    }
  }

  getBatchedArrays(array: string[], batchSize: number) {
    let batchedArrays = [];
    while (array.length > 0) {
      batchedArrays.push(array.splice(0, batchSize));
    }

    return batchedArrays;
  }


  openFile(event: any) {
    const input = event.target;
    for (let index = 0; index < input.files.length; index++) {
      const reader = new FileReader();
      reader.onload = () => {
        this.entityNumbers = reader.result as string;
      };
      reader.readAsText(input.files[index]);
    }
  }

  addLogLine(line: string) {
    const currentDateTime = new Date();
    this.logMessage += '[' + currentDateTime.toLocaleString() + ']' + ' ' + line + '\n';
  }

  getEntityNumberArray(entityNumbers: string): string[] {
    let entityNumberArrayUntrimmed: string[];
    let entityNumberArrayTrimmed: string[];

    if (this.mode == Constants.LoadPlanModeSync) {
      entityNumberArrayUntrimmed = entityNumbers.split(',');

    } else if (this.mode == Constants.LoadPlanModeLoad) {
      entityNumberArrayUntrimmed = entityNumbers.replace(/\r/g, '').split('\n');
    }

    entityNumberArrayTrimmed = [];
    entityNumberArrayUntrimmed.forEach(element => {
      if (element != null) {
        entityNumberArrayTrimmed.push(element.trim());
      }
    });

    return entityNumberArrayTrimmed;
  }

  handleEntityNumbersChange(formControl: any) {
    if (this.loadPlanRequest.entityType == 'deliveryOrder' &&
      this.getEntityNumberArray(this.entityNumbers).length > 10) {
      formControl.control.setErrors({ 'maxCountExceeded': true });
      this.entityNumbersValid = false;
    } else {
      delete formControl.control.errors.maxCountExceeded;
      if (Object.keys(formControl.control.errors).length === 0) {
        formControl.control.setErrors(null);
        // Because formControl can still be invalid if errors obj exists,
        // we must manually nullify the errors object
      }
      this.entityNumbersValid = true;
    }
  }

  clearLogs() {
    this.logMessage = '';
  }
}
