<div style="width: 100%; height: calc(100vh - 46px); ">
  <div class="input-group">
    <h3>Test Run Overview</h3>
    <div>&nbsp;</div>
  </div>
  <br>
  <div style="text-align: right; padding-right: 5px">
    <a role="link" target="_blank" rel="noopener noreferrer" href="https://msit.powerbi.com/reportEmbed?reportId=715136e5-282e-4989-a368-4e607f4bde3d&autoAuth=true&ctid=72f988bf-86f1-41af-91ab-2d7cd011db47&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9kZi1tc2l0LXNjdXMtcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQvIn0%3D">Click Here for Test Run Overview Report</a>
  </div>

  <div style="border:2px solid #666; border-radius:11px; padding:20px;  height:39vw">


    <iframe role="presentation" aria-label="TestRunOverview" id="form-iframe" src="https://msit.powerbi.com/reportEmbed?reportId=715136e5-282e-4989-a368-4e607f4bde3d&autoAuth=true&ctid=72f988bf-86f1-41af-91ab-2d7cd011db47&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9kZi1tc2l0LXNjdXMtcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQvIn0%3D" allowfullscreen="true" style="margin:0; width:100%; height:100%; border:none; overflow:hidden;" scrolling="no"></iframe>

  </div>
</div>

