import { Component, OnInit } from '@angular/core';
import { ShipmentService } from '../service/shipment.service';
import {
  ShipmentMockRequest,
  ShipmentMockRequestType,
  ShipmentParams,
  Entity,
  OutboundDC,
  COO,
  LineItem,
  OrderLine,
  ItemDetails,
  CooStateItem
} from '../model/shipment.model';
import { Constants } from '../service/constant.service';
import { SampleFilesService } from '../service/samplefiles.service';
import { MasterDataService } from '../service/masterdata.service';
import { Vendor, Plant } from '../model/masterdata.model';
import { ConfigService } from '../service/config.service';
import * as log from '../AppInsightsLogger';
import { DescriptionService } from '../service/description.service';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-shipmentmocker',
  templateUrl: './shipmentmocker.component.html',
  styleUrls: ['./shipmentmocker.component.css']
})
export class ShipmentmockerComponent implements OnInit {
  private static readonly SYNCMODE = 'SYNC';
  private static readonly LOADMODE = 'LOAD';
  name: string;

  itemDetails: ItemDetails[] = []

  MockRequestType = ShipmentMockRequestType;
  typeList: { label: string, value: ShipmentMockRequestType }[] = [
    { label: 'Warehouse Outbound', value: ShipmentMockRequestType.DC },
    { label: 'CM Inbound', value: ShipmentMockRequestType.CM },
    { label: 'Acknowledge Receipt Delivery', value: ShipmentMockRequestType.ARD }
  ];

  shipmentFlavorTypeList: { label: string, value: string }[] = [
  ];

  dcShipmentFlavorTypeList: { label: string, value: string }[] = [
    { label: 'Full Pallet', value: 'FullPallet' },
    { label: 'Loose Carton', value: 'LooseCarton' },
    { label: 'Mixed Pallet', value: 'MixedPallet' },
    { label: 'Loose Carton Mixed Carton', value: 'LooseCartonMixedCarton' },
    { label: 'Mixed Pallet Mixed Carton', value: 'MixedPalletMixedCarton' }
  ];

  wtpShipmentFlavorTypeList: { label: string, value: string }[] = [
    { label: 'Loose Carton', value: 'LooseCarton' }
  ];

  cmATOShipmentFlavorTypeList: { label: string, value: string }[] = [
    { label: 'Full Pallet', value: 'FullPallet' },
    { label: 'Mixed Pallet', value: 'MixedPallet' },
    { label: 'Loose Carton', value: 'LooseCarton' },
  ];

  cmBTSShipmentFlavorTypeList: { label: string, value: string }[] = [
    { label: 'Full Pallet', value: 'FullPallet' },
    { label: 'Mixed Pallet', value: 'MixedPallet' }
  ];

  ardSubProcessTypeList: { label: string, value: string }[] = [
    { label: 'Confirm Onboard', value: 'ConfirmOnboard' },
    { label: 'Destination Scan', value: 'ConfirmDestinationScan' },
  ];

  ardProcessTypeList: { label: string, value: string }[] = [
    { label: 'Direct Ship', value: 'DirectShip' },
    { label: 'BTS', value: 'BTS' },
    { label: 'Refurb', value: 'Refurb' }
  ];


  cmProcessTypeList: { label: string, value: string }[] = [
    { label: 'BTS', value: 'BTS' },
    { label: 'Direct Ship', value: 'DirectShip' }
  ];

  dcProcessTypeList: { label: string, value: string }[] = [
    { label: 'Standard', value: 'Standard' },
    { label: 'STO', value: 'STO' },
    { label: 'CSCP_SparesForward_RSD->DC', value: 'WTP_FORWARD_RSD_DC' },
    { label: 'CSCP_SparesReturn_RSD->SI', value: 'WTP_RETURN_RSD_SI' },
    { label: 'CSCP_SparesReturn_RSD->T2', value: 'WTP_RETURN_RSD_T2' },
    { label: 'ShipLite.DO', value: 'shiplite_do' }
  ];

  cmPartnerList: Vendor[];
  dcPartnerList: Plant[];
  dcOutboundPartnerList: OutboundDC[] = [
    {
      PartnerId: 'ARVATO',
      TenantId: '9000000013',
      SupportedModes: [ShipmentmockerComponent.SYNCMODE, ShipmentmockerComponent.LOADMODE],
      Platform:['Device','Cloud']
    },
    {
      PartnerId: 'ARVATOPERF',
      TenantId: '8000000001',
      SupportedModes: [ShipmentmockerComponent.SYNCMODE, ShipmentmockerComponent.LOADMODE],
      Platform: ['Device']
    },
    {
      PartnerId: 'IML',
      TenantId: '9000000013',
      SupportedModes: [ShipmentmockerComponent.SYNCMODE, ShipmentmockerComponent.LOADMODE],
      Platform: ['Device']
    },
    {
      PartnerId: 'FLEX',
      TenantId: '9000000013',
      SupportedModes: [ShipmentmockerComponent.SYNCMODE, ShipmentmockerComponent.LOADMODE],
      Platform: ['Device']
    },
    {
      PartnerId: 'CEVA',
      TenantId: '9000000013',
      SupportedModes: [ShipmentmockerComponent.SYNCMODE, ShipmentmockerComponent.LOADMODE],
      Platform: ['Device']
    },
    {
      PartnerId: 'SYNNEX',
      TenantId: '9000000013',
      SupportedModes: [ShipmentmockerComponent.SYNCMODE, ShipmentmockerComponent.LOADMODE],
      Platform: ['Device']
    },
    {
      PartnerId: 'EXPEDITORS',
      TenantId: '9000000013',
      SupportedModes: [ShipmentmockerComponent.SYNCMODE, ShipmentmockerComponent.LOADMODE],
      Platform: ['Device']
    },
    {
      PartnerId: '3040568',
      TenantId: '9000000013',
      SupportedModes: [ShipmentmockerComponent.SYNCMODE, ShipmentmockerComponent.LOADMODE],
      Platform: ['Device','Cloud']
    },
    {
      PartnerId: '3057945',
      TenantId: '9000000013',
      SupportedModes: [ShipmentmockerComponent.SYNCMODE, ShipmentmockerComponent.LOADMODE],
      Platform: ['Device','Cloud']
    }
  ];

  platform: string;
  type: ShipmentMockRequestType;
  doNumbers: string;
  poNumbers: string;
  partnerProfile: OutboundDC;
  senderInfo: Vendor;
  receiverInfo: Plant;
  mode: string;
  itemPerCarton: number;
  batchSize: number;
  showLoader: boolean;
  logMessage: '';
  flavorType: 'FullPallet';
  processType: string;
  subProcessType: string;
  newPOAlertClosed = false;
  loadIdAlertClosed = false;
  isConsolidated = false;
  isSerialized = false;
  consolidationAlert = false;
  serializedAlert = false;
  showSubmitWarning = false;
  doNumbersValid = false;
  poNumbersValid = false;
  private logger: log.AppInsightsLogger;
  pageDetails: any[];
  pageDescription: string;
  loadId: string = null;
  scacCode: string;
  total: number = 0;
  sidebarOpened: boolean;
  firstSplitPercent: any[];
  secondSplitPercent: any[];
  firstCOO: string[];
  secondCOO: string[];
  checkBox: boolean[];
  cooState: CooStateItem[];
  doError: boolean;
  poError: boolean;
  countryCodes: string[];
  cache: { [orderId: string]: ItemDetails[] };
  showHwpnPreloader: boolean;

  ngOnInit() {
   // window.sessionStorage.setItem("orgSelected", "1");
    this.type = ShipmentMockRequestType.DC;
    this.showLoader = false;
    this.sidebarOpened = false;
    this.logMessage = '';
    this.mode = Constants.ShipmentModeSync;
    this.platform = 'Device';
    this.shipmentFlavorTypeList = this.dcShipmentFlavorTypeList;
    this.logger.trackTrace(`Successfully landed to Shipment Mocker`);
    this.descriptionService.pageDetailsMocker().subscribe((response: any[]) => {
      // this.logger.trackTrace(`Serial Number Generation Get page Description successfully`);
      this.pageDetails = response;
      this.pageDetails.forEach(element => {
        console.log(element.mockerName)
        if (element.mockerName == "Shipment Mocker") {
          this.pageDescription = element.mockerDescription
        }
      });
    });

    this.masterDataService.getVendors().subscribe((response: Vendor[]) => {
      this.cmPartnerList = response.filter(vendor => vendor.scenario === 'BTS' || vendor.scenario === 'ATO');
      this.logger.trackTrace(`In Shipment Mocker, CM Partner List Successfully `);
    }, (error: any) => {
      this.addLogLine(`Failed to load CM list: ${error}`);
      this.logger.trackTrace(`In Shipment Mocker, Failed to load CM list`);
      this.logger.trackException(error)
    });
    this.masterDataService.getPlants().subscribe((response: Plant[]) => {
      this.dcPartnerList = response.filter(plant => plant.scenario === 'BTS' || plant.scenario === 'ATO');
      this.logger.trackTrace(`In Shipment Mocker, DC Partner List Successfully `);
    }, (error: any) => {
      this.addLogLine(`Failed to load plant list: ${error}`);
      this.logger.trackTrace(`In Shipment Mocker, Failed to load plant list`);
      this.logger.trackException(error);
    });

    this.masterDataService.getCountry().subscribe((response) => {
      let countries = response || [];
      this.countryCodes = countries.map(c => {
        return c.shortCountryCode;
      });
      this.logger.trackTrace(`In Shipment Mocker, Country List loaded successfully `);
    }, (error: any) => {
      this.logger.trackTrace(`In Shipment Mocker, Failed to load country list`);
      this.logger.trackException(error);
    });
  }

  constructor(private descriptionService: DescriptionService, private masterDataService: MasterDataService, private shipmentService: ShipmentService, private msalService: MsalService, private configService: ConfigService) {
    this.filterDCOutboundPartners = this.filterDCOutboundPartners.bind(this);
    this.firstSplitPercent = [];
    this.firstCOO = [];
    this.secondSplitPercent = [];
    this.secondCOO = [];
    this.checkBox = [];
    this.cooState = [];
    this.doError = false;
    this.poError = false;
    this.cache = {};
    this.showHwpnPreloader = false;

    this.countryCodes = [];
    this.name = this.msalService.instance.getActiveAccount().name;
    this.logger = new log.AppInsightsLogger(configService, msalService);
  }

  onPostShipmentClick() {
    const byDoNumbers = ShipmentMockRequestType[this.type] === ShipmentMockRequestType[ShipmentMockRequestType.DC];
    let batchCounter = 0;
    this.showLoader = true;
    this.logMessage = '';

    const numbersArray = this.getNumberArray(byDoNumbers ? this.doNumbers : this.poNumbers);

    if (this.mode === Constants.ShipmentModeLoad) {
      const totalNumberOfOrders = numbersArray.length;
      const batchedArrays = this.getBatchedArrays(numbersArray, this.batchSize);

      this.addLogLine(`Selected Shipment Mode: ${Constants.ShipmentModeLoad}, Type: ${ShipmentMockRequestType[this.type]}` +
        `, Number of Delivery Orders in file: ${totalNumberOfOrders}, Total batches: ${batchedArrays.length}`);
      this.logger.trackTrace(`In Shipment Mocker, ${this.addLogLine} `);

      batchedArrays.forEach(element => {
        const shipmentRequest = this.createShipmentMockRequest(element, true);
        this.shipmentService.postMockShipmentRequest(shipmentRequest).subscribe(shipmentResponse => {
          this.addLogLine(`Batch ${++batchCounter} submitted. Batch - ${JSON.stringify(element)}`);
        }, (error) => {
          this.addLogLine(`Some error occured for batch : ${batchCounter}. Batch - ${JSON.stringify(element)}. Error msg - ${JSON.stringify(error)}`);
          this.logger.trackTrace(`In Shipment Mocker, Some error occured for batch`);
          this.logger.trackException(error)
        });
      });

      this.showLoader = false;
    } else if (this.mode === Constants.ShipmentModeSync) {

      const totalNumberOfOrders = numbersArray.length;

      this.addLogLine(`Selected Shipment Mode: ${Constants.ShipmentModeSync}, Type: ${ShipmentMockRequestType[this.type]}` +
        `, Number of Delivery Orders: ${totalNumberOfOrders}`);

      const shipmentRequest = this.createShipmentMockRequest(numbersArray, false);

      this.shipmentService.postMockShipmentRequest(shipmentRequest).subscribe(shipmentResponse => {
        if (shipmentResponse != null && shipmentResponse.length > 0) {
          shipmentResponse.forEach(element => {
            const logline = `EntityNumber: ${element.entityNumber}, EntityType: ${element.entityType}`;
            if (element.correlationId === '') {
              this.addLogLine(`Shipment failed. ${logline}, Message: ${element.message}`);
              this.logger.trackTrace(`In Shipment Mocker, Shipment failed. ${logline}, Message: ${element.message} `);
            } else {
              this.addLogLine(`Shipment submitted. ` + logline +
                `, Shipment Correlation Id: ${element.correlationId}, Message: ${element.message}`);
              this.logger.trackTrace(`In Shipment Mocker, Shipment submitted. ` + logline +
                `, Shipment Correlation Id: ${element.correlationId}, Message: ${element.message}`);
            }
          });
          this.showLoader = false;
        } else {
          this.addLogLine(`Some error occured: ${shipmentResponse}`);
          this.logger.trackTrace(`In Shipment Mocker, Some error occured`);
          this.showLoader = false;
        }
      }, (error) => {
        this.addLogLine(`Some error occured: ${JSON.stringify(error)}`);
        this.logger.trackTrace(`In Shipment Mocker, Some error occured: ${JSON.stringify(error)} `);
        this.logger.trackException(error)
        this.showLoader = false;
      });
    }
  }

  createCOOLineItem(orderNumber: string) {
    let response: LineItem[] = [];

    for (let i = 0; i < this.cooState.length; i++){
      if(orderNumber == this.cooState[i].orderNumber){
        var lineItem = new LineItem();
        var coos = [new COO(), new COO()];

        coos[0].Name = this.cooState[i].firstCoo;
        coos[0].Quantity = this.cooState[i].firstQuantity;
        coos[1].Name = this.cooState[i].secondCoo;
        coos[1].Quantity = this.cooState[i].secondQuantity;
        lineItem.COO = coos;
        lineItem.SKU = this.cooState[i].sku;

        response.push(lineItem);
      }
    }

    return response.length > 0 ? response : null;
  }

  getBatchedArrays(array: string[], batchSize: number) {
    const batchedArrays = [];

    while (array.length > 0) {
      batchedArrays.push(array.splice(0, batchSize));
    }

    return batchedArrays;
  }

  openFile(event: any, type: ShipmentMockRequestType) {
    const input = event.target;
    for (let index = 0; index < input.files.length; index++) {
      const reader = new FileReader();
      reader.onload = () => {
        if (type === ShipmentMockRequestType.DC) {
          this.doNumbers = reader.result as string;
        } else if (type === ShipmentMockRequestType.CM) {
          this.poNumbers = reader.result as string;
        }
      };
      reader.readAsText(input.files[index]);
    }
  }

  downloadSampleFile(type: ShipmentMockRequestType) {
    SampleFilesService.downloadSampleFile(
      type === ShipmentMockRequestType.DC ?
        SampleFilesService.DeliveryOrdersLoadModeSample :
        SampleFilesService.PurchaseOrdersLoadModeSample);
  }

  addLogLine(line: string) {
    const currentDateTime = new Date();
    this.logMessage += '[' + currentDateTime.toLocaleString() + '] ' + line + '\n';
  }

  getNumberArray(numInput: string) {
    let numberArrayUntrimmed: string[];
    let numberArrayTrimmed: string[];

    if (this.mode === Constants.ShipmentModeSync) {
      numberArrayUntrimmed = numInput.split(',');
    } else if (this.mode === Constants.ShipmentModeLoad) {
      numberArrayUntrimmed = numInput.replace(/\r/g, '').split('\n');
    }

    numberArrayTrimmed = [];
    numberArrayUntrimmed.forEach(num => {
      if (num != null) {
        numberArrayTrimmed.push(num.trim());
      }
    });

    return numberArrayTrimmed;
  }

  createShipmentMockRequest(numberArray: string[], isLoadMode: boolean) {
    if (ShipmentMockRequestType[this.type] === ShipmentMockRequestType[ShipmentMockRequestType.DC]) {
      return this.createDCShipmentMockRequest(numberArray, isLoadMode);
    } else if (ShipmentMockRequestType[this.type] === ShipmentMockRequestType[ShipmentMockRequestType.CM]) {
      return this.createCMShipmentMockRequest(numberArray, isLoadMode);
    } else if (ShipmentMockRequestType[this.type] === ShipmentMockRequestType[ShipmentMockRequestType.ARD]) {
      return this.createInboundShipmentMockRequest(numberArray, isLoadMode);
    }
    return null;
  }

  createDCShipmentMockRequest(numberArray: string[], isLoadMode: boolean) {
    const shipmentRequest = new ShipmentMockRequest();

    shipmentRequest.mode = isLoadMode ? Constants.ShipmentModeLoad : Constants.ShipmentModeSync;
    shipmentRequest.shipmentType = 'DCShipment';
    shipmentRequest.tenantId = this.partnerProfile.TenantId;
    shipmentRequest.Entities = [];
    numberArray.forEach(doNumber => {
      shipmentRequest.Entities.push(
        {
          EntityNumber: doNumber,
          EntityType: 'DeliveryOrder',
          LoadId: this.loadId,
          LineItems: null
        });
    });

    const params = new ShipmentParams();
    // Enable Shipment Consolidation only for DC Outbound (Shipment by Delivery Order) & SYNC mode
    params.IsConsolidated = isLoadMode ? false : this.isConsolidated;
    params.IsSerializedScenario = isLoadMode ? false : this.isSerialized;
    params.FlavorType = this.flavorType;
    params.Sender = this.partnerProfile.PartnerId;
    params.ScacCode = this.scacCode;

    if (params.Sender == "3040568" || params.Sender == "3057945") {
      params.ReceiverId = "3040568";
      params.ReceiverPartnerId = "4412";
    }

    //Based on Shivank conversation:
   //USS DC shipment there is an added field in mocker input 'ProcessType' to update that field
    //For non sto we dont pass anything so deleting the params for now. 
    if (this.processType.toUpperCase() == "STANDARD") {
      delete params.ProcessType;
    }
    else {
      params.ProcessType = this.processType;
    }
    shipmentRequest.shipmentParams = params;
    return shipmentRequest;
  }

  createCMShipmentMockRequest(numberArray: string[], isLoadMode: boolean) {
    const shipmentRequest = new ShipmentMockRequest();

    shipmentRequest.mode = isLoadMode ? Constants.ShipmentModeLoad : Constants.ShipmentModeSync;
    shipmentRequest.shipmentType = 'CMShipment';
    shipmentRequest.Entities = [];
    let _entityType = 'PurchaseOrder';
    if (this.processType == 'DirectShip') {
      _entityType = 'CustomerPurchaseOrder';
    }

    numberArray.forEach(poNumber => {
      shipmentRequest.Entities.push(
        {
          EntityNumber: poNumber,
          EntityType: _entityType,
          LoadId: this.loadId,
          LineItems: isLoadMode? null : this.createCOOLineItem(poNumber)  // for now support mixed COO in only Sync mode
        });
    });


    const params = new ShipmentParams();
    params.IsConsolidated = false;
    params.Sender = this.senderInfo.vendorName;
    params.SenderId = this.senderInfo.vendorCode;
    params.Receiver = this.receiverInfo.plantName;
    params.ReceiverId = this.receiverInfo.plantCode;
    params.IsConsolidated = this.isConsolidated;
    params.ItemsPerCarton = this.itemPerCarton;
    params.ScacCode = this.scacCode;
    params.ProcessType = this.processType;
    params.PackageScenario = this.flavorType;
    if (this.itemPerCarton < 1) {
      params.ItemsPerCarton = 1;
    }
    shipmentRequest.shipmentParams = params;
    return shipmentRequest;
  }

  createInboundShipmentMockRequest(numberArray: string[], isLoadMode: boolean) {
    const shipmentRequest = new ShipmentMockRequest();

    shipmentRequest.mode = isLoadMode ? Constants.ShipmentModeLoad : Constants.ShipmentModeSync;
    shipmentRequest.shipmentType = 'InboundShipment';
    shipmentRequest.Entities = [];
    numberArray.forEach(poNumber => {
      shipmentRequest.Entities.push(
        {
          EntityNumber: poNumber,
          EntityType: 'PurchaseOrder',
          LoadId: this.loadId,
          LineItems: null
        });
    });

    const params = new ShipmentParams();
    // Enable Shipment Consolidation only for DC Outbound (Shipment by Delivery Order) & SYNC mode
    params.IsConsolidated = false;
    params.ProcessType = this.processType;
    params.SubProcessType = this.subProcessType;
    params.SenderId = this.senderInfo.vendorCode;
    params.ScacCode = this.scacCode;
    params.ReceiverId = this.receiverInfo.plantCode;

    shipmentRequest.shipmentParams = params;
    return shipmentRequest;
  }

  clearLogs() {
    this.logMessage = '';
  }

  filterDCOutboundPartners(partner: OutboundDC) {
    return partner.SupportedModes.includes(this.mode) && partner.Platform.includes(this.platform);
  }

  onModeChange(newMode: any) {
    this.partnerProfile = null;
    this.resetSyncModeFields();
  }

  onTypeChange(newType: any) {
    this.partnerProfile = null;
    this.resetSyncModeFields();
    this.loadId = null;
    this.scacCode = null;
    if (this.type == ShipmentMockRequestType.DC) {
      this.shipmentFlavorTypeList = this.dcShipmentFlavorTypeList;
    }
    else {
      this.shipmentFlavorTypeList = this.cmBTSShipmentFlavorTypeList;
    }
  }

  onProcessTypeChange(newProcessType: any) {
    this.partnerProfile = null;
    this.resetSyncModeFields();
    this.loadId = null;
    this.scacCode = null;
    if (this.type == ShipmentMockRequestType.CM) {
      if (this.processType == 'DirectShip') {
        this.shipmentFlavorTypeList = this.cmBTSShipmentFlavorTypeList;
      }
      else {
        this.shipmentFlavorTypeList = this.cmATOShipmentFlavorTypeList;
      }
    }
    else if (this.type == ShipmentMockRequestType.DC) {
      this.platform = this.processType.includes('WTP') ? 'Cloud' : 'Device';
      this.shipmentFlavorTypeList = this.processType.includes('WTP') ? this.wtpShipmentFlavorTypeList : this.dcShipmentFlavorTypeList;
    }
  }

  resetSyncModeFields() {
    this.doNumbers = null;
    this.poNumbers = null;
    //making default as fullpallet
    this.flavorType = 'FullPallet';
  }

  updateDoNumberTotal(formControl: any) {
    if (this.doNumbers == null || this.doNumbers.length == 0) {
      this.total = 0;
    } else {
      this.total = this.doNumbers.split(",").length;
    }
  }

  handleDoNumberChange(formControl: any) {
    this.updateDoNumberTotal(formControl);
    

    if (this.getNumberArray(this.doNumbers).length > 10) {
      formControl.control.setErrors({ 'maxCountExceeded': true });
      this.doNumbersValid = false;
    } else {
      delete formControl.control.errors.maxCountExceeded;
      if (Object.keys(formControl.control.errors).length === 0) {
        formControl.control.setErrors(null);
        // Because formControl can still be invalid if errors obj exists,
        // we must manually nullify the errors object
      }
      this.doNumbersValid = true;
    }
  }

  handleConsolidation(formControl: any) {
    this.handleDoNumberChange(formControl);
    this.consolidationAlert = this.isConsolidated ? true : false;
  }

  handleSerialized(formControl: any) {
    this.serializedAlert = this.isSerialized ? true : false;
  }

  handlePONumberTotal(formControl: any) {
    if (this.poNumbers == null || this.poNumbers.length == 0) {
      this.total = 0;
    } else {
      this.total = this.poNumbers.split(",").length;
    }
  }

  handlePONumberChange(formControl: any) {
    this.handlePONumberTotal(formControl);

    if (this.getNumberArray(this.poNumbers).length > 10) {
      formControl.control.setErrors({ 'maxCountExceeded': true });
      this.poNumbersValid = false;
    } else {
      delete formControl.control.errors.maxCountExceeded;
      if (Object.keys(formControl.control.errors).length === 0) {
        formControl.control.setErrors(null);
        // Because formControl can still be invalid if errors obj exists,
        // we must manually nullify the errors object
      }
      this.poNumbersValid = true;
    }
  }

  handleConsolidationCM(formControl: any) {
    this.handlePONumberChange(formControl);
  }

  async toggleSidebar() {
    this.sidebarOpened = !this.sidebarOpened;
    if (this.sidebarOpened) {
      // sidebar opened, clear any old data, and load fresh data
      this.clearSidebarState();
      this.showHwpnPreloader = false;
      await this.getCOOData();
    } else {
      this.showHwpnPreloader = true;
    }
  }

  changeFirstSplit(event: any, i: number) {
    this.firstSplitPercent[i] = 100 - this.secondSplitPercent[i];
  }

  changeSecondSplit(event: any, i: number) {
    this.secondSplitPercent[i] = 100 - this.firstSplitPercent[i];
  }

  /**
   * Clears data for mixed coo sidebar panel
   * */
  clearSidebarState() {
    this.firstSplitPercent.splice(0, this.firstSplitPercent.length);
    this.secondSplitPercent.splice(0, this.secondSplitPercent.length);
    this.firstCOO.splice(0, this.firstCOO.length);
    this.secondCOO.splice(0, this.secondCOO.length);
    this.checkBox.splice(0, this.checkBox.length);
    this.itemDetails.splice(0, this.itemDetails.length);
  }

  /**
   * Validate COO Sidebar for submission
   * */
  validateMixedCOOState() {

    if (this.sidebarOpened) {
      for (let i = 0; i < this.itemDetails.length; i++) {
        if (this.checkBox[i]) {

          // validation : ensure master pack value is present and divisible by total quantity
          let masterPackVal = parseInt(this.itemDetails[i].unitsPerMP);
          let totalQuantity = parseInt(this.itemDetails[i].qty);

          if (isNaN(masterPackVal) || isNaN(totalQuantity) || masterPackVal < 1 || totalQuantity % masterPackVal !== 0 || isNaN(totalQuantity / masterPackVal)) {
            return false;
          }

          // validation : ensure the 2 COO countries are selected and are different
          if ((this.firstCOO[i] || "").length == 0 || (this.secondCOO[i] || "").length == 0 || this.firstCOO[i] === this.secondCOO[i]) {
            return false;
          }

          // validation: ensure the 2 split percentages are some valid numbers
          if (isNaN(parseInt(this.calculateFirstCooEstimatedQuantity(this.firstSplitPercent, i))) ||
                isNaN(parseInt(this.calculateSecondCooEstimatedQuantity(this.secondSplitPercent, i)))) {
            return false;
          }
        }
      }
    }

    return true;
  }

  /**
   * Submit COO form
   * */
  submitSidebarState() {
    this.cooState.splice(0, this.cooState.length);
    let masterPackValue = "";

    for (let i = 0; i < this.itemDetails.length; i++) {
      if (this.checkBox[i]) {
        var coo = new CooStateItem();
        coo.firstQuantity = this.calculateFirstCooEstimatedQuantity(this.firstSplitPercent, i);
        coo.secondQuantity = this.calculateSecondCooEstimatedQuantity(this.secondSplitPercent, i);
        coo.firstCoo = this.firstCOO[i];
        coo.secondCoo = this.secondCOO[i];
        coo.orderNumber = this.itemDetails[i].orderNumber;
        coo.sku = this.itemDetails[i].material;
        coo.firstSplitPercent = this.firstSplitPercent[i];
        coo.secondSplitPercent = this.secondSplitPercent[i];
        
        this.cooState.push(coo);

        let masterPackVal = parseInt(this.itemDetails[i].unitsPerMP);
        if (!isNaN(masterPackVal)) {
          masterPackValue = masterPackVal.toString();
        }
      }
    }

    if ((masterPackValue || "").length > 0) {
      this.itemPerCarton = parseInt(masterPackValue);
    }

    this.clearSidebarState();
    this.toggleSidebar();
  }

  /**
   * Handle NAN number, if so retun string, otherwise return the number in string formaat
   * @param num number to tested
   */
  handleNan(num: number) {
    return isNaN(num) ? "" : ("" + num);
  }

  /**
   * Check if any of the master packs are not loaded successfully from database
   * */
  areMasterPacksLoaded() {
    let response = true;

    for (let i = 0; i < this.itemDetails.length; ++i) {
      response = response && this.itemDetails[i].isMasterPackLoaded;
    }

    return response;
  }

  /**
   * Calculate and return total master packs based on quantity and master pack value for the item
   * @param itemIdx index of the item
   */
  calculateTotalMasterPacks(itemIdx: number) {
    let itemDetail = this.itemDetails[itemIdx];

    let quantity = parseInt(itemDetail.qty);
    let masterPackVal = parseInt(itemDetail.unitsPerMP);

    if (!isNaN(quantity) && !isNaN(masterPackVal) && masterPackVal > 0 && quantity % masterPackVal == 0) {
      return (quantity / masterPackVal).toString();
    }
    else {
      return "";
    }
  }

  /**
   * Calculate quantity for first split based on percentage
   * @param splitPercent first split percent array
   * @param elementIdx index of the element to be processed
   */
  calculateFirstCooEstimatedQuantity(splitPercent: any[], elementIdx: number){
    return this.handleNan(
      ( Math.floor(
        (0.01 * this.firstSplitPercent[elementIdx] * parseInt(this.itemDetails[elementIdx].qty))
        / parseInt(this.itemDetails[elementIdx].unitsPerMP)
        )
      )
      * parseInt(this.itemDetails[elementIdx].unitsPerMP));
  }

  /**
   * Calculate quantity for second split based on percentage
   * @param splitPercent second split percent array
   * @param elementIdx index of the element to be processed
   */
  calculateSecondCooEstimatedQuantity(splitPercent: any[], elementIdx: number) {
    return this.handleNan(
      parseInt(this.itemDetails[elementIdx].qty)
      - parseInt(this.calculateFirstCooEstimatedQuantity(splitPercent, elementIdx))
    );
  }

  /**
   * Cache item
   * @param item Item to be cached
   */
  cacheDataItem(item: ItemDetails) {
    if (!(item.orderNumber in this.cache)) {
      this.cache[item.orderNumber] = [];
    }

    for (let i = 0; i < this.cache[item.orderNumber].length; ++i) {
      if (this.cache[item.orderNumber][i].lineNum == item.lineNum) {
        return; // already cached
      }
    }

    this.cache[item.orderNumber].push(item);
  }

  /**
   * Get cached item 
   * @param orderNumber order number for which item is to retrieved from cache
   */
  getCachedDataItem(orderNumber: string) {
    if (orderNumber in this.cache) {
      return this.cache[orderNumber];
    }

    return null;
  }

  /**
   * Get Data for COO form
   * */
  async getCOOData(){

    var orderIds: string[] = [];

    // Only CM Shipment mocker supported for Mixed COO
    if (ShipmentMockRequestType[this.type] === ShipmentMockRequestType[ShipmentMockRequestType.CM]) {
      orderIds = this.getNumberArray(this.poNumbers);
    }
    else {
      return; 
    }

    await this.getMixedCooData(orderIds);
  }

  /**
   * Get data for COO from Order IDs
   * !! WARNING - only 1 order id supported for now, see how to expand to multiple orders in future !!
   * @param idNumber order IDs
   */
  async getMixedCooData(idNumber: string[]) {

    this.poError = false;
    this.doError = false;

    for (let i = 0; i < idNumber.length; i++) {
      var orderId = idNumber[i];

      var items = this.getCachedDataItem(orderId);
      if (items != null && items.length > 0) {
        // Item is present in cache already, then restore its details, and COO state 
        var holds: boolean = false;
        for (let j = 0; j < this.itemDetails.length; j++) {
          holds = holds || this.itemDetails[j].orderNumber == orderId;
        }
        if (!holds) {
          this.itemDetails.push(...items);
        }

        // restore COO state as was setup by user last time
        for (let k = 0; k < this.cooState.length; ++k) {
          if (this.cooState[k].orderNumber == orderId) {
            this.checkBox[k] = true;
            this.firstCOO[k] = this.cooState[k].firstCoo;
            this.secondCOO[k] = this.cooState[k].secondCoo;
            this.firstSplitPercent[k] = this.cooState[k].firstSplitPercent; 
            this.secondSplitPercent[k] = this.cooState[k].secondSplitPercent;
          }
        }
      }
      else
      {
        // Item not present in cache, then load information from API

        var orderLineObservable =
          ShipmentMockRequestType[this.type] === ShipmentMockRequestType[ShipmentMockRequestType.CM] && this.processType == "DirectShip" ?
            this.shipmentService.getCustomerPurchaseOrderLine(orderId)
            : this.shipmentService.getPurchaseOrderLines(orderId);
        
        orderLineObservable.subscribe(
          (orderLines: OrderLine[]) => {

            if ((orderLines || []).length == 0) {
              this.handleCooError(true, "Error - can't load order details");
              return;
            }

            for (let j = 0; j < orderLines.length; ++j) {
              let orderLine = orderLines[j];

              var detail: ItemDetails = new ItemDetails();
              detail.material = orderLine.sku;
              detail.orderNumber = orderId;
              detail.lineNum = orderLine.orderNumber;
              detail.plant = orderLine.plant;
              detail.qty = orderLine.quantity;
              detail.unitsPerMP = orderLine.masterPackQuantity;
              detail.totalMP = (parseInt(detail.qty) / parseInt(detail.unitsPerMP)).toString();
              this.itemDetails.push(detail);
              this.cacheDataItem(detail);

              if (!isNaN(parseInt(detail.unitsPerMP))) {
                detail.isMasterPackLoaded = true;
              }
              else {
                detail.isMasterPackLoaded = false;
              }
            }
          },
          (error: any) => {
            this.handleCooError(true, error);
          }
        );
      }
    }
  }

  /**
   * Handle error for info not being loaded for COO
   * @param isPurchaseOrder true if this is purchase order
   * @param error error object if any
   */
  handleCooError(isPurchaseOrder: boolean, error: any){
    if (error != null) {
      this.logger.trackTrace(`In Shipment Mocker, COO sidebar failed`);
      this.logger.trackException(error);
      console.log(error);
    }

    if(isPurchaseOrder){
      this.poError = true;
      this.doError = false;
    }else{
      this.poError = false;
      this.doError = true;
    }

    this.toggleSidebar();
  }
}
