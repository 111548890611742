import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { Routes, RouterModule, ChildActivationEnd } from '@angular/router';
import { NewTestRunComponent } from './new-test-run/new-test-run.component';
import { BrowserUtils } from '@azure/msal-browser';
import { ShipmentmockerComponent } from './shipmentmocker/shipmentmocker.component';
import { LoadPlanMockerComponent } from './loadplanmocker/loadplanmocker.component';
import { LoadTestComponent } from './loadtest/loadtest.component';
import { LoadTestExecutionComponent } from './loadtestexecution/loadtestexecution.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { TestRunListComponent } from './test-run-list/test-run-list.component';
import { ManageScenariosComponent } from './manage-scenarios/manage-scenarios.component';
import { ExecuteTestRunsComponent } from './manage-scenarios/execute-testruns/execute-testruns.component';
import { ManageTestRunsComponent } from './manage-scenarios/manage-testruns/manage-testruns.component';
import { OrderSummaryComponent } from './order-summary/order-summary.component';
import { OrderListComponent } from './order-list/order-list.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { SerialNumberGeneratorComponent } from './serial-number-generator/serial-number-generator.component';
import { SerialNumberListComponent } from './serial-number-list/serial-number-list.component';
import { SkuNumberListComponent } from './sku-number-list/sku-number-list.component';
import { SkuValidationComponent } from './skuvalidation/skuvalidation.component';
import { SapOutputTriggerComponent } from './sapoutputtrigger/sapoutputtrigger.component';
import { ComingSoonComponent } from './comming-soon/comming-soon.component';
import { ScenarioCatalogComponent } from './scenariocatalog/scenariocatalog.component';
import { TestRunOverviewComponent } from './testrunoverview/testrunoverview.component';
import { GoodReceiptMockerComponent } from './goodreceiptmocker/goodreceiptmocker.component'
import { EntityTrackingComponent } from './entity-tracking/entitytracking.component';
import { ToolsComponent } from './tools/tools.component';
import { MockersComponent } from './mockers/mockers.component';
import { TestRunComponent } from './testrun/testrun.component';
import { UATAvailabilityComponent } from './uatAvailability/uatAvailability.component';
import { PrivacyNoticeComponent } from './privacynotice/privacynotice.component';
import { TestProjectViewComponent } from './project-test-runs/project-view/test-project-view.component';
import { TestCaseViewComponent } from './project-test-runs/test-case-view/test-case-view.component';
import { OrgProjectViewComponent } from './project-test-runs/org-project-view/org-project-view.component';
import { OrgViewLandingPageComponent } from './landing-page/org-view-landing-page/org-view-landing-page.component';
import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';
import { RoleGuard } from './common/role-guard';
import { SelfServeComponent } from './self-serve/self-serve.component';
import { FormlyPlaygroundComponent } from './formly-playground/formly-playground.component';
import { GenerateMapperStringComponent } from './generate-mapper-string/generate-mapper-string.component';
import { DevicecareComponent } from './devicecare/devicecare.component';
import { MockersformlyComponent } from './mockersformly/mockersformly.component';
import { BlobDownloadComponent } from './blob-download/blob-download.component';


const appRoutes: Routes = [
  {
    path: "",
    redirectTo: "org-landing",
    pathMatch: "full",
    canActivate:[MsalGuard],
    data: {
      expectedRole: [
        "OMTestSuiteTester",
        "OMTestSuiteAdmin",
        "OMTestSuiteRead",
      ],
    },
  },
  {
    path: "org-landing",
    pathMatch: "full",
    component: OrgViewLandingPageComponent,
    canActivate: [MsalGuard],
    data: {
      expectedRole: [
        "OMTestSuiteTester",
        "OMTestSuiteAdmin",
        "OMTestSuiteRead",
      ],
    },
  },
  {
    path: "self-serve",
    pathMatch: "full",
    component: SelfServeComponent,
    canActivate: [MsalGuard,RoleGuard],
    data: {
      expectedRole: [
       "OMTestSuiteAdmin",
       
      ],
    },
  },
  {
    path: "self-serve/generateMapperString",
    pathMatch: "full",
    component: GenerateMapperStringComponent,
    canActivate: [MsalGuard,RoleGuard],
    data: {
      expectedRole: [
       "OMTestSuiteAdmin",
       
      ],
    },
  },
  {
    path: "landing-page",
    pathMatch: "full",
    component: LandingPageComponent,
    canActivate: [MsalGuard, RoleGuard],
    data: {
      expectedRole: [
        "OMTestSuiteTester",
        "OMTestSuiteAdmin",
        "OMTestSuiteRead",
      ],
    },
  },
  {
    path: "shipment-mocker",
    pathMatch: "full",
    component: ShipmentmockerComponent,
    canActivate: [MsalGuard, RoleGuard],
    data: { expectedRole: ["OMTestSuiteTester", "OMTestSuiteAdmin"] },
  },
  {
    path: "loadplan-mocker",
    pathMatch: "full",
    component: LoadPlanMockerComponent,
    canActivate: [MsalGuard, RoleGuard],
    data: { expectedRole: ["OMTestSuiteTester", "OMTestSuiteAdmin"] },
  },
  {
    path: "goodreceipt-mocker",
    pathMatch: "full",
    component: GoodReceiptMockerComponent,
    canActivate: [MsalGuard, RoleGuard],
    data: { expectedRole: ["OMTestSuiteTester", "OMTestSuiteAdmin"] },
  },
  {
    path: "loadtest",
    pathMatch: "full",
    component: LoadTestComponent,
    canActivate: [MsalGuard, RoleGuard],
    data: { expectedRole: ["OMTestSuiteTester", "OMTestSuiteAdmin"] },
  },
  {
    path: "loadtestexecution/:loadTestRunId",
    pathMatch: "full",
    component: LoadTestExecutionComponent,
    canActivate: [MsalGuard, RoleGuard],
    data: { expectedRole: ["OMTestSuiteTester", "OMTestSuiteAdmin"] },
  },
  {
    path: "new-test-run",
    pathMatch: "full",
    component: NewTestRunComponent,
    canActivate: [MsalGuard, RoleGuard],
    data: { expectedRole: ["OMTestSuiteTester", "OMTestSuiteAdmin"] },
  },
  {
    path: "manage-scenarios",
    pathMatch: "full",
    component: ManageScenariosComponent,
    canActivate: [MsalGuard, RoleGuard],
    data: {
      expectedRole: [
        "OMTestSuiteTester",
        "OMTestSuiteAdmin",
        "OMTestSuiteRead",
      ],
    },
  },
  {
    path: "manage-testruns",
    pathMatch: "full",
    component: ManageTestRunsComponent,
    canActivate: [MsalGuard, RoleGuard],
    data: {
      expectedRole: [
        "OMTestSuiteTester",
        "OMTestSuiteAdmin",
        "OMTestSuiteRead",
      ],
    },
  },
  {
    path: "execute-testruns",
    pathMatch: "full",
    component: ExecuteTestRunsComponent,
    canActivate: [MsalGuard, RoleGuard],
    data: {
      expectedRole: [
        "OMTestSuiteTester",
        "OMTestSuiteAdmin",
        "OMTestSuiteRead",
      ],
    },
  },
  {
    path: "testruns",
    pathMatch: "full",
    component: TestRunListComponent,
    canActivate: [MsalGuard, RoleGuard],
    data: { expectedRole: ["OMTestSuiteTester", "OMTestSuiteAdmin"] },
  },
  {
    path: "project-view",
    pathMatch: "full",
    component: TestProjectViewComponent,
    canActivate: [MsalGuard, RoleGuard],
    data: {
      expectedRole: [
        "OMTestSuiteTester",
        "OMTestSuiteAdmin",
        "OMTestSuiteRead",
      ],
    },
  },
  {
    path: "org-project-view",
    pathMatch: "full",
    component: OrgProjectViewComponent,
    canActivate: [MsalGuard, RoleGuard],
    data: {
      expectedRole: [
        "OMTestSuiteTester",
        "OMTestSuiteAdmin",
        "OMTestSuiteRead",
      ],
    },
  },
  {
    path: "test-case-view",
    pathMatch: "full",
    component: TestCaseViewComponent,
    canActivate: [MsalGuard, RoleGuard],
    data: { expectedRole: ["OMTestSuiteTester", "OMTestSuiteAdmin"] },
  },
  {
    path: "order-summary/:customerPurchaseOrder",
    pathMatch: "full",
    component: OrderSummaryComponent,
    canActivate: [MsalGuard, RoleGuard],
    data: {
      expectedRole: [
        "OMTestSuiteTester",
        "OMTestSuiteAdmin",
        "OMTestSuiteRead",
      ],
    },
  },
  {
    path: "orders",
    pathMatch: "full",
    component: OrderListComponent,
    canActivate: [MsalGuard, RoleGuard],
    data: {
      expectedRole: [
        "OMTestSuiteTester",
        "OMTestSuiteAdmin",
        "OMTestSuiteRead",
      ],
    },
  },
  {
    path: "serial-number-generator",
    pathMatch: "full",
    component: SerialNumberGeneratorComponent,
    canActivate: [MsalGuard, RoleGuard],
    data: {
      expectedRole: [
        "OMTestSuiteTester",
        "OMTestSuiteAdmin",
        "OMTestSuiteRead",
      ],
    },
  },
  {
    path: "serial-number-list",
    pathMatch: "full",
    component: SerialNumberListComponent,
    canActivate: [MsalGuard, RoleGuard],
    data: {
      expectedRole: [
        "OMTestSuiteTester",
        "OMTestSuiteAdmin",
        "OMTestSuiteRead",
      ],
    },
  },
  {
    path: "sku-number-list",
    pathMatch: "full",
    component: SkuNumberListComponent,
    canActivate: [MsalGuard, RoleGuard],
    data: {
      expectedRole: [
        "OMTestSuiteTester",
        "OMTestSuiteAdmin",
        "OMTestSuiteRead",
      ],
    },
  },
  {
    path: "skuvalidation",
    pathMatch: "full",
    component: SkuValidationComponent,
    canActivate: [MsalGuard, RoleGuard],
    data: {
      expectedRole: [
        "OMTestSuiteTester",
        "OMTestSuiteAdmin",
        "OMTestSuiteRead",
      ],
    },
  },
  {
    path: "sapoutputtrigger",
    pathMatch: "full",
    component: SapOutputTriggerComponent,
    canActivate: [MsalGuard, RoleGuard],
    data: {
      expectedRole: [
        "OMTestSuiteTester",
        "OMTestSuiteAdmin",
        "OMTestSuiteRead",
      ],
    },
  },
  {
    path: "entitytracking",
    pathMatch: "full",
    component: EntityTrackingComponent,
    canActivate: [MsalGuard, RoleGuard],
    data: {
      expectedRole: [
        "OMTestSuiteTester",
        "OMTestSuiteAdmin",
        "OMTestSuiteRead",
      ],
    },
  },
  {
    path: "mockers-formly",
    pathMatch: "full",
    component: MockersformlyComponent,
    canActivate: [MsalGuard, RoleGuard],
    data: {
      expectedRole: [
        "OMTestSuiteTester",
        "OMTestSuiteAdmin",
        "OMTestSuiteRead",
      ],
    },
  },
  {
    path: "blob-download/:blobAdapterName/:blobName",
    pathMatch: "full",
    component: BlobDownloadComponent,
    canActivate: [MsalGuard, RoleGuard],
    data: {
      expectedRole: [
        "OMTestSuiteTester",
        "OMTestSuiteAdmin",
        "OMTestSuiteRead",
      ],
    },
  },
  {
    path: "coming-soon",
    pathMatch: "full",
    component: ComingSoonComponent,
    canActivate: [MsalGuard, RoleGuard],
    data: {
      expectedRole: [
        "OMTestSuiteTester",
        "OMTestSuiteAdmin",
        "OMTestSuiteRead",
      ],
    },
  },
  {
    path: "tools",
    pathMatch: "full",
    component: ToolsComponent,
    canActivate: [MsalGuard, RoleGuard],
    data: {
      expectedRole: [
        "OMTestSuiteTester",
        "OMTestSuiteAdmin",
        "OMTestSuiteRead",
      ],
    },
  },
  {
    path: "privacynotice",
    pathMatch: "full",
    component: PrivacyNoticeComponent,
    canActivate: [MsalGuard, RoleGuard],
    data: {
      expectedRole: [
        "OMTestSuiteTester",
        "OMTestSuiteAdmin",
        "OMTestSuiteRead",
      ],
    },
  },
  {
    path: "mockers",
    pathMatch: "full",
    component: MockersComponent,
    canActivate: [MsalGuard, RoleGuard],
    data: {
      expectedRole: [
        "OMTestSuiteTester",
        "OMTestSuiteAdmin",
        "OMTestSuiteRead",
      ],
    },
  },
  {
    path: "testscenario",
    pathMatch: "full",
    component: TestRunComponent,
    canActivate: [MsalGuard, RoleGuard],
    data: {
      expectedRole: [
        "OMTestSuiteTester",
        "OMTestSuiteAdmin",
        "OMTestSuiteRead",
      ],
    },
  },
  {
    path: "scenariocatalog",
    pathMatch: "full",
    component: ScenarioCatalogComponent,
    canActivate: [MsalGuard, RoleGuard],
    data: {
      expectedRole: [
        "OMTestSuiteTester",
        "OMTestSuiteAdmin",
        "OMTestSuiteRead",
      ],
    },
  },
  {
    path: "testrunoverview",
    pathMatch: "full",
    component: TestRunOverviewComponent,
    canActivate: [MsalGuard, RoleGuard],
    data: {
      expectedRole: [
        "OMTestSuiteTester",
        "OMTestSuiteAdmin",
        "OMTestSuiteRead",
      ],
    },
  },
  {
    path: "uatAvailability",
    pathMatch: "full",
    component: UATAvailabilityComponent,
    canActivate: [MsalGuard, RoleGuard],
    data: {
      expectedRole: [
        "OMTestSuiteTester",
        "OMTestSuiteAdmin",
        "OMTestSuiteRead",
      ],
    },
  },
  {
    path: "formly-playground",
    pathMatch: "full",
    component: FormlyPlaygroundComponent,
    canActivate: [MsalGuard, RoleGuard],
    data: {
      expectedRole: [
        "OMTestSuiteTester",
        "OMTestSuiteAdmin",
        "OMTestSuiteRead",
      ],
    },
  },
  { path: "unauthorized", pathMatch: "full", component: UnauthorizedComponent },
  { path: "**", redirectTo: "org-landing", pathMatch: "full" },
  { path: "auth", component: MsalRedirectComponent },
];



@NgModule({
  imports: [RouterModule.forRoot(appRoutes, {
    useHash: false,
    // Don't perform initial navigation in iframes or popups
    initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabled' : 'disabled' 
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
