import { Component, OnInit, Input } from "@angular/core";

@Component({
    selector:'app-progress',
    templateUrl: './progress.component.html',
    styleUrls:['./progress.component.css'],
})

export class ProgressComponent implements OnInit{
    @Input() completed = 0;
    @Input() total = 1;


    constructor(){

    }

    ngOnInit(): void {
        
    }
}

