import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';

export function jsonValidator(control: AbstractControl): ValidationErrors {
 
  try {
    JSON.parse(control.value);
    return {json:true};
  } catch (e) {
    return {json:false};
  }
}

@Component({
  selector: 'app-self-serve',
  templateUrl: './self-serve.component.html',
  styleUrls: ['./self-serve.component.css']
})
export class SelfServeComponent  {
  form = new FormGroup({});
  model: any = {
  };
  options: FormlyFormOptions = {};

  fields: FormlyFieldConfig[] = [{
    type: 'tabs',
    fieldGroup: [
      {
        key:'components',
        type: 'repeat-button',
        wrappers:['card'],
        templateOptions: { 
          label: 'Component Form',
          addText: 'Add Component Form',
        },
       fieldArray:{
        fieldGroup: [
          {
            key: 'ComponentDescription',
            type: 'input',
            templateOptions: {
              label: 'ComponentDescription',
              required: true,
             },
          },
          {
            key: 'ComponentName',
            type: 'input',
            templateOptions: {
              label: 'Component Name',
              required: true,
            },
          },
          {
            key: 'Vertical',
            type: 'input',
            templateOptions: {
              label: 'Vertical',
              required: true,
            },
          },
          {
            key: 'ComponentPayload',
            type: 'input',
            templateOptions: {
              label: 'Component Payload',
              required: true,
            },
          },
          {
            key: 'ComponentServiceURL',
            type: 'input',
            templateOptions: {
              label: 'Component Service URL',
              required: true,
            },
          },
          {
            key: 'ComponentServiceAPIMethod',
            type: 'select',
            templateOptions: {
              label: 'Component Service API Method',
              required: true,
              options: [
                { id: '1', name: 'GET' },
                { id: '2', name: 'POST' },
              ],
              valueProp: 'id',
              labelProp: 'name',
            },
          },
          {
            key: 'ClientID',
            type: 'input',
            templateOptions: {
              label: 'Client ID',
              required: true,
            },
          },
          {
            key: 'ResourceURL',
            type: 'input',
            templateOptions: {
              label: 'Resource URL',
              required: true,
            },
          },
          {
            key: 'Authority',
            type: 'input',
            templateOptions: {
              label: 'Authority',
              required: true,
            },
          },
          {
            key: 'ComponentType',
            type: 'input',
            templateOptions: {
              label: 'Component Type',
              required: true,
            },
          },
          {
            key: 'GetFilterCriteria',
            type: 'input',
            
            templateOptions: {
              label: 'Get Filter Criteria',
              required: true,
            },
            hideExpression:'field.parent.model.ComponentServiceAPIMethod=="2"',
            
          },
          {
            key: 'SucessCriteria',
            type: 'input',
            templateOptions: {
              label: 'Sucess Criteria',
              required: true,
            },
          },
          {
            key: 'HttpHeaders',
            type: 'input',
            templateOptions: {
              label: 'HttpHeaders',
              required: true,
            },
            validators: {
              validation: [jsonValidator],
            },
          },
          {
            key: 'UsesManagedIdentity',
            type: 'input',
            defaultValue:true,
            templateOptions: {
              label: 'User Managed Identity',
              required: true,
              },
          },
          {
            key: 'LongDescription',
            type: 'input',
            templateOptions: {
              label: 'Long Description',
              required: true,
            },
          },
          {
            key: 'AdvOptions',
            type: 'checkbox',
            templateOptions: {
              label: 'Advanced options',
              
            },
            defaultValue:false,
            
          },
          { key:'advComponentOptions',
            hideExpression:'!field.parent.model.AdvOptions',
              fieldGroup:[
                {
                key: 'UserInputJson',
                type: 'input',
                templateOptions: {
                  label: 'User Input Json',
                },
               },
               {
                key: 'MappersConfig',
                type: 'input',
                templateOptions: {
                  label: 'Mappers Config',
                },
               },
               {
                key: 'KeyVaultNameForSecrets',
                type: 'input',
                templateOptions: {
                  label: 'Key Vault Name For Secrets',
                },
               },
               {
                key: 'KeyVaultNameForStorageLocation',
                type: 'input',
                templateOptions: {
                  label: 'Key Vault Name For StorageLocation',
                },
               },
               {
                key: 'SubscriptionKey',
                type: 'input',
                templateOptions: {
                  label: 'Subscription Key',
                },
               },
               {
                key: 'SubscriptionValue',
                type: 'input',
                templateOptions: {
                  label: 'Subscription Value',
                },
               },
               {
                key: 'QueueName',
                type: 'input',
                templateOptions: {
                  label: 'Queue Name',
                },
               },
               {
                key: 'DisplayTracking',
                type: 'input',
                templateOptions: {
                  label: 'DisplayTracking',
                },
               },
               {
                key: 'TrackingType',
                type: 'input',
                templateOptions: {
                  label: 'Tracking Type',
                },
               },
               {
                key: 'DownloadSuccessKey',
                type: 'input',
                templateOptions: {
                  label: 'Download Success Key',
                },
               },
               {
                key: 'DownloadErrorKey',
                type: 'input',
                templateOptions: {
                  label: 'Download Error Key',
                },
               },
               {
                key: 'IsDisabled',
                type: 'input',
                templateOptions: {
                  label: 'Is Disabled',
                },
               },
               {
                key: 'ProcessingTime',
                type: 'input',
                templateOptions: {
                  label: 'ProcessingTime',
                },
               },
               {
                key: 'RestartDisplayCondition',
                type: 'input',
                templateOptions: {
                  label: 'Restart Display Condition',
                },
               },
               {
                key: 'CustomErrorMessage',
                type: 'input',
                templateOptions: {
                  label: 'Custom Error Message',
                },
               },
               {
                key: 'AllowProceedOnError',
                type: 'input',
                templateOptions: {
                  label: 'Allow Proceed On Error',
                },
               },
               {
                key: 'enableRefresh',
                type: 'input',
                templateOptions: {
                  label: 'Enable Refresh',
                },
               },
               {
                key: 'EnableErrorEmail',
                type: 'input',
                templateOptions: {
                  label: 'Enable Error Email',
                },
               },
               {
                key: 'ErrorEmaillink',
                type: 'input',
                templateOptions: {
                  label: 'Error Email link',
                },
               },
               {
                key: 'PayloadDataType',
                type: 'input',
                templateOptions: {
                  label: 'Payload Data Type',
                },
               },
               {
                key: 'UpdateResponseMapping',
                type: 'input',
                templateOptions: {
                  label: 'Update Response Mapping',
                },
               },
               {
                key: 'IsUpdateResponse',
                type: 'input',
                templateOptions: {
                  label: 'Is Update Response',
                },
               },
               {
                key: 'GrantType',
                type: 'input',
                templateOptions: {
                  label: 'Grant Type',
                },
               },
               {
                key: 'MultiPayloadDisplayName',
                type: 'input',
                templateOptions: {
                  label: 'Multi Payload Display Name',
                },
               },
               {
                key: 'ComponentServiceType',
                type: 'input',
                templateOptions: {
                  label: 'Component Service Type',
                },
               },
              ] ,
            }
          ]
        }
      },
      {
        key:'scenario',
        wrappers:['card'],
        templateOptions: { 
          label: 'Scenario Form',
         
        },
        fieldGroup:[
              {
                key: 'ScenarioName',
                  type: 'input',
                  templateOptions: {
                    label: 'Scenario Name',
                    required: true,
                  },
                },
                {
                  key: 'ScenarioDescription',
                  type: 'input',
                  templateOptions: {
                    label: 'Scenario Description',
                    required: true,
                  },
                },
                {
                  key: 'Vertical',
                  type: 'input',
                  templateOptions: {
                    label: 'Vertical',
                    required: true,
                  },
                },
                {
                  key: 'Section',
                  type: 'input',
                  templateOptions: {
                    label: 'Section',
                    required: true,
                  },
                },
                {
                  key: 'UserInputJson',
                  type: 'input',
                  templateOptions: {
                    label: 'User Input Json',
                    required: true,
                  },
                },
                {
                  key: 'TableColumnsJson',
                  type: 'input',
                  templateOptions: {
                    label: 'Table Columns Json',
                    required: true,
                  },
                },
                {
                  key: 'LongDescription',
                  type: 'input',
                  templateOptions: {
                    label: 'Long Description',
                    required: true,
                  },
                },
                {
                  key: 'AllowMultipleTestRuns',
                  type: 'input',
                  templateOptions: {
                    label: 'AllowMultipleTestRuns',
                    required: true,
                  },
                },
                
                {
                  key: 'AdvOptions',
                  type: 'checkbox',
                  templateOptions: {
                    label: 'Advanced options',
                    
                  },
                  defaultValue:false,
                  
                },
                { key:'advComponentOptions',
                  hideExpression:'!field.parent.model.AdvOptions',
                    fieldGroup:[
                      {
                        key: 'IncludeAllOption',
                        type: 'input',
                        templateOptions: {
                          label: 'Include All Option',
                        },
                       },
                       {
                        key: 'IncludeLoadOption',
                        type: 'input',
                        templateOptions: {
                          label: 'Include Load Option',
                        },
                       },
                       {
                        key: 'CreatedAt',
                        type: 'input',
                        defaultValue: new Date(),
                        templateOptions: {
                          label: 'Created At',
                         },
                       },
                       {
                        key: 'LastModifiedAt',
                        type: 'input',
                        defaultValue: new Date(),
                        templateOptions: {
                          label: 'Last Modified At',
                         },
                       },
                       {
                        key: 'OrderStatusTrackEventSequence',
                        type: 'input',
                        templateOptions: {
                          label: 'Order Status Track Event Sequence',
                        },
                       },
                       {
                        key: 'ScenarioCode',
                        type: 'input',
                        templateOptions: {
                          label: 'Scenario Code',
                        },
                       },
                       {
                        key: 'EnableBulkUploadOption',
                        type: 'input',
                        templateOptions: {
                          label: 'Enable Bulk Upload Option',
                        },
                       },
                       {
                        key: 'BusinessProcessDefinition',
                        type: 'input',
                        templateOptions: {
                          label: 'Business Process Definition',
                        },
                       },
                       {
                        key: 'Description',
                        type: 'input',
                        templateOptions: {
                          label: 'Description',
                        },
                       },
                       {
                        key: 'BusinessProcessName',
                        type: 'input',
                        templateOptions: {
                          label: 'Business Process Name',
                        },
                       },
                       {
                        key: 'LastModifiedBy',
                        type: 'input',
                        templateOptions: {
                          label: 'Last Modified By',
                          type:'DateTime'
                        },
                       },
                       {
                        key: 'isTestCase',
                        type: 'input',
                        templateOptions: {
                          label: 'is TestCase',
                        },
                       },
                       {
                        key: 'InputParameter',
                        type: 'input',
                        templateOptions: {
                          label: 'Input Parameter',
                        },
                       },
                       {
                        key: 'TestCaseIdentifier',
                        type: 'input',
                        templateOptions: {
                          label: 'TestCase Identifier',
                        },
                       },
                       {
                        key: 'InputFromScenario',
                        type: 'input',
                        templateOptions: {
                          label: 'Input From Scenario',
                        },
                       },
                       {
                        key: 'InputToPull',
                        type: 'input',
                        templateOptions: {
                          label: 'Input To Pull',
                        },
                       },
                       {
                        key: 'ADOTestCaseId',
                        type: 'input',
                        templateOptions: {
                          label: 'ADO Test CaseId',
                        },
                       },
                       {
                        key: 'Initiatives',
                        type: 'input',
                        templateOptions: {
                          label: 'Initiatives',
                        },
                       },
                      ] ,
                    }
                  ]
                },
      {
        key:'scenarioFlavour',
        type: 'repeat-button',
        wrappers:['card'],
        templateOptions: { 
          label: 'Scenario Flavour Form',
          addText: 'Add Form',
        },
       fieldArray:{
        fieldGroup: [
          {
            key: 'ScenarioID',
            type: 'input',
            templateOptions: {
              label: 'Scenario ID',
              required: true,
            },
          },
          {
            key: 'FlavourDescription',
            type: 'input',
            templateOptions: {
              label: 'Flavour Description',
              required: true,
            },
          },
          
          {
            key: 'AdvOptions',
            type: 'checkbox',
            templateOptions: {
              label: 'Advanced options',
              
            },
            defaultValue:false,
            
          },
          { key:'advComponentOptions',
            hideExpression:'!field.parent.model.AdvOptions',
              fieldGroup:[
                {
                key: 'CreatedAt',
                type: 'input',
                defaultValue: new Date(),
                templateOptions: {
                  label: 'Created At',
                  },
                },
                {
                  key: 'LastModifiedAt',
                  type: 'input',
                   defaultValue: new Date(),
                  templateOptions: {
                    label: 'LastModified At',
                    },
                },
                {
                  key: 'OrderStatusTrackEventSequence',
                  type: 'input',
                  templateOptions: {
                    label: 'Order Status TrackEvent Sequence',
                    },
                },
                {
                  key: 'FlavorCode',
                  type: 'input',
                  templateOptions: {
                    label: 'FlavorCode',
                    },
                },
                {
                  key: 'SheetDataMapperString',
                  type: 'input',
                  templateOptions: {
                    label: 'SheetData Mapper String',
                    },
                },
                {
                  key: 'DefaultUserInputParameter',
                  type: 'input',
                  templateOptions: {
                    label: 'Default User Input Parameter',
                    },
                },
              ] ,
            }
          ]
        }
      },
      {
        key:'OrganizationScenarioMapping',
        wrappers:['card'],
        templateOptions: { 
          label: 'Organization Form',
         
        },
        fieldGroup:[
              {
                key: 'OrganizationID',
                  type: 'input',
                  templateOptions: {
                    label: 'Organization ID',
                    required: true,
                  },
                },
                {
                  key: 'ScenarioID',
                  type: 'input',
                  templateOptions: {
                    label: 'Scenario ID',
                    required: true,
                  },
                },
              ]
             
      },
      {
        key:'ScenarioStep ',
        type: 'repeat-button',
        wrappers:['card'],
        templateOptions: { 
          label: 'Scenario Step  Form',
          addText: 'Add Form',
        },
       fieldArray:{
        fieldGroup: [
          {
            key: 'ScenarioID',
            type: 'input',
            templateOptions: {
              label: 'Scenario ID',
              required: true,
            },
          },
          {
            key: 'ComponentID',
            type: 'input',
            templateOptions: {
              label: 'Component ID',
              required: true,
            },
          },
          {
            key: 'StepRank',
            type: 'input',
            templateOptions: {
              label: 'Step Rank',
              required: true,
            },
          },
          {
            key: 'CreatedAt',
            type: 'input',
            defaultValue: new Date(),
            templateOptions: {
              label: 'Created At',
              required: true,
            },
          },
          {
            key: 'LastModified',
            type: 'input',
            defaultValue: new Date(),
            templateOptions: {
             label: 'Last Modified',
              required: true,
            },
          },
          {
            key: 'DelayAfterPreviousStep',
            type: 'input',
            defaultValue: 0,
            templateOptions: {
              label: 'Delay After Previous Step',
              required: true,
            },
          },
          {
            key: 'DisplayToUser',
            type: 'input',
            defaultValue: true,
            templateOptions: {
              label: 'Display To User',
              required: true,
            },
          },
          {
            key: 'IsManualStep',
            type: 'input',
            defaultValue: false,
            templateOptions: {
              label: 'Is Manual Step',
              required: true,
            },
          },
          {
            key: 'Authority',
            type: 'input',
            templateOptions: {
              label: 'Authority',
              required: true,
            },
          },
         
          {
            key: 'AdvOptions',
            type: 'checkbox',
            templateOptions: {
              label: 'Advanced options',
              
            },
            defaultValue:false,
            
          },
          { key:'advComponentOptions',
            hideExpression:'!field.parent.model.AdvOptions',
            fieldGroup:[
              {
              key: 'IsPartnerDependent',
              type: 'input',
              templateOptions: {
                label: 'Is Partner Dependent',
                },
              },
              {
                key: 'RequireAuthentication',
                type: 'input',
                templateOptions: {
                  label: 'Require Authentication',
                 },
              },
              {
                key: 'ExplicitExecutionRequired',
                type: 'input',
                templateOptions: {
                  label: 'Explicit Execution Required',
                  },
              },
              {
                key: 'GetInputFromStepOfRank',
                type: 'input',
                templateOptions: {
                  label: 'Get Input From StepOfRank',
                  },
              },
              {
                key: 'IsPreReqStep',
                type: 'input',
                templateOptions: {
                  label: 'Is Pre Req Step',
                  },
              },
              {
                key: 'IsDummyStep',
                type: 'input',
                templateOptions: {
                  label: 'Is Dummy Step',
                  },
              },
            ] ,

           
          }
        ]
       }
      },
      {
        key:'ScenarioFlavourConfigData',
        type: 'repeat-button',
        wrappers:['card'],
        templateOptions: { 
          label: 'Scenario Flavour Config Data',
          addText: 'Add Form',
        },
       fieldArray:{
        fieldGroup: [
          {
            key: 'ScenarioFlavour',
            type: 'input',
            templateOptions: {
              label: 'Scenario Flavour',
              required: true,
            },
          },
          {
            key: 'FlavourID',
            type: 'input',
            templateOptions: {
              label: 'Flavour ID',
              required: true,
            },
          },
          {
            key: 'UserInputPayloadSchema',
            type: 'input',
            templateOptions: {
              label: 'User Input Payload Schema',
              required: true,
            },
          },
          {
            key: 'CreatedAt',
            type: 'input',
            defaultValue: new Date(),
            templateOptions: {
             label: 'Created At',
              required: true,
            },
          },
          {
            key: 'LastModifiedAt',
            type: 'input',
            defaultValue: new Date(),
            templateOptions: {
              label: 'Last Modified At',
              required: true,
            },
          },
          {
            key: 'MapperString',
            type: 'input',
            templateOptions: {
              label: 'Mapper String',
              required: true,
            },
          },
          {
              key:'url',
              type:'link',
              templateOptions: {
                label: 'link',
                required: true,
              },
          },
          {
            key: 'ComponentId',
            type: 'input',
            templateOptions: {
              label: 'Component Id',
              required: true,
            },
          },
          {
            key: 'DefaultInputJson',
            type: 'input',
            templateOptions: {
              label: 'DefaultInputJson',
              required: true,
            },
          },
          {
            key: 'StepRank',
            type: 'input',
            templateOptions: {
              label: 'Step Rank',
              required: true,
            },
          },
          
          {
            key: 'AdvOptions',
            type: 'checkbox',
            templateOptions: {
              label: 'Advanced options',
              
            },
            defaultValue:false,
            
          },
          { key:'advComponentOptions',
            hideExpression:'!field.parent.model.AdvOptions',
            fieldGroup:[
              {
              key: 'PostInputAsOutput',
              type: 'input',
              templateOptions: {
                label: 'Post Input As Output',
                },
              },
              {
                key: 'GetInputFromStepOfRank',
                type: 'input',
                templateOptions: {
                  label: 'Get Input From StepOfRank',
                 },
              },
              {
                key: 'GetFilterCriteria',
                type: 'input',
                templateOptions: {
                  label: 'Get Filter Criteria',
                  },
              },
              {
                key: 'SuccessCriteria',
                type: 'input',
                templateOptions: {
                  label: 'Success Criteria',
                  },
              },
              {
                key: 'ComponentPayload',
                type: 'input',
                templateOptions: {
                  label: 'Component Payload',
                  },
              },
              {
                key: 'OutputMapperString',
                type: 'input',
                templateOptions: {
                  label: 'Output Mapper String',
                  },
              },
              {
                key: 'CommonOutputStore',
                type: 'input',
                templateOptions: {
                  label: 'CommonOutputStore',
                  },
              },
            ] ,
          }
        ]
       }
      },
    ],
  }];

  submit() {
    alert(JSON.stringify(this.model));
  }
}
