import { OutputTriggerRequest } from "../model/outputtrigger.model";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ConfigService } from "./config.service";

@Injectable()
export class SAPService {

  constructor(private httpClient: HttpClient, private configService: ConfigService) {
  }

  postSAPOutPutTrigger(outputTriggerRequest: OutputTriggerRequest) {
    const baseUrlWithVersion = this.configService.omtestSuiteAPI.baseUrl + '/api/' + this.configService.omtestSuiteAPI.apiVersion;
    return this.httpClient.post<string []>(baseUrlWithVersion + '/SAP/sapoutputtrigger', outputTriggerRequest);
  }
}
