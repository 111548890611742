import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ConfigService } from "./config.service";

@Injectable()
export class GenerateBlobSasTokenService{
  constructor(private httpClient: HttpClient, private configService: ConfigService) {
  }

  getBlobUrl(blobFileName: string) {
    const baseUrlWithVersion = this.configService.omtestSuiteAPI.baseUrl + '/api/' + this.configService.omtestSuiteAPI.apiVersion;
    return this.httpClient.get(baseUrlWithVersion + `/BlobDownload/GetBlobSASUrl?blobName=${blobFileName}`);
  }

}
