<div class="row no-margin margin-top-10" style="margin-top: 0px; height: calc(100vh - 56px); overflow: hidden;">
  <div class="col-lg-12 no-padding">
    <ul role="list" class="nav nav-tabs justify-content-start" style="padding: 0px 15px;">
      <li class="nav-item cursor-pointer" *ngFor="let tab of tabs; index as i" style="outline: 0;" role="tablist">
        <a class="nav-link tab-link" role="tab" id="tabExecuteTestRuns_{{tab.id}}" [ngClass]="{'active': activeTabId == tab.id}"
          aria-expanded="true" aria-disabled="false" tabindex="{{tab.id}}" (click)="changeTab(tab.id)"
          style="outline: 0;">
          <strong><span>{{tab.title}}</span></strong>
        </a>
      </li>
      <button *ngIf="activeTabId == 1" type="button" class="position-abs-right btn btn-report" style="font-size: 14px; height: min-content; margin-left: 15px; margin-right: 15px;"
      role="button" matTooltip="Problem with some test runs? Send us an email! Let us help you."
      (click)="this.manageTestRunsComponent.launchReportIssueMailto(false)">Report a Problem</button>
    </ul>
    <div class="tab-content">
      <app-execute-testruns *ngIf="activeTabId == 0"></app-execute-testruns>
      <app-manage-testruns *ngIf="activeTabId == 1"></app-manage-testruns>
    </div>
  </div>
</div>
