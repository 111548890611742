<nav role="navigation" class="navbar navbar-expand-md navbar-dark" style="  margin-bottom:10px; background-color: rgb(0, 120, 212);
    color: #FFFFFF;
    padding: 10px 15px;">
  <div class=" vertical-menu container-fluid" style="padding: 0px; line-height: 15px;">
    <img aria-label="MicrosoftLogo" class="img-fluid pr-2" alt="" src="../assets/images/MicrosoftLogo.png" style="width: 30px;" />
    <div id="skip">
      <a aria-disabled="false" class="navbar-brand" aria-label="skipLink" [routerLink]="['/landing-page']">Welcome to Microsoft Supply Chain Test Platform</a>
    </div>
    <div class="navbar-collapse collapse w-50 order-3 dual-collapse2">
        <ul class="navbar-nav ml-auto">
          <li  aria-disabled="false" class="nav-item dropdown" *ngIf="!isDevMode" >
            <a aria-disabled="false" class="nav-link dropdown-toggle" href="#" id="environment" role="button" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
              {{ this.configService.selectedEnvironment }}
            </a>
            <div class="dropdown-menu" aria-labelledby="linkEnvironment" *ngIf="!isDevMode">
              <ng-container id="linkEnvironment" *ngFor="let env of this.configService.uiAppSettings.environments">
                <a aria-describedby="linkEnvironment" class="dropdown-item" [routerLink]="['/landing-page']" (click)="switchEnvironment(env.environmentName)"
                  *ngIf="env.environmentName.toLowerCase() != 'ci'">
                  <span>{{ env.environmentName }}</span>
                </a>
              </ng-container>
            </div>
          </li>
          <li aria-disabled="false"  id="linkUserName" class="nav-item dropdown no-padding-right">
            <a aria-describedby="linkUserName" aria-label="home" class="nav-link dropdown-toggle" href="#"  role="button" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
              {{ username }}
            </a>
            <div class="dropdown-menu" aria-labelledby="Log out">
              <a class="dropdown-item" href="#" (click)="logout()">Log out</a>
            </div>
          </li>
        </ul>
      </div>
  </div>
</nav>
<!-- vertical navbar -->
<nav role="navigation" class="navbar-primary" [ngClass]="isCollapsed ? 'collapsed' : ''" style="background-color: rgb(229, 229, 229);">
  <div>
    <ul class="navbar-primary-menu">
      <li class="nav-item">
        <a [routerLink]="['/org-landing']" style="margin-left: 5px;" (click)="navigateMainApp('OrgLanding')"  title="Organizations">
          <i class="ms-Icon ms-Icon--NumberedListText" style="font-size: larger; vertical-align: bottom;"></i>
          <span class="nav-label margin-left-5">
            Organizations
         </span>
        </a>
      </li>
      
      <li class="nav item" style= " margin-top: 5px; border: 1px darkgray;
      border-style: solid none;" *ngIf="SelectedOrgName !== null && IsOrgLanding !== true ">
       
        <a [routerLink]="" *ngIf="isEnabled" disabled=true class="organization" title="Selected Organization" style="display:flex; margin-left: -3px;">
          <span class="ms-Icon ms-Icon--Org" style="color:#0051a9; font-size: large; margin-right:10px;"></span><span class="nav-label" style="color:#0051a9; font-size: large;" >
             {{this.orgName}}
          </span>
        </a>
      </li>

      <li class="nav-item" [ngClass]="currentAppName === 'Portal' ? 'active' : ''" *ngIf="IsOrgLanding === true">
        <a href="https://portal.supplychain.microsoft.com/home" target="_blank" title="Supply Chain Portal" >
          <span class="ms-Icon ms-Icon--ReadingMode"></span><span class="nav-label">
            Supply Chain Portal
          </span>
        </a>
      </li>
      <li class="nav-item" [ngClass]="currentAppName === 'Script' ? 'active' : ''"*ngIf="IsOrgLanding === true">
        <a href="https://script.supplychain.microsoft.com" target="_blank" title="Supply Chain SCRIPT">
          <span class="ms-Icon ms-Icon--GridViewSmall"></span><span class="nav-label">
            Supply Chain SCRIPT
          </span>
        </a>
      </li>
      <li class="nav-item" [ngClass]="currentAppName === 'Home' ? 'active' : ''" *ngIf="SelectedOrgName !== null && IsOrgLanding !== true">
        <a data-toggle="collapse" [routerLink]="['/landing-page',  getUrlParams()]" (click)="navigateMainApp('Home')" data-target="#Home" *ngIf="isEnabled" title="Home">
          <span class="pr-1 fa fa-home"></span><span class="nav-label">
            Home
          </span>
        </a>
      </li>
      <li id="linkUserTestRuns" class="nav-item" [ngClass]="currentAppName === 'Test Runs' ? 'active' : ''" *ngIf="filterNavMenu('Test Runs')">
        <a aria-disabled="false" aria-describedby="linkUserTestRuns" class="nav-link" [routerLink]="['/testscenario',  getUrlParams()]" (click)="navigateMainApp('Test Runs')" data-target="#testRuns"
           data-toggle="collapse" *ngIf="isEnabled" title="Test Runs">
          <span class="pr-1 fa fa-cube"></span><span class="nav-label">
            Test Catalog & Runs
          </span>
        </a>
      </li>
      <li>
        <!--SUb menu-->
        <div id="testRuns" [hidden]="isCollapsed"
             class="collapse" *ngIf="currentAppName === 'Test Runs'">
          <ul class="navbar-nav mr-auto">
            <li class="nav-item">
              <a class="nav-link" [routerLink]="['/execute-testruns', getUrlParams()]" [queryParams]="{env: this.configService.selectedEnvironment}" *ngIf="isManageTestRunEnabled" title="Create">Create</a>
              <a class="nav-link" [routerLink]="['/manage-testruns', getUrlParams()]" [queryParams]="{env: this.configService.selectedEnvironment}" *ngIf="isManageTestRunEnabled" title="Manage">Manage</a>
            </li>
          </ul>
        </div>
      </li>

      <li class="nav-item" [ngClass]="currentAppName === 'ProjectTestRun' ? 'active' : ''" *ngIf="filterNavMenu('ProjectTestRun')">
        <a class="nav-link" [routerLink]="['/org-project-view', getUrlParams()]" 
           (click)="navigateMainApp('ProjectTestRun')" 
           data-target="#projectTestRun"
           data-toggle="collapse" *ngIf="isEnabled" 
           [queryParams]="{env: this.configService.selectedEnvironment}" 
           title="Project View">
          <span class="fa fa-users"></span><span class="nav-label"> Project View</span>
        </a>
      </li>

      <li class="nav-item" [ngClass]="currentAppName === 'Load Test' ? 'active' : ''" *ngIf="filterNavMenu('Load Test')">
        <a class="nav-link" [routerLink]="['/loadtest']" (click)="navigateMainApp('Load Test')" data-target="#loadTest"
           data-toggle="collapse" *ngIf="isEnabled" [queryParams]="{env: this.configService.selectedEnvironment}" title="Load Test">
          <span class="fa fa-cloud-upload"></span><span class="nav-label">
            Load Test
          </span>
        </a>
      </li>
      <li id="tools" class="nav-item" [ngClass]="currentAppName === 'Tools' ? 'active' : ''"  *ngIf="filterNavMenu('Tools')">
        <a aria-disabled="false" aria-describedby="tools" aria-label="tools" class="nav-link" [routerLink]="['/tools']" (click)="navigateMainApp('Tools')" data-target="#Tools"
           data-toggle="collapse" *ngIf="isEnabled" [queryParams]="{env: this.configService.selectedEnvironment}" title="Tools">
          <span class="fa fa-cogs"></span><span class="nav-label">
            Tools
          </span>
        </a>
      </li>
      <li>
        <!--SUb menu-->
        <div id="Tools" [hidden]="isCollapsed"
             class="collapse" *ngIf="currentAppName === 'Tools'">
          <ul class="navbar-nav mr-auto">
            <li class="nav-item">
              <a aria-disabled="false" class="nav-link" [routerLink]="['/serial-number-generator']"
                 *ngIf="isSerialNumberGenerationEnabled" title="Serial Number Generator">Serial Number Generator</a>
              <a aria-disabled="false" class="nav-link" [routerLink]="['/serial-number-list']"
                 *ngIf="isSerialNumberSearchEnabled" title="Serial Number Search">Serial Number Search</a>
              <a aria-disabled="false" class="nav-link" [routerLink]="['/sku-number-list']"
                 *ngIf="isSkuSearchEnabled" title="Search SKU">Search SKU</a>
              <a aria-disabled="false" class="nav-link" [routerLink]="['/skuvalidation']"
                 *ngIf="isSkuValidationEnabled" title="SKU Validation">SKU Validation</a>
              <a aria-disabled="false" class="nav-link" [routerLink]="['/sapoutputtrigger']"
                 *ngIf="isSAPOutputTriggerEnabled" title="SAP Trigger Output">SAP Trigger Output</a>
              <a aria-disabled="false" class="nav-link" [routerLink]="['/entitytracking']"
                 *ngIf="isSAPOutputTriggerEnabled" title="Order Tracking">Order Tracking</a>
              <a aria-disabled="false" class="nav-link" [routerLink]="['/uatAvailability']"
                 *ngIf="isSAPOutputTriggerEnabled" title="UAT Availability">UAT Availability View</a>
            </li>
          </ul>
        </div>
      </li>
      <li id="mockers" class="nav-item" [ngClass]="currentAppName === 'Mockers' ? 'active' : ''" *ngIf="filterNavMenu('Mockers')">
        <a aria-describedby="mockers" class="nav-link" [routerLink]="['/mockers']" (click)="navigateMainApp('Mockers')" data-target="#Mockers"
           data-toggle="collapse" *ngIf="isEnabled" [queryParams]="{env: this.configService.selectedEnvironment}" title="Mockers">
          <span class="fa fa-window-restore"></span><span class="nav-label">
            Mockers
          </span>
        </a>
      </li>
      <li>
        <!--SUb menu-->
        <div id="Mockers" [hidden]="isCollapsed"
             class="collapse" *ngIf="currentAppName === 'Mockers'">
          <ul class="navbar-nav mr-auto">
            <li class="nav-item">
              <a class="nav-link" [routerLink]="['/shipment-mocker']"
                 *ngIf="isShipmentMockEnabled" title="Shipment Mocker">Shipment Mocker</a>
              <a class="nav-link" [routerLink]="['/loadplan-mocker']"
                 *ngIf="isLoadPlanMockEnabled" title="LoadPlan Mocker">LoadPlan Mocker</a>
              <a class="nav-link" [routerLink]="['/goodreceipt-mocker']"
                 *ngIf="isGoodreceiptMockEnabled" title="Goods Receipt Mocker">Goods Receipt Mocker &#9733;</a>
                 <a class="nav-link" [routerLink]="['/mockers-formly']"
                 *ngIf="isMockerV2Enabled" title="Mocker V2">Mocker V2</a>
            </li>
          </ul>
        </div>
      </li>
      <li id="library" class="nav-item" [ngClass]="currentAppName === 'Library' ? 'active' : ''" *ngIf="filterNavMenu('Library')">
        <a aria-describedby="library"  [routerLink]="['/library']" (click)="navigateMainApp('Library')" data-target="#Library"
           data-toggle="collapse" title="Library">
          <span class="pr-1 fa fa-book"></span><span class="nav-label">
            Library
          </span>
        </a>
      </li>
      <li>
        <!--SUb menu-->
        <div id="Library" class="collapse" [hidden]="isCollapsed" *ngIf="currentAppName === 'Library'" >
          <ul class="navbar-nav mr-auto">
            <li class="nav-item">
              <a [routerLink]="['/scenariocatalog']" *ngIf="isEnabled" title="Scenario Catalog">Scenario Catalog</a>
              <a [href]="swaggerUrl" target="_blank" title="API Reference">API Reference</a>
              <a [routerLink]="['/coming-soon']" *ngIf="isEnabled" title="How to">How to</a>
            </li>
          </ul>
        </div>
      </li>
      <li id="admin" [ngClass]="currentAppName === 'Admin' ? 'active' : ''" *ngIf="filterNavMenu('Admin')">
        <a aria-describedby="admin"  [routerLink]="['/admin']" (click)="navigateMainApp('Admin')" data-target="#Admin"
           data-toggle="collapse" *ngIf="isEnabled" title="Admin">
          <span class="fa fa-dashboard"></span><span class="nav-label">

            Admin

          </span>
        </a>
      </li>
      <li>
        <!--SUb menu-->
        <div id="Admin" class="collapse" [hidden]="isCollapsed" *ngIf="currentAppName === 'Admin'">
          <ul class="navbar-nav mr-auto">
            <li class="nav-item">
              <a [routerLink]="['/testrunoverview']" title="Test Run Overview">Test Run Overview</a>
            </li>
          </ul>
        </div>
      </li>
      <li id="feedback" [ngClass]="currentAppName === 'Support' ? 'active' : ''">
        <a aria-describedby="feedback" [routerLink]="[]" data-target="#Support"
           data-toggle="collapse" (click)="feedbackComponent.showFeedbackPopup()" title="Give Us Feedback">
          <span class="pr-1 fa fa-envelope"></span><span class="nav-label">

            Give Us Feedback

          </span>
        </a>
      </li>

      <!-- Commented below code for skipping Accessibility Manual assessment work.-->
      <!--<li id="legacy" class="nav-item" [ngClass]="currentAppName === 'Legacy' ? 'active' : ''">
    <a aria-describedby="legacy" class="nav-link" [routerLink]="" data-target="#Legacy"
       data-toggle="collapse" (click)="navigateMainApp('Legacy')" *ngIf="isOrderViewEnabled" title="Legacy">
      <span class="pr-1 fa fa-key"></span><span class="nav-label">

        Legacy

      </span>
    </a>
  </li>
  <li>-->
      <!--SUb menu-->
      <!--<div id="Legacy" class="collapse" [hidden]="isCollapsed" *ngIf="currentAppName === 'Legacy'">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/new-test-run']"
             [queryParams]="{env: this.configService.selectedEnvironment}" *ngIf="isOrderViewEnabled" title="Create Test Run">Create Test Run</a>
          <a class="nav-link" [routerLink]="['/testruns']"
             [queryParams]="{env: this.configService.selectedEnvironment}" *ngIf="isManageTestRunEnabled" title="Test Runs">Test Runs</a>
          <a class="nav-link" [routerLink]="['/orders']"
             [queryParams]="{env: this.configService.selectedEnvironment}" *ngIf="isOrderViewEnabled" title="Orders">Orders</a>
        </li>
      </ul>
    </div>
  </li>-->
      <li id="privacyNotices" class="nav-item" [ngClass]="currentAppName === 'Privacy Notice' ? 'active' : ''">
        <a aria-describedby="privacyNotices" class="nav-link" [routerLink]="['/privacynotice']" data-target="#privacyNotice"
           data-toggle="collapse" (click)="navigateMainApp('Privacy Notice')" *ngIf="isOrderViewEnabled" title="Privacy Notice">
          <span class="ms-Icon ms-Icon--AlertSolid"></span><span class="nav-label">
            Privacy Notice
          </span>
        </a>
      </li>
      <li>
        <!--SUb menu-->
        <div id="privacyNotice" class="collapse" [hidden]="isCollapsed" *ngIf="currentAppName === 'Privacy Notice'">
          <ul class="navbar-nav mr-auto">
            <li class="nav-item">
              <a target="_blank" href="https://privacy.microsoft.com/en-US/data-privacy-notice"
                 aria-label="Data Protection Notice">Data Protection Notice</a>
              <a target="_blank" href="https://privacy.microsoft.com/en-us/privacystatement"
                 aria-label="Privacy Statement">Privacy Statement</a>
            </li>
          </ul>
        </div>
      </li>
    </ul>
    <a class="btn-expand-collapse bg-dark" (click)="collapseNavbar()" [routerLink]="">
      <div *ngIf="!isCollapsed" title="Collapse Navigaiton Bar">
        <span class="fa fa-angle-double-left"></span>
      </div>
      <div *ngIf="isCollapsed" title="Open Navigaiton Bar">
        <span class="fa fa-angle-double-right"></span>
      </div>
    </a>
  </div>
</nav>
