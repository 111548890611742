import { Injectable } from '@angular/core';

@Injectable()
export class SampleFilesService {
  public static readonly CreateOrderSample = 'CreateOrderSample';
  public static readonly GenerateSNsSample = 'GenerateSNsSample';
  public static readonly DeliveryOrdersLoadModeSample = 'DeliveryOrdersLoadModeSample';
  public static readonly PurchaseOrdersLoadModeSample = 'PurchaseOrdersLoadModeSample';
  public static readonly SkuListSample = 'SkuListSample';

  public static readonly CareConsumerBulkUploadSample = 'CareConsumerBulkUploadSample';
  public static readonly DeliverBuildToStockBulkUploadSample = 'DeliverBuildToStockBulkUploadSample';
  public static readonly Delivery3PPOnboardingsBulkUploadSample = 'Delivery3PPOnboardingsBulkUploadSample';
  public static readonly DeliverAssembleToOrderBulkUploadSample = 'DeliverAssembleToOrderBulkUploadSample';
  public static readonly WTPDCToRSDBulkUploadSample = 'WTPDCToRSDBulkUploadSample';
  private static readonly sampleFilePath: any = {
    CreateOrderSample: './assets/CreateOrderSample.xlsx',
    GenerateSNsSample: './assets/GenerateSNsSample.xlsx',
    DeliveryOrdersLoadModeSample: './assets/DeliveryOrdersLoadModeSample.txt',
    PurchaseOrdersLoadModeSample: './assets/PurchaseOrdersLoadModeSample.txt',
    SkuListSample: './assets/ProductData.xlsx',
    CareConsumerBulkUploadSample: './assets/CareConsumerBulkUploadSample.xlsx',
    DeliverBuildToStockBulkUploadSample: './assets/DeliverBuildToStockBulkUploadSample.xlsx',
    Delivery3PPOnboardingsBulkUploadSample: './assets/Delivery3PPOnboardingsBulkUploadSample.xlsx',
    DeliverAssembleToOrderBulkUploadSample: './assets/DeliverAssembleToOrderBulkUploadSample.xlsx',
    WTPDCToRSDBulkUploadSample: './assets/WTPDCToRSDBulkUploadSample.xlsx',
  };

  public static downloadSampleFile(fileType: string) {
    if (!(fileType in SampleFilesService.sampleFilePath)) {
      console.log(`Nonexistent sample file: ${fileType}`);
      return;
    }

    const link = document.createElement('a');
    link.setAttribute('type', 'hidden');
    link.setAttribute('download', '');
    link.href = SampleFilesService.sampleFilePath[fileType];
    link.click();
  }
}
