export class LoadTestExecutionResponse {
  public status: string;
  public response: LoadTestDetail;
}

export class LoadTestDetail {
  public testRunId: string;
  public name: string;
  public vertical: string;
  public section: string;
  public orders: number;
  public createdBy: string;
  public createdAt: string;
  public stepSummary: stepSummary[];
}

export class stepSummary {
  public name: string;
  public stepIdentifier: string;
  public processed: number;
  public remaining: number;
  public mockDC: boolean;
  public mockFMV: boolean;
  public canPause: boolean;
  public isPaused: boolean;
  public status: string;
  public eventStartedAt: Date;
  public eventLastModifiedAt: Date;
}

export class InputsModel {
  constructor() {
    this.TestRunId = 0,
      this.LoadTestName = "havi",
      this.Vertical = '-1',
      this.Section = '-1',
      this.LoadSize = 25000,
      this.CreatedBy = "Maha Ramaswamy",
      this.CreatedAt = Date(); 
  }
  TestRunId: number;
  LoadTestName: string;
  Vertical: string;
  Section: string;
  LoadSize: number;
  ServiceLineNumber: number;
  CreatedBy: string;
  CreatedAt: string;
}
