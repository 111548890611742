import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MsalService } from '@azure/msal-angular';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormControl } from '@angular/forms';
import { LoadTestExecutionResponse, LoadTestDetail, stepSummary } from '../model/loadtesting.model';
import { LoadTestExecutionRequest } from '../model/loadtestingrequest.model';
import { LoadTestService } from '../service/loadtest.service';
import { ExecuteStepRequest, ExecuteStepResponse } from '../model/executestep.model';
import { ActivatedRoute } from '@angular/router';
import { ManageScenariosService } from '../service/manage-scenarios.service';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { VerifyRetryLoadTestComponent } from '../verifyretry-loadtest/verifyretry-loadtest.component';
import { GenerateTestResultsComponent } from '../generateTestResults/generateTestResults.component';
import { HttpClient } from '@angular/common/http';

import { ConfigService } from '../service/config.service';
import * as log from '../AppInsightsLogger';

import { ApplicationInsights } from '@microsoft/applicationinsights-web';

@Component({
  selector: 'app-loadtestexecution',
  templateUrl: './loadtestexecution.component.html',
  styleUrls: ['./loadtestexecution.component.css']
})
export class LoadTestExecutionComponent implements OnInit {

  name: string;
  LoadSize: number;
  CreatedAt: string;
  msgTxt: string;
  loadTestDetail: LoadTestDetail;
  status: string;
  loadResponse: Response;

  loadTestExectuionRequest: LoadTestExecutionRequest;
  loadTestExectuionResponse: LoadTestExecutionResponse;
  executeStepRequest: ExecuteStepRequest;
  executeStepResponse: ExecuteStepResponse;
  loadTestDetailResponse: LoadTestDetail;
  stepSummary: stepSummary[];
  loadTestRunId: string;
  loadTestName: string;
  gtrcontent: any;
  str1: string;
  private logger: log.AppInsightsLogger;

  constructor(public dialog: MatDialog,
    private route: ActivatedRoute,
    private ngxLoader: NgxUiLoaderService,
    private modalService: NgbModal,
    private msalService: MsalService,
    private loadTestService: LoadTestService,
    private manageTestRunsService: ManageScenariosService,
    private http: HttpClient,
    private configService: ConfigService) {
    this.manageTestRunsService.ngxLoaderConfig.text = '';
    this.name = this.msalService.instance.getActiveAccount().name;
    this.logger = new log.AppInsightsLogger(configService, msalService);
  }

  ngOnInit() {
    this.manageTestRunsService.ngxLoaderConfig.text = 'Loading Load Test Execution Details! Please wait!';
    this.ngxLoader.start();
    this.loadTestRunId = this.route.snapshot.params['loadTestRunId'];
    const parent = this;
    this.stepSummary = [];
    this.loadTestExectuionResponse = new LoadTestExecutionResponse();
    this.loadTestExectuionRequest = new LoadTestExecutionRequest();
    this.loadTestDetailResponse = new LoadTestDetail();
    this.loadTestExectuionRequest.LoadTestRunId = this.loadTestRunId;
    this.logger.trackTrace(`Landed to Load Test Execution Page`);

    this.loadTestService.getLoadTestExecution(this.loadTestExectuionRequest).subscribe((response) => {
      this.loadTestExectuionResponse = response;
      this.status = this.loadTestExectuionResponse.status;
      this.loadTestDetailResponse = this.loadTestExectuionResponse.response;
      this.loadTestName = this.loadTestExectuionResponse.response.name;
      this.CreatedAt = this.loadTestExectuionResponse.response.createdAt;
      this.name = this.loadTestExectuionResponse.response.createdBy;
      this.stepSummary = this.loadTestExectuionResponse.response.stepSummary;
      this.logger.trackTrace(`Load Test Execution Successfully for LoadTestName ${this.loadTestName} Status: ${this.status} LoadTestRunId: ${this.loadTestExectuionRequest.LoadTestRunId}`);
      this.ngxLoader.stop();
    }, (error: any) => {
        try {
          this.logger.trackTrace(`Load Test Execution ${error} LoadTestRunId: ${this.loadTestExectuionRequest.LoadTestRunId}`);
          this.logger.trackException(error);
        this.ngxLoader.stop();
      } catch (ex) {
          this.msgTxt = 'Some error occured while loading.';
          this.logger.trackTrace(`Load Test Execution ${error} ${this.msgTxt}`);
        this.ngxLoader.stop();
      }
    });
  }

  openDialog() {
    const dialogConfig = new MatDialogConfig();
    this.http.get('./assets/gtrcontent.txt', { responseType: 'text' })
      .subscribe((Response) => {
        var content = Response;
        var re = /testrunid/gi;
        var newstr = content.replace(re, this.loadTestRunId);
        this.gtrcontent = newstr.split('\n');
      dialogConfig.data = {
        id: this.loadTestRunId,
        title: this.gtrcontent,
    };
    const modalDialog = this.dialog.open(GenerateTestResultsComponent, dialogConfig);
    }, (error: any) => {
          this.msgTxt = 'Some error occured';
          this.logger.trackTrace(`Load Test Execution ${error} ${this.msgTxt}`);
          this.logger.trackException(this.msgTxt)
    }); 
  }

  openRetryDialog(stepIdentifier) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      id: this.loadTestRunId,
      stepSummary: this.stepSummary.filter(step => step.stepIdentifier === stepIdentifier)[0],
    };
    const dialogRef = this.dialog.open(VerifyRetryLoadTestComponent, dialogConfig);
    dialogRef.afterClosed().subscribe( data => {
      if(data === "CONFIRM"){
        this.start(stepIdentifier);
      }
    });
  }

  start(stepIdentifier) {
    this.manageTestRunsService.ngxLoaderConfig.text = 'Processing Step! Please wait!';
    this.ngxLoader.start();
    this.executeStepRequest = new ExecuteStepRequest();
    this.executeStepResponse = new ExecuteStepResponse();
    this.executeStepRequest.LoadTestRunId = this.loadTestRunId;
    this.executeStepRequest.StepIdentifier = stepIdentifier;
    this.loadTestService.getExecuteStep(this.executeStepRequest).subscribe((response) => {
      if(response.status === "FAIL"){
        this.msgTxt = 'Some error occured while loading.';
        this.logger.trackTrace(`Load Test Execution ${this.msgTxt} Status: ${response.status}, ${this.executeStepRequest.LoadTestRunId}`);
        this.ngxLoader.stop();
      }
      else {
        this.executeStepResponse = response;
        this.logger.trackTrace(`Load Test Execution ${this.msgTxt} Status: ${response.status}.  ${this.executeStepRequest.LoadTestRunId}`);
        this.ngxLoader.stop();
        location.reload();
      }
    }, (error: any) => {
        this.msgTxt = 'Some error occured while loading.';
        this.logger.trackTrace(`Load Test Execution ${error} ${this.msgTxt} ,  ${this.executeStepRequest.LoadTestRunId}`);
        this.logger.trackException(this.msgTxt+ "Load Test Execution")
      this.ngxLoader.stop();
    });
  }
}


